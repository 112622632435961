import React from 'react';
import PropType from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { Check } from '@mui/icons-material';
// import LockIcon from '@mui/icons-material/Lock';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Typography,
  Pagination,
  paginationClasses,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/api/http';
// import { createCompletedContent } from 'src/server/api/schoolCourse';

import EmptyContent from 'src/components/empty-content';

const ContentList = ({ sessionData, schoolCourseData, isStudent, page, setPage, total }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useGetToken(TOKEN_PREFIX);
  const { role } = useGetRoles();

  // const [isDescription, setIsDescription] = useState({});

  const checkUserLogged = () => {
    if (!token && role !== 'student') {
      enqueueSnackbar('To access the course material, kindly log in', {
        variant: 'info',
        autoHideDuration: 5000,
      });
      return false;
    }
    return true;
  };

  // const handleCompletedContent = (contentId) => {
  //   const credentials = {
  //     schoolCourseId: schoolCourseData?._id,
  //     state: {
  //       content: contentId,
  //     },
  //     dispatch,
  //   };

  //   const logged = checkUserLogged();
  //   if (logged) {
  //     dispatch(createCompletedContent(credentials));
  //   }
  // };

  // const handleCompletedStatus = (id) => {
  //   const isCompleted = schoolCourseData?.completed_contents?.some((item) => item?.content === id);
  //   return isCompleted;
  // };

  const handleContentViewClick = (id) => {
    const logged = checkUserLogged();
    if (logged) {
      navigate(paths.auth.course_content_view(id));
    }
  };

  return (
    <>
      <Typography fontSize={18} fontWeight={700} color="#212B36" pt="32px">
        Sessions
      </Typography>
      {sessionData?.total <= 0 && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      <Grid container spacing={1} mt="32px">
        {sessionData?.contents?.map((item, key) => (
          <Grid key={key} md={12}>
            {/* {!item?.is_locked && ( */}
            <Card sx={{ mr: '24px', mb: '24px', minHeight: '92px', width: '75%' }}>
              <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                {/* <Box
                    onClick={() => handleContentViewClick(item?._id)}
                    style={{
                      textDecoration: 'none',
                      color: '#212B36',
                      width: '70%',
                      cursor: 'pointer',
                    }}
                  > */}
                <Stack>
                  <Typography fontWeight={600} fontSize={16} display="flex" flexDirection="column">
                    {item?.title}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    onClick={() => handleContentViewClick(item?._id)}
                    state={{ schoolCourseId: schoolCourseData?._id }}
                    variant="outlined"
                    size="medium"
                    sx={{
                      border: '1px solid #919EAB52',
                      px: 1,
                      py: 0.5,
                      '&:hover': {
                        border: 'none',
                      },
                    }}
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      {((item?.pdf_url && item?.pdf_url?.length > 0) ||
                        (item?.training_pdf_url && item?.training_pdf_url > 0)) && (
                        <TextSnippetIcon fontSize="small" sx={{ color: '#795192' }} />
                      )}
                      {(item?.video_url || item?.video_url?.length > 0) && (
                        <PlayCircleIcon fontSize="small" sx={{ color: '#795192' }} />
                      )}
                      Go to lesson
                    </Stack>
                  </Button>
                </Stack>
                {/* </Box> */}
                {/* {handleCompletedStatus(item?._id) && (
                    <Typography
                      fontWeight={700}
                      fontSize={13}
                      color="#22C55E"
                      display="flex"
                      alignItems="center"
                    >
                      <Check sx={{ color: '#22C55E', mr: '8px', width: '18px', height: '18px' }} />
                      Completed
                      {isDescription[key] ? (
                        <Button
                          size="small"
                          onClick={() => setIsDescription({ ...isDescription, [key]: false })}
                        >
                          <ExpandLessIcon fontSize="large" color="disabled" />
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => setIsDescription({ ...isDescription, [key]: true })}
                        >
                          <NavigateNextIcon fontSize="large" color="disabled" />
                        </Button>
                      )}
                    </Typography>
                  )}
                  {!handleCompletedStatus(item?._id) && (
                    <Box display="flex" alignItems="center">
                      {!isStudent && (
                        <Button
                          size="small"
                          onClick={() => handleCompletedContent(item?._id)}
                          sx={{
                            border: '1px solid #919EAB52',
                            px: '8px',
                            py: '4px',
                            fontWeight: 500,
                            fontSize: '13px',
                            '&:hover': {
                              backgroundColor: '#00A76F29',
                              color: '#00A76F',
                              border: 'none',
                            },
                          }}
                        >
                          Mark as completed
                        </Button>
                      )}
                    </Box>
                  )} */}
              </Box>
              <Typography fontSize={13} fontFamily={400} color="#637381" px="24px" py="12px">
                {parse(item?.description)}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      {sessionData?.total > 10 && (
        <Pagination
          onChange={(e, value) => setPage(value)}
          count={total}
          defaultValue={page}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default ContentList;

ContentList.propTypes = {
  sessionData: PropType.any,
  schoolCourseData: PropType.any,
  isStudent: PropType.bool,
  page: PropType.any,
  setPage: PropType.any,
  total: PropType.any,
};
