import { createSlice } from '@reduxjs/toolkit';

import { getAllState, getAllDistrict } from '../api/address';

export const AddressSlice = createSlice({
  name: 'address',
  initialState: {
    loading: false,
    error: {},
    district: {},
    state: {},
    staffDropList: [],
    CountryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all district
    [getAllDistrict.pending]: (state) => {
      state.loading = true;
    },
    [getAllDistrict.fulfilled]: (state, action) => {
      state.loading = false;
      state.district = action.payload;
      state.error = {};
    },
    [getAllDistrict.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all state
    [getAllState.pending]: (state) => {
      state.loading = true;
    },
    [getAllState.fulfilled]: (state, action) => {
      state.loading = false;
      state.state = action.payload;
      state.error = {};
    },
    [getAllState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = AddressSlice.actions;

export default AddressSlice.reducer;
