import { useState } from 'react';
import PropType from 'prop-types';
import { pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@react-pdf-viewer/core/lib/styles/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

import { Stack, Container } from '@mui/material';

import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content/empty-content';

import Youtube from 'src/sections/common/youtube';

export default function TabContent({ activeTab }) {
  const { contentDetails } = useSelector((state) => ({
    contentDetails: state.content.contentDetails,
  }));

  const [numPages, setNumPages] = useState(null);
  console.log(numPages); // do not remove

  const onDocumentLoadSuccess = ({ Pages }) => {
    setNumPages(Pages);
  };

  const pdfUrl = contentDetails?.pdf_url?.length > 0 && contentDetails?.pdf_url[0]?.url;
  const trainingPdfUrl =
    contentDetails?.training_pdf_url?.length > 0 && contentDetails?.training_pdf_url[0]?.url;

  return (
    <Container maxWidth="xl">
      {!pdfUrl && !contentDetails?.video_url && !trainingPdfUrl && (
        <EmptyContent filled title="No Data" sx={{ py: 10 }} />
      )}
      <Stack container mt="30px">
        {activeTab === 'video' && <Youtube link={contentDetails?.video_url} />}
        {activeTab === 'document' && pdfUrl && (
          <Scrollbar sx={{ maxHeight: 600 }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={pdfUrl}
                defaultScale={SpecialZoomLevel.PageWidth}
                onDocumentLoad={onDocumentLoadSuccess}
              />
            </Worker>
          </Scrollbar>
          // <div className="my-pdf-reader">
          //   <Box display="flex" justifyContent="center" className='my-pdf-reader'>
          //     <iframe
          //       src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`}
          //       width="1200"
          //       height="580"
          //       title="pdf-viewer"
          //       className="my-pdf-reader-iframe"
          //       id="my-pdf-reader-iframe"
          //       frameBorder="0"
          //       style={{ background: 'transparent', backgroundColor: '#ffff' }}
          //     />
          //   </Box>
          // </div>
        )}
        {activeTab === 'training' && trainingPdfUrl && (
          <Scrollbar sx={{ maxHeight: 600 }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={trainingPdfUrl}
                defaultScale={SpecialZoomLevel.PageWidth}
                onDocumentLoad={onDocumentLoadSuccess}
              />
            </Worker>
          </Scrollbar>
          // <div className="my-pdf-reader">
          //   <Box display="flex" justifyContent="center">
          //     <iframe
          //       src={`https://docs.google.com/viewer?url=${trainingPdfUrl}&embedded=true`}
          //       width="1200"
          //       height="580"
          //       title="pdf-viewer"
          //       className="my-pdf-reader-iframe"
          //       id="my-pdf-reader-iframe"
          //       frameBorder="0"
          //       style={{ background: 'transparent', backgroundColor: '#ffff' }}
          //     />
          //   </Box>
          // </div>
        )}
      </Stack>
    </Container>
  );
}

TabContent.propTypes = {
  activeTab: PropType.any,
};
