import CourseListView from '../courses';
import AcademyHero from './academy-hero';
import AcademyBanner from './academy-banner';

export default function AcademyLandingView() {
  return (
    <>
      <AcademyHero />
      <AcademyBanner />
      <CourseListView />
    </>
  );
}
