/* eslint-disable import/order */
import { Box, Grid } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import { useDevice } from '../hooks/useResponsive';

function VideoPlayer({ videoId, height, width }) {
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  console.log(isMobile?.isMobile);

  const opts = {
    width: isMobile ? 'auto' : width || 720,
    height: isMobile ? '180px' : height || 405,
    playerVars: {
      autoplay: 5,
      modestbranding: 1,
      showinfo: 0,
      controls: 0,
      rel: 0,
      fs: 0,
      iv_load_policy: 3,
      disablekb: 1,
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      }}
    >
      <Grid className="react-player-web" sx={{ p: 0 }}>
        <YouTube
          videoId={videoId || 'nVGT15qSBMo'}
          className=" react-player-video-web"
          containerClassName=""
          opts={opts}
          onReady={onReady}
        />
      </Grid>
    </Box>
  );
}

export default VideoPlayer;

VideoPlayer.propTypes = {
  videoId: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};
