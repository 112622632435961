import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AcademyRegistrationSuccessDialog({ open, onClose }) {
  const router = useRouter();

  const { partnerDetails } = useSelector((state) => ({
    partnerDetails: state.partner.partnerDetails,
  }));

  return (
    <div>
      <Dialog keepMounted open={open} TransitionComponent={Transition} onClose={onClose}>
        <DialogTitle color="green" sx={{ mb: 0, pb: 0 }}>
          Your registration has been successfully completed
        </DialogTitle>

        <DialogTitle color="GrayText" variant="body2">
          <Typography variant="subtitle2" sx={{ color: green[700] }}>
            {partnerDetails?.setPasswordData?.message}
          </Typography>
          By using this link, you can create a password. Once you have both the email and password,
          you will be able to login to your dashboard.
        </DialogTitle>

        <DialogActions>
          <Button variant="outlined" onClick={() => router.push(paths.public.partner_login)}>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AcademyRegistrationSuccessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
