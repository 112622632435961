import PropType from 'prop-types';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, CardMedia, Typography } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';

import { getPublicCourseDetails } from 'src/server/api/courses';
import { getAcademyContentsByCourse } from 'src/server/api/content';

import DashboardLoading from 'src/components/loading/DashboardLoading';

import ContentList from 'src/sections/lms/courses/contentList';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AcademyCourseSingleView({ minimal }) {
  const dispatch = useDispatch();
  const params = useParams();
  const isWeb = useResponsive('up', 'sm');

  const { contents, loading, courseDetails } = useSelector((state) => ({
    courseDetails: state.Course.publicCourseDetails,
    contents: state.content.contents,
    loading: state.content.loading,
  }));

  const [pageContent, setPageContent] = useState(1);
  const totalContent = contents?.total;
  const totalClassCount = Math.ceil(totalContent / 10);

  useEffect(() => {
    const credentials = {
      page: pageContent,
      search: '',
      limit: 10,
      courseId: params?.id,
      dispatch,
    };
    dispatch(getAcademyContentsByCourse(credentials));
  }, [dispatch, pageContent, params?.id]);

  useEffect(() => {
    const credentials = {
      courseId: params?.id,
      dispatch,
    };
    dispatch(getPublicCourseDetails(credentials));
  }, [dispatch, params?.id]);

  return (
    <>
      {loading ? (
        <DashboardLoading />
      ) : (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
          {/* {!minimal && (
          <CustomBreadcrumbs
            heading="Course Details"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.root,
              },

              {
                name: 'Courses',
                href: paths.dashboard.course,
              },
              {
                name: 'Course View',
              },
            ]}
            action=""
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )} */}
          {isWeb ? (
            <Box display="flex" justifyContent="space-between" mt="14px">
              <Box>
                <Typography fontSize={24} fontWeight={700}>
                  {courseDetails?.title}
                </Typography>
                {/* <Label variant="soft" color="success">
                  {courseDetails?.completion_percentage}% Completed
                </Label> */}
                <Typography fontSize={16} fontWeight={500} color="#5119B7" pt="8px">
                  {contents?.total} Sessions{' '}
                </Typography>
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  color="#637381"
                  pt="8px"
                  width="95%"
                  textAlign="justify"
                >
                  {courseDetails?.description}
                </Typography>
              </Box>

              <CardMedia
                component="img"
                src={
                  (courseDetails &&
                    courseDetails?.images?.length > 0 &&
                    courseDetails?.images[0]?.url) ||
                  '/assets/images/lms/academy_bg.png'
                }
                sx={{ height: '180px', width: '200px', borderRadius: '8px' }}
              />
            </Box>
          ) : (
            <Box mt="14px">
              <Box>
                <CardMedia
                  component="img"
                  src={
                    (courseDetails &&
                      courseDetails?.images?.length > 0 &&
                      courseDetails?.images[0]?.url) ||
                    '/assets/images/lms/academy_bg.png'
                  }
                  sx={{ height: '220px', width: '100%', borderRadius: '8px' }}
                />
              </Box>
              <Box mt={2}>
                <Typography fontSize={24} fontWeight={700}>
                  {courseDetails?.title}
                </Typography>
                {/* <Label variant="soft" color="success">
                  {courseDetails?.completion_percentage}% Completed
                </Label> */}
                <Typography fontSize={16} fontWeight={500} color="#5119B7" pt="8px">
                  {contents?.total} Sessions{' '}
                </Typography>
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  color="#637381"
                  pt="8px"
                  width="95%"
                  textAlign="justify"
                >
                  {courseDetails?.description}
                </Typography>
              </Box>
            </Box>
          )}
          <ContentList
            isStudent
            sessionData={contents}
            schoolCourseData={courseDetails}
            page={pageContent}
            setPage={setPageContent}
            total={totalClassCount}
          />
        </Container>
      )}
    </>
  );
}

AcademyCourseSingleView.propTypes = {
  minimal: PropType.bool,
};
