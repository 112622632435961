import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CurrencySwitch } from 'src/hooks/use-currency';

import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function PartnerCheckInWidgets({ chart, ...other }) {
  const settings = useSettingsContext();
  const currency = CurrencySwitch();
  console.log(currency);
  // const smUp = useResponsive('up', 'sm');
  const {
    // colors = [
    //   [theme.palette.primary.light, theme.palette.primary.main],
    //   [theme.palette.warning.light, theme.palette.warning.main],
    // ],
    series,
    // options,
  } = chart;

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
      {series.map((item, index) => (
        <Card
          {...other}
          key={item.label}
          sx={{
            width: '100%',
            backgroundColor: settings.themeMode === 'light' ? item.background : '',
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'column' }}
            // divider={
            //   <Divider
            //     orientation={smUp ? 'vertical' : 'horizontal'}
            //     flexItem
            //     sx={{ borderStyle: 'dashed' }}
            //   />
            // }
          >
            <Stack
              spacing={3}
              sx={{
                py: 5,
                px: 2,
                width: 1,
                // px: { xs: 3, sm: 0 },
              }}
            >
              {/* <Stack justifyContent="center" alignItems="center">
              <Chart
                dir="ltr"
                type="radialBar"
                series={[item.percent]}
                options={index === 1 ? chartOptionsCheckout : chartOptionsCheckIn}
                width={106}
                height={106}
              />
              <Typography variant="body2" sx={{ opacity: 0.72 }}>
                Average {item.label1}
              </Typography>
            </Stack> */}
              <Stack direction="column" alignItems="center" justifyContent={{ sm: 'center' }}>
                <img
                  src={item.img}
                  alt="value"
                  style={{ width: '60px', height: '40px', objectFit: 'cover' }}
                />
                <Typography sx={{ mb: 0.5, fontSize: '32px', fontWeight: '700', color: '#A75A24' }}>
                  {/* {currency}  */}
                  {item.total}
                </Typography>
                <Typography sx={{ mb: 0.5, fontSize: '14px', fontWeight: '600', color: '#A5471E' }}>
                  {' '}
                  {item.label}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
}

PartnerCheckInWidgets.propTypes = {
  chart: PropTypes.object,
};
