import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createResource,
  deleteResource,
  getAllResources,
  getResourceDetails,
  updateResourceDetails,
} from '../api/resource';

export const resourceSlice = createSlice({
  name: 'resource',
  initialState: {
    loading: false,
    error: {},
    // staff
    resources: {},
    staffDropList: [],
    ResourceDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createResource.pending]: (state) => {
      state.loading = true;
    },
    [createResource.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.resource;
      const newResourcesList = jsonState?.countries;

      const modifiedResourceList = {
        ...jsonState,
        countries: Array.isArray(newResourcesList)
          ? [action.payload, ...newResourcesList]
          : [action.payload],
      };
      state.loading = false;
      state.ResourceDetails = action.payload;
      state.resources = modifiedResourceList;
      state.error = {};
    },
    [createResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all resource
    [getAllResources.pending]: (state) => {
      state.loading = true;
    },
    [getAllResources.fulfilled]: (state, action) => {
      state.loading = false;
      state.resources = action.payload;
      state.error = {};
    },
    [getAllResources.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getResourceDetails.pending]: (state) => {
      state.loading = true;
    },
    [getResourceDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResourceDetails = action.payload;
      state.error = {};
    },
    [getResourceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateResourceDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateResourceDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ResourceDetails = action.payload;
    //   state.error = {};
    // },
    [updateResourceDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.resources;
      const modifiedResourceList = {
        ...jsonState,
        resources: jsonState.resources?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.resource = modifiedResourceList;
      state.ResourceDetails = action.payload;
      state.resources = modifiedResourceList;
      state.error = {};
    },
    // [updateResourceDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.resource;
    //   console.log(jsonState)
    //   const modifiedResourceList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.ResourceDetails = action.payload;
    //   state.partnerType = modifiedResourceList;
    //   state.error = {};
    // },
    [updateResourceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteResource.pending]: (state) => {
      state.loading = true;
    },
    [deleteResource.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.resources;
      const modifiedResourceList = {
        ...jsonState,
        resources: jsonState.resources?.filter((resource) => resource._id !== action.payload),
      };
      state.loading = false;
      state.resources = modifiedResourceList;
      state.error = {};
    },
    [deleteResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = resourceSlice.actions;

export default resourceSlice.reducer;
