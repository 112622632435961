import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TabList, TabContext } from '@mui/lab';
import Container from '@mui/material/Container';
import { Box, Tab, Typography } from '@mui/material';

import { colors } from 'src/theme/colors';
import { getAllClasses } from 'src/server/api/class';
import { getLabModelList } from 'src/server/api/labModel';
import { getAllPublicCourses } from 'src/server/api/courses';

import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import LabModels from './lab-models';
import CourseList from './course-list';

export default function CourseListView() {
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const { courseList, loading, labModelList, classList } = useSelector((state) => ({
    loading: state.Course.loading,
    courseList: state.Course.course,
    labModelList: state.labModel.labModel,
    classList: state.class.classes,
  }));
  const [selectedLabModel, setSelectedLabModel] = useState({
    title: 'All',
    _id: 1,
  });
  const [currentClass, setCurrentClass] = useState(1);
  const [coursePage, setCoursePage] = useState(1);

  const handleChange = (event, newValue) => {
    console.log('data :', newValue);
    setCurrentClass(newValue);
  };

  const notFound = courseList?.total <= 0;
  useEffect(() => {
    const credentials = {
      page: coursePage,
      limit: 8,
      search: '',
      dispatch,
      labModel: selectedLabModel?._id !== 1 ? selectedLabModel?._id : '',
      currentClass: currentClass !== 1 ? currentClass : '',
    };
    dispatch(getAllPublicCourses(credentials));
  }, [coursePage, currentClass, dispatch, selectedLabModel?._id]);

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: '',
      dispatch,
    };
    dispatch(getLabModelList(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: '',
      dispatch,
      labModel: '',
    };
    dispatch(getAllClasses(credentials));
  }, [dispatch]);

  const classes = classList?.classes || [];

  const modifiedClassList = [
    {
      title: 'All',
      _id: 1,
    },
    ...classes,
  ];

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 5, mt: 5 }}>
      <LabModels
        list={labModelList?.labModels || []}
        selectedLabModel={selectedLabModel}
        setSelectedLabModel={setSelectedLabModel}
      />
      <TabContext value={currentClass}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {modifiedClassList?.map((item, key) => (
              <Tab label={item?.title} value={item?._id} key={key} />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <Typography variant="h5" color={colors.stem_blue} my={2}>
        All
      </Typography>

      {notFound && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      {loading ? (
        <DashboardLoading />
      ) : (
        <CourseList
          page={coursePage}
          setPage={setCoursePage}
          total={courseList?.total}
          course={courseList?.courses}
        />
      )}
    </Container>
  );
}
