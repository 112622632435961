import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const registerPartner = createAsyncThunk(
  'partner/register',
  async ({ state, reset, enqueueSnackbar, openDialog }) => {
    try {
      const URL = '/partner/register';
      const response = await post(URL, state);
      if (response) {
        enqueueSnackbar('Partner Registration Form Submitted Successfully!');
        reset();
        openDialog();
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const registerPartnerV2 = createAsyncThunk(
  'partner/register/v2',
  async ({ state, reset, dispatch, router }) => {
    try {
      const URL = '/partner/v2/profile/new';
      const response = await post(URL, state);
      if (response) {
        if (reset) {
          reset();
        }
        if (router) {
          router.push(paths.public.partner_login);
        }
        dispatch(activeSnack({ type: 'success', message: 'Partner Registered Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const emailVerifyPartner = createAsyncThunk(
  'partner/register',
  async ({ state, dispatch, handleOtpCode }) => {
    try {
      const URL = `/partner/v2/register`;
      const response = await post(URL, state);
      if (response?.user) {
        if (handleOtpCode) {
          handleOtpCode();
        }
        dispatch(activeSnack({ type: 'success', message: response?.message }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createPartner = createAsyncThunk(
  'partner/create',
  async ({ state, dispatch, handleClose, handleClear, identifier }) => {
    try {
      const URL = `/partner/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Partner created successfully' }));
        dispatch(
          getAllPartner({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem(`rowsPerPage_admin-partner`),
            sort: '',
            type: '',
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllPartner = createAsyncThunk(
  'partner/list',
  async ({ page, search, dispatch, limit, type, partner_type, country, partnerType }) => {
    try {
      const URL = page
        ? `/partner/admin/all?page=${page || 1}&search=${search || ''}&limit=${
            limit || 5
          }&type=${type || ''}&partner_type=${partner_type || ''}&country=${country || ''}`
        : `/partner/admin/all?search=${search || ''}&limit=${limit || 5}`;

      // const SUB_PARTNER_URL = `/partner/admin/sub-partner/list?page=${page && page}&search=${
      //   search && search
      // }&limit=${limit || 5}&type=${type || ''}&partner_type=${partner_type || ''}&country=${
      //   country || ''
      // }`;

      const response = await get(URL);
      // console.log(response);
      // if (response) {
      //   return {
      //     // response,
      //     partners: response?.partners,
      //     total: response?.total,
      //     page: response?.page,
      //   };
      // }
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllDirectPartner = createAsyncThunk(
  'partner/direct/list',
  async ({ page, search, dispatch, limit, type, partner_type, country, partnerType }) => {
    try {
      const URL = page
        ? `/partner/direct/all?page=${page || 1}&search=${search || ''}&limit=${
            limit || 5
          }&type=${type || ''}&partner_type=${partner_type || ''}&country=${country || ''}`
        : `/partner/admin/all?search=${search || ''}&limit=${limit || 5}`;

      // const SUB_PARTNER_URL = `/partner/admin/sub-partner/list?page=${page && page}&search=${
      //   search && search
      // }&limit=${limit || 5}&type=${type || ''}&partner_type=${partner_type || ''}&country=${
      //   country || ''
      // }`;

      const response = await get(URL);
      // console.log(response);
      // if (response) {
      //   return {
      //     // response,
      //     partners: response?.partners,
      //     total: response?.total,
      //     page: response?.page,
      //   };
      // }
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerDetails = createAsyncThunk(
  'partner/single',
  async ({ partnerId, dispatch }) => {
    try {
      const response = await get(`/partner/admin/${partnerId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePartnerDetails = createAsyncThunk(
  'partner/update',
  async ({ state, partnerId, dispatch, navigate, tabChange }) => {
    try {
      const URL = `/partner/admin/${partnerId}`;

      const response = await put(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'partner updated Successfully' }));
        if (navigate) {
          navigate();
        }
        if (tabChange) {
          tabChange();
        }

        dispatch(
          getPartnerDetails({
            partnerId,
            dispatch,
          })
        );
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePartner = createAsyncThunk(
  'Partner/delete',
  async ({ partnerId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/partner/admin/${partnerId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Partner  deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// partner profile
export const getPartnerProfile = createAsyncThunk(
  'partner/profile',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get(`/partner`);
      if (response?.findPartner?._id) {
        useSetEncryptLocalData('partnerType', response?.findPartner?.type);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updatePartnerProfile = createAsyncThunk(
  'partner/profile',
  async ({ state, dispatch, enqueueSnackbar }) => {
    try {
      const response = await put(`/partner`, state);
      if (response) {
        const cred = {
          enqueueSnackbar,
        };
        dispatch(getPartnerProfile(cred));

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'Profile updated successfully' }));
    } catch (error) {
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    }
  }
);
// PartnerShool all list
export const getAllPartnerSchool = createAsyncThunk(
  'partner-school/list',
  async ({ page, search, date, limit, partner, lab_model, dispatch }) => {
    try {
      const response = await get(
        `/school/partner/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&partner=${partner || ''}&lab_model=${lab_model || ''}&dateFrom=${date.from}&dateTo=${
          date.to
        }`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSubPartnerSchool = createAsyncThunk(
  'sub-partner-school/list',
  async ({ page, search, date, limit, partner, lab_model, dispatch }) => {
    // console.log('sub partner school api called ');

    try {
      const response = await get(
        `school/v2/sub-partner/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&partner=${partner || ''}&lab_model=${lab_model || ''}&dateFrom=${date.from}&dateTo=${
          date.to
        }`
      );
      // console.log(response);
      if (response) {
        // console.log('response of sub partner schools ',response);

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
// partner school single view
export const getPartnerSchoolDetails = createAsyncThunk(
  'Partner/single',
  async ({ partnerSchoolId, dispatch }) => {
    try {
      const response = await get(`/school/partner/${partnerSchoolId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
