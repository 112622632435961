import PropType from 'prop-types';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Paper, Container, CardMedia, Typography, CircularProgress } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';

import { getAllContents } from 'src/server/api/content';
import { getSchoolCourseDetails } from 'src/server/api/schoolCourse';

import Label from 'src/components/label';

import SessionsList from 'src/sections/schoolCourse/sessionsList';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CourseSingleView({ minimal }) {
  const dispatch = useDispatch();
  const params = useParams();
  const isWeb = useResponsive('up', 'sm');

  const { schoolCourseDetails, contents, schoolUserProfile, sessionLoading } = useSelector(
    (state) => ({
      schoolCourseDetails: state.schoolCourse.schoolCourseDetails,
      contents: state.content.contents,
      schoolUserProfile: state.schoolUser.schoolUserProfile?.school,
      sessionLoading: state.content.loading,
    })
  );

  const courseData = schoolCourseDetails?.school_class?.class?.course;

  const [pageContent, setPageContent] = useState(1);
  const totalContent = contents?.total;
  const totalClassCount = Math.ceil(totalContent / 10);

  useEffect(() => {
    if (schoolCourseDetails && (courseData?._id || schoolCourseDetails?.course?._id)) {
      const credentials = {
        page: pageContent,
        search: '',
        limit: 10,
        courseId: courseData?._id || schoolCourseDetails?.course?._id,
        dispatch,
      };
      dispatch(getAllContents(credentials));
    }
  }, [dispatch, pageContent, schoolCourseDetails, courseData]);

  useEffect(() => {
    const credentials = {
      schoolCourseId: params?.id,
      dispatch,
    };
    dispatch(getSchoolCourseDetails(credentials));
  }, [dispatch, params?.id]);

  const completionPercentage = schoolCourseDetails?.completion_percentage;

  return (
    <>
      {/* <Container maxWidth={settings.themeStretch ? false : 'lg'}> */}
      <Container maxWidth="xl">
        {/* {!minimal && (
          <CustomBreadcrumbs
            heading="Course Details"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.root,
              },

              {
                name: 'Courses',
                href: paths.dashboard.course,
              },
              {
                name: 'Course View',
              },
            ]}
            action=""
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )} */}
        {isWeb ? (
          <Box display="flex" justifyContent="space-between" mt="14px">
            <Box>
              <Typography fontSize={24} fontWeight={700}>
                {courseData?.title || schoolCourseDetails?.title}
              </Typography>
              <Label variant="soft" color={completionPercentage === 0 ? 'warning' : 'success'}>
                {/* {`${completionPercentage === 0}
                  ? 'Not Started'
                  : ${completionPercentage?.toFixed(2)} % Completed`} */}
                {completionPercentage === 0
                  ? 'Not Started !'
                  : `${completionPercentage?.toFixed(2)} % Completed`}
              </Label>
              <Typography fontSize={16} fontWeight={500} color="#5119B7" pt="8px">
                {contents?.total} Sessions{' '}
              </Typography>
              <Typography fontSize={15} fontWeight={400} pt="8px" width="95%" textAlign="justify">
                {schoolCourseDetails?.description}
              </Typography>
            </Box>
            {courseData && courseData?.images[0] && (
              <CardMedia
                component="img"
                src={courseData?.images[0]?.url}
                sx={{ height: '180px', width: '200px', borderRadius: '8px' }}
              />
            )}
          </Box>
        ) : (
          <Box mt="14px">
            <Box>
              {courseData && courseData?.images[0] && (
                <CardMedia
                  component="img"
                  src={courseData?.images[0]?.url}
                  sx={{ height: '220px', width: '100%', borderRadius: '8px' }}
                />
              )}
            </Box>
            <Box mt={2}>
              <Typography fontSize={24} fontWeight={700}>
                {courseData?.title}
              </Typography>

              <Label variant="soft" color="success">
                {schoolCourseDetails?.completion_percentage?.toFixed(2)}% Completed
              </Label>
              <Typography fontSize={16} fontWeight={500} color="#5119B7" pt="8px">
                {contents?.total} Sessions{' '}
              </Typography>
              <Typography
                fontSize={15}
                fontWeight={400}
                color="#637381"
                pt="8px"
                width="95%"
                textAlign="justify"
              >
                {schoolCourseDetails?.description}
              </Typography>
            </Box>
          </Box>
        )}
        {sessionLoading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="warning" />
          </Paper>
        ) : (
          <SessionsList
            sessionData={contents}
            schoolCourseData={schoolCourseDetails}
            page={pageContent}
            setPage={setPageContent}
            total={totalClassCount}
            schoolProfile={schoolUserProfile}
          />
        )}
      </Container>
    </>
  );
}

CourseSingleView.propTypes = {
  minimal: PropType.bool,
};
