import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Stack, Container, CircularProgress } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import useResponsive from 'src/hooks/useResponsive';

import { getClasses } from 'src/server/api/class';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllSessions } from 'src/server/api/session';
import { getAllLabModel } from 'src/server/api/labModel';
import { updateSchoolDetails } from 'src/server/api/school';
import { getSchoolUserProfile } from 'src/server/api/schoolUser';
import { createSchoolCourse } from 'src/server/api/schoolCourse';
import { createSchoolClass, getAllSchoolClass } from 'src/server/api/schoolClass';
import { createSchoolDivision, getAllSchoolDivision } from 'src/server/api/schoolDivision';

import Logo from 'src/components/logo';
import OnBoardingStepper from 'src/components/stepper/stepper';

import OnBoardingContent from 'src/sections/app/school/OnBoardingContent';

export default function OnBoarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'md');

  const steps = ['Lab Model ', 'Classes', 'Division', 'Finish'];

  const {
    labModel,
    schoolUserProfile,
    classes,
    schoolClassList,
    sessions,
    schoolDivision,
    loading,
    labLoading,
    classLoading,
    divisionLoading,
  } = useSelector((state) => ({
    labModel: state.labModel.labModel,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
    classes: state.class.classes,
    schoolClassList: state.schoolClass.schoolClasses,
    sessions: state.session.sessions,
    schoolDivision: state.schoolDivision.schoolDivisions,
    loading: state.schoolCourse.loading,
    labLoading: state.labModel.loading,
    classLoading: state.schoolClass.loading,
    divisionLoading: state.schoolDivision.loading,
  }));

  const schoolId = schoolUserProfile?.school?._id;
  const [selectedLabModel, setSelectedLabModel] = useState({
    title: '',
    id: '',
    defaultSession: {},
  });
  const [selectedSession, setSelectedSession] = useState({ count: 0, title: '', id: '' });
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [schoolClassDivisions, setSchoolClassDivisions] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  if (!selectedLabModel?.defaultSession?._id) {
    steps.splice(1, 0, 'Session');
  }

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    if (activeStep === 0 && selectedLabModel?.defaultSession?._id) {
      setActiveStep(2);
      setSelectedSession({
        id: selectedLabModel?.defaultSession?._id,
        count: selectedLabModel?.defaultSession?.count,
        title: selectedLabModel?.defaultSession?.title,
      });
    }
    if (activeStep === 3) {
      setActiveStep(4);
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleNextButton = () => {
    if (activeStep === 0 && selectedLabModel?.id) {
      const schoolCrud = {
        state: {
          lab_model: selectedLabModel?.id,
        },
        schoolId: schoolUserProfile?.school?._id,
        dispatch,
        handleNext,
      };
      dispatch(updateSchoolDetails(schoolCrud));
      setSelectedSession({
        id: selectedLabModel?.defaultSession?._id,
        count: selectedLabModel?.defaultSession?.count,
        title: selectedLabModel?.defaultSession?.title,
      });
    }

    if (activeStep === 1 && selectedSession) {
      handleNext();
    }

    if (activeStep === 2 && selectedClasses?.length > 0) {
      const schoolCrud = {
        state: {
          school_classes: selectedClasses,
        },
        dispatch,
      };
      dispatch(createSchoolClass(schoolCrud));
      handleNext();
    }

    if (activeStep === 3) {
      if (schoolClassDivisions?.length > 0) {
        const schoolCrud = {
          state: {
            school_divisions: schoolClassDivisions,
          },
          dispatch,
        };
        dispatch(createSchoolDivision(schoolCrud));
      }
      handleNext();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 2 && selectedLabModel?.defaultSession?._id) {
      setActiveStep(0);
    }
    if (activeStep === 3) {
      setActiveStep(2);
    }
    setCompleted({});
  };

  useEffect(() => {
    dispatch(getSchoolUserProfile({ dispatch }));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 10,
      school: '',
      dispatch,
    };
    dispatch(getAllLabModel(credentials));
  }, [dispatch]);

  useEffect(() => {
    if (activeStep === 1 && !selectedLabModel?.defaultSession?._id) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        dispatch,
      };
      dispatch(getAllSessions(credentials));
    }
  }, [activeStep, dispatch, selectedLabModel]);

  useEffect(() => {
    if (activeStep === 2) {
      const credentials = {
        page: 1,
        search: '',
        limit: 20,
        labModel: selectedLabModel?.id,
        session: selectedSession?.id,
        dispatch,
      };
      dispatch(getClasses(credentials));
    }
  }, [activeStep, dispatch, selectedLabModel, selectedSession]);

  useEffect(() => {
    if (activeStep === 2) {
      const credentials = {
        page: 1,
        dispatch,
        limit: 20,
        school: schoolId,
      };
      dispatch(getAllSchoolClass(credentials));
    }
  }, [activeStep, dispatch, schoolId]);

  useEffect(() => {
    if (activeStep === 3) {
      const credentials = {
        page: 1,
        dispatch,
        data: 'full',
        school: schoolId,
      };
      dispatch(getAllSchoolDivision(credentials));
    }
  }, [activeStep, dispatch, schoolId]);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    dispatch(createSchoolCourse({ dispatch, navigate }));
  };

  const CustomNextButton = styled(Button)((props) => ({
    // eslint-disable-next-line react/prop-types
    backgroundColor: props.disabled ? '#9C9C9C' : '#007867',
    color: 'white',
    borderRadius: '50px',
    paddingLeft: '20px',
    // marginBottom: '50px',
    '&:hover': {
      background: '#00A76F',
      color: 'white',
    },
  }));

  const onBoardingData = {
    activeStep,
    setSelectedLabModel,
    selectedLabModel,
    setSelectedClasses,
    selectedClasses,
    setSchoolClassDivisions,
    schoolClassDivisions,
    setSelectedSession,
    selectedSession,
    labModel,
    classes,
    schoolClassList,
    sessions,
    schoolDivision,
    schoolId,
  };

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="OnBoarding" />
      <Stack ml={5} my="20px">
        <Logo />
      </Stack>
      <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
        <Box sx={{ width: '100%' }} mt={5}>
          <OnBoardingStepper activeStep={activeStep} steps={steps} />
          <Stack>
            <OnBoardingContent onBoardingData={onBoardingData} />
            <Box
              justifyContent={activeStep === 0 ? 'end' : 'space-between'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                pt: 2,
                position: isMobile && 'fixed',
                bottom: isMobile && '0px',
                right: isMobile && '0px',
                // width: isMobile && '100%',
                backgroundColor: isMobile && '#f3f3f3',
                left: isMobile && '0px',
                paddingBottom: isMobile && '30px',
                paddingTop: '30px',
                paddingRight: '20px',
                paddingLeft: '20px',
                alignItems: isMobile && 'center',
                marginBottom: '50px',
              }}
            >
              {activeStep !== 0 && (
                <Stack>
                  <Button
                    color="inherit"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    disabled={activeStep === 2 && schoolClassList?.schoolClasses?.length > 0}
                  >
                    <KeyboardArrowLeftIcon fontSize="medium" />
                    Previous
                  </Button>
                </Stack>
              )}
              {/* <Box sx={{ flex: '1 1 auto' }} /> */}
              {activeStep !== 4 && (
                <Stack>
                  <CustomNextButton
                    className="next-button"
                    onClick={handleNextButton}
                    disabled={
                      (activeStep === 0 && !selectedLabModel?.id) ||
                      (activeStep === 1 && !selectedSession?.id) ||
                      (activeStep === 2 && selectedClasses?.length <= 0) ||
                      // (activeStep === 3 && schoolClassDivisions?.length <= 0) ||
                      activeStep === 4
                    }
                  >
                    Next
                    {loading || labLoading || classLoading || divisionLoading ? (
                      <CircularProgress color="inherit" size={17} sx={{ ml: 1 }} />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </CustomNextButton>
                </Stack>
              )}
              {activeStep === 4 && (
                <CustomNextButton type="submit" sx={{ mr: 1, pr: 2 }}>
                  {loading ? <CircularProgress color="inherit" size={17} sx={{ mr: 1 }} /> : null}
                  Finish Setup
                </CustomNextButton>
              )}
            </Box>
          </Stack>
        </Box>
      </form>
    </Container>
  );
}
