export const colors = {
  primary: '#B63422',
  secondary: '#CF7761',
  secondary_1: '#637381',
  white: '#ffffff',
  black: '#000000',
  light: '#E4AE92',
  exLight: '#FEF5CE',
  green: '#418e8c',
  stem_blue: '#1A447B',
  stem_orange: '#F1A24A',
  stem_green: '#00A76F',
};
