import React from 'react';

import { Stack, Container, Typography } from '@mui/material';

function Privacy() {
  return (
    <Stack>
      <Container sx={{ color: '#A9A9A9', py: 5 }}>
        <Typography variant="h3">Privacy Policy:</Typography>
        <Typography variant="h6">
          This Privacy Policy (“Policy”) outlines how STEM-Xpert, Techgenius Innovations Pvt Ltd
          (“STEM-Xpert,” “we,” “us,” or “our”), collects, uses, discloses, and safeguards personal
          information obtained through our website located at www.stem-xpert.com (“Website”). By
          using our Website, you consent to the practices described in this Policy.
        </Typography>
        <br />
        <Typography>
          <Typography variant="h5"> 1. Information Collection </Typography>
          We may collect the following types of personal information from you when you use our
          Website:
          <br />
          a. Contact Information: Such as your name, email address, phone number, institution, class
          group, and postal address.
          <br />
          b. Account Information: If you create an account with us, we may collect your user name
          and other related information.
          <br />
          c. Payment Information: If you make purchases through our Website, we may collect
          payment-related information, such as credit card details and billing address.
          <br />
          d. Usage Data: We may collect information about how you interact with our Website,
          including your IP address, browser type, operating system, and browsing behavior.
          <br />
          e. Communication Data: We may collect information about your communication preferences and
          interactions with us, including emails and other correspondence.
          <br />
        </Typography>
        <br />
        <Typography>
          <Typography variant="h5"> 2. Use of Information </Typography>
          We use the collected personal information for various purposes, including but not limited
          to:
          <br />
          a. Providing Services: To provide you with information and updates on our products, and
          services.
          <br />
          b. Communication: To respond to your inquiries, provide customer support, and send you
          important notices.
          <br />
          c. Marketing: To send you promotional materials and updates about our products, services,
          and events, with your consent.
          <br />
          d. Analytics: To analyze website usage, improve our content and services, and enhance user
          experience.
          <br />
          e. Compliance: To comply with legal obligations and enforce our terms of use.
          <br />
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 3. Disclosure of Information</Typography>
          We may share your personal information with third parties under the following
          circumstances:
          <br />
          a. Service Providers: We may share information with third-party service providers who
          assist us in delivering our services.
          <br />
          b. Legal Compliance: We may disclose information if required by law, legal process, or
          government request.
          <br />
          c. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion
          of our assets, your information may be transferred.
          <br />
          d. Consent: With your consent, we may share your information for other purposes.
          <br />
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 4. Security </Typography>
          We are committed to ensuring that your information is secure. We implement reasonable
          security measures to protect your personal information from unauthorized access and
          misuse.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 5. Your Choices </Typography>
          You can exercise certain rights regarding your personal information, including accessing,
          correcting, or deleting your information. You can also opt out of receiving marketing
          communications.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 6. Contact Us </Typography>
          If you have any questions about this Policy or wish to exercise your rights, you can
          contact us at Email: &nbsp;
          <a
            href="mailto:contact@stem-xpert.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'white' }}
          >
            contact@stem-xpert.com
          </a>
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 7. Updates to this Policy </Typography>
          We may update this Policy from time to time. The most current version will be posted on
          our Website.
          <br />
          Please review this Privacy Policy carefully. By using our Website, you agree to the terms
          outlined above.
          <br />
          If you believe that any information we are holding on you is incorrect or incomplete,
          please write to or email us as soon as possible, at the above address. We will promptly
          correct any information found to be incorrect.
        </Typography>
        <br />
      </Container>
    </Stack>
  );
}

export default Privacy;
