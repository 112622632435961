import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Badge from '@mui/icons-material/Badge';
import Grid from '@mui/material/Unstable_Grid2';
import PhoneIcon from '@mui/icons-material/Phone';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { updateStudentProfile } from 'src/server/api/auth';
import { updatePartnerProfile } from 'src/server/api/partner';
import { updateSchoolUserProfile } from 'src/server/api/schoolUser';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { role } = useGetRoles();
  const { loading, schoolUserProfile, partnerProfile, studentProfile } = useSelector((state) => ({
    partnerProfile: state.partner.profile?.findPartner,
    loading: state.auth.loading,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
    studentProfile: state.auth.profile,
  }));

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string().required('Phone required'),
  });

  const [selectedCode, setSelectedCode] = useState('91');

  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };

  const handleProfileData = () => {
    if (role === 'partner') {
      return {
        name: partnerProfile?.name,
        email: partnerProfile?.email,
        phone: partnerProfile?.phone,
        countryCode: studentProfile?.country_code || '91',
      };
    }
    if (role === 'school_user') {
      return {
        name: schoolUserProfile?.name,
        email: schoolUserProfile?.email,
        phone: schoolUserProfile?.phone,
        countryCode: studentProfile?.country_code || '91',
      };
    }
    if (role === 'student') {
      return {
        name: studentProfile?.name,
        email: studentProfile?.email,
        phone: studentProfile?.phone,
        countryCode: studentProfile?.country_code || '91',
      };
    }
    return '';
  };

  const name = handleProfileData()?.name || '';
  const email = handleProfileData()?.email || '';
  const phone =
    `+${handleProfileData()?.countryCode}${handleProfileData()?.phone?.replace(/\s+/g, '')}` || '';

  const defaultValues = {
    name,
    email,
    phone,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,
        country_code: selectedCode,
      },
      dispatch,
      enqueueSnackbar,
    };

    const studentCredentials = {
      state: {
        ...data,
        phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,
        country_code: selectedCode,
        grade: studentProfile?.grade,
      },
      dispatch,
      enqueueSnackbar,
    };

    if (role === 'partner') {
      dispatch(updatePartnerProfile(credentials));
    } else if (role === 'student') {
      dispatch(updateStudentProfile(studentCredentials));
    } else {
      dispatch(updateSchoolUserProfile(credentials));
    }
  });

  useEffect(() => {
    reset({
      name,
      email,
      phone,
    });
  }, [reset, name, email, phone]);

  const phoneNumber = partnerProfile?.head_partner?.country_code
    ? `+${partnerProfile?.head_partner?.country_code} ${partnerProfile?.head_partner?.phone}`
    : partnerProfile?.head_partner?.phone;

  const headPartnerDetails = [
    {
      label: 'Partner ID',
      value: partnerProfile?.head_partner?.partner_code || '',
      icon: <Badge sx={{ color: '#808080' }} />,
    },
    {
      label: 'Phone',
      value: phoneNumber || '',
      icon: <PhoneIcon sx={{ color: '#808080' }} />,
    },
    {
      label: 'Email',
      value: partnerProfile?.head_partner?.email || '',
      icon: <MailOutlineIcon sx={{ color: '#808080' }} />,
    },
  ];

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3} mb={5} alignItems="stretch">
        {/* Left side - Form Section */}
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label="Name" />
              {/* <RHFTextField name="phone" label="Phone Number" /> */}
              <RHFPhoneInput
                name="phone"
                label="Phone"
                placeholder="Enter Phone"
                setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
              />
              <RHFTextField disabled name="email" label="Email Address" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 'auto' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting || loading}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>

        {role === 'partner' && partnerProfile?.head_partner && (
          <Grid xs={12} md={4}>
            <Card
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                Head Partner Information
              </Typography>

              <Stack direction="row" alignItems="center" spacing={2} mb={1}>
                <PersonIcon
                  sx={{
                    fontSize: 40,
                    color: '#808080',
                    background: '#A9A9A9',
                    borderRadius: '50%',
                    paddingTop: '10px',
                  }}
                />
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {partnerProfile?.head_partner?.name}
                  </Typography>
                  <Typography variant="body2" fontWeight="medium">
                    {partnerProfile?.head_partner?.partner_type?.title || ''}
                  </Typography>
                </Box>
              </Stack>

              {headPartnerDetails.map(({ label, value, icon }) => (
                <Stack key={label} direction="row" alignItems="center" spacing={2} mb={1}>
                  <IconButton
                    sx={{
                      border: '1px solid #F1F1F1',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  >
                    {icon}
                  </IconButton>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      {label}
                    </Typography>
                    <Typography variant="body2" fontWeight="medium">
                      {value || '---'}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Card>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}
