import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Grid, Card, Link, Stack, Button, Container, Typography } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BrandImages from 'src/sections/landingPage/segment/BrandImages';
import EnquiryForm from 'src/sections/landingPage/segment/enquiryForm';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

// import VideoPlayer from '../segment/@common/Video/VideoPlayer';

export default function CollagePage() {
  const { isMobile } = useDevice();
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  const curriculumImages = [
    { img: '/assets/imagesWeb/collage/Curriculum/python.png', title: 'Python' },
    { img: '/assets/imagesWeb/collage/Curriculum/coding.png', title: 'Coding' },
    { img: '/assets/imagesWeb/collage/Curriculum/3d.png', title: `3D Designing & Printing` },
    { img: '/assets/imagesWeb/collage/Curriculum/mobile.png', title: 'Internet Of Things(IoT)' },
    {
      img: '/assets/imagesWeb/collage/Curriculum/electronic.png',
      title: 'Artificial Intelligence & Machine Learning',
    },
    { img: '/assets/imagesWeb/collage/Curriculum/mechine.png', title: 'Robotics' },
    { img: '/assets/imagesWeb/collage/Curriculum/pcb.png', title: 'PCB Designing & Printing' },
    { img: '/assets/imagesWeb/collage/Curriculum/arduino.png', title: 'Arduino Programming' },
    { img: '/assets/imagesWeb/collage/Curriculum/electronicss.png', title: 'Electronics' },
  ];
  const featuresBoxes = [
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-1.png',
      title: 'Focus on Practical approach over theory for competitiveness in today’s era',
    },
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-2.png',
      title: 'Forge a Strong Foundation in Emerging Technologies',
    },
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-3.png',
      title: 'Explore Tech Integration, Advancements, and Impactful Collaborations like IoT ',
    },
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-4.png',
      title:
        'Develop practical expertise applying these technologies in real-life, recognizing their tangible impact',
    },
    // {
    //   img: '/assets/imagesWeb/collage/Benefits/benefits-5.png',
    //   title: `Enhance Critical Thinking to Assess Tech's Impact on Society and the Global Economy`,
    // },
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-6.png',
      title:
        'Earn Activity Points for Prestigious Institutions (KTU-12 Credits; IIT/NIT-20 Credits for Workshops, KTU-20 Credits for Internship)',
    },
    {
      img: '/assets/imagesWeb/collage/Benefits/benefits-7.png',
      title: 'Hands-On Practical Experience with a Completion Certificate',
    },
  ];

  return (
    <>
      <MetaHelmet title="For Collages" />

      <StyledRoot>
        {isMobile === true ? (
          <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
            <Grid
              container
              spacing={2}
              className="image-container"
              sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
            >
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#E1E1E1',
                      fontSize: 17,
                      fontWeight: 600,
                      lineHeight: 'normal',
                      pb: 2,
                    }}
                  >
                    Explore The World of Emerging Technologies an Exclusive Program for college
                    students
                  </Typography>
                  <Typography className="course-subtitle-mobile">
                    This course enhances the Engineering and Science skills for College Students,
                    preparing them for the career challenges and futuristic opportunities in
                    Robotics, AI, Coding, 3D Design, and Electronics.
                  </Typography>
                </Box>
              </Grid>

              {/* Image Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box sx={{ width: '70%', height: '100%' }}>
                  <img
                    src="/assets/imagesWeb/collage/collage-main-image.png"
                    alt="mainimg"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Grid container spacing={2} className="image-container">
            <Grid
              item
              xs={12}
              sm={6}
              className="content-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box paddingLeft={17} paddingBottom={10}>
                <Typography className="lab-image-container-title">
                  Explore The World Of
                  <br /> Emerging Technologies
                </Typography>
                <Typography className="lab-image-container-1">
                  An Exclusive Program for Graduates & College Students.
                </Typography>
                <Typography className="collage-subtitle" paddingTop={3} lineHeight={1.5}>
                  This course enhances the Engineering and Science skills for College Students,
                  preparing them for the career challenges and futuristic opportunities in Robotics,
                  AI, Coding, 3D Design, and Electronics.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="image-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                <img
                  src="/assets/imagesWeb/collage/collage-main-image.png"
                  alt="mainimg"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </StyledRoot>

      <Stack display="flex" alignItems="center" justifyContent="center" padding={isMobile ? 2 : 3}>
        <Typography
          className={isMobile ? 'collage-after-main-content-mobile' : 'collage-after-main-content'}
        >
          {/* We are providing internships */}
          {/*  */}
          For Engineering, Diploma, Computer Science graduates
        </Typography>
      </Stack>
      {/* <Stack>
        <VideoPlayer />
      </Stack> */}
      <Stack display="flex" alignItems="center" justifyContent="center" padding={5}>
        {/* <Typography className={isMobile ? ' collage-internship-title-mobile' : 'collage-internship-title'}>
          Internship
        </Typography> */}
        <Typography
          className={
            isMobile ? 'collage-internship-experience-mobile' : 'collage-internship-experience'
          }
        >
          Get experience before you graduate
        </Typography>
      </Stack>

      <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
        <Card
          sx={{
            backgroundColor: '#212121',
            border: 'none',
            borderRadius: '15px',
            boxShadow: 'none',
            height: isMobile ? 'auto' : '500px',
            width: isMobile ? 'auto' : '1000px',
            py: 1,
            px: 2,
          }}
        >
          {isMobile === true ? (
            <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
              {/* <Grid
                container
                spacing={2}
                className="image-container"
                sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <img
                      src="/assets/imagesWeb/collage/collage1.jpeg"
                      alt="mainimg"
                      style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: 25 }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="content-box" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', display: '', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography className="collage-box-content-header"> Basic Internship Course</Typography>
                    <Typography className="collage-box-content-sub-header">Duration 3 Months</Typography>

                    <Typography className="collage-description">
                      <ul style={{paddingLeft:'0px'}}>
                        <li style={{ paddingBottom: 5 }}>
                          Provide a foundational understanding of key emerging technologies, including Artificial
                          Intelligence, Robotics, Python Coding etc.
                        </li>
                        <li style={{ paddingTop: 5 }}>
                          Engage students in practical exercises to develop basic technical skills required in all
                          emerging technologies.
                        </li>
                        <li style={{ paddingTop: 5 }}>
                          Foster problem-solving skills by presenting real-world challenges and guiding students through
                          the process of applying theoretical knowledge to practical scenarios.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
              </Grid> */}
              <img
                src="/assets/imagesWeb/collage/collage1.jpeg"
                alt="mainimg"
                style={{ objectFit: 'cover', borderRadius: 25 }}
              />
              <Box mt={2}>
                <Typography className="collage-box-content-header">
                  {' '}
                  Basic Internship Course
                </Typography>
                <Typography className="collage-box-content-sub-header">
                  Duration: 3 Months
                </Typography>

                <Typography className="collage-description">
                  <ul style={{ paddingLeft: '14px' }}>
                    <li style={{ paddingBottom: 5 }}>
                      Provide a foundational understanding of key emerging technologies, including
                      Artificial Intelligence, Robotics, Python Coding etc
                    </li>
                    <li style={{ paddingTop: 5 }}>
                      Engage students in practical exercises to develop basic technical skills
                      required in all emerging technologies
                    </li>
                    <li style={{ paddingTop: 5 }}>
                      Foster problem-solving skills by presenting real-world challenges and guiding
                      students through the process of applying theoretical knowledge to practical
                      scenarios
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Container>
          ) : (
            <Grid container spacing={2} className="image-container">
              {/* Image Column */}

              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2,
                  }}
                >
                  <Typography className="collage-box-content-header" pt={2} pb={2}>
                    {' '}
                    Basic Internship Course
                  </Typography>
                  <Typography className="collage-box-content-sub-header">
                    Duration: 3 Months
                  </Typography>

                  <Typography className="collage-description">
                    <ul style={{ padding: '0px' }}>
                      <li style={{ paddingBottom: 5 }}>
                        Provide a foundational understanding of key emerging technologies, including
                        Artificial Intelligence, Robotics, Python Coding etc
                      </li>
                      <li style={{ paddingTop: 5 }}>
                        Engage students in practical exercises to develop basic technical skills
                        required in all emerging technologies
                      </li>
                      <li style={{ paddingTop: 5 }}>
                        Foster problem-solving skills by presenting real-world challenges and
                        guiding students through the process of applying theoretical knowledge to
                        practical scenarios
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ width: '100%', height: '100%', padding: 3 }}>
                  <img
                    src="/assets/imagesWeb/collage/collage1.jpeg"
                    alt="mainimg"
                    style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: 25 }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          <Stack sx={{ padding: '0px 25px 25px 25px' }}>
            <Typography className="mini-card-curriculum-collage" pb={1}>
              Curriculum
            </Typography>
            <Grid container spacing={0} width="100%">
              <Grid container spacing={2} className="image-container">
                {/* Image Column */}

                {/* Text Column */}
                <Box
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-start' }}
                >
                  <ul className="list-items" style={{ paddingLeft: isMobile ? '30px' : '35px' }}>
                    <li>Electronics</li>
                    <li>Python Coding</li>
                    <li>Arduino Circuits Programming</li>
                  </ul>
                  <ul
                    className="list-items"
                    style={{ paddingLeft: isMobile ? '4px' : '35px', width: '215px' }}
                  >
                    <li> Artificial Intelligence (AI)</li>
                    <li>Robotics Programming</li>
                    <li>Internet of Things (IoT)</li>
                  </ul>
                </Box>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  // sx={{
                  //   display: 'flex',
                  //   alignItems: 'center',
                  //   justifyContent: 'space-around',
                  // }}
                >
                  <Stack ml={!isMobile && 15}>
                    <Link href="/contactUs" target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        className="px-1"
                        size="lg"
                        style={{
                          width: isMobile === true ? '100%' : 'auto',
                          minWidth: isMobile ? 130 : 200,

                          borderRadius: 8,
                          backgroundColor: '#05286C',
                          boxShadow: 'none',
                          height: '40px',
                          paddingLeft: isMobile === true ? 15 : 30,
                          paddingRight: isMobile === true ? 15 : 30,
                        }}
                      >
                        Enroll Now
                      </Button>
                    </Link>
                  </Stack>
                  <Stack sx={{ marginLeft: isMobile === true ? 2 : 2 }}>
                    <Link href="/contactUs" target="_blank" rel="noreferrer">
                      <Button
                        // variant="contained"
                        className=""
                        size="lg"
                        style={{
                          width: isMobile === true ? '100%' : 'auto',
                          minWidth: isMobile ? 140 : 200,

                          boxShadow: 'none',
                          height: '40px',
                          border: '2px solid #393939',
                          backgroundColor: '',
                          color: '#fff',
                          paddingLeft: isMobile === true ? 15 : 30,
                          paddingRight: isMobile === true ? 15 : 30,
                        }}
                      >
                        Free Class
                      </Button>
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={2}
        flexDirection="row-reverse"
      >
        <Card
          sx={{
            backgroundColor: '#212121',
            border: 'none',
            borderRadius: '15px',
            boxShadow: 'none',
            height: 'auto',
            width: isMobile ? 'auto' : '1000px',
            py: 1,
            px: 2,
          }}
          // className="mini-basic-card"
        >
          {isMobile === true ? (
            <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
              {/* <Grid
                container
                spacing={2}
                className="image-container"
                sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 1 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <img
                      src="/assets/imagesWeb/collage/collage2.jpeg"
                      alt="mainimg"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} className="content-box" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', display: '', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography className="collage-box-content-header"> Advanced Internship Course</Typography>
                    <Typography className="collage-box-content-sub-header">Duration 3 Months</Typography>

                    <Typography className="collage-description" sx={{ p: 2 }}>
                      <ul className='ul-advanced'>
                        <li style={{ paddingBottom: 5 }}>
                          Dive deeper into all areas of emerging technologies including pcb designing and printing, 3D
                          Printing etc along with advanced knowledge in Artificial Intelligence, Robotics and Python
                          coding concepts allowing students to achieve a comprehensive understanding and mastery of
                          advanced concepts and tools.
                        </li>
                        <li style={{ paddingBottom: 5 }}>
                          Provide opportunities for students to work on complex projects that simulate real-world
                          challenges, encouraging creativity and innovation.
                        </li>
                        <li style={{ paddingBottom: 5 }}>
                          Encourage students to explore the latest advancements in emerging technologies through
                          independent research, promoting a forward-thinking mindset.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
              </Grid> */}
              <img
                src="/assets/imagesWeb/collage/collage2.jpeg"
                alt="mainimg"
                style={{ objectFit: 'cover', borderRadius: 25 }}
              />
              <Box mt={2}>
                <Typography className="collage-box-content-header">
                  {' '}
                  Advanced Internship Course
                </Typography>
                <Typography className="collage-box-content-sub-header">
                  Duration: 3 Months
                </Typography>

                <Typography className="collage-description" sx={{ p: 2 }}>
                  <ul className="ul-advanced">
                    <li style={{ paddingBottom: 5 }}>
                      Dive deeper into all areas of emerging technologies including pcb designing
                      and printing, 3D Printing etc along with advanced knowledge in Artificial
                      Intelligence, Robotics and Python coding concepts allowing students to achieve
                      a comprehensive understanding and mastery of advanced concepts and tools
                    </li>
                    <li style={{ paddingBottom: 5 }}>
                      Provide opportunities for students to work on complex projects that simulate
                      real-world challenges, encouraging creativity and innovation
                    </li>
                    <li style={{ paddingBottom: 5 }}>
                      Encourage students to explore the latest advancements in emerging technologies
                      through independent research, promoting a forward-thinking mindset
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Container>
          ) : (
            <Grid container spacing={2} className="image-container">
              {/* Image Column */}

              {/* Text Column */}

              <Grid item xs={12} sm={6} className="image-box" mt={2}>
                <Box sx={{ width: '100%', height: '100%', padding: 3 }}>
                  <img
                    src="/assets/imagesWeb/collage/collage2.jpeg"
                    alt="mainimg"
                    style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: 25 }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <Typography className="collage-box-content-header" pb={2} pt={4}>
                    {' '}
                    Advanced Internship Course
                  </Typography>
                  <Typography className="collage-box-content-sub-header">
                    Duration: 3 Months
                  </Typography>

                  <Typography className="collage-description">
                    <ul className="ul-advanced">
                      <li style={{ paddingBottom: 5 }}>
                        Dive deeper into all areas of emerging technologies including pcb designing
                        and printing, 3D Printing etc along with advanced knowledge in Artificial
                        Intelligence, Robotics and Python coding concepts allowing students to
                        achieve a comprehensive understanding and mastery of advanced concepts and
                        tools
                      </li>
                      <li style={{ paddingBottom: 5 }}>
                        Provide opportunities for students to work on complex projects that simulate
                        real-world challenges, encouraging creativity and innovation
                      </li>
                      <li style={{ paddingBottom: 5 }}>
                        Encourage students to explore the latest advancements in emerging
                        technologies through independent research, promoting a forward-thinking
                        mindset
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          <Container>
            {/* {!isMobile && (
              <Typography
                className=""
                sx={{
                  fontSize: 20,
                  color: '#f1f1f1',
                  marginLeft: 15,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 700,
                }}
              >
                Curriculum
              </Typography>
            )} */}
          </Container>
          <Stack sx={{ padding: '0px 25px 25px 25px' }}>
            <Grid container spacing={0} width="100%">
              <Grid container spacing={2} className="">
                {!isMobile && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="content-box"
                    sx={{ display: 'flex', alignItems: 'start' }}
                  >
                    <Stack>
                      <Link href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          variant="contained"
                          className="px-1"
                          size="lg"
                          style={{
                            width: isMobile ? '100%' : 'auto',
                            minWidth: isMobile ? 120 : 200,
                            borderRadius: 8,
                            backgroundColor: '#05286C',
                            boxShadow: 'none',
                            height: '40px',
                            paddingLeft: isMobile ? 15 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Enroll Now
                        </Button>
                      </Link>
                    </Stack>
                    <Stack sx={{ marginLeft: isMobile ? 2 : 2 }}>
                      <Link href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          // variant="contained"
                          className=""
                          size="lg"
                          style={{
                            width: isMobile ? '100%' : 'auto',
                            minWidth: isMobile ? 120 : 200,
                            boxShadow: 'none',
                            height: '40px',
                            border: '2px solid #393939',
                            backgroundColor: '',
                            color: '#fff',
                            paddingLeft: isMobile ? 15 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Free Class
                        </Button>
                      </Link>
                    </Stack>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  // className="image-box"
                  // sx={{
                  //   display: 'flex',
                  //   alignItems: 'center',
                  //   justifyContent: 'space-around',
                  // }}
                >
                  <Box>
                    <Typography
                      className=""
                      sx={{
                        fontSize: 20,
                        color: '#f1f1f1',
                        // marginLeft: 15,
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Curriculum
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <ul className="list-items" style={{ paddingLeft: isMobile ? '4px' : '35px' }}>
                        <li>Electronics</li>
                        <li> Python Coding</li>
                        <li>Arduino Circuits Programming</li>
                        <li>Robotics Programming</li>
                      </ul>
                      <ul
                        className="list-items"
                        style={{ paddingLeft: isMobile ? '4px' : '35px', width: '230px' }}
                      >
                        <li>Artificial Intelligence (AI)</li>
                        <li>PCB Fabrication</li>
                        <li>Internet of Things (IoT)</li>
                        <li>3D designing and printing</li>
                      </ul>
                    </Stack>
                  </Box>
                </Grid>
                {isMobile && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="content-box"
                    sx={{ display: 'flex', alignItems: 'start' }}
                  >
                    <Stack>
                      <Link href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          variant="contained"
                          className="px-1"
                          size="lg"
                          style={{
                            width: isMobile ? '100%' : 'auto',
                            minWidth: isMobile ? 120 : 200,
                            borderRadius: 8,
                            backgroundColor: '#05286C',
                            boxShadow: 'none',
                            height: '40px',
                            paddingLeft: isMobile ? 15 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Enroll Now
                        </Button>
                      </Link>
                    </Stack>
                    <Stack sx={{ marginLeft: isMobile ? 2 : 2 }}>
                      <Link href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          // variant="contained"
                          className=""
                          size="lg"
                          style={{
                            width: isMobile ? '100%' : 'auto',
                            minWidth: isMobile ? 120 : 200,
                            boxShadow: 'none',
                            height: '40px',
                            border: '2px solid #393939',
                            backgroundColor: '',
                            color: '#fff',
                            paddingLeft: isMobile ? 15 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Free Class
                        </Button>
                      </Link>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Box>
      <Stack sx={{ py: 3 }}>
        <Typography className="collage-after-main-content-second">
          For Engineering, Diploma, Computer science graduates and {isMobile ? '' : <br />} final
          year students for CS/CSE/ESE/IT/EEE/MCA/MSC/BSC/
          <br />
          Diploma/BCA
        </Typography>
      </Stack>

      <Container maxWidth="xl" sx={{ padding: isMobile ? '' : 3 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: isMobile ? '' : 1,
            marginTop: isMobile ? '' : 1,
          }}
        >
          <img
            src="/assets/imagesWeb/collage/Benefits/feature.png"
            alt="mainimg"
            style={{ width: isMobile ? '50px' : ' 80px', height: '70px', objectFit: 'contain' }}
          />
          <Typography
            className={isMobile ? 'benefits-title-mobile' : 'benefits-title'}
            style={{ color: '#FFFFFF' }}
            pl={1}
          >
            How Our Program Benefits You
          </Typography>
        </Box>
      </Container>
      <Container>
        <Grid container spacing={2} paddingY={2} paddingX={5}>
          {featuresBoxes?.map((i, k) => (
            <Grid key={k} item sm={6} md={6} xs={12}>
              <Box paddingTop={1}>
                <Stack
                  className={isMobile ? 'feature-collage-boxes-mobile' : 'feature-collage-boxes'}
                >
                  <Grid display="flex" alignItems="center" justifyContent="space-between">
                    <Stack paddingTop={!isMobile && 3} className="img-parent-collage">
                      <img
                        alt={i?.title}
                        src={i?.img}
                        // style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                        className={
                          isMobile
                            ? ' img-container-collage-feature-mobile'
                            : 'img-container-collage-feature'
                        }
                      />
                    </Stack>
                    <Stack
                      className={
                        isMobile ? 'feature-collage-box-title-mobile' : 'feature-collage-box-title'
                      }
                    >
                      {i?.title}
                    </Stack>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container>
        <Stack paddingLeft={-3} paddingTop={isMobile ? 5 : 10}>
          <Typography className="curriculum-title">Curriculum</Typography>
        </Stack>
        <Grid
          container
          spacing={0}
          paddingY={5}
          paddingX={isMobile ? 0 : 15}
          className="curriculum-alignment"
        >
          {curriculumImages?.map((i, k) => (
            <Grid key={k} item xs={6} sm={4} spacing={isMobile ? 2 : ''}>
              <Box paddingTop={3}>
                <Stack className="lab-Curriculum-boxes">
                  <Stack display="flex" alignItems="center" justifyItems="center">
                    <Stack paddingTop={3} className="lab-curriculum-img-parent">
                      <img
                        alt={i?.title}
                        src={i?.img}
                        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                        className="img-container-lab-curriculum"
                      />
                    </Stack>
                    <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container>
        <Stack sx={{ py: 2 }}>
          <EnquiryForm />
        </Stack>
      </Container>
      <Stack sx={{ padding: isMobile ? '' : 5 }}>
        <BrandImages />
      </Stack>
    </>
  );
}
