import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSchoolDivision = createAsyncThunk(
  'school-division/create',
  async ({ state, dispatch }) => {
    try {
      const URL = `/school-division/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'School-division Item created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSchoolDivision = createAsyncThunk(
  'school-division/list',
  async ({ page, search, limit, schoolClass, dispatch, isFullData, school }) => {
    try {
      const URL = `/school-division/admin/all?page=${page || 1}&search=${search || ''}&limit=${
        limit || 10
      }&school_class=${schoolClass || ''}&school=${school || ''}`;

      const URL_NO_LIMIT = `/school-division/admin/all?page=${page || 1}&search=${
        search || ''
      }&school_class=${schoolClass || ''}&data=${isFullData || 'full'}&school=${school || ''}`;

      const response = await get(limit ? URL : URL_NO_LIMIT);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolDivisionDetails = createAsyncThunk(
  'school-division/single',
  async ({ schoolDivisionId, dispatch }) => {
    try {
      const response = await get(`/school-division/admin/${schoolDivisionId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSchoolDivisionDetails = createAsyncThunk(
  'school-division/update',
  async ({ state, schoolDivisionId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/school-division/admin/${schoolDivisionId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'School-division Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSchoolDivision = createAsyncThunk(
  'school-division/delete',
  async ({ schoolDivisionId, dispatch }) => {
    try {
      const response = await del(`/school-division/admin/${schoolDivisionId}/`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'School Division deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
