// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Stack, CardMedia, Container, Typography } from '@mui/material';

import { colors } from 'src/theme/colors';
import labModel from 'src/assets/labModel/course.png';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getLabModelDetails } from 'src/server/api/labModel';
import { getAllSupportMaterial } from 'src/server/api/supportMaterial';

import SupportMaterials from 'src/sections/labModel/supportMaterials';
import LabImages from 'src/sections/app/school/labModelImageCarousal';
import LabModelEquipments from 'src/sections/labModel/labModelEquipments';

function LabModelView() {
  const dispatch = useDispatch();

  const { schoolUserProfile, LabModelDetails, supportMaterial } = useSelector((state) => ({
    schoolUserProfile: state.schoolUser.schoolUserProfile,
    LabModelDetails: state.labModel.LabModelDetails,
    supportMaterial: state.supportMaterial.supportMaterial,
  }));

  useEffect(() => {
    if (schoolUserProfile && schoolUserProfile?.school) {
      const credentials = {
        labModelId: schoolUserProfile?.school?.lab_model?._id,
        dispatch,
      };
      dispatch(getLabModelDetails(credentials));
    }
  }, [dispatch, schoolUserProfile]);

  useEffect(() => {
    if (schoolUserProfile && schoolUserProfile?.school) {
      const credentials = {
        page: 1,
        search: '',
        limit: 10,
        labModelId: schoolUserProfile?.school?.lab_model?._id,
        dispatch,
      };
      dispatch(getAllSupportMaterial(credentials));
    }
  }, [dispatch, schoolUserProfile]);

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Lab Model" />
      {/* <CustomBreadcrumbs
        heading="Lab Model View"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },

          {
            name: 'Lab Model View',
          },
        ]}
        action=""
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      /> */}
      <Grid item container spacing={3}>
        <Grid item md="6">
          <Typography fontSize={24} fontWeight={700}>
            {LabModelDetails?.title}
            <Typography
              fontSize={15}
              fontWeight={400}
              textAlign="justify"
              color="#637381"
              pt="8px"
              pr={3}
              className="bg-color-remove"
            >
              {LabModelDetails?.description ? parse(LabModelDetails?.description) : null}
            </Typography>
          </Typography>
          {LabModelDetails?.curriculum_data && (
            <>
              <Typography pt={3} variant="h6" color={colors.secondary_1}>
                Curriculum
              </Typography>
              <Typography variant="caption">
                {LabModelDetails?.curriculum_data ? parse(LabModelDetails.curriculum_data) : null}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item md="6">
          {LabModelDetails?.images?.length > 0 ? (
            <LabImages list={LabModelDetails?.images} />
          ) : (
            <Box display="flex" justifyContent="end">
              <CardMedia
                component="img"
                src={labModel}
                sx={{ height: '180px', width: '200px', borderRadius: '8px' }}
              />
            </Box>
          )}
          <SupportMaterials materialData={supportMaterial} />
        </Grid>
      </Grid>
      <Stack mt={8}>
        <LabModelEquipments labModelData={LabModelDetails?.equipment_list} />
      </Stack>
    </Container>
  );
}

export default LabModelView;
