import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createLabModel,
  getAllLabModel,
  deleteLabModel,
  getLabModelList,
  getLabModelDetails,
  updateLabModelDetails,
} from '../api/labModel';

export const labModelSlice = createSlice({
  name: 'labModel',
  initialState: {
    loading: false,
    error: {},
    labModel: {},
    staffDropList: [],
    LabModelDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createLabModel.pending]: (state) => {
      state.loading = true;
    },
    [createLabModel.fulfilled]: (state, action) => {
      // console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.labModel;
      const oldLabModels = jsonState?.labModels;
      // console.log('JSON State :', jsonState?.labModels);
      const modifiedLabModelList = {
        ...jsonState,
        labModels: Array.isArray(oldLabModels)
          ? [action.payload, ...oldLabModels]
          : [action.payload],
      };
      state.loading = false;
      state.LabModelDetails = action.payload;
      state.labModel = modifiedLabModelList;
      state.error = {};
    },
    [createLabModel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all labModel public
    [getLabModelList.pending]: (state) => {
      state.loading = true;
    },
    [getLabModelList.fulfilled]: (state, action) => {
      state.loading = false;
      state.labModel = action.payload;
      state.error = {};
    },
    [getLabModelList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all labModel
    [getAllLabModel.pending]: (state) => {
      state.loading = true;
    },
    [getAllLabModel.fulfilled]: (state, action) => {
      state.loading = false;
      state.labModel = action.payload;
      state.error = {};
    },
    [getAllLabModel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single labModel details
    [getLabModelDetails.pending]: (state) => {
      state.loading = true;
    },
    [getLabModelDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.LabModelDetails = action.payload;
      state.error = {};
    },
    [getLabModelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update labModel details
    [updateLabModelDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateLabModelDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.LabModelDetails = action.payload;
    //   state.error = {};
    // },
    [updateLabModelDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.labModel;
      const modifiedLabModelList = {
        ...jsonState,
        labModels: jsonState.labModels?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.labModel = modifiedLabModelList;
      state.LabModelDetails = action.payload;
      state.labModel = modifiedLabModelList;
      state.error = {};
    },
    // [updateLabModelDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.labModel;
    //   console.log(jsonState)
    //   const modifiedLabModelList = {
    //     ...jsonState,
    //     labModels: jsonState.labModels?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.LabModelDetails = action.payload;
    //   state.partnerType = modifiedLabModelList;
    //   state.error = {};
    // },
    [updateLabModelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteLabModel.pending]: (state) => {
      state.loading = true;
    },
    [deleteLabModel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.labModel;
      const modifiedLabModelList = {
        ...jsonState,
        labModels: jsonState.labModels?.filter((labModel) => labModel._id !== action.payload),
      };
      state.loading = false;
      state.labModel = modifiedLabModelList;
      state.error = {};
    },
    [deleteLabModel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = labModelSlice.actions;

export default labModelSlice.reducer;
