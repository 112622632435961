import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCertificate,
  bulkUploadCertificate,
  createLmsExamCertificate,
  deleteLmsExamCertificate,
  getAllLmsExamCertificate,
  getLmsExamCertificateDetails,
  updateLmsExamCertificateDetails,
} from '../api/lmsExamCertificate';

export const lmsExamSlice = createSlice({
  name: 'lmsExamCertificate',
  initialState: {
    loading: false,
    error: {},
    // sale
    certificates: {},
    staffDropList: [],
    certificateDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createLmsExamCertificate.pending]: (state) => {
      state.loading = true;
    },
    [createLmsExamCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.certificates = action.payload;
    },
    [createLmsExamCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all school
    [getAllLmsExamCertificate.pending]: (state) => {
      state.loading = true;
    },
    [getAllLmsExamCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificates = action.payload;
      state.error = {};
    },
    [getAllLmsExamCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single school details
    [getLmsExamCertificateDetails.pending]: (state) => {
      state.loading = true;
    },
    [getLmsExamCertificateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificateDetails = action.payload;
      state.error = {};
    },
    [getLmsExamCertificateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update school details
    [updateLmsExamCertificateDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateLmsExamCertificateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificates = action.payload;
      state.error = {};
    },
    [updateLmsExamCertificateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete school
    [deleteLmsExamCertificate.pending]: (state) => {
      state.loading = true;
    },
    [deleteLmsExamCertificate.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.certificates;
      const modifiedSchoolList = {
        ...jsonState,
        studentCertificates: jsonState.studentCertificates?.filter(
          (certificate) => certificate._id !== action.payload
        ),
      };

      state.loading = false;
      state.certificates = modifiedSchoolList;
      state.error = {};
    },
    [deleteLmsExamCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createCertificate.pending]: (state) => {
      state.loading = true;
    },
    [createCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.certificates;
      const newCertificateList = jsonState?.studentCertificates;

      const modifiedCertificateList = {
        ...jsonState,
        studentCertificates: Array.isArray(newCertificateList)
          ? [action.payload, ...newCertificateList]
          : [action.payload],
      };
      state.certificates = modifiedCertificateList;
      state.error = {};
    },
    [createCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [bulkUploadCertificate.pending]: (state) => {
      state.loading = true;
    },
    [bulkUploadCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.certificates = action.payload;
      state.error = {};
    },
    [bulkUploadCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = lmsExamSlice.actions;

export default lmsExamSlice.reducer;
