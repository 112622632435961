import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, DialogTitle, DialogContent } from '@mui/material';

// components

import { createSession, updateSession } from 'src/server/api/session';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

SessionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setIsUpdate: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function SessionDialog({ open, setOpen, isUpdate, setIsUpdate }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    setOpen(false);
    setIsUpdate({});
  };
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => ({
    loading: state.class.loading,
  }));

  const sessionSchema = Yup.object().shape({
    title: Yup.string().required(),
    count: Yup.number().required(),
    description: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?._id) {
      return {
        title: isUpdate?.title || '',
        count: isUpdate?.count || 0,
        description: isUpdate?.description || '',
      };
    }
    return {
      title: '',
      count: 0,
      description: '',
    };
  }, [isUpdate?._id, isUpdate?.count, isUpdate?.description, isUpdate?.title]);

  const methods = useForm({
    resolver: yupResolver(sessionSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: {
        ...data,
      },
      dispatch,
      handleClose,
    };
    const UpdateCredentials = {
      state: {
        ...data,
      },
      sessionId: isUpdate?._id,
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      dispatch(updateSession(UpdateCredentials));
    } else {
      dispatch(createSession(CreateCredentials));
    }
  });

  const renderCourseForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack spacing={1.5}>
        <RHFTextField name="title" label="Title" type="text" required placeholder="Title" />
      </Stack>
      <Stack spacing={1.5}>
        <RHFTextField name="count" label="Count" type="number" required placeholder="Count" />
      </Stack>
      <Stack spacing={1.5}>
        <RHFTextField
          name="description"
          type="text"
          placeholder="Description"
          label="Description"
          multiline
          rows={4}
        />
      </Stack>
      <Stack
        sx={{
          marginBottom: '1rem',
          alignItems: 'center',
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          sx={{ color: 'white', marginLeft: 'auto' }}
          color="warning"
        >
          Submit Form
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="testnew template"
        sx={{ maxWidth: '100%' }}
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Session' : 'Create Session'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderCourseForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
