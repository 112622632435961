import React, { useRef, useState } from 'react';

import {
  Box,
  Grid,
  Link,
  Stack,
  styled,
  Button,
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import VideoPlayer from 'src/sections/landingPage/segment/VideoPlayer';
import BrandImages from 'src/sections/landingPage/segment/BrandImages';
import Form from 'src/sections/landingPage/segment/online&offlineForm';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';
import BranchSearch from 'src/sections/landingPage/segment/branchSearch';

export default function CoursePage() {
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  const { isMobile } = useDevice();
  const [alignment, setAlignment] = useState('offline');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  console.log(alignment);

  console.log(alignment);

  const courseDetails = [
    {
      heading: 'Learner ',
      img: '/assets/imagesWeb/Courses/learner.png',
      description:
        'Specifically designed for kids who are interested in programming and want to explore the exciting world of coding. This software-based course provides a strong foundation in programming skills and is tailored to beginners, making it an ideal starting point for young learners.',
      sessions: '10 SESSIONS',
      flipData: [
        'Block Coding',
        'Animation Making',
        '3D Designing',
        'Mobile App Development',
        'Circuit Simulation',
      ],
    },
    {
      heading: 'Maker',
      img: '/assets/imagesWeb/Courses/maker.png',
      description:
        'The Maker is a comprehensive course that blends software and hardware skills to provide students with a hands-on learning experience in the exciting fields of Electronics, Robotics, and Coding. This course is designed to foster creativity, problem-solving abilities, and a deep understanding of technology.        ',
      sessions: '30 Sessions',
      flipData: [
        'Circuit Simulation',
        'Block Coding',
        'Electronic Circuit',
        'Microbit Circuit',
        'Arduino Programming',
        'AI Robotics',
      ],
    },
    {
      heading: 'Innovator',
      img: '/assets/imagesWeb/Courses/innovater.png',
      description:
        'The Innovator course blends software and hardware skills, offering students a hands-on learning experience in Python, AI robotics, coding, 3D printing, artificial intelligence, IoT, and AIoT.Fosters innovation and problem-solving through hands-on experimentation and project-based learning.',
      sessions: '40 Sessions',
      flipData: [
        'Python Turtle',
        'Python Programming',
        'Advanced Robotics',
        'PCB Designing and Printing',
        '3D Printing',
        'Artificial Intelligence',
        'Internet of Things',
      ],
    },
    {
      heading: 'Robotics',
      img: '/assets/imagesWeb/Courses/robotics.png',
      description:
        'Prepare for an exciting future in robotics with our comprehensive course. Gain the skills needed to excel in prestigious competitions like the World Robo Cup and Robotics Olympiad. Join us and unlock a world of innovation and endless opportunities in this thrilling field.',
      sessions: '20 Sessions',
      basic: [
        'PWM Free Running AI Robot',
        'Beep Sound Moving AI Robot',
        'Light Following AI Robot',
      ],
      advanced: [
        'Object Avoidance AI Robot',
        'Object Follower AI Robot',
        'Edge Detecter AI Robot',
        'Object Kicker AI Robot',
        'Mobile Controlled AI Robot',
        'Voice Controlled AI Robot',
      ],
    },
    {
      heading: 'AI',
      img: '/assets/imagesWeb/Courses/ai.png',
      description: `Our AI course offers an interactive platform where young learners can explore AI's potential to innovate, create, and shape the future.The course introduces core AI concepts such as algorithms, data, and machine learning through interactive activities and real-world examples.It provides the knowledge and skills to stay up-to-date and trains on the various application.`,
      sessions: '20 Sessions',
      basic: ['Al Chat bot', 'Al based Image classification using teachable machine'],
      advanced: [
        'Plant health prediction system',
        'Al based Fire detection using open CV',
        'Face detection using Al',
      ],
    },
    {
      heading: 'IoT',
      img: '/assets/imagesWeb/Courses/learner.png',
      description: `Children today will grow up with Internet of Things(IoT), so it's crucial they prepare early. Our course enhances their understanding, introducing device interconnectedness and data exchange, fostering digital literacy. Mastering these basics opens up countless opportunities for their future choices in higher education and career, paving the way for a tech-savvy generation. `,
      sessions: '20 Sessions',
      basic: ['Home Automation System', 'Smart Agriculture System', 'Weather Monitoring System'],
      advanced: [
        'IoT Based Parking Slot System',
        'Detecting Motion With PIR Sensor',
        'Air Pollution Meter',
        'NodeMCU MQTT Client With Arduino IDE',
      ],
    },
  ];

  const courseIcons = [
    { img: '/assets/imagesWeb/Courses/python.png', title: 'Python' },
    { img: '/assets/imagesWeb/Courses/github.png', title: 'Coding' },
    // { img: '/assets/imagesWeb/Courses/game.png', title: 'Game Development' },
    { img: '/assets/imagesWeb/Courses/mobile.png', title: 'App Development' },
    { img: '/assets/imagesWeb/Courses/electronics.png', title: 'Electronics' },
    { img: '/assets/imagesWeb/Courses/mechine.png', title: 'Machine Learning' },
    { img: '/assets/imagesWeb/Courses/3d.png', title: '3D Design' },
  ];

  const scrollTargetRef = useRef(null);

  const handleClick = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <MetaHelmet title="Innovation Courses" />
      <StyledRoot>
        {isMobile === true ? (
          <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
            <Grid
              container
              spacing={2}
              className="image-container"
              sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
            >
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ color: '#E1E1E1', fontSize: 20, fontWeight: 600 }}>
                    Say Hello to{' '}
                    <Stack className="course-edited-main-head">STEM & Innovation Courses</Stack>
                  </Typography>
                  <Typography className="course-subtitle-mobile">
                    {`
                    STEM & Innovation course trains students in skills such as Coding, Robotics, AI, IoT, and more. The curriculum is designed on STEM topics that nurture creativity and foster innovation.
                    `}
                  </Typography>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    textAlign="center"
                    py={3}
                    mt={2}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={5} md={5}>
                        <Link href="/contactUs" className="course-offline">
                          <Button className="course-main-button1-mobile">
                            Locate Our Offline Centers
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Link href="/contactUs" target="_blank" rel="noreferrer">
                          <Button className="course-main-button2-mobile"> Join Free Courses</Button>
                        </Link>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Link href="/contactUs" target="_blank" rel="noreferrer">
                          <Button className="course-main-button1-mobile">Register</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>

              {/* Image Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box sx={{ width: '70%', height: '100%' }}>
                  <img
                    src="/assets/imagesWeb/Courses/main-image.png"
                    alt="mainimg"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              className="image-container"
              style={{ marginTop: 20 }}
              padding={5}
            >
              {/* Image Column */}

              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <Typography className="stem-exp-course-head">
                    Say Hello to{' '}
                    <span className="course-edited-main-head">
                      STEM &<br /> Innovation Courses
                    </span>
                  </Typography>
                  <Typography className="course-subtitle" lineHeight={1.5}>
                    {`
                  STEM & Innovation course trains students in skills such as Coding, Robotics, AI, IoT, and more. The curriculum is designed on STEM topics that nurture creativity and foster innovation.
                  `}
                  </Typography>
                  <Stack direction="row" spacing={3} alignItems="center" py={3}>
                    {/* <Grid container spacing={1}>
                    <Grid item xs={5} md={5}> */}
                    <Link
                      href="/contactUs"
                      target="_blank"
                      rel="noreferrer"
                      className="course-offline"
                    >
                      <Button className="course-main-button1" sx={{ px: 3, width: 220 }} fullWidth>
                        Locate our offline centres
                      </Button>
                    </Link>
                    {/* </Grid>
                    <Grid item xs={5} md={4}> */}
                    <Link href="/contactUs" target="_blank" rel="noreferrer">
                      <Button className="course-main-button2" sx={{ px: 3, width: 220 }} fullWidth>
                        Join Free Courses
                      </Button>
                    </Link>
                    {/* </Grid>
                    <Grid item xs={2} md={3}> */}
                    <Link href="/contactUs" target="_blank" rel="noreferrer">
                      <Button className="course-main-button1" sx={{ px: 3 }} fullWidth>
                        Register
                      </Button>
                    </Link>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={4}>
                      <a href="https://admin.stem-xpert.com/auth/academy-registration" target="_blank" rel="noreferrer">
                        <Button className="course-main-button2" fullWidth>
                          Try Our Free Class
                        </Button>
                      </a>
                    </Grid> */}
                    {/* </Grid> */}
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Box sx={{ width: '70%', height: '100%', marginBottom: 5 }}>
                  <img
                    src="/assets/imagesWeb/Courses/main-image.png"
                    alt="mainimg"
                    style={{ width: '90%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
      </StyledRoot>
      {/* video section */}
      <Stack>
        <VideoPlayer videoId="IKrtJozW1CA" />
      </Stack>
      {/* switch button */}
      {/* <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} textAlign={'center'} flexDirection={'row'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid',
            padding: 5,
            borderRadius: 35,
            width: 350,
          }}
        >
          <ToggleButtonGroup value={alignment} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="online" className="course-switch-button">
              Online Course
            </ToggleButton>
            <ToggleButton
              style={{ borderRadius: 25, marginLeft: 1, border: 'none', backgroundColor: '#262525' }}
              value="offline"
            >
              Offline Course
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid> */}
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="row"
        paddingY={isMobile ? 2 : 5}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid',
            padding: 4,
            borderRadius: 45,
            // width: 300,
          }}
          className="btn-group"
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              id="online"
              value="online"
              className={`lab-switch-button ${alignment === 'online' ? 'active' : ''}`}
              sx={{ fontSize: isMobile ? 12 : 14 }}
            >
              <Link
                href="/contactUs"
                target="_blank"
                style={{ textDecoration: 'none', color: 'white' }}
                rel="noreferrer"
              >
                Online Course
              </Link>
            </ToggleButton>
            {/* <Link to={'#'}> */}
            <ToggleButton
              id="offline"
              style={{ borderRadius: 25, marginLeft: 5 }}
              value="offline"
              sx={{ fontSize: isMobile ? 12 : 14 }}
              className={`lab-switch-button ${alignment === 'offline' ? 'active' : ''}`}
              onClick={handleClick}
            >
              {/* <Link className="scroll-smooth" href="#sectionId"> */}
              Offline Centre
              {/* </Link> */}
            </ToggleButton>
            {/* </Link> */}
          </ToggleButtonGroup>
        </div>
      </Grid>
      <Container>
        <Grid container spacing={3} paddingTop={2}>
          {courseDetails?.map((item, key) => (
            <Grid key={key} item xs={12} sm={4} md={4}>
              <Box className="flip-card-course" style={{ Width: '100%' }}>
                <Box className="flip-card-inner-course">
                  <Box className="flip-card-front-course">
                    <Stack
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pt: 3,
                      }}
                      className="front"
                    >
                      <Stack>
                        <img
                          src={item?.img}
                          alt="mainimg"
                          style={{ width: '120px', height: '100px', objectFit: 'contain' }}
                        />
                      </Stack>
                      <Stack textAlign="center" padding={1}>
                        <Typography className="mini-card-course-page-head">
                          {item?.heading}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="main-description-course"
                          px={2}
                          lineHeight={1.3}
                        >
                          {item?.description}
                        </Typography>
                        <Typography variant="body2" className="mini-card-course-page">
                          {item?.sessions}
                        </Typography>
                      </Stack>
                    </Stack>
                    {/* </Card> */}
                  </Box>
                  <Box className="flip-card-back-course">
                    <Stack
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 2,
                      }}
                      className=""
                    >
                      {item?.flipData ? (
                        <Stack className="mini-flip-data-features-course" sx={{ padding: 1 }}>
                          <Stack display="flex" padding={3}>
                            <img
                              src={item?.img}
                              alt="mainimg"
                              style={{ width: '120px', height: '100px', objectFit: 'contain' }}
                            />
                          </Stack>
                          <Typography className="mini-card-course-page-head" sx={{ p: 2 }}>
                            {item?.heading}
                          </Typography>

                          <ul className="mini-card-last-list">
                            {item?.flipData?.map((flip, index) => (
                              <li key={index} className="" style={{ textAlign: 'start' }}>
                                {flip}
                              </li>
                            ))}
                          </ul>
                        </Stack>
                      ) : (
                        <>
                          <Stack
                            sx={{
                              // border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              width: '90%',
                            }}
                          >
                            <Stack display="flex" alignItems="center" justifyContent="center">
                              <img
                                src={item?.img}
                                alt="mainimg"
                                style={{ width: '120px', height: '100px', objectFit: 'contain' }}
                              />
                            </Stack>
                            <Typography className="mini-card-course-page-head" sx={{}}>
                              {item?.heading}
                            </Typography>

                            <Stack className="mini-flip-data-features-course">
                              <Stack className="text-flip-card-title" sx={{ textAlign: 'center' }}>
                                Basic
                              </Stack>
                              <ul className="mini-card-last-list">
                                {item?.basic?.map((basic, index) => (
                                  <li key={index} className="text-white">
                                    {basic}
                                  </li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              // border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              marginTop: 1,
                              width: '90%',
                            }}
                          >
                            <Stack className="mini-flip-data-advanced-course">
                              <Stack className="text-flip-card-title">Advanced</Stack>
                              <ul className="mini-card-last-list">
                                {item?.advanced?.map((advanced, index) => (
                                  <li key={index}>{advanced}</li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* <section id="sectionId">
        <Stack>
          <Container>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                py: 2,
              }}
            >
              <img
                src={`/assets/images/Courses/bookmark.png`}
                alt="mainimg"
                style={{ width: '80px', height: '70px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ mx: 1, color: '#FFFFFF', fontSize: '20px', fontWeight: 0 }}
                className="font-normal-common"
              >
                Discover other courses that we providing
              </Typography>
            </Box>
          </Container>
        </Stack>{' '}
      </section> */}

      {isMobile ? (
        <Container>
          <Grid container spacing={1} paddingY={3} className="course-alignment-curriculum">
            {alignment === 'offline' ? (
              <>
                {courseIcons?.slice(0, 7).map((i, k) => (
                  <Grid key={k} item xs={6} sm={2}>
                    <Box paddingTop={2}>
                      <Stack className="course-boxes">
                        <Stack display="flex" alignItems="center" justifyItems="center">
                          <Stack paddingTop={3} className="img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-course"
                            />
                          </Stack>
                          <Stack className="course-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {courseIcons?.slice(0, 6)?.map((i, k) => (
                  <Grid key={k} item xs={6} sm={isMobile === true ? 0 : 4}>
                    <Box paddingTop={2}>
                      <Stack className="course-boxes">
                        <Stack display="flex" alignItems="center" justifyItems="center">
                          <Stack paddingTop={3} className="img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-course"
                            />
                          </Stack>
                          <Stack className="course-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Container>
      ) : (
        <Container>
          <Grid container spacing={1} paddingY={5} paddingX={15}>
            {alignment === 'offline' ? (
              <>
                {courseIcons?.slice(0, 7).map((i, k) => (
                  <Grid key={k} item xs={12} sm={4}>
                    <Box paddingTop={2}>
                      <Stack className="course-boxes">
                        <Stack display="flex" alignItems="center" justifyItems="center">
                          <Stack paddingTop={3} className="img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-course"
                            />
                          </Stack>
                          <Stack className="course-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {courseIcons?.slice(0, 6)?.map((i, k) => (
                  <Grid key={k} item xs={12} sm={isMobile === true ? 0 : 4}>
                    <Box paddingTop={2}>
                      <Stack className="course-boxes">
                        <Stack display="flex" alignItems="center" justifyItems="center">
                          <Stack paddingTop={3} className="img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-course"
                            />
                          </Stack>
                          <Stack className="course-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Container>
      )}
      <Stack textAlign="center" justifyContent="center" alignItems="center" mx={isMobile ? 2 : 0}>
        <Button
          component={Link}
          href="/contactUs"
          target="_blank"
          rel="noreferrer"
          variant="outlined"
          style={{ backgroundColor: '#f49935', color: 'white', width: isMobile && '100%' }}
          size="small"
          className="buttons"
        >
          {/* <Link
            href='/contactUs'
            target="_blank"
            rel="noreferrer"
            className="course-link"
          > */}
          Join Free Courses
          {/* </Link> */}
        </Button>
      </Stack>
      <Stack ref={scrollTargetRef}>
        <BranchSearch />
      </Stack>
      {/* enquiry Form */}
      <Stack
        sx={{
          paddingLeft: isMobile ? '' : 15,
          paddingRight: isMobile ? '' : 15,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Form />
      </Stack>
      {/* brands */}
      <Stack sx={{ padding: isMobile ? '' : 3 }}>
        <BrandImages />
      </Stack>
    </>
  );
}
