import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSchoolClass,
  getAllSchoolClass,
  deleteSchoolClass,
  getSchoolClassDetails,
  updateSchoolClassDetails,
} from '../api/schoolClass';

export const schoolClassSlice = createSlice({
  name: 'schoolClass',
  initialState: {
    loading: false,
    error: {},
    // sale
    schoolClasses: {},
    staffDropList: [],
    schoolUserDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create schoolClass details
    [createSchoolClass.pending]: (state) => {
      state.loading = true;
    },
    [createSchoolClass.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schoolClasses;
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolClasses: action.payload,
      };
      // const modifiedSchoolUserList = {
      //   ...jsonState,
      //   schoolClasses: {
      //     ...jsonState.schoolClasses,
      //     schoolClasses: action.payload,
      //   },
      // };
      state.schoolClasses = modifiedSchoolUserList;
      state.loading = false;
      state.error = {};
    },
    [createSchoolClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all schoolClass
    [getAllSchoolClass.pending]: (state) => {
      state.loading = true;
    },
    [getAllSchoolClass.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolClasses = action.payload;
      state.error = {};
    },
    [getAllSchoolClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single schoolClass details
    [getSchoolClassDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolClassDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [getSchoolClassDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update schoolClass details
    [updateSchoolClassDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSchoolClassDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [updateSchoolClassDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete schoolClass
    [deleteSchoolClass.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchoolClass.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schoolClasses;
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolClasses: jsonState.schoolClasses?.filter(
          (schoolClasses) => schoolClasses._id !== action.payload
        ),
      };

      state.loading = false;
      state.schoolClasses = modifiedSchoolUserList;
      state.error = {};
    },
    [deleteSchoolClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = schoolClassSlice.actions;

export default schoolClassSlice.reducer;
