import React from 'react';

import { Stack, Container } from '@mui/material';

import EnquiryForm from 'src/sections/landingPage/segment/enquiryForm';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

function ContactUs() {
  const isMobile = useDevice()?.isMobile;
  return (
    <Stack padding={isMobile ? 0 : 5}>
      <Container>
        <EnquiryForm />
      </Container>
    </Stack>
  );
}

export default ContactUs;
