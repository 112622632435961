import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';

import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  Box,
  Grid,
  List,
  Stack,
  ListItem,
  Container,
  Typography,
  ListItemText,
} from '@mui/material';

import { getSettingsDetails } from 'src/server/api/settings';

import WhatsappIcon from 'src/sections/landingPage/segment/WhatsappIcon';

function MobileFooter({ company, quick }) {
  const dispatch = useDispatch();
  const { settingsDetails } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails?.settings,
  }));
  const socialLinks = settingsDetails?.social_links;

  useEffect(() => {
    dispatch(getSettingsDetails({ dispatch }));
  }, [dispatch]);

  return (
    <div>
      <footer
        style={{
          backgroundColor: '#1A1A1A',
          padding: '20px 0',
          color: '#FFF',
          border: '1px solid #212121',
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1} mb={2}>
                <img
                  className="footerlogo"
                  src="/assets/logo-black.png"
                  alt="Logo"
                  style={{ height: '80px', width: '100%', objectFit: 'contain', marginRight: 2 }}
                />
                <Typography sx={{ color: '#A9A9A9' }}>
                  <a
                    target="_blank"
                    href="mailto:contact@stem-xpert.com"
                    style={{ color: '#d9cbcb', textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      contact@stem-xpert.com
                    </span>
                  </a>
                  <a style={{ color: '#d9cbcb', textDecoration: 'none' }} href="tel:9400 451 133">
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      +91 9400 451 133
                    </span>
                  </a>
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={6} md={6}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  sx={{
                    color: '#FFF',
                    px: 2,
                  }}
                >
                  Company
                </Typography>
                {company?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem>
                      <ListItemText
                        className="footer-item"
                        sx={{ color: '#A9A9A9', padding: 0 }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>

            <Grid item xs={6} md={6}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  sx={{
                    color: '#FFF',
                    px: 2,
                  }}
                >
                  Quick Links
                </Typography>
                {quick?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem sx={{}}>
                      <ListItemText
                        className="footer-item"
                        sx={{ color: '#A9A9A9' }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                textAlign="center"
                pb={2}
                sx={{
                  marginTop: 1,
                  color: '#FFF',
                }}
              >
                Follow Us! &nbsp;
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  color: '#A9A9A9',
                  justifyContent: 'center',
                  gap: 3,
                }}
              >
                {socialLinks?.whatsapp_chanel && (
                  <a
                    href={socialLinks?.whatsapp_chanel}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <WhatsAppIcon fontSize="large" sx={{ color: '#25d366', borderRadius: '7px' }} />
                  </a>
                )}
                {socialLinks?.instagram && (
                  <a
                    href={socialLinks?.instagram}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <InstagramIcon fontSize="large" className="instagram-gradient" />
                  </a>
                )}
                {socialLinks?.facebook && (
                  <a
                    href={socialLinks?.facebook}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <FacebookIcon fontSize="large" sx={{ color: '#316FF6', borderRadius: '7px' }} />
                  </a>
                )}
                {socialLinks?.youtube && (
                  <a
                    href={socialLinks?.youtube}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <YouTubeIcon fontSize="large" sx={{ color: '#FF0000', borderRadius: '7px' }} />
                  </a>
                )}
                {socialLinks?.telegram && (
                  <a
                    href={socialLinks?.telegram}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <TelegramIcon fontSize="large" sx={{ color: '#229ED9', borderRadius: '7px' }} />
                  </a>
                )}
                {socialLinks?.x && (
                  <a
                    href={socialLinks?.x}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <XIcon
                      fontSize="large"
                      sx={{ color: 'white', backgroundColor: 'black', borderRadius: '7px' }}
                    />
                  </a>
                )}
                {socialLinks?.linkedIn && (
                  <a
                    href={socialLinks?.linkedIn}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <LinkedInIcon fontSize="large" sx={{ color: '#0077B5', borderRadius: '7px' }} />
                  </a>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Footer Copyrights */}
        <Box display="flex" alignItems="center" justifyContent="center" padding={3}>
          <Typography className="footer-copyrights">
            <span style={{ color: '#767676', fontSize: 14 }}>© 2024</span>
            <span style={{ color: '#A9A9A9', fontSize: 12 }}> www.STEM-xpert.com</span>{' '}
            <span style={{ color: '#767676', fontSize: 12 }}>
              All rights reserved.
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Powered by &nbsp;
                <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5' }}>
                  osperb
                </a>
              </span>
            </span>
          </Typography>
        </Box>
        <WhatsappIcon link={socialLinks?.whatsapp} />
      </footer>
    </div>
  );
}

export default MobileFooter;

MobileFooter.propTypes = {
  company: PropTypes.array,
  quick: PropTypes.array,
};
