import PropTypes from 'prop-types';

import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  deleteMessage,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {!deleteMessage?.message && content && (
        <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>
      )}

      {deleteMessage?.message && (
        <DialogContent sx={{ typography: 'body2' }}>
          <Alert severity="error" sx={{ width: 1, alignItems: 'center', mt: '12px' }}>
            {deleteMessage?.message}
          </Alert>
        </DialogContent>
      )}

      <DialogActions>
        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  deleteMessage: PropTypes.any,
};
