import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken, useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  async ({ state, enqueueSnackbar, handleRoles }) => {
    try {
      const response = await post(`/forgot`, state);
      if (response) {
        enqueueSnackbar('Email Sent Successfully!');
        if (handleRoles) {
          localStorage.setItem('userRole', handleRoles());
        }
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updatePassword = createAsyncThunk(
  'auth/password/update',
  async ({ state, enqueueSnackbar }) => {
    try {
      const response = await post(`password/update`, state);
      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const passwordReset = createAsyncThunk(
  'auth/password/reset',
  async ({ state, enqueueSnackbar, router, token, type }) => {
    try {
      const response = await post(`password/reset/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        switch (type) {
          case 'admin':
            return router.push(paths.public.admin_login);
          case 'partner':
            return router.push(paths.public.partner_login);
          case 'school':
            return router.push(paths.public.school_login);
          case 'student':
            return router.push(`${paths.public.academy_login}?isExam`);

          default:
            router.push(paths.public.admin_login);
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const confirmPassword = createAsyncThunk(
  'auth/password/confirm',
  async ({ state, enqueueSnackbar, router, token, type }) => {
    try {
      const response = await post(`accept/invitation/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Verified Successfully!');
        switch (type) {
          case 'admin':
            return router.push(paths.public.admin_login);
          case 'partner':
            return router.push(paths.public.partner_login);
          case 'school':
            return router.push(paths.public.school_login);
          case 'student':
            return router.push(paths.public.academy_login);

          default:
            router.push(paths.public.admin_login);
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const loginPartnerApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('partner/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const loginSchoolApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('school-user/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Welcome Onboard !' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const adminLoginApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('admin/login', state);

      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate(paths.public.home, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(paths.public.home, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// the following api call are not used
export const registerApi = createAsyncThunk(
  'auth/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/auth/signup', state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: response.message }));
        navigate(paths.public.admin_login, { replace: true });
        return response?.user;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getUserProfile = createAsyncThunk('user/profile', async ({ enqueueSnackbar }) => {
  try {
    const response = await get(`/user`);
    if (response?._id) {
      return response;
    }
    return enqueueSnackbar('something went wrong', { variant: 'error' });
  } catch (error) {
    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// student login

export const studentLoginApi = createAsyncThunk(
  'auth/student/login',
  async ({ state, dispatch, navigate, isExam }) => {
    try {
      const response = await post('student/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        if (isExam === 'isExam') {
          navigate(paths.auth.exams);
        } else {
          navigate(paths.public.academy_landing, { replace: true });
        }
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const studentRegisterApi = createAsyncThunk(
  'auth/student/register',
  async ({ state, enqueueSnackbar, navigate, isExam }) => {
    try {
      const response = await post('/student/register', state);
      if (response?.message) {
        enqueueSnackbar(response?.message);
      }
      if (response?.createStudentData?._id) {
        localStorage.setItem('userRole', 'student');
        enqueueSnackbar(response?.setPasswordData?.message);
        if (isExam) {
          navigate(`${paths.public.academy_login}?${isExam}`, { replace: true });
        } else {
          navigate(paths.public.academy_login, { replace: true });
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });

      throw error?.response?.data?.message;
    }
  }
);

export const emailVerifyAcademy = createAsyncThunk(
  'student/register',
  async ({ state, dispatch, handleOtpCode }) => {
    try {
      const URL = `/student/v2/register`;
      const response = await post(URL, state);
      if (response?.user) {
        if (handleOtpCode) {
          handleOtpCode();
        }
        dispatch(activeSnack({ type: 'success', message: response?.message }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const registerAcademyV2 = createAsyncThunk(
  'student/register/v2',
  async ({ state, reset, dispatch, router }) => {
    try {
      const URL = '/student/v2/profile/new';
      const response = await post(URL, state);
      if (response) {
        if (reset) {
          reset();
        }
        if (router) {
          router.push(paths.public.academy_login);
        }
        dispatch(activeSnack({ type: 'success', message: 'Student Registered Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateStudentProfile = createAsyncThunk(
  'student/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/student/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getStudentProfile = createAsyncThunk('student/profile', async ({ dispatch }) => {
  try {
    const response = await get('/student/profile');
    if (response) {
      useSetEncryptLocalData('isOnBoarded', response?.is_onboarded);
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const otpVerification = createAsyncThunk(
  'otp/verify',
  async ({ state, dispatch, email }) => {
    try {
      const response = await post('/verify_otp', state);
      dispatch(activeSnack({ type: 'success', message: response?.message }));
      if (response) {
        useSetToken(response?.token);
        return {
          response,
          email,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
