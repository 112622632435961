import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  getAllCounts,
  getPaymentKey,
  createOtherProduct,
  getAllOtherProducts,
  registerOtherProduct,
  getOtherProductDetails,
  getallotherproductpublic,
  updateOtherProductDetails,
  createOtherProductPayment,
} from '../api/otherProduct';

export const otherProductSlice = createSlice({
  name: 'otherProductList',
  initialState: {
    loading: false,
    updateLoading: false,
    error: {},
    alldatas: {},
    allOtherProducts: [],
    allCounts: {},
    otherProductDetails: {},
    otherProductRegister: {},
    otherProductPaymentInfo: {},
    paymentKey: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearOtherProductData: (state) => {
      state.otherProductDetails = {};
    },
    clearOtherPaymentInfo: (state) => {
      state.otherProductPaymentInfo = {};
      state.otherProductRegister = {};
    },
  },
  extraReducers: {
    // get payment key
    [getPaymentKey.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentKey.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentKey = action.payload;
    },
    [getPaymentKey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // other product payment create
    [createOtherProductPayment.pending]: (state) => {
      state.loading = true;
    },
    [createOtherProductPayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.otherProductPaymentInfo = action.payload;
    },
    [createOtherProductPayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // other product register
    [registerOtherProduct.pending]: (state) => {
      state.loading = true;
    },
    [registerOtherProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.otherProductRegister = action.payload;
    },
    [registerOtherProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all lists into other products
    [getallotherproductpublic.pending]: (state) => {
      state.loading = true;
    },
    [getallotherproductpublic.fulfilled]: (state, action) => {
      state.loading = false;
      state.alldatas = action.payload;
    },
    [getallotherproductpublic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all lists into Admin

    [getAllOtherProducts.pending]: (state) => {
      state.loading = true;
    },
    [getAllOtherProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.allOtherProducts = action.payload;
    },
    [getAllOtherProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get All Counts

    [getAllCounts.pending]: (state) => {
      state.loading = true;
    },
    [getAllCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCounts = action.payload;
    },
    [getAllCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createOtherProduct.pending]: (state) => {
      state.updateLoading = true;
    },

    [createOtherProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.course;
      const newCourseList = jsonState?.courses;

      const modifiedCourseList = {
        ...jsonState,
        courses: Array.isArray(newCourseList)
          ? [action.payload, ...newCourseList]
          : [action.payload],
      };
      state.updateLoading = false;
      state.allOtherProducts = modifiedCourseList;
      state.error = {};
    },
    [createOtherProduct.rejected]: (state, action) => {
      state.updateLoading = false;
      state.error = action.error;
    },

    // get single course details
    [getOtherProductDetails.pending]: (state) => {
      state.loading = true;
    },
    [getOtherProductDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.otherProductDetails = action.payload;
      state.error = {};
    },
    [getOtherProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update course details
    [updateOtherProductDetails.pending]: (state) => {
      state.updateLoading = true;
    },

    [updateOtherProductDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.allOtherProducts;
      const modifiedOtherProductList = {
        ...jsonState,
        otherProducts: jsonState.otherProducts?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.updateLoading = false;
      state.allOtherProducts = modifiedOtherProductList;
      state.error = {};
    },
    [updateOtherProductDetails.rejected]: (state, action) => {
      state.updateLoading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError, clearOtherProductData, clearOtherPaymentInfo } =
  otherProductSlice.actions;

export default otherProductSlice.reducer;
