import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';

import CourseItem from './course-item';

// ----------------------------------------------------------------------

export default function CourseList({ course, page, setPage, total }) {
  const totalPages = Math.ceil(total / 8);
  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
      >
        {course?.map((item) => (
          <CourseItem key={item._id} course={item} />
        ))}
      </Box>

      {course?.length > 0 && (
        <Pagination
          onChange={(e, value) => setPage(value)}
          count={totalPages}
          defaultValue={page}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}

CourseList.propTypes = {
  course: PropTypes.array,
  page: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
};
