import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Alert, Button, TextField, IconButton, Autocomplete, InputAdornment } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { getAllCountries } from 'src/server/api/country';
import { CheckPartnerCode } from 'src/server/api/school';
import { registerPartnerV2 } from 'src/server/api/partner';
import { getAllState, getAllDistrict } from 'src/server/api/address';

import Iconify from 'src/components/iconify';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';
import FormProvider, { RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function PartnerRegistrationForm({ email, isNewUser }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const password = useBoolean();
  const location = useLocation();
  const { isMobile } = useDevice();

  const { loading, countryList, stateList, districtList, partnerCodesCheck } = useSelector(
    (state) => ({
      error: state.partner.error,
      loading: state.partner.loading,
      countryList: state.country.country,
      stateList: state.address.state,
      districtList: state.address.district,
      partnerCodesCheck: state.school.checkPartnerCodes,
    })
  );

  const partnerCode = location?.search?.split('?ref_id=')[1];

  const [selectedCode, setSelectedCode] = useState('91');

  // country
  const [selectedCountry, setSelectedCountry] = useState({
    label: '',
    value: '',
  });
  const [searchValue, setSearchValue] = useState('');
  // state
  const [selectedState, setSelectedState] = useState({
    label: '',
    value: '',
  });
  const [searchValueState, setSearchValueSate] = useState('');

  // district
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: '',
    value: '',
  });
  const [searchValueDistrict, setSearchValueDistrict] = useState('');

  const [activeTab, setActiveTab] = useState(1);

  const UAE_COUNTRY_ID = selectedCountry?.value === '660f7c95ca0f7e05752a3820';

  const RegisterPartnerSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string(),
    country_code: Yup.string(),
    email: Yup.string().email(),
    qualification: Yup.string(),
    type: Yup.string(),
    password: Yup.string(),
    partner_code: Yup.string(),
    country: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    other_country: Yup.string(),
    address: Yup.object().shape({
      address_line1: Yup.string().required('address is required'),
      address_line2: Yup.string(),
      pincode: Yup.number().required('Pincode is required'),
      state: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
      district: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
      other_state: Yup.string(),
      other_district: Yup.string(),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email,
      qualification: '',
      type: 'affiliate',
      password: '',
      country: {},
      other_country: '',
      partner_code: '',
      address: {
        address_line1: '',
        address_line2: '',
        pincode: '',
        state: {},
        district: {},
        other_state: '',
        other_district: '',
      },
    }),
    [email]
  );

  const methods = useForm({
    resolver: yupResolver(RegisterPartnerSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const otherCountry = watch('other_country');
  const partnerCodeValue = watch('partner_code');

  const isValidateCountry = selectedCountry?.value || otherCountry;

  const onSubmit = handleSubmit(async (data) => {
    const registerData = {
      ...data,
      phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,
      country_code: selectedCode,
      country: selectedCountry?.label === 'Other' ? null : selectedCountry?.value || null,
      other_country: selectedCountry?.value ? '' : data?.other_country,
      address: {
        ...data?.address,
        state: selectedCountry?.label === 'Other' ? null : selectedState?.value || null,
        district: selectedCountry?.label === 'Other' ? null : selectedDistrict?.value || null,
        other_state: selectedCountry?.label !== 'Other' ? '' : data?.address?.other_state,
        other_district: selectedCountry?.label !== 'Other' ? '' : data?.address?.other_district,
      },
    };
    if (isNewUser) {
      ['confirmNewPassword']?.forEach((key) => {
        delete registerData[key];
      });
    } else {
      ['password', 'confirmNewPassword']?.forEach((key) => {
        delete registerData[key];
      });
    }
    const credentials = {
      state: registerData,
      reset,
      dispatch,
      router,
    };
    // console.log('credentials', credentials);
    dispatch(registerPartnerV2(credentials));
  });

  useEffect(() => {
    if (partnerCode) {
      setValue('partner_code', partnerCode);
    }
  }, [partnerCode, setValue]);

  useEffect(() => {
    if (partnerCodeValue?.length >= 6) {
      dispatch(CheckPartnerCode({ state: { partner_code: partnerCodeValue } }));
    }
  }, [dispatch, partnerCodeValue]);

  const PARTNER_TYPE_OPTIONS = [
    { value: 'affiliate', label: 'Affiliate' },
    { value: 'franchise', label: 'Learning Center' },
    { value: 'country_head', label: 'Partner' },
  ];

  const optionCountry = [
    { label: 'Other', value: '' },
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValue,
      dispatch,
      limit: 10,
    };
    dispatch(getAllCountries(credentials));
  }, [dispatch, searchValue]);

  const handleAutocompleteChange = (_, newValue) => {
    setSelectedCountry({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  const handleTextFieldChange = (event) => {
    setSearchValue(event.target.value);
  };

  // state
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueState,
      limit: 10,
      dispatch,
      countryId: selectedCountry?.value,
    };
    dispatch(getAllState(credentials));
  }, [dispatch, searchValueState, selectedCountry?.value]);

  const handleAutocompleteChangeState = (_, newValue) => {
    setSelectedState({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  const handleTextFieldChangeState = (event) => {
    setSearchValueSate(event.target.value);
  };
  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];
  // district
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueDistrict,
      limit: 10,
      dispatch,
      stateId: selectedState?.value,
    };
    dispatch(getAllDistrict(credentials));
  }, [dispatch, searchValueDistrict, selectedState?.value]);

  const handleAutocompleteChangeDistrict = (_, newValue) => {
    setSelectedDistrict({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  const handleTextFieldChangeDistrict = (event) => {
    setSearchValueDistrict(event.target.value);
  };

  const optionDistrict = [
    ...(districtList?.districts || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const renderProfile = (
    <Card sx={{ mb: 5, minWidth: isMobile ? 400 : 450 }}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack>
          <Typography variant="subtitle1" fontWeight={900}>
            Add your details
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <RHFTextField required name="name" placeholder="Name" label="Name" />
        </Stack>
        <Stack spacing="5px" direction="row">
          <RHFTextField
            label="Qualifications"
            name="qualification"
            type="text"
            placeholder="Qualifications"
          />
          <Box width="50%">
            <RHFTextField
              label="Partner Code"
              name="partner_code"
              type="text"
              placeholder="Partner code"
            />
            {partnerCodeValue?.length >= 6 && (
              <>
                {partnerCodesCheck?.is_verify ? (
                  <Typography
                    variant="caption"
                    pl={1}
                    display="flex"
                    alignItems="start"
                    color="green"
                    gap={0.3}
                    pt={0.2}
                  >
                    <DoneIcon sx={{ fontSize: 15 }} />
                    Verified
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    pl={1}
                    display="flex"
                    alignItems="start"
                    color="red"
                    gap={0.3}
                    pt={0.2}
                  >
                    <ReportGmailerrorredIcon sx={{ fontSize: 15 }} />
                    Invalid Referral ID
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Partner Type</Typography>
          <RHFRadioGroup
            row
            spacing={4}
            id="partner-type-id"
            name="type"
            options={PARTNER_TYPE_OPTIONS}
          />
        </Stack>

        <Stack>
          <Typography variant="subtitle2" fontWeight="600">
            Address
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <RHFTextField
            name="address.address_line1"
            placeholder="Address Line 1"
            type="textarea"
            label="Address"
            multiline
            rows={4}
            required
          />
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: '5px' }}>
          <RHFTextField
            label="Pincode"
            required
            name="address.pincode"
            type="number"
            placeholder="Pincode"
          />
          <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={optionCountry}
            autoHighlight
            value={selectedCountry}
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChange}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                required={!otherCountry}
                name="country"
                value={selectedCountry}
                onChange={handleTextFieldChange}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Stack>
        {selectedCountry?.value && (
          <Stack sx={{ flexDirection: 'row', gap: '5px' }}>
            <Autocomplete
              id="state-select-demo"
              sx={{ width: !UAE_COUNTRY_ID ? '50%' : '100%' }}
              options={optionState}
              autoHighlight
              value={selectedState}
              getOptionLabel={(option) => option.label}
              onChange={handleAutocompleteChangeState}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Emirates/Province"
                  required={selectedCountry?.value && !UAE_COUNTRY_ID}
                  name="address.state"
                  onChange={handleTextFieldChangeState}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {!UAE_COUNTRY_ID && selectedState?.value && (
              <Autocomplete
                id="district-select-demo"
                sx={{ width: '50%' }}
                options={optionDistrict}
                autoHighlight
                value={selectedDistrict}
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeDistrict}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    required={selectedCountry?.value && !UAE_COUNTRY_ID}
                    name="address.district"
                    onChange={handleTextFieldChangeDistrict}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Stack>
        )}
        {selectedCountry?.label === 'Other' && (
          <Stack sx={{ flexDirection: 'row', gap: '5px' }}>
            <RHFTextField
              label="Other Country"
              required={!selectedCountry?.value}
              name="other_country"
              type="text"
              placeholder="Other Country"
            />
            <RHFTextField
              label="Other State/Emirates/Province"
              name="address.other_state"
              type="text"
              placeholder="Other State/Emirates/Province"
            />
          </Stack>
        )}
        {!UAE_COUNTRY_ID && selectedCountry?.label === 'Other' && (
          <Stack sx={{ flexDirection: 'row', gap: '5px' }}>
            <RHFTextField
              label="Other District"
              name="address.other_district"
              type="text"
              placeholder="Other District"
            />
          </Stack>
        )}
        <Typography variant="caption" display="flex" alignItems="center">
          <Typography color="red" pr={0.5}>
            *
          </Typography>
          Indicate Required fields
        </Typography>
        <Button
          type="button"
          variant="contained"
          size="large"
          fullWidth
          sx={{
            backgroundColor: '#ED672B',
            color: 'white',
            '&:hover': { backgroundColor: '#ED672B', color: 'white' },
          }}
          onClick={() => setActiveTab(2)}
          disabled={!isValidateCountry || (partnerCodeValue && !partnerCodesCheck?.is_verify)}
        >
          Continue
        </Button>
      </Stack>
    </Card>
  );

  const renderSecurity = (
    <Card sx={{ mb: 5, minWidth: isMobile ? 400 : 450 }}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack>
          <Typography variant="subtitle1" fontWeight={900}>
            Finish your registration
          </Typography>
        </Stack>
        {Object.keys(errors).length > 0 && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <Typography variant="caption">
              Fill following required felids before submitting
            </Typography>
            <Stack direction="row">
              {Object.keys(errors).map((fieldName) => (
                <Typography variant="caption" style={{ marginRight: 5 }} key={fieldName}>
                  {fieldName},
                </Typography>
              ))}
            </Stack>
          </Alert>
        )}
        <RHFTextField label="Email" name="email" type="text" placeholder="Email" disabled />
        <RHFPhoneInput
          name="phone"
          label="Phone Number"
          placeholder="Enter Phone Number"
          required
          setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
        />
        {isNewUser && (
          <>
            <RHFTextField
              name="password"
              label="Password"
              required={isNewUser}
              type={password.value ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // helperText={
              //   <Stack component="span" direction="row" alignItems="center">
              //     <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Password must be minimum
              //     6+
              //   </Stack>
              // }
            />
            <RHFTextField
              name="confirmNewPassword"
              type={password.value ? 'text' : 'password'}
              label="Confirm Password"
              required={isNewUser}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        <Stack>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            loading={isSubmitting || loading}
            sx={{
              backgroundColor: '#ED672B',
              color: 'white',
              '&:hover': { backgroundColor: '#ED672B', color: 'white' },
            }}
          >
            Complete Registration
          </LoadingButton>
          <Button sx={{ mt: 2 }} onClick={() => setActiveTab(1)}>
            Back
          </Button>
          <Typography
            justifyContent="center"
            variant="caption"
            display="flex"
            gap={0.5}
            color="text.disabled"
            pt={1}
          >
            By registering, I agree to{' '}
            <Link to="/terms&condition">
              <Typography variant="caption" color="black" sx={{ textDecoration: 'underline' }}>
                Terms of service
              </Typography>
            </Link>
            and{' '}
            <Link to="/privacy&policy">
              <Typography variant="caption" color="black" sx={{ textDecoration: 'underline' }}>
                Privacy policy
              </Typography>
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );

  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {activeTab === 1 ? renderProfile : renderSecurity}
    </FormProvider>
  );
}

PartnerRegistrationForm.propTypes = {
  email: PropTypes.any,
  isNewUser: PropTypes.any,
};
