import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { paths, AuthRoles } from 'src/routes/routes/paths';

import MainLayout from 'src/layouts/main';
import SettingsPage from 'src/pages/Admin/settings';
import PublicGuard from 'src/auth/guard/public-guard';
import AcademyExams from 'src/pages/LMS/AcademyExams';
import PartnerBg from 'src/assets/other/partnerBg.png';
import LabView from 'src/pages/School/LabModel/labView';
import AcademyBg from 'src/assets/other/StudentBg.jpeg';
import LoginAdmin from 'src/assets/other/LoginAdmin.jpeg';
import SchoolProfile from 'src/pages/School/SchoolProfile';
import LoginSchool from 'src/assets/other/LoginSchool.jpeg';
import LoginPartner from 'src/assets/other/LoginPartner.jpeg';
import AcademyLandingPage from 'src/pages/LMS/AcademyLanding';
import AcademyExamsView from 'src/pages/LMS/AcademyExamsView';
import AcademyExamsOver from 'src/pages/LMS/AcademyExamsOver';
// import AcademyRegisterPage from 'src/pages/LMS/AcademyRegister';
import SchoolBg from 'src/assets/other/School-registration.jpeg';
import LabModelView from 'src/pages/School/LabModel/labModelView';
import AuthClassicLayoutFull from 'src/layouts/auth/classic-full';
import AcademyContentView from 'src/pages/LMS/AcademyContentView';
import SessionView from 'src/pages/School/SessionView/SessionView';
import CourseListView from 'src/pages/School/course/CourseListView';
import AcademyExamRegister from 'src/pages/LMS/AcademyExamRegister';
import SaleItemsListViewForPartner from 'src/pages/Partner/saleItems';
import CourseSingleView from 'src/pages/School/course/CourseSingleView';
import AcademyCourseSingleView from 'src/pages/LMS/AcademyCourseSingleView';
import PasswordVerification from 'src/pages/Common/Auth/PasswordVerification';
// import StudentCertificate2 from 'src/pages/Common/Certificate/student-certificate-download2';

import LabPage from 'src/pages/LandingPage/Lab';
import Terms from 'src/pages/LandingPage/Terms';
import HomePage from 'src/pages/LandingPage/Home';
import Privacy from 'src/pages/LandingPage/Privacy';
import AboutUs from 'src/pages/LandingPage/AboutUs';
import CoursePage from 'src/pages/LandingPage/Courses';
import CollagePage from 'src/pages/LandingPage/Collage';
import ContactUs from 'src/pages/LandingPage/ContactUs';
import ClassList from 'src/pages/Admin/class/Class-list';
import SimpleLayout from 'src/layouts/simple/SimpleLayout';
import PartnerWithUs from 'src/pages/LandingPage/partnerWithUs';
// import AcademyRegisterPage from 'src/pages/LMS/AcademyRegister';
import StudentExams from 'src/pages/Admin/StudentExam/exam-list';
import { AptitudeTest } from 'src/pages/LandingPage/AptitudeTest';
// import SchoolRegistration from 'src/pages/School/SchoolRegistration';
import FacilitatorPage from 'src/pages/LandingPage/CourseFaciliatator';
// import PartnerRegistration from 'src/pages/Admin/Partner/PartnerRegistration';
import CreateOtherProduct from 'src/pages/Admin/otherProducts/createOtherProduct';
import CertificateTemplateList from 'src/pages/Admin/certificate/Certificate-Template-list';

import { SplashScreen } from 'src/components/loading-screen';

import AcademyRegistrationView from 'src/sections/auth/academyRegistration/academy-register-view';
import SchoolRegistrationView from 'src/sections/auth/schoolRegistration/school-registration-view';
import PartnerRegistrationView from 'src/sections/auth/partnerRegistration/partner-registration-view';

const PartnerCommissionAdmin = lazy(
  () => import('src/pages/Admin/partnerCommission/PartnerCommission-all-list')
);
const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const SaleItemsListView = lazy(() => import('src/pages/Admin/SaleItems'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));
const RegisterLayout = lazy(() => import('src/layouts/auth/registorLayout'));
const StudentProfile = lazy(() => import('src/pages/LMS/studentProfile'));
const ResourceView = lazy(() => import('src/pages/Admin/resource/Resource-view'));
const SubscriptionView = lazy(() => import('src/pages/Admin/subscription/Subscription-view'));
const ResourceLmsView = lazy(() => import('src/pages/LMS/Resource-lms-view'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));
const PartneredSales = lazy(() => import('src/pages/Partner/sale/partner-sale-list-view'));
const ResourceSchoolView = lazy(() => import('src/pages/School/resource/Resource-school-view'));
const PartnerTypePartnerView = lazy(() => import('src/pages/Partner/partnerType/PartnerTypePage'));
const PartneredSchools = lazy(
  () => import('src/pages/Partner/partneredSchools/partner-school-list-view')
);

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

// const PartnerRegistrationView = lazy(
//   () => import('src/sections/auth/partnerRegistration/partner-registration-view')
// );

// const SchoolRegistrationView = lazy(
//   () => import('src/sections/auth/schoolRegistration/school-registration-view')
// );

// const AcademyRegistrationView = lazy(
//   () => import('src/sections/auth/academyRegistration/academy-register-view')
// );

// const SchoolRegistration = lazy(() => import('src/pages/School/SchoolRegistration'));
const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
const SalePage = lazy(() => import('src/pages/Admin/Sale/SaleListView'));
const CompactLayout = lazy(() => import('src/layouts/compact'));
const SchoolPage = lazy(() => import('src/pages/Admin/school/School-list-view'));
const PartnersList = lazy(() => import('src/pages/Partner/partner-list/partner-list-view'));
const CountryPage = lazy(() => import('src/pages/Common/Country/CountryPage'));
const LabModelPage = lazy(() => import('src/pages/Admin/labModel/LabModelPage'));
const SaleDetailsPage = lazy(() => import('src/pages/Admin/Sale/SaleUpdatePage'));
const SchoolUserPage = lazy(() => import('src/pages/Admin/SchoolUser/SchoolUserPage'));
const SchoolDetailsPage = lazy(() => import('src/pages/School/SchoolUpdatePage'));
const PartnerTypePage = lazy(() => import('src/pages/Admin/PartnerType/PartnerTypePage'));
const SchoolSingleViewPage = lazy(() => import('src/pages/School/SchoolSingleView'));
const PartnerProfileView = lazy(() => import('src/pages/Partner/partner-list/PartnerProfileView'));
const PartneredEarnings = lazy(() => import('src/pages/Partner/earning/partner-earning-list-view'));
const EarningAdminPage = lazy(() => import('src/pages/Admin/earning/Earning-list-view'));
const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));
const PartnerCommissionListView = lazy(
  () => import('src/pages/Partner/partnerCommission/PartnerCommission-list')
);
// const SchoolOldPage = lazy(() => import('src/pages/School/School'));
const AdminPartnerList = lazy(() => import('src/pages/Admin/Partner/Partner'));
const SupportMaterialPage = lazy(() => import('src/pages/Admin/supportMaterial/SupportMaterial'));
const CourseListPage = lazy(() => import('src/pages/Admin/course/Course-Admin-list'));
const CourseSingleViewPage = lazy(() => import('src/pages/Admin/course/SingleViewIndex'));
const ExamPage = lazy(() => import('src/pages/Admin/exam/Exam-Admin-list'));
const ExamSingleViewPage = lazy(() => import('src/pages/Admin/exam/SingleViewIndex'));
const StudentPage = lazy(() => import('src/pages/Admin/student/Student'));
const StudentSingleViewPage = lazy(() => import('src/pages/Admin/student/StudentSingleView'));
const StudentCertificatePage = lazy(
  () => import('src/pages/Admin/studentCertificate/Student-Certificate-list')
);
const OtherProductPage = lazy(() => import('src/pages/LandingPage/otherProductPage'));
const OtherProductsDashboard = lazy(() => import('src/pages/Admin/otherProducts/otherProducts'));

const Registrations = lazy(() => import('src/pages/Admin/registrations/Registrations'));
const RegistrationView = lazy(() => import('src/pages/Admin/registrations/Registration-View'));

const PartneredRegistrations = lazy(
  () => import('src/pages/Partner/registrations/partner-registration-list-view')
);
const PartnerRegistrationsView = lazy(
  () => import('src/pages/Partner/registrations/Registration-View')
);

// LMS
const lmsRoutes = [
  {
    path: 'academy',
    element: (
      <MainLayout fallback={<SplashScreen />}>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { element: <Navigate to="/academy/home" />, index: true },
      {
        path: 'home',
        element: (
          <PublicGuard>
            <AcademyLandingPage />
          </PublicGuard>
        ),
      },
      {
        path: 'resource',
        element: (
          <PublicGuard>
            <ResourceLmsView />
          </PublicGuard>
        ),
      },
      {
        path: 'student-profile',
        element: (
          <PublicGuard>
            <StudentProfile />
          </PublicGuard>
        ),
      },
      {
        path: 'course/:id',
        element: (
          <PublicGuard>
            <AcademyCourseSingleView />
          </PublicGuard>
        ),
      },
      {
        path: 'course/content/:id',
        element: (
          <PublicGuard isProtected>
            <AcademyContentView />
          </PublicGuard>
        ),
      },
      {
        path: 'exams',
        element: (
          <PublicGuard isProtected>
            <AcademyExams />
          </PublicGuard>
        ),
      },
      {
        path: 'exams/register/:id',
        element: (
          <PublicGuard isProtected>
            <AcademyExamRegister />
          </PublicGuard>
        ),
      },
      {
        path: 'exams/:studentExam/:exam',
        element: (
          <PublicGuard isProtected>
            <AcademyExamsView />
          </PublicGuard>
        ),
      },
      {
        // remove incept option from this page
        path: 'exams/:id/over',
        element: (
          <PublicGuard isProtected>
            <AcademyExamsOver />
          </PublicGuard>
        ),
      },
    ],
  },
];

const publicRoutes = [
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/home" />, index: true },
      {
        path: '/',
        children: [
          {
            path: 'home',
            element: <HomePage />,
          },
          {
            path: 'page/:title',
            element: <OtherProductPage />,
          },
          {
            path: 'robotics-ai-course',
            element: <CoursePage />,
          },
          {
            path: 'robotics-atl-tinkering-lab',
            element: <LabPage />,
          },
          {
            path: 'college-robotics-ai',
            element: <CollagePage />,
          },
          {
            path: 'robotics-trainer',
            element: <FacilitatorPage />,
          },
          // {
          //   path: 'DIY-kits',
          //   element: <KitPage />,
          // },
          {
            path: 'stem-exam',
            element: <AptitudeTest />,
          },
          {
            path: 'terms&condition',
            element: <Terms />,
          },
          {
            path: 'privacy&policy',
            element: <Privacy />,
          },
          {
            path: 'partner-program',
            element: <PartnerWithUs />,
          },
          {
            path: 'contactUs',
            element: <ContactUs />,
          },
          {
            path: 'aboutUs',
            element: <AboutUs />,
          },
          // add other routes here
        ],
      },

      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ path: 'landing', element: <LandingPage /> }, ...lmsRoutes],
  },
  {
    path: 'admin-login',
    element: (
      <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
        <LoginAdminPage role={AuthRoles.admin} />
      </AuthLayout>
    ),
  },
  {
    path: 'partner-login',
    element: (
      <AuthLayout image={LoginPartner} title="Authenticate as an partner" userRole="partner">
        <LoginAdminPage role={AuthRoles.partner} />
      </AuthLayout>
    ),
  },
  {
    path: 'school-login',
    element: (
      <AuthLayout image={LoginSchool} title="Authenticate as an school admin" userRole="school">
        <LoginAdminPage role={AuthRoles.school} />
      </AuthLayout>
    ),
  },
  {
    path: 'academy-login',
    element: (
      <AuthClassicLayoutFull>
        <LoginAdminPage role={AuthRoles.academy} />
      </AuthClassicLayoutFull>
    ),
  },
  {
    path: 'partner/registration',
    element: (
      <RegisterLayout
        name="Partner"
        image={PartnerBg}
        text="Join us in our mission to empower future innovators and technologists. As a partner, will have the opportunity to collaborate, contribute, and make a meaningful impact inthe world of coding, robotics, and technology education. Together, we can shape the future."
        route={paths.public.partner_login}
      >
        {/* <PartnerRegistration /> */}
        <PartnerRegistrationView />
      </RegisterLayout>
    ),
  },
  {
    path: 'school-registration',
    element: (
      <RegisterLayout
        name="School"
        image={SchoolBg}
        text="Become a STEM based Futuristic School. Empower your students with
        Coding,
        Robotics, Artificial Intelligence and beyond, prepare them for the Robotics &
        Artificial Intelligence era! Register Now"
        route={paths.public.school_login}
      >
        <SchoolRegistrationView />
        {/* <SchoolRegistrationView /> */}
      </RegisterLayout>
    ),
  },
  {
    path: 'academy-registration',
    element: (
      <RegisterLayout
        name="Academy"
        image={AcademyBg}
        text="Get the Complete Solution for STEM Education, Coding, Robotics, AI, IoT,
       and more. Our programs help students learn important
       skills for the future, making technology easy
        to understand and use."
        route={paths.public.academy_login}
      >
        <AcademyRegistrationView />
      </RegisterLayout>
    ),
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      // {
      //   path: 'login',
      //   element: <Outlet />,
      //   children: [
      //     {
      //       path: 'admin',
      //       element: (
      //         <AuthLayout
      //           image={LoginAdmin}
      //           title="Authenticate as an administrator"
      //           userRole="admin"
      //         >
      //           <LoginAdminPage role={AuthRoles.admin} />
      //         </AuthLayout>
      //       ),
      //     },
      //     {
      //       path: 'partner',
      //       element: (
      //         <AuthLayout
      //           image={LoginPartner}
      //           title="Authenticate as an partner"
      //           userRole="partner"
      //         >
      //           <LoginAdminPage role={AuthRoles.partner} />
      //         </AuthLayout>
      //       ),
      //     },
      //     {
      //       path: 'school',
      //       element: (
      //         <AuthLayout
      //           image={LoginSchool}
      //           title="Authenticate as an school admin"
      //           userRole="school"
      //         >
      //           <LoginAdminPage role={AuthRoles.school} />
      //         </AuthLayout>
      //       ),
      //     },
      //     {
      //       path: 'academy',
      //       element: (
      //         <AuthClassicLayoutFull>
      //           <LoginAdminPage role={AuthRoles.academy} />
      //         </AuthClassicLayoutFull>
      //       ),
      //     },
      //   ],
      // },

      // {
      //   path: 'select-user-role',
      //   element: (
      //     <AuthGuard>
      //       <AuthRoleLayout image={roleBg}>
      //         <ConfirmRole />
      //       </AuthRoleLayout>
      //     </AuthGuard>
      //   ),
      // },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot-password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'invitation/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <PasswordVerification />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },
      // {
      //   path: 'partner-registration',
      //   element: (
      //     <RegisterLayout
      //       name="Partner"
      //       image={PartnerBg}
      //       text="Join us in our mission to empower future innovators and technologists. As a partner, will have the opportunity to collaborate, contribute, and make a meaningful impact inthe world of coding, robotics, and technology education. Together, we can shape the future."
      //       route={paths.public.partner_regstration}
      //     >
      //       <PartnerRegistration />
      //     </RegisterLayout>
      //   ),
      // },
      // {
      //   path: 'school-registration',
      //   element: (
      //     <RegisterLayout
      //       name="School"
      //       image={SchoolBg}
      //       text="Empower your students with cutting-edge technology education. Register your school to unlock a world of possibilities in coding, robotics, and beyond."
      //       route={paths.auth.login_school}
      //     >
      //       <SchoolRegistration />
      //     </RegisterLayout>
      //   ),
      // },
      // {
      //   path: 'academy-registration',
      //   element: (
      //     <AuthClassicLayoutFull route={paths.auth.academy_registration}>
      //       <AcademyRegisterPage />
      //     </AuthClassicLayoutFull>
      //   ),
      // },
      {
        path: 'create-password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  // common
  // {
  //   path: 'certificate',
  //   element: <StudentCertificate2 />,
  // },
  {
    path: 'country',
    element: <CountryPage />,
  },
  {
    path: 'other-products',
    element: <OtherProductsDashboard />,
  },

  {
    path: 'create-otherproduct',
    element: <CreateOtherProduct />,
  },
  {
    path: 'update-otherproduct/:id',
    element: <CreateOtherProduct />,
  },
  {
    path: 'registrations',
    element: <Registrations />,
  },
  {
    path: 'registration/:id',
    element: <RegistrationView />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },

  // partner
  {
    path: 'partner',
    element: <AdminPartnerList />,
  },
  { path: 'partner/:id', element: <PartnerProfileView /> },
  {
    path: 'partner-type',
    element: <PartnerTypePage />,
  },
  {
    path: 'partner-income',
    element: <PartnerCommissionAdmin />,
  },
  {
    path: 'earnings',
    element: <EarningAdminPage />,
  },
  {
    path: 'sale',
    element: <SalePage />,
  },
  { path: 'sale/:id', element: <SaleDetailsPage /> },

  {
    path: 'sale-items',
    element: <SaleItemsListView />,
  },

  // School
  {
    path: 'school',
    element: <SchoolPage />,
  },
  {
    path: 'class',
    element: <ClassList />,
  },
  { path: 'school/update/:id', element: <SchoolDetailsPage /> },
  { path: 'school/singleView/:id', element: <SchoolSingleViewPage /> },

  {
    path: 'schoolUser',
    element: <SchoolUserPage />,
  },

  {
    path: 'labModel',
    element: <LabModelPage />,
  },
  {
    path: 'supportMaterial',
    element: <SupportMaterialPage />,
  },
  {
    path: 'course',
    element: <CourseListPage />,
  },
  { path: 'course/:id', element: <CourseSingleViewPage /> },
  {
    path: 'exam',
    element: <ExamPage />,
  },
  { path: 'exam/:id', element: <ExamSingleViewPage /> },
  {
    path: 'students',
    element: <StudentPage />,
  },
  { path: 'student/:id', element: <StudentSingleViewPage /> },
  {
    path: 'student-certificates',
    element: <StudentCertificatePage />,
  },
  {
    path: 'certificate-template',
    element: <CertificateTemplateList />,
  },
  {
    path: 'student-exams',
    element: <StudentExams />,
  },
  {
    path: 'resource',
    element: <ResourceView />,
  },
  {
    path: 'subscription',
    element: <SubscriptionView />,
  },
];

// partner
const partnerRoutes = [
  {
    path: 'earnings',
    element: <PartneredEarnings />,
  },
  {
    path: 'partnership-schools',
    element: <PartneredSchools />,
  },

  {
    path: 'sale',
    element: <PartneredSales />,
  },
  { path: 'sale/:id', element: <SaleDetailsPage /> },

  {
    path: 'sale-items',
    element: <SaleItemsListViewForPartner />,
  },
  {
    path: 'partner',
    element: <PartnersList />,
  },
  { path: 'partner/:id', element: <PartnerProfileView /> },
  {
    path: 'partner-income',
    element: <PartnerCommissionListView />,
  },

  {
    path: 'partner-type',
    element: <PartnerTypePartnerView />,
  },
  {
    path: 'registrations-partner',
    element: <PartneredRegistrations />,
  },
  {
    path: 'registrations-view/:id',
    element: <PartnerRegistrationsView />,
  },
];

// school user (school admin)
const schoolUserRoutes = [
  {
    path: 'school-profile',
    element: <SchoolProfile />,
  },
  {
    path: 'course',
    element: <CourseListView />,
  },
  {
    path: 'course/:id',
    element: <CourseSingleView />,
  },
  {
    path: 'session/:id',
    element: <SessionView />,
  },
  {
    path: 'lab',
    element: <LabModelView />,
  },
  {
    path: 'lab/:id',
    element: <LabView />,
  },
  {
    path: 'resource',
    element: <ResourceSchoolView />,
  },
];

export { authRoutes, adminRoutes, publicRoutes, partnerRoutes, schoolUserRoutes };
