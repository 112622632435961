import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createStudentExam,
  deleteStudentExam,
  getAllStudentExam,
  registerStudentExam,
  getStudentExamDetails,
  getAllStudentExamList,
  submitStudentExamInput,
  updateStudentExamDetails,
  getAllAttendedStudentExam,
} from '../api/studentExam';

export const studentExamSlice = createSlice({
  name: 'studentExam',
  initialState: {
    loading: false,
    error: {},
    // sale
    studentExams: {},
    attendedStudentExams: {},
    studentExamsInput: {},
    staffDropList: [],
    studentExamDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // student
    [submitStudentExamInput.pending]: (state) => {
      state.loading = true;
    },
    [submitStudentExamInput.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.studentExamsInput = action.payload;
    },
    [submitStudentExamInput.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [registerStudentExam.pending]: (state) => {
      state.loading = true;
    },
    [registerStudentExam.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.studentExams = action.payload;
    },
    [registerStudentExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createStudentExam.pending]: (state) => {
      state.loading = true;
    },
    [createStudentExam.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.studentExams = action.payload;
    },
    [createStudentExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all school
    [getAllStudentExam.pending]: (state) => {
      state.loading = true;
    },
    [getAllStudentExam.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentExams = action.payload;
      state.error = {};
    },
    [getAllStudentExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllStudentExamList.pending]: (state) => {
      state.loading = true;
    },
    [getAllStudentExamList.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentExams = action.payload;
      state.error = {};
    },
    [getAllStudentExamList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all school
    [getAllAttendedStudentExam.pending]: (state) => {
      state.loading = true;
    },
    [getAllAttendedStudentExam.fulfilled]: (state, action) => {
      state.loading = false;
      state.attendedStudentExams = action.payload;
      state.error = {};
    },
    [getAllAttendedStudentExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single school details
    [getStudentExamDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStudentExamDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentExamDetails = action.payload;
      state.error = {};
    },
    [getStudentExamDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update school details
    [updateStudentExamDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateStudentExamDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentExams = action.payload;
      state.error = {};
    },
    [updateStudentExamDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete school
    [deleteStudentExam.pending]: (state) => {
      state.loading = true;
    },
    [deleteStudentExam.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state);
      console.log('JSON State :', jsonState);

      const modifiedSchoolList = {
        ...jsonState,
        studentExams: jsonState.studentExams?.filter(
          (studentExams) => studentExams._id !== action.payload
        ),
      };

      state.loading = false;
      state.studentExams = modifiedSchoolList;
      state.error = {};
    },
    [deleteStudentExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = studentExamSlice.actions;

export default studentExamSlice.reducer;
