import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Tab, Container } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';

import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllLmsExam } from 'src/server/api/lmsExam';
import { getAllLmsExamCertificate } from 'src/server/api/lmsExamCertificate';
import { getAllStudentExamList, getAllAttendedStudentExam } from 'src/server/api/studentExam';

import DashboardLoading from 'src/components/loading/DashboardLoading';

import ExamList from 'src/sections/lms/exams/exam-list';
import CertificateList from 'src/sections/lms/exams/certificate-list';
import RegisteredExamList from 'src/sections/lms/exams/registered-exam-list';

function AcademyExams() {
  const dispatch = useDispatch();
  const params = useParams();

  const { exams, studentExam, loading, certificates, attendedStudentExams, certificateLoading } =
    useSelector((state) => ({
      exams: state.lmsExam.exams,
      studentExam: state.studentExam.studentExams,
      loading: state.lmsExam.loading,
      certificates: state.lmsExamCertificate.certificates,
      certificateLoading: state.lmsExamCertificate.loading,
      attendedStudentExams: state.studentExam.attendedStudentExams,
    }));

  const mobile = useResponsive('down', 'md');

  const [value, setValue] = useState(params?.state?.examId || '1');
  const [pageCertificate, setPageCertificate] = useState(1);
  const [pageExam, setPageExam] = useState(1);
  const [pageStudentExam, setPageStudentExam] = useState(1);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const certificateTotal = Math.ceil(certificates?.total / 12);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const examTotal = Math.ceil(exams?.total / 12);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const studentExamTotal = Math.ceil(studentExam?.total / 12);

  useEffect(() => {
    if (params?.state) {
      setValue(params?.state?.examId || '0');
    }
  }, [params?.state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const credentialsStudentExam = {
      page: pageStudentExam,
      search: '',
      limit: 12,
      sort: 'pending',
      dispatch,
    };
    if (value === '0') {
      dispatch(getAllStudentExamList(credentialsStudentExam));
    }
  }, [dispatch, pageStudentExam, value]);

  useEffect(() => {
    const credentials = {
      page: pageExam,
      search: '',
      limit: 12,
      dispatch,
      exam_type: 'common',
    };
    if (value === '1') {
      dispatch(getAllLmsExam(credentials));
    }
  }, [dispatch, pageExam, value]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllAttendedStudentExam(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: pageCertificate,
      search: '',
      limit: 12,
      dispatch,
    };
    if (value === '2') {
      dispatch(getAllLmsExamCertificate(credentials));
    }
  }, [dispatch, pageCertificate, value]);

  const modifiedStudentExam = {
    exams: studentExam?.studentExams,
  };

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Exams" />
      <Box sx={{ width: '100%', mt: mobile ? 10 : 3, typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Registered Exams" value="0" />
              <Tab label="All Exams" value="1" />
              <Tab label="Certificates" value="2" />
            </TabList>
          </Box>
          <TabPanel value="0" sx={{ px: 0 }}>
            {loading ? (
              <DashboardLoading />
            ) : (
              <RegisteredExamList
                materialData={modifiedStudentExam}
                attendedExams={attendedStudentExams}
                pageExam={pageStudentExam}
                setPageExam={setPageStudentExam}
                examTotal={studentExamTotal}
              />
            )}
          </TabPanel>

          <TabPanel value="1" sx={{ px: 0 }}>
            {loading ? (
              <DashboardLoading />
            ) : (
              <ExamList
                materialData={exams}
                attendedExams={attendedStudentExams}
                pageExam={pageExam}
                setPageExam={setPageExam}
                examTotal={examTotal}
              />
            )}
          </TabPanel>

          <TabPanel value="2" sx={{ px: 0 }}>
            {certificateLoading ? (
              <DashboardLoading />
            ) : (
              <CertificateList
                materialData={certificates}
                page={pageCertificate}
                setPage={setPageCertificate}
                total={certificateTotal}
              />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
}

export default AcademyExams;
