import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSchoolCourse,
  getAllSchoolCourse,
  deleteSchoolCourse,
  getSchoolCourseDetails,
  createCompletedContent,
  createUnCompletedContent,
  updateSchoolCourseDetails,
} from '../api/schoolCourse';

export const schoolCourseSlice = createSlice({
  name: 'schoolCourse',
  initialState: {
    loading: false,
    error: {},
    // schoolCourse
    schoolCourse: {},
    staffDropList: [],
    schoolCourseDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create schoolCourse details
    [createSchoolCourse.pending]: (state) => {
      state.loading = true;
    },
    [createSchoolCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schools;
      const newSchoolList = jsonState?.schools;
      const modifiedSchoolUserList = {
        ...jsonState,
        schools: Array.isArray(newSchoolList)
          ? [action.payload, ...newSchoolList]
          : [action.payload],
      };
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.schoolCourse = modifiedSchoolUserList;
      state.error = {};
    },
    [createSchoolCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all schoolCourse
    [getAllSchoolCourse.pending]: (state) => {
      state.loading = true;
    },
    [getAllSchoolCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolCourse = action.payload;
      state.error = {};
    },
    [getAllSchoolCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single schoolCourse details
    [getSchoolCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolCourseDetails = action.payload;
      state.error = {};
    },
    [getSchoolCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update schoolCourse details
    [updateSchoolCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSchoolCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [updateSchoolCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete schoolCourse
    [deleteSchoolCourse.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchoolCourse.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.schoolCourse;
      console.log('JSON State :', jsonState);

      const modifiedSchoolUserList = {
        ...jsonState,
        schools: jsonState.schools?.filter((schools) => schools._id !== action.payload),
      };

      state.loading = false;
      state.schoolCourse = modifiedSchoolUserList;
      state.error = {};
    },
    [deleteSchoolCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // create completed course content
    [createCompletedContent.pending]: (state) => {
      state.loading = true;
    },
    [createCompletedContent.fulfilled]: (state, action) => {
      // const jsonState = covertToJSON(state)?.schools;
      // const newSchoolList = jsonState?.schools;
      // const modifiedSchoolUserList = {
      //   ...jsonState,
      //   schools: Array.isArray(newSchoolList)
      //     ? [action.payload, ...newSchoolList]
      //     : [action.payload],
      // };
      state.loading = false;
      state.schoolCourseDetails = action.payload;
      state.error = {};
    },
    [createCompletedContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createUnCompletedContent.pending]: (state) => {
      state.loading = true;
    },
    [createUnCompletedContent.fulfilled]: (state, action) => {
      // const jsonState = covertToJSON(state)?.schools;
      // const newSchoolList = jsonState?.schools;
      // const modifiedSchoolUserList = {
      //   ...jsonState,
      //   schools: Array.isArray(newSchoolList)
      //     ? [action.payload, ...newSchoolList]
      //     : [action.payload],
      // };
      state.loading = false;
      state.schoolCourseDetails = action.payload;
      state.error = {};
    },
    [createUnCompletedContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = schoolCourseSlice.actions;

export default schoolCourseSlice.reducer;
