import { useState } from 'react';

import {
  Box,
  Grid,
  Card,
  Stack,
  styled,
  Button,
  Container,
  CardMedia,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Form from 'src/sections/landingPage/segment/experienceLabForm';
import VideoPlayer from 'src/sections/landingPage/segment/VideoPlayer';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';
import VideoCarousel from 'src/sections/landingPage/segment/videoCarousal';

export default function LabPage() {
  const { isMobile } = useDevice();

  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  const [alignment, setAlignment] = useState('thinkering');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  console.log(alignment);

  const ClientImageUrls = [
    {
      url: '/assets/imagesWeb/Lab/client-image-1.png',
    },
    {
      url: '/assets/imagesWeb/Lab/client-image-2.png',
    },
    {
      url: '/assets/imagesWeb/Lab/client-image-3.png',
    },
    {
      url: '/assets/imagesWeb/Lab/client-image-4.png',
    },
    {
      url: '/assets/imagesWeb/Lab/client-image-5.png',
    },
  ];

  const curriculumImages =
    alignment === 'innovation'
      ? [
          { img: '/assets/imagesWeb/Lab/python.png', title: 'Python' },
          { img: '/assets/imagesWeb/Lab/github.png', title: 'Coding' },
          { img: '/assets/imagesWeb/Lab/mechine.png', title: 'Robotics' },
          {
            img: '/assets/imagesWeb/Lab/electronics.png',
            title: 'Artificial Intelligence & Machine Learning',
          },
          { img: '/assets/imagesWeb/Lab/mobile.png', title: 'AIoT' },
          { img: '/assets/imagesWeb/Lab/3d.png', title: '3D Printing' },
        ]
      : [
          // { img: '/assets/images/Lab/python.png', title: 'Python' },
          { img: '/assets/imagesWeb/Lab/github.png', title: 'Coding' },
          { img: '/assets/imagesWeb/Lab/mechine.png', title: 'Robotics' },
          {
            img: '/assets/imagesWeb/Lab/electronics.png',
            title: 'Artificial Intelligence & Machine Learning',
          },
          { img: '/assets/imagesWeb/Lab/mobile.png', title: 'AIoT' },
          // { img: '/assets/images/Lab/3d.png', title: '3d Printing' },
        ];
  const LabEquipmentImages =
    alignment === 'robotics'
      ? [
          { img: '/assets/imagesWeb/Lab/robokits.png', title: 'Robotics Kits' },
          { img: '/assets/imagesWeb/Lab/stemkits.png', title: 'STEM Kits' },
          { img: '/assets/imagesWeb/Lab/aiotkits.png', title: 'AIoT kits' },
          { img: '/assets/imagesWeb/Lab/solderingkits.png', title: 'Electronics Kits ' },
        ]
      : [
          { img: '/assets/imagesWeb/Lab/robokits.png', title: 'Robotics Kits' },
          { img: '/assets/imagesWeb/Lab/aikits.png', title: 'AI kits' },
          { img: '/assets/imagesWeb/Lab/3dprinting.png', title: '3D printing' },
          { img: '/assets/imagesWeb/Lab/mech-ele.png', title: 'Electronics and Mechanical tools' },
          { img: '/assets/imagesWeb/Lab/stemkits.png', title: 'STEM Kits' },
          { img: '/assets/imagesWeb/Lab/aiotkits.png', title: 'AIoT kits' },
          { img: '/assets/imagesWeb/Lab/solderingkits.png', title: 'Electronics Kits ' },
          { img: '/assets/imagesWeb/Lab/ring.png', title: 'Safety equipments' },
        ];
  // alignment === 'innovation'
  //   ? [
  //       { img: '/assets/imagesWeb/Lab/robokits.png', title: 'Robotics Kits' },
  //       { img: '/assets/imagesWeb/Lab/aikits.png', title: 'AI kits' },
  //       { img: '/assets/imagesWeb/Lab/3dprinting.png', title: '3D printing' },
  //       { img: '/assets/imagesWeb/Lab/mech-ele.png', title: 'Electronics and Mechanical tools' },
  //       { img: '/assets/imagesWeb/Lab/stemkits.png', title: 'STEM Kits' },
  //       { img: '/assets/imagesWeb/Lab/aiotkits.png', title: 'AIoT kits' },
  //       { img: '/assets/imagesWeb/Lab/solderingkits.png', title: 'Electronics Kits ' },
  //       { img: '/assets/imagesWeb/Lab/ring.png', title: 'Safety equipments' },
  //     ]
  //   : [
  //       { img: '/assets/imagesWeb/Lab/robokits.png', title: 'Robotics Kits' },
  //       { img: '/assets/imagesWeb/Lab/stemkits.png', title: 'STEM Kits' },
  //       { img: '/assets/imagesWeb/Lab/aiotkits.png', title: 'AIoT kits' },
  //       { img: '/assets/imagesWeb/Lab/solderingkits.png', title: 'Electronics Kits ' },
  //     ];

  const featuresBoxes =
    alignment === 'innovation'
      ? [
          { img: '/assets/imagesWeb/Lab/cbse.png', title: 'CBSE, ICSE recommended Curriculum' },
          { img: '/assets/imagesWeb/Lab/learning.png', title: 'Learning Management System' },
          {
            img: '/assets/imagesWeb/Lab/advanced.png',
            title: 'Advanced Technology Kits & Safety Equipments',
          },
          {
            img: '/assets/imagesWeb/Lab/engaged.png',
            title: 'Engage in real-world problem-solving exercises',
          },
          {
            img: '/assets/imagesWeb/Lab/oppertunity.png',
            title: 'Opportunity to learn emerging technologies in the fast-paced era',
          },
          {
            img: '/assets/imagesWeb/Lab/national.png',
            title: 'Opportunity to win National & International STEM competitions – ',
          },
          {
            img: '/assets/imagesWeb/Lab/edits.png',
            title: 'Skill development Lab',
          },
        ]
      : [
          {
            img: '/assets/imagesWeb/Lab/learning.png',
            title: 'Integrated Curriculum aligned with CBSE and ICSE',
          },
          { img: '/assets/imagesWeb/Lab/cbse.png', title: 'Learning Management System' },

          {
            img: '/assets/imagesWeb/Lab/national.png',
            title: 'Inspire students with DIY kits for meaningful projects',
          }, // { img: '/assets/imagesWeb/Lab/advanced.png', title: 'Advanced Technology Kits and Safety Equipment' },
          {
            img: '/assets/imagesWeb/Lab/engaged.png',
            title: 'National and International Level events & competitions',
          },
          {
            img: '/assets/imagesWeb/Lab/oppertunity.png',
            title: 'Engage in real-world problem-solving exercises',
          },
          {
            img: '/assets/imagesWeb/Lab/advanced.png',
            title: 'Opportunity to learn emerging technologies in the fast-paced era',
          },
        ];

  return (
    <>
      <MetaHelmet title="Stem & Tinkering-lab" />
      {/* main content */}
      <StyledRoot>
        {isMobile === true ? (
          <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
            <Grid
              container
              spacing={2}
              className="image-container"
              sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
            >
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ color: '#E1E1E1', fontSize: 15, fontWeight: 600 }}>
                    Say Hello to <br />{' '}
                    <span className="lab-main-container-texts-mobile">STEM & Tinkering Lab</span>
                  </Typography>
                  <Typography className="course-subtitle-mobile">
                    STEM Labs are innovation spaces to tinker and learn emerging technologies such
                    as Coding, Robotics, AI, IoT. Our structured curriculum is aligned with NEP
                    2020, CBSE, ICSE, IB Schools.
                  </Typography>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    py={3}
                    spacing={2}
                  >
                    <a
                      href={paths.public.school_login}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Button
                        size="medium"
                        variant="filled"
                        sx={{ backgroundColor: '#072256', color: 'white' }}
                      >
                        Go To School Login
                      </Button>
                    </a>
                    <a
                      style={{ textDecoration: 'none', color: 'white' }}
                      href={paths.public.school_regstration}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button size="medium" variant="outlined" sx={{ color: 'white' }}>
                        Experience Lab For Free
                      </Button>
                    </a>
                  </Stack>
                </Box>
              </Grid>

              {/* Image Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box sx={{ width: '70%', height: '100%' }}>
                  <img
                    src="/assets/imagesWeb/Lab/main-image.png"
                    alt="mainimg"
                    style={{ width: '80%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container maxWidth="xl">
            <Grid container spacing={2} className="image-container" sx={{ marginTop: 3, pb: 3 }}>
              {/* Image Column */}

              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <Typography className="stem-exp-course-head">
                    Say Hello to &nbsp;
                    <span className="lab-main-container-texts">STEM & Tinkering Lab</span>
                  </Typography>
                  <Typography className="course-subtitle" lineHeight={1.5}>
                    STEM Labs are innovation spaces to tinker and learn emerging technologies such
                    as Coding, Robotics, AI, IoT. Our structured curriculum is aligned with NEP
                    2020, CBSE, ICSE, IB Schools.
                  </Typography>
                  {/* <Stack alignItems="center" justifyContent="center" py={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={5}>
                      <Button className="lab-main-button1">Go to School Login</Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Button className="lab-main-button2">Register a free Demo</Button>
                    </Grid>
                  </Grid>
                </Stack> */}

                  <Grid display="flex" mt={3}>
                    <a
                      href={paths.public.school_login}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <Stack>
                        <Button className="lab-main-button1">Go To School Login</Button>
                      </Stack>
                    </a>
                    <a
                      style={{ textDecoration: 'none', color: 'white' }}
                      href={paths.public.school_regstration}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack ml={2}>
                        <Button className="lab-main-button2">Experience Lab For Free</Button>
                      </Stack>
                    </a>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Box sx={{ width: '50%', height: '100%' }}>
                  <img
                    src="/assets/imagesWeb/Lab/main-image.png"
                    alt="mainimg"
                    style={{ width: '80%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
      </StyledRoot>
      {/* video player */}
      <Stack>
        <VideoPlayer videoId="0LUcQPLwN8E" />
      </Stack>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="row"
        paddingY={isMobile ? 3 : 5}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid',
            padding: 4,
            borderRadius: 45,
            // width: isMobile ? 400 : 450,
          }}
          className="btn-group"
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            // onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              style={{ borderRadius: 25, marginLeft: 5 }}
              value="thinkering"
              sx={{ fontSize: isMobile ? 12 : 14 }}
              onClick={handleChange}
              className={`lab-switch-button ${alignment === 'thinkering' ? 'active' : ''}`}
            >
              Tinkering Centre
            </ToggleButton>
            <ToggleButton
              value="innovation"
              sx={{ fontSize: isMobile ? 12 : 14 }}
              onClick={handleChange}
              className={`lab-switch-button ${alignment === 'innovation' ? 'active' : ''}`}
            >
              Innovation Space
            </ToggleButton>
            <ToggleButton
              style={{ borderRadius: 25, marginLeft: 5 }}
              value="robotics"
              sx={{ fontSize: isMobile ? 12 : 14 }}
              onClick={handleChange}
              className={`lab-switch-button ${alignment === 'robotics' ? 'active' : ''}`}
            >
              Robotics & AIoT Lab
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>
      {alignment === 'innovation' && (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Card
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              borderRadius: '15px',
              boxShadow: 'none',
              height: isMobile ? 'auto' : '350px',
              width: isMobile ? 'auto' : '1100px',
            }}
          >
            {isMobile === true ? (
              <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7, pt: 2 }}>
                <Grid
                  container
                  spacing={2}
                  className="image-container"
                  sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
                >
                  {/* Text Column */}

                  {/* Image Column */}
                  <Grid item xs={12} sm={6} className="image-box-lab-mobile">
                    <Box sx={{ width: '100%', height: '100%' }}>
                      <img
                        src="/assets/imagesWeb/Lab/innovationNew.jpeg"
                        alt="mainimg"
                        style={{ objectFit: 'cover', width: '350px', height: '175px' }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="content-box"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: '',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className="lab-box-content-header-mobile">
                        Innovation Space
                      </Typography>
                      <Typography className="lab-box-content-subtitle-mobile" textAlign="justify">
                        {`
                        The "Innovation Space" focuses on futuristic technologies such as Robotics, Python, AIoT, 3D printing, and many more. The state-of-the-art equipment and teaching methodologies prepare students for the challenges and opportunities of becoming new innovators.
                        `}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <Grid container spacing={2} className="image-container" mt={3}>
                {/* Image Column */}

                {/* Text Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/assets/imagesWeb/Lab/innovationNew.jpeg"
                      alt="mainimg"
                      style={{ objectFit: 'cover', width: '395px', height: '222px' }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: '',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 5,
                    }}
                  >
                    <Typography className="lab-box-content-header">Innovation Space</Typography>
                    <Typography className="lab-box-content-subtitle" textAlign="justify">
                      {`
                      The "Innovation Space" focuses on futuristic technologies such as Robotics, Python, AIoT, 3D printing, and many more. The state-of-the-art equipment and teaching methodologies prepare students for the challenges and opportunities of becoming new innovators.
                      `}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
        </Box>
      )}
      {alignment === 'robotics' && (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Card
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              borderRadius: '15px',
              boxShadow: 'none',
              height: isMobile ? 'auto' : '350px',
              width: isMobile ? 'auto' : '1100px',
            }}
          >
            {isMobile === true ? (
              <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7, pt: 2 }}>
                <Grid
                  container
                  spacing={2}
                  className="image-container"
                  sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
                >
                  {/* Text Column */}

                  {/* Image Column */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="image-box-lab-mobile"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Box sx={{ width: '100%', height: '100%' }}>
                      <img
                        src="/assets/imagesWeb/Lab/robotics.png"
                        alt="mainimg"
                        style={{ objectFit: 'cover', width: '350px', height: '175px' }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="content-box"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: '',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className="lab-box-content-header-mobile">
                        Robotics & AloT Lab
                      </Typography>
                      <Typography className="lab-box-content-subtitle-mobile" textAlign="justify">
                        {`The "Robotics and AIoT Lab" serves as an innovative environment where cutting-edge STEM concepts, Robotics, and Coding converge with the transformative world of Artificial Intelligence of Things (AIoT). Within the lab, students engage in immersive, hands-on experiments with IoT technology, and through the integration of machine learning, they are empowered to cultivate critical thinking and the ability to tackle complex problem-solving tasks.`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <Grid container spacing={2} className="image-container" mt={3}>
                {/* Image Column */}

                {/* Text Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/assets/imagesWeb/Lab/robotics.png"
                      alt="mainimg"
                      style={{ objectFit: 'cover', width: '395px', height: '222px' }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: '',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 5,
                    }}
                  >
                    <Typography className="lab-box-content-header">Robotics & Alot Lab</Typography>
                    <Typography className="lab-box-content-subtitle" textAlign="justify">
                      {`The "Robotics and AIoT Lab" serves as an innovative environment where cutting-edge STEM concepts, Robotics, and Coding converge with the transformative world of Artificial Intelligence of Things (AIoT). Within the lab, students engage in immersive, hands-on experiments with IoT technology, and through the integration of machine learning, they are empowered to cultivate critical thinking and the ability to tackle complex problem-solving tasks.`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
        </Box>
      )}
      {alignment !== 'robotics' && alignment !== 'innovation' && (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Card
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              borderRadius: '15px',
              boxShadow: 'none',
              height: isMobile ? 'auto' : '350px',
              width: isMobile ? 'auto' : '1100px',
            }}
          >
            {isMobile === true ? (
              <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7, pt: 2 }}>
                <Grid
                  container
                  spacing={2}
                  className="image-container"
                  sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
                >
                  {/* Text Column */}

                  {/* Image Column */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="image-box-lab-mobile"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Box sx={{ width: '100%', height: '100%' }}>
                      <img
                        src="/assets/imagesWeb/Lab/thinkeringNew.jpeg"
                        alt="mainimg"
                        style={{ objectFit: 'cover', width: '350px', height: '175px' }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="content-box"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: '',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className="lab-box-content-header-mobile">
                        Tinkering Centre
                      </Typography>
                      <Typography className="lab-box-content-subtitle-mobile" textAlign="justify">
                        {`The "Tinkering Centre" provides a level-wise STEM training platform for the
                        1-5 and 6-12 categories in various subjects like Coding, Robotics, AI, IoT,
                        3D Printing, and many more. It contains sophisticated, quality kits on
                        various emerging technologies for upcoming Tinkerers.`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <Grid container spacing={2} className="image-container" mt={3}>
                {/* Image Column */}

                {/* Text Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/assets/imagesWeb/Lab/thinkeringNew.jpeg"
                      alt="mainimg"
                      style={{ objectFit: 'cover', width: '395px', height: '222px' }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: '',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 5,
                    }}
                  >
                    <Typography className="lab-box-content-header">Tinkering Centre</Typography>
                    <Typography className="lab-box-content-subtitle" textAlign="justify">
                      {`The "Tinkering Centre" provides a level-wise STEM training platform for the 1-5
                      and 6-12 categories in various subjects like Coding, Robotics, AI, IoT, 3D
                      Printing, and many more. It contains sophisticated, quality kits on various
                      emerging technologies for upcoming Tinkerers.`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
        </Box>
      )}

      <Stack sx={{ py: isMobile === true ? 0 : 3 }}>
        <Grid>
          <Typography className="course-video-header">How We Work</Typography>
        </Grid>
        <Stack sx={{ px: isMobile ? '' : 10, py: 2 }}>
          <VideoCarousel videoId="zqu2p2FB3Kg" videoId2="vyTuY58hsL0" videoId3="PnFmgcyEGQA" />
        </Stack>
      </Stack>
      <Stack sx={{ py: 3 }}>
        <Form />
      </Stack>

      <Container>
        <Grid container spacing={1} paddingY={5}>
          <Grid item xs={12} md={6}>
            <Stack display="flex" alignItems="center" justifyContent="center">
              <Typography className="curriculum-title" style={{ color: '#FFFFFF' }}>
                Curriculum
              </Typography>
            </Stack>
            <Grid container spacing={isMobile ? 1 : 0} className="curriculum-alignment">
              {curriculumImages?.map((i, k) => (
                <Grid item xs={6} md={6} key={k}>
                  <Box paddingTop={3}>
                    <Stack className="lab-Curriculum-boxes" pb={2}>
                      <Stack display="flex" alignItems="center" justifyItems="center">
                        <Stack paddingTop={3} className="lab-curriculum-img-parent">
                          <img
                            alt={i?.title}
                            src={i?.img}
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                            className="img-container-lab-curriculum"
                          />
                        </Stack>
                        <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              ))}

              {/* <Grid item xs={6} md={6}>
                {curriculumImages?.slice?.(3, 6)?.map((i, k) => (
                  <Box key={k} paddingTop={3}>
                    <Stack className="lab-Curriculum-boxes">
                      <Stack display="flex" alignItems="center" justifyItems="center">
                        <Stack paddingTop={3} className="lab-curriculum-img-parent">
                          <img
                            alt={i?.title}
                            src={i?.img}
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                            className="img-container-lab-curriculum"
                          />
                        </Stack>
                        <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                      </Stack>
                    </Stack>
                  </Box>
                ))}
              </Grid> */}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            className={isMobile ? 'curriculum-image-mobile' : 'curriculum-image'}
          >
            <Stack className={isMobile === true ? 'curriculum-image-mobile' : 'curriculum-image'}>
              <img
                src="/assets/imagesWeb/Lab/curriculum.png"
                style={{ objectFit: 'contain' }}
                alt="curriculum"
                // eslint-disable-next-line no-nested-ternary
                height={isMobile ? '100%' : alignment === 'innovation' ? '750px' : '600px'}
                width={isMobile ? '50%' : '70%'}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {/* <Container> */}
      <Stack className="feature">
        <Typography className="feature-title">Features</Typography>
      </Stack>
      <Container>
        <Grid container spacing={isMobile ? '0' : 5} paddingY={3} paddingX={isMobile ? 0 : 5}>
          {featuresBoxes?.map((i, k) => (
            <>
              {isMobile ? (
                <Stack
                  px={1.5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={isMobile ? 'feature-lab-boxes-mobile' : 'feature-lab-boxes'}
                  mb={3}
                >
                  <img
                    alt={i?.title}
                    src={i?.img}
                    style={{
                      height: isMobile ? ' 50px' : '100%',
                      width: isMobile ? '50%' : '100%',
                      objectFit: 'contain',
                    }}
                    // className="img-container-feature-lab"
                  />
                  <Typography
                    width="400px"
                    className={isMobile ? 'feature-lab-box-title-mobile' : 'feature-lab-box-title'}
                  >
                    {i?.title}
                  </Typography>
                </Stack>
              ) : (
                <Grid key={k} item sm={6} xs={12}>
                  <Box paddingTop={2}>
                    <Stack className="feature-lab-boxes">
                      <Grid display="flex" alignItems="center" justifyContent="center">
                        <Stack paddingTop={3} className="img-parent-feature">
                          <img
                            alt={i?.title}
                            src={i?.img}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                            }}
                            className="img-container-feature-lab"
                          />
                        </Stack>
                        <Stack className="feature-lab-box-title">{i?.title}</Stack>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Container>
      {/* </Container> */}

      <Stack padding={isMobile ? 0 : 5} paddingTop={isMobile ? 10 : 10}>
        <Typography className="curriculum-title" style={{ color: '#FFFFFF' }}>
          Lab Equipments
        </Typography>
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={2}
        className="LabEquipment"
      >
        <Card
          sx={{
            backgroundColor: '#1A1A1A',
            border: 'none',
            boxShadow: 'none',
            // eslint-disable-next-line no-nested-ternary
            height: isMobile ? 'auto' : alignment === 'innovation' ? '400px' : '200px',
            // width: isMobile ? 'auto' : '900px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: isMobile ? '10px' : '',
          }}
        >
          {isMobile === true ? (
            <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 0, pt: 2 }}>
              <Grid
                container
                spacing={2}
                className="image-container"
                sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
              >
                {/* Text Column */}
                <Grid container spacing={1} paddingY={3} paddingX={1}>
                  {LabEquipmentImages?.slice(0, alignment === 'innovation' ? 7 : 3)?.map((i, k) => (
                    <Grid key={k} item xs={6} sm={4}>
                      <Box paddingTop={2}>
                        <Stack className="">
                          <Stack display="flex" alignItems="center" justifyItems="center">
                            <Stack paddingTop={3} className="img-parent">
                              <img
                                alt={i?.title}
                                src={i?.img}
                                style={{ height: '70px', width: '100%', objectFit: 'contain' }}
                                className="img-container-course"
                              />
                            </Stack>
                            <Stack className="course-box-title">{i?.title}</Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {/* Image Column */}
                <Grid item xs={12} sm={6} className="image-box">
                  <Box
                    sx={{
                      width: '70%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/assets/imagesWeb/Lab/box-image.png"
                      alt="mainimg"
                      style={{ width: '100%', objectFit: 'contain' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          ) : (
            // <Grid container spacing={2} className="image-container">
            //   <Container>
            //     <Grid container spacing={alignment === 'robotics' ? 20 : 1} paddingY={3} paddingX={1}>
            //       {LabEquipmentImages?.map((i, k) => (
            //         <Grid key={k} item xs={12} sm={3}>
            //           <Box paddingTop={2}>
            //             <Stack className="">
            //               <Stack display="flex" alignItems="center" justifyItems="center">
            //                 <Stack paddingTop={3} className="img-parent">
            //                   <img
            //                     alt={i?.title}
            //                     src={i?.img}
            //                     style={{ height: '80px', width: '100%', objectFit: 'contain' }}
            //                     className="img-container-course"
            //                   />
            //                 </Stack>
            //                 <Stack className="course-box-title">{i?.title}</Stack>
            //               </Stack>
            //             </Stack>
            //           </Box>
            //         </Grid>
            //       ))}
            //     </Grid>
            //     {/* ) : ( */}
            //   </Container>
            // </Grid>
            <Container maxWidth="xl">
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={10}>
                {LabEquipmentImages?.map((i, k) => (
                  <Box>
                    <Box display="flex" justifyContent="center">
                      <CardMedia
                        component="img"
                        alt={i?.title}
                        src={i?.img}
                        sx={{ height: '100px', width: '100px', objectFit: 'contain' }}
                        // className="img-container-course"
                      />
                    </Box>
                    <Typography className="course-box-title" fontWeight={700} pt={2}>
                      {i?.title}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Container>
          )}
        </Card>
      </Box>

      <Grid sx={{ padding: isMobile ? ' ' : 7 }}>
        <Typography className="client-list-header">Our Top Clients</Typography>
      </Grid>
      <Grid container spacing={isMobile ? 2 : 5} justifyContent="center">
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 10}
          p={isMobile ? 2 : 5}
          alignItems="center"
          justifyContent="space-evenly"
        >
          {ClientImageUrls.map((url, index) => (
            <img
              key={index}
              src={url?.url}
              alt={`mainimg-${index}`}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          ))}
        </Stack>
      </Grid>
    </>
  );
}
