import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCourse,
  deleteCourse,
  getAllCourses,
  getCourseDetails,
  hardDeleteCourse,
  getAllCoursesList,
  updateCourseDetails,
  getAllPublicCourses,
  getPublicCourseDetails,
} from '../api/courses';

export const coursesSlice = createSlice({
  name: 'Course',
  initialState: {
    loading: false,
    error: {},
    // partner
    courses: {},
    course: {},
    publicCourseDetails: {},
    staffDropList: [],
    courseDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // // get all courses public
    [getAllPublicCourses.pending]: (state) => {
      state.loading = true;
    },
    [getAllPublicCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.courses = action.payload;
      state.error = {};
    },
    [getAllPublicCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // // get all courses public details
    [getPublicCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPublicCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.publicCourseDetails = action.payload;
      state.error = {};
    },
    [getPublicCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // // get all courses
    [getAllCourses.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.courses = action.payload;
      state.error = {};
    },
    [getAllCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCoursesList.pending]: (state) => {
      state.loading = true;
    },
    [getAllCoursesList.fulfilled]: (state, action) => {
      state.loading = false;
      state.course = action.payload;
      state.error = {};
    },
    [getAllCoursesList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createCourse.pending]: (state) => {
      state.loading = true;
    },

    [createCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.course;
      const newCourseList = jsonState?.courses;

      const modifiedCourseList = {
        ...jsonState,
        courses: Array.isArray(newCourseList)
          ? [action.payload, ...newCourseList]
          : [action.payload],
      };
      state.loading = false;
      state.CountryDetails = action.payload;
      state.course = modifiedCourseList;
      state.error = {};
    },
    [createCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single course details
    [getCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseDetails = action.payload;
      state.error = {};
    },
    [getCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update course details
    [updateCourseDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateCourseDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.course;
      const modifiedCourseList = {
        ...jsonState,
        courses: jsonState.courses?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.course = modifiedCourseList;
      state.courseDetails = action.payload;
      state.course = modifiedCourseList;
      state.error = {};
    },
    [updateCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete course
    [deleteCourse.pending]: (state) => {
      state.loading = true;
    },

    [deleteCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.course;
      const modifiedCourseList = {
        ...jsonState,
        courses: jsonState.courses?.filter((course) => course._id !== action.payload),
      };
      state.loading = false;
      state.course = modifiedCourseList;
      state.error = {};
    },
    [deleteCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [hardDeleteCourse.pending]: (state) => {
      state.loading = true;
    },

    [hardDeleteCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.course;
      const modifiedCourseList = {
        ...jsonState,
        courses: jsonState.courses?.filter((course) => course._id !== action.payload),
      };
      state.loading = false;
      state.course = modifiedCourseList;
      state.error = {};
    },
    [hardDeleteCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = coursesSlice.actions;

export default coursesSlice.reducer;
