import moment from 'moment';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Check } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import RemoveIcon from '@mui/icons-material/Remove';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box, Card, Grid, Stack, Button, Typography, Pagination, IconButton } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { createCompletedContent } from 'src/server/api/schoolCourse';

import EmptyContent from 'src/components/empty-content/empty-content';

const SessionsList = ({ sessionData, schoolCourseData, page, setPage, total, schoolProfile }) => {
  const dispatch = useDispatch();
  const mdUp = useResponsive('up', 'sm');

  const [expanded, setExpanded] = useState({});

  const handleCompletedContent = (contentId) => {
    const credentials = {
      schoolCourseId: schoolCourseData?._id,
      state: {
        content: contentId,
      },
      dispatch,
    };
    dispatch(createCompletedContent(credentials));
  };

  const handleCompletedStatus = (id) =>
    schoolCourseData?.completed_contents?.some((item) => item?.content === id);

  const isSubscribed = schoolProfile?.current_subscription?.payment_status === 'paid';
  const today = new Date();
  const todayDate = moment(today).format('DD-MM-yyyy');
  const endDate = moment(schoolProfile?.current_subscription?.end_date).format('DD-MM-yyyy');

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const handleCheckSubscription = () => {
    const date = parseDate(todayDate);
    const end = parseDate(endDate);
    return date < end;
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const toggleDescription = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <>
      <Typography fontSize={18} fontWeight={700} pt="32px">
        Sessions
      </Typography>
      {sessionData?.total <= 0 && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      <Grid container spacing={1} mt="32px">
        {sessionData?.contents?.map((item, key) => (
          <Grid key={key} item xs={12}>
            {((isSubscribed && handleCheckSubscription()) ||
              (!isSubscribed && item?.is_free) ||
              (isSubscribed && !handleCheckSubscription() && item?.is_free)) && (
              <Card
                sx={{ mr: '24px', mb: '24px', minHeight: '92px', width: mdUp ? '75%' : '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection={mdUp ? 'row' : 'column'}
                  justifyContent="space-between"
                  mx="24px"
                  mt="24px"
                >
                  <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography fontWeight={600} fontSize={16} pb={mdUp ? 0 : 1}>
                      {`${(page - 1) * 10 + key + 1}. ${item?.title}`}
                    </Typography>
                    <IconButton onClick={() => toggleDescription(key)} sx={{ ml: 0 }}>
                      {expanded[key] ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      component={Link}
                      to={paths?.dashboard?.session_view(item?._id)}
                      state={{ schoolCourseId: schoolCourseData?._id, page }}
                      variant="outlined"
                      size="medium"
                      sx={{
                        border: '1px solid #919EAB52',
                        px: 1,
                        py: 0.5,
                        '&:hover': {
                          border: 'none',
                        },
                      }}
                    >
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {item?.pdf_url && item?.pdf_url?.length > 0 && (
                          <TextSnippetIcon fontSize="small" sx={{ color: '#795192' }} />
                        )}
                        {item?.video_url && item?.video_url?.length > 0 && (
                          <PlayCircleIcon fontSize="small" sx={{ color: '#795192' }} />
                        )}
                        Go to lesson
                      </Stack>
                    </Button>
                    {handleCompletedStatus(item?._id) ? (
                      <Button
                        size="medium"
                        sx={{
                          border: 'none',
                          px: '8px',
                          py: '4px',
                          fontWeight: 700,
                          fontSize: '13px',
                          color: '#22C55E',
                        }}
                      >
                        <Check
                          sx={{ color: '#22C55E', mr: '8px', width: '18px', height: '18px' }}
                        />
                        Completed
                      </Button>
                    ) : (
                      <Button
                        size="medium"
                        onClick={() => handleCompletedContent(item?._id)}
                        sx={{
                          border: '1px solid #919EAB52',
                          px: '8px',
                          py: '4px',
                          fontWeight: 600,
                          fontSize: '13px',
                          '&:hover': {
                            backgroundColor: '#00A76F29',
                            color: '#00A76F',
                            border: 'none',
                          },
                        }}
                      >
                        Mark as completed
                      </Button>
                    )}
                  </Stack>
                </Box>

                {expanded[key] && (
                  <Typography fontSize={13} fontFamily={400} color="#637381" px="24px" py="12px">
                    {parse(item?.description)}
                  </Typography>
                )}
              </Card>
            )}
            {((!isSubscribed && !item?.is_free) ||
              (isSubscribed && !handleCheckSubscription() && !item?.is_free)) && (
              <Card
                sx={{ mr: '24px', mb: '24px', minHeight: '92px', width: mdUp ? '75%' : '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection={mdUp ? 'row' : 'column'}
                  justifyContent="space-between"
                  mx="24px"
                  mt="24px"
                >
                  <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography fontWeight={600} fontSize={16} pb={mdUp ? 0 : 1}>
                      {`${(page - 1) * 10 + key + 1}. ${item?.title}`}
                    </Typography>
                    <IconButton onClick={() => toggleDescription(key)} sx={{ ml: 0 }}>
                      {expanded[key] ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Button
                      disabled
                      variant="outlined"
                      size="medium"
                      sx={{
                        border: '1px solid #919EAB52',
                        px: 1,
                        py: 0.5,
                        '&:hover': { border: 'none' },
                      }}
                    >
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {item?.pdf_url && item?.pdf_url?.length > 0 && (
                          <TextSnippetIcon fontSize="small" sx={{ color: 'grey' }} />
                        )}
                        {item?.video_url && item?.video_url?.length > 0 && (
                          <PlayCircleIcon fontSize="small" sx={{ color: 'grey' }} />
                        )}
                        Go to lesson
                      </Stack>
                    </Button>
                    <Typography fontWeight={700} fontSize={15} color="#959595">
                      <Stack direction="row" spacing={0.5}>
                        <LockIcon fontSize="small" />
                        Locked
                      </Stack>
                    </Typography>
                  </Stack>
                </Box>
                {expanded[key] && (
                  <Typography fontSize={13} fontFamily={400} color="#637381" px="24px" py="12px">
                    {parse(item?.description)}
                  </Typography>
                )}
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
      {sessionData?.total > 10 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination count={total} page={page} onChange={handleChange} color="standard" />
        </Stack>
      )}
    </>
  );
};

SessionsList.propTypes = {
  sessionData: PropTypes.object,
  schoolCourseData: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  total: PropTypes.number,
  schoolProfile: PropTypes.object,
};

export default SessionsList;
