import React from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// import whatsapp from 'src/assets/other/Whatsapp2.svg';

function WhatsappIcon({ link, isWeb }) {
  return (
    <Box
      sx={{ backgroundColor: '#25d366', borderRadius: 50 }}
      position="fixed"
      bottom={isWeb ? 100 : 65}
      right={isWeb ? 50 : 20}
    >
      <a
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${link}&text=Hi`}
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <Stack direction="row" spacing={1} alignItems="center" px={1.5} py={0.8}>
          {/* <CardMedia
            component="img"
            src={whatsapp}
            // title="babyday-whatsapp"
            loading="eager"
            alt="whatsapp"
            // height={50}
            sx={{ objectFit: 'contain', height: 50, width: 50 }}
          /> */}
          <WhatsAppIcon sx={{ color: 'white' }} fontSize="large" />
          <Typography variant="subtitle1" color="white">
            Chat With Us
          </Typography>
        </Stack>
      </a>
    </Box>
  );
}

export default WhatsappIcon;

WhatsappIcon.propTypes = {
  link: PropTypes.any,
  isWeb: PropTypes.any,
};
