import { Box, Grid, Card, Link, Stack, Button, Container, Typography } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BrandImages from 'src/sections/landingPage/segment/BrandImages';
import VideoPlayer from 'src/sections/landingPage/segment/VideoPlayer';
import EnquiryForm from 'src/sections/landingPage/segment/enquiryForm';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

export default function FacilitatorPage() {
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;
  return (
    <>
      <MetaHelmet title="STEM Facilitator" />

      {isMobile === true ? (
        <Container className="landgmainsection" maxWidth="" sx={{ pt: 2 }}>
          <Grid
            container
            spacing={2}
            className="image-container"
            sx={{ position: '', minHeight: '200px', display: 'flex', padding: 2 }}
          >
            {/* Text Column */}
            <Grid
              item
              xs={12}
              sm={6}
              className="content-box"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                sx={{ width: '100%', display: '', alignItems: 'center', justifyContent: 'center' }}
              >
                <Typography
                  sx={{ color: '#E1E1E1', fontSize: 28, fontWeight: 600, lineHeight: 'normal' }}
                >
                  STEM Facilitator
                </Typography>
                <Typography className="course-subtitle-mobile">
                  Our STEM Facilitator course upskills Graduates and Educators through experiential
                  training on Coding, Robotics, AI, IoT and 3D Printing to Become a Certified STEM
                  Facilitator.
                </Typography>
                <Stack py={1}>
                  <Link style={{ textDecoration: 'none', color: '#fff' }} href="/contactUs">
                    <Button className="login-button-stem" variant="outlined" size="lg">
                      Login
                    </Button>
                  </Link>
                </Stack>
              </Box>
            </Grid>

            {/* Image Column */}
            <Grid
              item
              xs={12}
              sm={6}
              className="image-box"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Box sx={{ width: '100%', height: '100%' }}>
                <img
                  src="/assets/imagesWeb/Faciliatoter/rocket.png"
                  alt="mainimg"
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Grid
          container
          spacing={2}
          className="image-container"
          sx={{ my: isMobile === true ? '' : 5 }}
        >
          <Grid
            item
            xs={12}
            sm={7}
            className="content-box"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box paddingLeft={isMobile ? 1 : 25}>
              <Typography
                sx={{ color: '#E1E1E1', fontSize: 38, fontWeight: 600, lineHeight: '40px' }}
              >
                STEM Facilitator
              </Typography>
              <Typography className="facilitator-subtitle">
                Our STEM Facilitator course upskills graduates and educators through experiential
                training on coding, Robotics, AI, IoT and 3D Printing to become a certified STEM
                Facilitator.
              </Typography>
              <Stack py={1}>
                <Link
                  style={{ textDecoration: 'none', color: '#fff' }}
                  href="/contactUs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="login-button-stem" variant="outlined" size="lg">
                    Login
                  </Button>
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            className="image-box"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: '80%', height: '100%' }}>
              <img
                src="/assets/imagesWeb/Faciliatoter/rocket.png"
                alt="mainimg"
                style={{ width: '60%', height: '100%', objectFit: 'contain' }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <Container style={{ paddingTop: isMobile ? '' : 50, paddingBottom: isMobile ? 25 : 15 }}>
        <Stack className={isMobile ? 'enroll-card-mobile' : 'enroll-card'}>
          <Box>
            <Stack className="enroll-card-title-layout">
              <Typography className="enroll-card-title">who can enroll ?</Typography>
            </Stack>
            <Stack className="enroll-card-description-parent">
              <Typography className="enroll-card-description" p={isMobile && 2.5}>
                Engineering & Science Students: Complement your academic studies with practical
                skills that set you apart in a competitive job market.
                <br /> Educators: Enhance your teaching methods and classroom engagement with our
                comprehensive training program. <br />
                Tech Enthusiasts: Fuel your passion for technology and robotics by diving into
                hands-on projects and advanced concepts
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <Stack sx={{ py: isMobile ? '' : 6 }}>
        <VideoPlayer videoId="th9UOGAhCog" />
      </Stack>
      <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
        <Card
          sx={{
            backgroundColor: '#212121',
            border: 'none',
            borderRadius: '15px',
            boxShadow: 'none',
            height: 'auto',
            width: 'auto',
          }}
          className="mini-basic-card"
        >
          {isMobile ? (
            <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7, pt: 2 }}>
              <Grid
                container
                spacing={2}
                className="image-container"
                sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 1 }}
              >
                {/* Text Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: '',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography className="stem-facilitator-advancer-mobile">
                      STEM Facilitator Basic
                    </Typography>
                    <Typography className="stem-facilitator-advancer-duration-mobile">
                      Duration: 4 Months
                    </Typography>
                    <Typography className="lab-box-content-subtitle-mobile">
                      {`
                      The STEM Facilitator Basic course is designed for Science & Engineering graduates, Educators, Tech
                      enthusiasts and provides solid foundation in STEM, Robotics, Coding, Electronics. You'll learn to
                      effectively teach and facilitate STEM education by our passionate, well qualified instructors.
                      Course offers hands-on experience, tools & techniques, current technology applications, flexible
                      learning options and thus enable a transition into a career in STEM education This course is your
                      gateway to a rewarding career in Robotics and STEM education, promoting STEM literacy and
                      encouraging students to pursue careers in STEM fields.
                      `}
                    </Typography>
                  </Box>
                </Grid>

                {/* Image Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <img
                      src="/assets/imagesWeb/collage/facilitator1.jpeg"
                      alt="mainimg"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Grid container spacing={2} className="image-container">
              {/* Image Column */}

              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <Typography className="facilitator-box-content-header">
                    {' '}
                    STEM Facilitator Basic
                  </Typography>
                  <Typography className="facilitator-box-content-sub-header">
                    Duration: 4 Months
                  </Typography>

                  <Typography className="facilitator-description">
                    {`
                    The STEM Facilitator Basic course is designed for Science & Engineering graduates, Educators, Tech
                    enthusiasts and provides solid foundation in STEM, Robotics, Coding, Electronics. You'll learn to
                    effectively teach and facilitate STEM education by our passionate, well qualified instructors.
                    `}
                    <br />
                    {`
                    Course offers hands-on experience, tools & techniques, current technology applications, flexible
                    learning options and thus enable a transition into a career in STEM education This course is your
                    gateway to a rewarding career in Robotics and STEM education, promoting STEM literacy and
                    encouraging students to pursue careers in STEM fields.
                    `}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ width: '100%', height: '100%', padding: 3 }}>
                  <img
                    src="/assets/imagesWeb/collage/facilitator1.jpeg"
                    alt="mainimg"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          <Stack sx={{ padding: '0px 25px 25px 25px' }}>
            <Typography className="mini-card-curriculum">Curriculum</Typography>
            <Grid container spacing={0} width="100%">
              <Grid container spacing={2} className="image-container">
                {/* Image Column */}

                {/* Text Column */}
                <Box
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <ul className="list-items">
                    <li>Block Coding</li>
                    <li>Python Coding</li>
                    <li>Game Development</li>
                  </ul>
                  <ul className="list-items">
                    <li> AI Robotics</li>
                    <li>Electronics & Paper Circuits</li>
                    <li>Arduino Circuits Programming</li>
                  </ul>
                </Box>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                  ml={!isMobile && '80px'}
                >
                  <Stack>
                    <a href="/contactUs" target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        size="lg"
                        style={{
                          width: isMobile === true ? '100%' : 'auto',
                          minWidth: isMobile ? '100px' : '170px',
                          borderRadius: 8,
                          backgroundColor: '#05286C',
                          boxShadow: 'none',
                          height: '40px',
                          paddingLeft: isMobile ? 10 : 30,
                          paddingRight: isMobile ? 15 : 30,
                        }}
                      >
                        Enroll Now
                      </Button>
                    </a>
                  </Stack>
                  <Stack
                    sx={{ marginLeft: isMobile ? 2 : 0 }}
                    display="flex"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <a href="/contactUs" target="_blank" rel="noreferrer">
                      <Button
                        // variant="contained"
                        className=""
                        size="lg"
                        style={{
                          width: isMobile === true ? '100%' : 'auto',
                          minWidth: isMobile ? '100px' : '170px',
                          boxShadow: 'none',
                          height: '40px',
                          border: '2px solid #393939',
                          backgroundColor: '',
                          color: '#fff',
                          paddingLeft: isMobile === true ? 15 : 30,
                          paddingRight: isMobile === true ? 15 : 30,
                        }}
                      >
                        Free Class
                      </Button>
                    </a>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={2}
        flexDirection="row-reverse"
      >
        <Card
          sx={{
            backgroundColor: '#212121',
            border: 'none',
            borderRadius: '15px',
            boxShadow: 'none',
            height: 'auto',
            width: isMobile ? 'auto' : '1000px',
          }}
          className="mini-basic-card"
        >
          {isMobile === true ? (
            <Container
              className="landgmainsection"
              maxWidth="lg"
              sx={{ pb: !isMobile && 7, pt: 2 }}
            >
              <Grid
                container
                spacing={2}
                className="image-container"
                sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 1 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <img
                      src="/assets/imagesWeb/collage/facilitator2.jpeg"
                      alt="mainimg"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  </Box>
                </Grid>
                {/* Text Column */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="content-box"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: '',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography className="stem-facilitator-advancer-mobile">
                      STEM Facilitator Advanced
                    </Typography>
                    <Typography className="stem-facilitator-advancer-duration-mobile">
                      Duration: 3 Months
                    </Typography>
                    <Typography className="lab-box-content-subtitle-mobile">
                      The STEM Facilitator Advanced course is designed for aspirants who have
                      completed the basic course and interested to upskill their knowledge and
                      expertise to the next level. Training on 3D design, AI, Advanced Ai Robotics,
                      IoT, PCB printing, advanced Electronics ensures students stay up-to-date with
                      the latest developments and become well-positioned for a successful career in
                      the field of STEM education.
                      <br />
                      Led by our passionate and experienced instructors, this course offers hands-on
                      experience, flexible learning options, exciting opportunities to deepen your
                      understanding of STEM technologies and effective facilitation techniques.
                      Become a Certified STEM Facilitator and inspire the next generation of
                      Innovators.
                    </Typography>
                  </Box>
                </Grid>

                {/* Image Column */}
              </Grid>
            </Container>
          ) : (
            <Grid container spacing={2} className="image-container">
              {/* Image Column */}

              {/* Text Column */}

              <Grid
                item
                xs={12}
                sm={6}
                className="image-box"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ width: '100%', height: '100%', padding: 3 }}>
                  <img
                    src="/assets/imagesWeb/collage/facilitator2.jpeg"
                    alt="mainimg"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <Typography className="facilitator-box-content-header">
                    {' '}
                    STEM Facilitator Advanced
                  </Typography>
                  <Typography className="facilitator-box-content-sub-header">
                    Duration: 3 Months
                  </Typography>

                  <Typography className="facilitator-description">
                    The STEM Facilitator Advanced course is designed for aspirants who have
                    completed the basic course and interested to upskill their knowledge and
                    expertise to the next level. Training on 3D design, AI, Advanced Ai Robotics,
                    IoT, PCB printing, advanced Electronics ensures students stay up-to-date with
                    the latest developments and become well-positioned for a successful career in
                    the field of STEM education.
                    <br /> Led by our passionate and experienced instructors, this course offers
                    hands-on experience, flexible learning options, exciting opportunities to deepen
                    your understanding of STEM technologies and effective facilitation techniques.
                    Become a Certified STEM Facilitator and inspire the next generation of
                    Innovators.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {!isMobile && (
            <Typography
              className=""
              sx={{
                fontSize: 20,
                color: '#f1f1f1',
                marginLeft: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 700,
                paddingBottom: 2,
              }}
            >
              Curriculum
            </Typography>
          )}
          <Stack sx={{ padding: '0px 25px 25px 25px' }}>
            <Grid container spacing={0} width="100%">
              <Grid container spacing={2} className="image-container">
                {/* Text Column */}
                {!isMobile && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="image-box"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Stack>
                      <a href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          variant="contained"
                          size="lg"
                          style={{
                            width: isMobile === true ? '100%' : 'auto',
                            minWidth: isMobile ? '100px' : '170px',
                            borderRadius: 8,
                            backgroundColor: '#05286C',
                            boxShadow: 'none',
                            height: '40px',
                            paddingLeft: isMobile ? 10 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Enroll Now
                        </Button>
                      </a>
                    </Stack>
                    <Stack
                      sx={{ marginLeft: isMobile ? 2 : 5 }}
                      display="flex"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <a href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          // variant="contained"
                          className=""
                          size="lg"
                          style={{
                            width: isMobile === true ? '100%' : 'auto',
                            minWidth: '170px',
                            boxShadow: 'none',
                            height: '40px',
                            border: '2px solid #393939',
                            backgroundColor: '',
                            color: '#fff',
                            paddingLeft: isMobile === true ? 15 : 30,
                            paddingRight: isMobile === true ? 15 : 30,
                          }}
                        >
                          Free Class
                        </Button>
                      </a>
                    </Stack>
                  </Grid>
                )}
                {isMobile && (
                  <Typography className="mini-card-curriculum" sx={{ pt: 4 }}>
                    Curriculum
                  </Typography>
                )}
                <Box
                  item
                  xs={12}
                  sm={6}
                  className="image-box"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  <ul className="list-items">
                    <li>3D Designing</li>
                    <li> Advanced Python Coding</li>
                    <li>Advanced Electronics</li>
                    <li>PCB Designing & Printing</li>
                  </ul>
                  <ul className="list-items">
                    <li>Advanced AI Robotics</li>
                    <li>Artificial Intelligence (AI)</li>
                    <li>Internet of Things (IoT)</li>
                    {/* <li>Internet of Things (IoT)</li> */}
                  </ul>
                </Box>
                {isMobile && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="image-box"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Stack>
                      <a href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          variant="contained"
                          size="lg"
                          style={{
                            width: isMobile === true ? '100%' : 'auto',
                            minWidth: isMobile ? '100px' : '170px',
                            borderRadius: 8,
                            backgroundColor: '#05286C',
                            boxShadow: 'none',
                            height: '40px',
                            paddingLeft: isMobile ? 10 : 30,
                            paddingRight: isMobile ? 15 : 30,
                          }}
                        >
                          Enroll Now
                        </Button>
                      </a>
                    </Stack>
                    <Stack
                      sx={{ marginLeft: isMobile ? 2 : 5 }}
                      display="flex"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <a href="/contactUs" target="_blank" rel="noreferrer">
                        <Button
                          // variant="contained"
                          className=""
                          size="lg"
                          style={{
                            width: isMobile === true ? '100%' : 'auto',
                            // minWidth: '170px',
                            boxShadow: 'none',
                            height: '40px',
                            border: '2px solid #393939',
                            backgroundColor: '',
                            color: '#fff',
                            paddingLeft: isMobile === true ? 15 : 30,
                            paddingRight: isMobile === true ? 15 : 30,
                          }}
                        >
                          Free Class
                        </Button>
                      </a>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Box>

      {isMobile ? (
        <EnquiryForm />
      ) : (
        <Container sx={{ px: isMobile ? 0 : 10, py: 4 }}>
          <EnquiryForm />
        </Container>
      )}
      <Stack sx={{ padding: isMobile === true ? '' : 3 }}>
        <BrandImages />
      </Stack>
    </>
  );
}
