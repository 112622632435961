import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export default function LoginButton({ sx, path, title, variant }) {
  return (
    <Button
      component={RouterLink}
      href={path || paths.public.admin_login}
      variant={variant || 'outlined'}
      sx={{ mr: 1, ...sx }}
    >
      {title || 'Login'}
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
  path: PropTypes.any,
  title: PropTypes.string,
  variant: PropTypes.string,
};
