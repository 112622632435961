import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSupportMaterial,
  deleteSupportMaterial,
  getAllSupportMaterial,
  getSupportMaterialDetails,
  updateSupportMaterialDetails,
} from '../api/supportMaterial';

export const supportMaterialSlice = createSlice({
  name: 'supportMaterial',
  initialState: {
    loading: false,
    error: {},
    // supportMaterial
    supportMaterial: {},
    materialDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create supportMaterial details
    [createSupportMaterial.pending]: (state) => {
      state.loading = true;
    },
    [createSupportMaterial.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportMaterial;
      const newSupportMaterialList = jsonState?.supportMaterials;
      const modifiedSupportMaterialList = {
        ...jsonState,
        supportMaterials: Array.isArray(newSupportMaterialList)
          ? [action.payload, ...newSupportMaterialList]
          : [action.payload],
      };
      state.loading = false;
      // state.supportMaterial = action.payload;
      state.supportMaterial = modifiedSupportMaterialList;
      state.error = {};
    },
    [createSupportMaterial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all supportMaterial
    [getAllSupportMaterial.pending]: (state) => {
      state.loading = true;
    },
    [getAllSupportMaterial.fulfilled]: (state, action) => {
      state.loading = false;
      state.supportMaterial = action.payload;
      state.error = {};
    },
    [getAllSupportMaterial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single supportMaterial details
    [getSupportMaterialDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSupportMaterialDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.materialDetails = action.payload;
      state.error = {};
    },
    [getSupportMaterialDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update supportMaterial details
    [updateSupportMaterialDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSupportMaterialDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportMaterial;
      const modifiedSupportMaterialList = {
        ...jsonState,
        supportMaterials: jsonState.supportMaterials?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.loading = false;
      state.supportMaterial = modifiedSupportMaterialList;
      state.error = {};
    },
    [updateSupportMaterialDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete supportMaterial
    [deleteSupportMaterial.pending]: (state) => {
      state.loading = true;
    },

    [deleteSupportMaterial.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportMaterial;
      console.log('JSON State :', jsonState);
      const modifiedSupportMaterialList = {
        ...jsonState,
        supportMaterials: jsonState.supportMaterials?.filter(
          (supportMaterial) => supportMaterial._id !== action.payload
        ),
      };
      state.loading = false;
      state.supportMaterial = modifiedSupportMaterialList;
      state.error = {};
    },
    [deleteSupportMaterial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = supportMaterialSlice.actions;

export default supportMaterialSlice.reducer;
