import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  Card,
  Grid,
  Stack,
  Button,
  TextField,
  CardMedia,
  Typography,
  CardContent,
} from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';

import { deleteSchoolClass } from 'src/server/api/schoolClass';
import { deleteSchoolDivision } from 'src/server/api/schoolDivision';

import TextMaxLine from 'src/components/text-max-line';

import LabModelViewDialog from './labModelView';
import labModel1 from '../../../assets/labModel/img1.png';
import classIcon from '../../../assets/labModel/file.png';
import session from '../../../assets/labModel/session.png';

export default function OnBoardingContent({ onBoardingData }) {
  const isMobile = useResponsive('down', 'md');
  const dispatch = useDispatch();
  const {
    activeStep,
    setSelectedLabModel,
    selectedLabModel,
    setSelectedClasses,
    selectedClasses,
    setSchoolClassDivisions,
    schoolClassDivisions,
    setSelectedSession,
    selectedSession,
    labModel,
    classes,
    schoolClassList,
    sessions,
    schoolDivision,
    schoolId,
  } = onBoardingData;

  const [divisionTitle, setDivisionTitle] = useState('');

  const [labModelViewModal, setLabModelViewModal] = useState(false);
  const [labModelDetails, setLabModelDetails] = useState({});

  const handleChange = (e) => {
    setDivisionTitle(e.target.value);
  };

  const handleLabModel = (labModelData) => {
    setSelectedLabModel({
      ...selectedLabModel,
      title: labModelData?.title,
      id: labModelData?._id,
      defaultSession: labModelData?.default_session,
    });
  };

  const handleSession = (sessionData) => {
    setSelectedSession({
      ...selectedSession,
      count: sessionData?.count,
      title: sessionData?.title,
      id: sessionData?._id,
    });
  };

  const handleClasses = (classDetails) => {
    setSelectedClasses((prevItems) => {
      const classIndex = prevItems.findIndex(
        (selectedClass) => selectedClass?.class === classDetails?._id
      );
      if (classIndex !== -1) {
        const filterSchoolclass = schoolClassList?.schoolClasses?.find(
          (item) => item?.class?._id === classDetails?._id
        );
        if (filterSchoolclass && filterSchoolclass?._id && filterSchoolclass !== undefined) {
          const credentials = {
            schoolClassId: filterSchoolclass?._id,
            dispatch,
          };
          dispatch(deleteSchoolClass(credentials));
        }
        const updatedItems = [...prevItems];
        updatedItems.splice(classIndex, 1);
        return updatedItems;
      }
      return [
        ...prevItems,
        {
          school: schoolId,
          class: classDetails?._id,
          course: classDetails?.course?._id,
          title: classDetails?.title,
          description: classDetails?.description,
        },
      ];
    });
  };

  const handleClickAddDivision = (item) => {
    const newDivision = {
      school: item?.school?._id,
      school_class: item?._id,
      course: item?.class?.course,
      title: divisionTitle,
      description: '',
    };

    // Check if the title already exists in schoolClassDivisions
    const titleExists = schoolClassDivisions.some(
      (division) =>
        division.school_class === newDivision.school_class && division.title === newDivision.title
    );

    if (!titleExists) {
      setSchoolClassDivisions((divisions) => [...divisions, newDivision]);
      setDivisionTitle('');
    } else {
      // Handle case where title already exists (e.g., show an error message)
      console.log('Division with the same title already exists.');
    }
  };

  const handleAddDivision = (e, item) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const newDivision = {
        school: item?.school?._id,
        school_class: item?._id,
        course: item?.course,
        title: e.target.value,
        description: '',
      };

      // Check if the title already exists in schoolClassDivisions
      const titleExists = schoolClassDivisions?.some(
        (division) =>
          division.school_class === newDivision.school_class && division.title === newDivision.title
      );

      if (!titleExists && newDivision?.title?.length >= 1) {
        setSchoolClassDivisions((divisions) => [...divisions, newDivision]);
      } else {
        // Handle case where title already exists (e.g., show an error message)
        console.log('Division with the same title already exists.');
      }
    }
  };

  const handleDelete = (e, divTitle, classId) => {
    e.preventDefault();
    const filterSchoolDivision = schoolDivision?.schoolDivisions?.filter(
      (item) => item?.title === divTitle && item?.school_class?._id === classId
    );
    console.log(filterSchoolDivision);
    if (
      filterSchoolDivision?.length > 0 &&
      filterSchoolDivision[0] &&
      filterSchoolDivision[0]?._id
    ) {
      const credentials = {
        schoolDivisionId: filterSchoolDivision[0]?._id,
        dispatch,
      };
      dispatch(deleteSchoolDivision(credentials));
    }
    setSchoolClassDivisions((division) =>
      division?.filter((x) => !(x.school_class === classId && x?.title === divTitle))
    );
  };

  const FilteredDivisions = (classId) => {
    const schoolDivisions = schoolDivision?.schoolDivisions?.filter(
      (item) => item?.school_class === classId
    );
    return schoolDivisions;
  };

  const FilteredSelectedDivisions = (classId) => {
    const schoolDivisions = schoolClassDivisions?.filter((item) => item?.school_class === classId);
    return schoolDivisions;
  };

  useEffect(() => {
    setSelectedClasses((prev) =>
      schoolClassList?.schoolClasses?.map((item) => ({
        school: item?.school?._id,
        class: item?.class?._id,
        course: item?.course,
        title: item?.title,
        description: item?.description,
      }))
    );
  }, [schoolClassList, setSelectedClasses]);

  useEffect(() => {
    setSchoolClassDivisions((prev) =>
      schoolDivision?.schoolDivisions?.map((item) => ({
        school: item?.school?._id,
        school_class: item?.school_class?._id,
        course: item?.course,
        title: item?.title,
        description: item?.description,
      }))
    );
  }, [schoolDivision, setSchoolClassDivisions]);

  const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      width: isMobile ? '180px' : '300px',
      height: '40px',
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: isMobile ? '0px' : '35px',
      '& fieldset': {
        borderRadius: '50px',
        borderStyle: 'dashed',
      },
      '&:hover fieldset': {
        borderColor: '#919EAB52',
        borderStyle: 'dashed',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#919EAB52',
        borderStyle: 'dashed',
      },
    },
  });

  const handleLabmodelData = (item) => {
    setLabModelViewModal(true);
    setLabModelDetails(item);
  };

  return (
    <>
      {labModelViewModal && (
        <LabModelViewDialog
          setOpen={setLabModelViewModal}
          open={labModelViewModal}
          data={labModelDetails}
        />
      )}
      {activeStep === 0 && (
        <Box ml={5} mt={5} minHeight="60vh">
          <Typography fontSize={24} fontWeight={700}>
            Choose your lab model
          </Typography>
          {/* <Typography fontSize={14} fontWeight={400} color="gray" pt={2}>
            Test description test description hjghdrtsdesa gcfsser
          </Typography> */}
          <Grid container spacing={1} mt={4} mb={isMobile && 15}>
            {labModel?.labModels?.map((item, key) => (
              <Grid key={key} xs="12" sm="6" md="4" lg="3">
                <Card sx={{ marginRight: '24px', mb: 2, minHeight: '500px' }}>
                  <CardMedia
                    component="img"
                    height="250"
                    image={
                      item && item?.images?.length > 0 && item?.images[0]?.url
                        ? item?.images[0]?.url
                        : labModel1
                    }
                    alt=""
                  />
                  <CardContent>
                    <Typography fontSize={15} fontWeight={600} component="div">
                      {item?.title}
                    </Typography>
                    <TextMaxLine
                      fontSize={13}
                      fontWeight={400}
                      pt={2}
                      color="text.secondary"
                      line={3}
                    >
                      {parse(item?.description?.slice(0, 150))}
                    </TextMaxLine>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={1}
                      position="absolute"
                      bottom={20}
                      right={20}
                    >
                      <Button onClick={() => handleLabmodelData(item)} type="button">
                        View more
                      </Button>
                      <Button
                        size="medium"
                        sx={{
                          color: selectedLabModel?.id === item?._id ? 'white' : '',
                          borderRadius: '50px',
                          border: selectedLabModel?.id === item?._id ? 'none' : '1px solid grey',
                          backgroundColor: selectedLabModel?.id === item?._id && '#007867',
                          '&:hover': {
                            backgroundColor: selectedLabModel?.id === item?._id && '#007867',
                            color: selectedLabModel?.id === item?._id ? 'white' : '#212B36',
                          },
                        }}
                        onClick={() => handleLabModel(item)}
                      >
                        {selectedLabModel?.id === item?._id && <CheckIcon />}
                        {selectedLabModel?.id === item?._id ? 'Selected' : 'Select'}
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {activeStep === 1 && (
        <Box ml={5} mt={5} minHeight="60vh" mb={isMobile && 15}>
          <Typography fontSize={24} fontWeight={700}>
            Choose your Session
          </Typography>
          {/* <Typography fontSize={14} fontWeight={400} color="gray" pt={2}>
            Test description test description hjghdrtsdesa gcfsser
          </Typography> */}
          <Grid container spacing={1}>
            {sessions?.sessions?.map((item, key) => (
              <Grid md="4">
                <Card
                  variant="outlined"
                  key={key}
                  sx={{ minWidth: 348, marginRight: '24px', marginTop: '40px', boxShadow: 'none' }}
                >
                  <CardContent>
                    <Grid display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <CardMedia
                          component="img"
                          image={session}
                          alt="green iguana"
                          sx={{ width: '64px', height: '64px' }}
                        />
                        <Stack ml="16px">
                          <Typography fontSize={32} fontWeight={600}>
                            {item?.count}
                          </Typography>
                          <Typography fontSize={16} fontWeight={400} color="#8A8A8A">
                            {item?.title}
                          </Typography>
                        </Stack>
                      </Box>
                      <Button
                        onClick={() => handleSession(item)}
                        sx={{
                          border: selectedSession?.id !== item?._id && '1px solid #919EAB52',
                          borderRadius: '50px',
                          px: '15px',
                          backgroundColor: selectedSession?.id === item?._id && '#00A76F14',
                          color: selectedSession?.id === item?._id ? '#007867' : '#212B36',
                          '&:hover': {
                            backgroundColor: selectedSession?.id === item?._id && '#00A76F14',
                            color: selectedSession?.id === item?._id ? '#007867' : '#212B36',
                          },
                        }}
                      >
                        {selectedSession?.id === item?._id && (
                          <CheckIcon fontSize="small" sx={{ mr: 1 }} />
                        )}
                        {selectedSession?.id === item?._id ? 'Selected' : 'Select'}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {activeStep === 2 && (
        <Box ml={5} mt={5} minHeight="60vh" mb={isMobile && 15}>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mb={3}>
            <Card
              variant="outlined"
              sx={{
                minWidth: 348,
                minHeight: 90,
                marginRight: '24px',
                marginTop: '20px',
                boxShadow: 'none',
                padding: '20px',
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#00A76F">
                Lab Model
              </Typography>
              <Typography pt="10px" fontSize={20} fontWeight={700} color="#212B36">
                {selectedLabModel?.title}
              </Typography>
            </Card>
            <Card
              variant="outlined"
              sx={{
                minWidth: 144,
                minHeight: 90,
                marginRight: '24px',
                marginTop: '20px',
                boxShadow: 'none',
                padding: '20px',
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#00A76F">
                Session
              </Typography>
              <Typography pt="10px" fontSize={20} fontWeight={700} color="#5119B7">
                {selectedSession?.count} {selectedSession?.title}
              </Typography>
            </Card>
          </Box>
          <Typography fontSize={24} fontWeight={700}>
            Choose your Classes
          </Typography>
          {/* <Typography fontSize={14} fontWeight={400} color="gray" pt={2}>
            Test description test description hjghdrtsdesa gcfsser
          </Typography> */}
          <Grid container spacing={1}>
            {classes?.classes?.map((item, key) => (
              <Grid lg="3" md="4" sm="6" xs="12">
                <Card
                  variant="outlined"
                  key={key}
                  sx={{ minWidth: 244, marginRight: '24px', marginTop: '40px', boxShadow: 'none' }}
                >
                  <CardContent>
                    <Grid display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <CardMedia
                          component="img"
                          image={classIcon}
                          alt="green iguana"
                          sx={{ width: '24px', height: '24px' }}
                        />
                        <Stack ml="16px">
                          <Typography fontSize={16} fontWeight={600}>
                            {item?.title}
                          </Typography>
                        </Stack>
                      </Box>
                      <Button
                        onClick={() => handleClasses(item)}
                        sx={{
                          border: selectedClasses?.some(
                            (selectedClass) => selectedClass?.class === item?._id
                          )
                            ? 'none'
                            : '1px solid #919EAB52',
                          borderRadius: '50px',
                          px: '15px',
                          backgroundColor:
                            selectedClasses?.some(
                              (selectedClass) => selectedClass?.class === item?._id
                            ) && '#00A76F14',
                          color: selectedClasses?.some(
                            (selectedClass) => selectedClass?.class === item?._id
                          )
                            ? '#007867'
                            : '#212B36',
                        }}
                      >
                        {selectedClasses?.some(
                          (selectedClass) => selectedClass?.class === item?._id
                        ) && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        {selectedClasses?.some(
                          (selectedClass) => selectedClass?.class === item?._id
                        )
                          ? 'Selected'
                          : 'Select'}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {activeStep === 3 && (
        <Box ml={5} mt={5} minHeight="60vh" mb={isMobile && 15}>
          <Typography fontSize={24} fontWeight={700}>
            Create your divisions
          </Typography>
          {/* <Typography fontSize={14} fontWeight={400} color="gray" pt={2}>
            Test description test description hjghdrtsdesa gcfsser
          </Typography> */}
          {schoolClassList && schoolClassList?.total > 0 && (
            <Stack mt="40px">
              {(schoolClassList?.schoolClasses || [])?.map((item, key) => (
                <Stack
                  key={key}
                  direction="row"
                  flexWrap="wrap"
                  spacing={1}
                  alignItems="center"
                  mb="40px"
                >
                  <Chip
                    label={`${item?.title}`}
                    sx={{
                      width: 'max-content',
                      height: '40px',
                      borderRadius: '50px',
                      color: 'white',
                      backgroundColor: '#007867',
                      fontSize: '14px',
                      fontWeight: 700,
                      '&:hover': {
                        backgroundColor: '#007867',
                      },
                      // px: '10px',
                      // py: '19px',
                    }}
                  />
                  <Typography fontSize="14px" fontWeight={700} pl="12px">
                    :
                  </Typography>
                  {FilteredSelectedDivisions(item?._id)?.map((div, index) => (
                    <Chip
                      label={div?.title}
                      className="div-chip"
                      key={index}
                      sx={{
                        width: 'max-content',
                        height: '40px',
                        borderRadius: '50px',
                        color: '#212B36',
                        backgroundColor: 'white',
                        fontSize: '14px',
                        fontWeight: 700,
                        border: '1px solid #919EAB52',
                        marginLeft: '12px',
                        '.div-delete': {
                          display: 'none',
                        },
                        '&:hover': {
                          backgroundColor: 'white',
                          '.div-delete': {
                            display: 'block',
                          },
                        },
                      }}
                      onDelete={(e) => handleDelete(e, div.title, item?._id)}
                      deleteIcon={<CancelIcon className="div-delete" />}
                    />
                  ))}
                  {isMobile ? (
                    <Stack direction="row" alignItems="center" ml="110px">
                      <CustomTextField
                        variant="outlined"
                        placeholder="Type and add division"
                        value={divisionTitle[key]}
                        onChange={handleChange}
                      />
                      <Button onClick={() => handleClickAddDivision(item)}>
                        <AddIcon sx={{ color: '#AAAAAA' }} fontSize="large" />
                      </Button>
                    </Stack>
                  ) : (
                    <Stack position="relative" ml="5px">
                      <AddIcon
                        sx={{ position: 'absolute', top: 4, left: 12, color: '#AAAAAA' }}
                        fontSize="large"
                      />
                      <CustomTextField
                        variant="outlined"
                        placeholder="Type Division Name and Press enter"
                        onKeyDown={(e) => handleAddDivision(e, item)}
                      />
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
      )}
      {activeStep === 4 && (
        <Box ml={5} mt={5} minHeight="60vh" mb={isMobile && 15}>
          <Typography fontSize={24} fontWeight={700}>
            Review and Finish
          </Typography>
          {/* <Typography fontSize={14} fontWeight={400} color="gray" pt={2}>
            Test description test description hjghdrtsdesa gcfsser
          </Typography> */}
          <Box display="flex">
            <Card
              variant="outlined"
              sx={{
                minWidth: 348,
                minHeight: 90,
                marginRight: '24px',
                marginTop: '40px',
                boxShadow: 'none',
                padding: '20px',
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#00A76F">
                Lab Model
              </Typography>
              <Typography pt="10px" fontSize={20} fontWeight={700} color="#212B36">
                {selectedLabModel?.title}
              </Typography>
            </Card>
            <Card
              variant="outlined"
              sx={{
                minWidth: 144,
                minHeight: 90,
                marginRight: '24px',
                marginTop: '40px',
                boxShadow: 'none',
                padding: '20px',
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#00A76F">
                Session
              </Typography>
              <Typography pt="10px" fontSize={20} fontWeight={700} color="#5119B7">
                {selectedSession?.count} {selectedSession?.title}
              </Typography>
            </Card>
          </Box>
          <Card
            variant="outlined"
            sx={{
              marginRight: '24px',
              marginTop: '40px',
              marginBottom: '60px',
              boxShadow: 'none',
              padding: '28px',
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#00A76F">
              Class and divisions
            </Typography>
            <Stack mt="20px">
              {schoolClassList?.schoolClasses?.map((item, key) => (
                <Stack key={key} direction="row" spacing={1} alignItems="center" mb="40px">
                  <Chip
                    label={`${item?.title}`}
                    sx={{
                      width: 'max-content',
                      height: '40px',
                      borderRadius: '50px',
                      color: 'white',
                      backgroundColor: '#007867',
                      fontSize: '14px',
                      fontWeight: 700,
                      '&:hover': {
                        backgroundColor: '#007867',
                      },
                    }}
                  />
                  <Typography fontSize="14px" fontWeight={700} pl="12px">
                    :
                  </Typography>
                  {FilteredDivisions(item?._id)?.map((div, index) => (
                    <Chip
                      label={div?.title}
                      className="div-chip"
                      key={index}
                      sx={{
                        width: 'max-content',
                        height: '40px',
                        borderRadius: '50px',
                        color: '#212B36',
                        backgroundColor: 'white',
                        fontSize: '14px',
                        fontWeight: 700,
                        border: '1px solid #919EAB52',
                        marginLeft: '12px',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Card>
        </Box>
      )}
    </>
  );
}

OnBoardingContent.propTypes = {
  onBoardingData: PropTypes.any,
};
