import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
// import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import ListIcon from '@mui/icons-material/List';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { Grid, List, Stack, Drawer, Divider } from '@mui/material';
import BiotechRoundedIcon from '@mui/icons-material/BiotechRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';

import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

import Logo from './logo/Logo';

// import Logo from '../../components/logo/Logo';

function Header() {
  const { isMobile } = useDevice();
  // const location = useLocation();

  // const [setAnchorElNav] = React.useState(null);

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const pages = [
    { item: 'Home', link: '/', icon: <HomeRoundedIcon sx={{ color: '#E59946' }} /> },
    {
      item: 'Course',
      link: '/robotics-ai-course',
      icon: <AutoStoriesRoundedIcon sx={{ color: '#E59946' }} />,
    },
    {
      item: 'STEM & Tinkering LAB',
      link: '/robotics-atl-tinkering-lab',
      icon: <BiotechRoundedIcon sx={{ color: '#E59946' }} />,
    },
    {
      item: 'For Colleges',
      link: '/college-robotics-ai',
      icon: <SchoolRoundedIcon sx={{ color: '#E59946' }} />,
    },
    {
      item: 'STEM Facilitator',
      link: '/robotics-trainer ',
      icon: <MenuBookRoundedIcon sx={{ color: '#E59946' }} />,
    },
    // { item: 'DIY Kits', link: '/DIY-kits' },
    {
      item: 'STEM Aptitude Test',
      link: '/stem-exam',
      icon: <DriveFileRenameOutlineRoundedIcon sx={{ color: '#E59946' }} />,
    },
    {
      item: 'Partner With Us',
      link: '/partner-program',
      icon: <HandshakeRoundedIcon sx={{ color: '#E59946' }} />,
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack container justifyContent="space-between" padding={1}>
        <CloseIcon color="" sx={{ color: '#E59946' }} onClose={() => toggleDrawer(anchor, false)} />
      </Stack>

      <Divider />
      <List>
        {pages?.map((page, key) => (
          <Link
            key={key}
            to={page?.link}
            style={{ textDecoration: 'none', textAlign: '', padding: 1 }}
          >
            <Typography
              textAlign="end"
              sx={{
                color: '#1B1212',
                py: 0,
                px: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {page?.icon}&nbsp;&nbsp;
              {page?.item}
            </Typography>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#1A1A1A',
        boxShadow: 'none',
        p: { xs: 0, sm: 0 },
        px: { xs: 0, sm: 10 },
        pb: { xs: 1 },
        pt: { xs: 1 },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* mobile view */}
          {/* <Grid className="header-links">
          
          </Grid> */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Stack>
              <Logo />
            </Stack>
            <Stack>
              {isMobile === true ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <Stack>
                    {['right'].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Stack onClick={toggleDrawer(anchor, true)}>
                          <Stack>
                            <ListIcon color="info" style={{ fontSize: 30, color: '#949494' }} />
                          </Stack>
                        </Stack>
                        <Drawer anchor={anchor} open={state[anchor]}>
                          {list(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Box>
              ) : (
                <Stack>
                  {/* {location?.pathname === '/robotics-ai-course' ? (
                    <Stack className="header-links">
                      {pages?.map((page, key) => (
                        <Button
                          className="dm-san-serif"
                          key={key}
                          component={Link}
                          to={page?.link}
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, color: '#fff', mx: 1, fontFamily: '' }}
                        >
                          {page?.item}
                        </Button>
                      ))}
                    </Stack>
                  ) : ( */}
                  <Stack className="header-links">
                    {pages?.map((page, key) => (
                      // <Button
                      //   className="dm-san-serif"
                      //   key={key}
                      //   component={Link}
                      //   to={page?.link}
                      //   // onClick={handleCloseNavMenu}
                      //   sx={{ my: 2, color: '#fff', mx: 1, fontFamily: '' }}
                      // >
                      //   {page?.item}
                      // </Button>
                      <a
                        href={page?.link}
                        key={key}
                        // target="_blank"
                        className="dm-san-serif"
                        style={{
                          textDecoration: 'none',
                          color: 'white',
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                        rel="noreferrer"
                        // onClick={handleCloseNavMenu}
                      >
                        {page?.item}
                      </a>
                    ))}
                  </Stack>
                  {/* )} */}
                </Stack>
              )}
            </Stack>
          </Grid>

          {/* web view */}
          {/* 
          <Box sx={{ flexGrow: 0 }}>
            {location?.pathname !== '/booking' && (
              <Box sx={{ alignSelf: 'center' }}>
                <Button
                  component={Link}
                  to="/booking"
                  variant="contained"
                  sx={{ display: 'flex', alignItems: 'center', boxShadow: 'none' }}
                  className="zeal-btn-solid hidebtn1"
                >
                  <img
                    src="/assets/images/Header/icon.png"
                    style={{ marginRight: '8px' }}
                    alt="icon"
                    className="btnimg"
                  />
                  Book A Free Home Visit
                </Button>
              </Box>
            )}
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
