import PropTypes from 'prop-types';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import PasswordVerificationView from 'src/sections/auth/password-verification-view';

// ----------------------------------------------------------------------

export default function PasswordVerification({ type }) {
  return (
    <>
      <MetaHelmet title="Confirm Password" />

      <PasswordVerificationView type={type} />
    </>
  );
}
PasswordVerification.propTypes = {
  type: PropTypes.any,
};
