import React from 'react';
import Slider from 'react-slick';
import propTypes from 'prop-types';

import { Stack, Container } from '@mui/material'; // Import the Slider component from react-slick
import YouTube from 'react-youtube';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useDevice } from '../hooks/useResponsive';

VideoCarousel.propTypes = {
  videoId: propTypes.string,
  videoId2: propTypes.string,
  videoId3: propTypes.string,
  videoId4: propTypes.string,
};
export default function VideoCarousel({ videoId, videoId2, videoId3 }) {
  const { isMobile } = useDevice();
  const screenWidth = window.screen.width;

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon />
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon />
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    height: 500,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: screenWidth === 1024 ? 2 : 3,
    slidesToScroll: 3,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  const carouselSettingsMobile = {
    height: 500,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 3000,
    speed: 300,
    slidesToShow: screenWidth === 768 ? 2 : 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const optsVideo1 = {
    height: isMobile ? 250 : 260,
    width: isMobile ? '100%' : 400,
    playerVars: {
      autoplay: 5,
    },
  };

  const onReadyVideo1 = (event) => {
    event.target.pauseVideo();
  };

  const videoCarouselId = [
    { id: videoId },
    { id: videoId2 },
    { id: videoId3 },
    { id: videoId },
    { id: videoId2 },
  ];

  return (
    <Container maxWidth="xl">
      <Stack style={{ padding: 5 }}>
        {isMobile ? (
          <Slider {...carouselSettingsMobile}>
            {/* Carousel Slides */}
            {videoCarouselId?.map((item, key) => (
              <Stack key={key} style={{ border: 'none' }} px="20px">
                {/* Slide 1 */}
                <YouTube
                  videoId={item?.id}
                  className="video-carousal"
                  containerClassName=""
                  opts={optsVideo1}
                  onReady={onReadyVideo1}
                />
              </Stack>
            ))}
          </Slider>
        ) : (
          <Slider {...carouselSettings}>
            {/* Carousel Slides */}
            {videoCarouselId?.map((video, k) => (
              <Container key={k}>
                {/* Slide 1 */}
                <YouTube
                  videoId={video?.id}
                  className="video-carousal"
                  containerClassName=""
                  opts={optsVideo1}
                  onReady={onReadyVideo1}
                />
              </Container>
            ))}
          </Slider>
        )}
      </Stack>
    </Container>
  );
}
