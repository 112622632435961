import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function CourseItem({ course }) {
  // const popover = usePopover();
  // const mobile = useResponsive('down', 'md');

  const { title, description, images, _id } = course;

  return (
    <Link component={RouterLink} href={paths.public.course_view(_id)} color="inherit">
      <Card>
        <Stack sx={{ p: 0, pb: 2 }}>
          <Box
            sx={{
              width: '100%',
              height: 250,
              position: 'relative',
              flexShrink: 0,
              padding: 0,
            }}
          >
            <Image
              alt="home"
              src={images?.[0]?.url || '/assets/images/lms/academy_bg.png'}
              sx={{ height: 1, borderRadius: 0, width: 1 }}
            />
          </Box>

          <ListItemText
            sx={{ p: 2 }}
            primary={title}
            secondary={
              <TextMaxLine variant="body2" line={3}>
                {description}
              </TextMaxLine>
            }
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
            secondaryTypographyProps={{
              mt: 1,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </Stack>
      </Card>
    </Link>
  );
}

CourseItem.propTypes = {
  course: PropTypes.object,
};
