// import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  // Select,
  Dialog,
  // MenuItem,
  Button,
  // InputLabel,
  Typography,
  DialogTitle,
  // FormControl,
  DialogContent,
} from '@mui/material';

import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// components

import {
  createCertificateTemplate,
  updateCertificateTemplateDetails,
} from 'src/server/api/certificateTemplate';

import Label from 'src/components/label';
import FormProvider from 'src/components/hook-form';

CertificateTemplateDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setIsUpdate: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function CertificateTemplateDialog({ open, setOpen, isUpdate, setIsUpdate }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [templateType, setTemplateType] = useState('');
  const [templatePreview, setTemplatePreview] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setIsUpdate({});
  };
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => ({
    loading: state.certificateTemplate.loading,
    error: state.certificateTemplate.error,
  }));

  // const CertificateTemplateSchema = Yup.object().shape({
  //   name: Yup.string().required(),
  //   remarks: Yup.string(),
  //   type: Yup.string().required(),
  // });

  const defaultValues = useMemo(() => {
    if (isUpdate?._id) {
      return {
        name: isUpdate?.name || '',
        remarks: isUpdate?.remarks || '',
        type: isUpdate?.type || '',
      };
    }
    return {
      name: '',
      remarks: '',
      type: '',
    };
  }, [isUpdate?._id, isUpdate?.name, isUpdate?.remarks, isUpdate?.type]);

  const methods = useForm({
    // resolver: yupResolver(CertificateTemplateSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };
  useEffect(() => {
    if (isUpdate?._id && isUpdate?.background_image?.url) {
      setTemplatePreview(
        [
          {
            public_id: isUpdate.background_image?.public_id,
            url: isUpdate.background_image?.url,
          },
        ] || []
      );
    }
  }, [isUpdate]);

  useEffect(() => {
    if (isUpdate?._id) {
      setTemplateType(isUpdate?.type);
    }
  }, [isUpdate]);

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: {
        ...data,
        type: templateType,
        background_image: templatePreview[0],
      },
      dispatch,
      handleClose,
    };
    const UpdateCredentials = {
      state: {
        ...data,
        type: templateType,
        background_image: templatePreview[0],
      },
      templateId: isUpdate?._id,
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      dispatch(updateCertificateTemplateDetails(UpdateCredentials));
    } else {
      dispatch(createCertificateTemplate(CreateCredentials));
    }
  });

  // const typeOptions = [
  //   { label: 'Exam', value: 'exam' },
  //   { label: 'Course', value: 'course' },
  //   { label: 'Other', value: 'other' },
  // ];
  // const handleTypeChange = (event) => {
  //   setTemplateType(event.target.value);
  // };
  const renderCourseForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      {/* <Stack spacing={1.5}>
        <RHFTextField name="name" label="Name" type="text" required placeholder="Name" />
      </Stack> */}
      {/* <Stack spacing={1.5}>
        <FormControl size="small" className="small-select">
          <InputLabel sx={{ backgroundColor: 'white' }} required>
            Type
          </InputLabel>
          <Select value={templateType} onChange={handleTypeChange}>
            {typeOptions.map((option) => (
              <MenuItem key={option.value} title={option?.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack> */}
      {/* <Stack spacing={1.5}>
        <RHFTextField
          name="remarks"
          type="text"
          placeholder="Remarks"
          label="Remarks"
          multiline
          rows={4}
        />
      </Stack> */}
      <Stack spacing={1.5}>
        <FileUploadCustom
          uploadUrl="stemxpert"
          setImagesPreview={setTemplatePreview}
          imagesPreview={templatePreview}
          title="Upload Template"
          isMultiple
          // isLogo={true}
          imageSize={4}
          // onChangeUpdate={updateProductWhenImageChange}
          // isUpdate={isUpdateData}
        />
      </Stack>
      {templatePreview?.length === 0 && (
        <Stack
          sx={{
            marginBottom: '1rem',
            alignItems: 'center',
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting || loading}
            sx={{ color: 'white', marginLeft: 'auto' }}
            color="warning"
          >
            Submit Form
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        // className="testnew template"
        // sx={{ maxWidth: '100%' }}
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Template' : 'Create Template'}
          <Label
            variant="soft"
            color={
              // eslint-disable-next-line no-nested-ternary
              isUpdate?.type === 'exam' ? 'success' : isUpdate?.type === 'course' ? 'info' : 'error'
            }
            sx={{ ml: 1 }}
          >
            {isUpdate?.type}
          </Label>
        </DialogTitle>
        {templatePreview && templatePreview[0] ? (
          <DialogContent className="">
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack spacing={1}>
                {/* <Stack width="50%"> */}
                {renderCourseForm}
                {/* </Stack> */}
                {/* <Stack width="50%"> */}
                <Typography textAlign="center">Preview</Typography>
                <img src={templatePreview && templatePreview[0]?.url} alt="" />
                {/* </Stack> */}
              </Stack>
              <Stack direction="row" justifyContent="end" m={2}>
                <Box>
                  <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="medium"
                    loading={isSubmitting || loading}
                    sx={{ color: 'white', marginLeft: 'auto' }}
                    color="warning"
                  >
                    Submit Form
                  </LoadingButton>
                </Box>
              </Stack>
            </FormProvider>
          </DialogContent>
        ) : (
          <DialogContent sx={{ minWidth: '450px' }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderCourseForm}
            </FormProvider>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
