import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';

import { Box, Stack } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { SaleItemCards } from './saleItemCards';
import SaleItemListView from './saleItemListView';
import AllCourseShare from '../common/ShareLinkAllCourse/all-course-share';

export default function ShareLinkAllCourse() {
  const location = useLocation();
  const tableSection = useRef(null);

  const { partnerDetails } = useSelector((state) => ({
    loading: state.Course.loading,
    courseList: state.Course.courses,
    partnerDetails: state.partner.profile,
  }));

  const [activeTab, setActiveTab] = useState('courses');

  const handleScroll = (type) => {
    tableSection?.current?.scrollIntoView({ behavior: 'smooth' });
    switch (type) {
      case 'school':
      case 'course':
        return setActiveTab('courses');
      case 'partner':
        return setActiveTab('partnership');
      default:
        return setActiveTab('otherproduct');
    }
  };

  useEffect(() => {
    if (location?.state) {
      handleScroll(location?.state);
      setTimeout(() => {
        tableSection?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [location?.state]);

  return (
    <Box sx={{ p: 2 }}>
      <SaleItemCards handleScroll={handleScroll} />

      <Stack ref={tableSection} sx={{ marginTop: 5 }}>
        {location?.pathname === `${paths.dashboard.root}/app` ? (
          <AllCourseShare />
        ) : (
          <SaleItemListView
            partnerCode={partnerDetails?.findPartner?.partner_code}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </Stack>
    </Box>
  );
}
