import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Container, Typography } from '@mui/material';

import { getSettingsDetails } from 'src/server/api/settings';

import VideoPlayer from 'src/sections/landingPage/segment/VideoPlayer';
import BrandImages from 'src/sections/landingPage/segment/BrandImages';
import BranchSearch from 'src/sections/landingPage/segment/branchSearch';
import MiniImageCarousel from 'src/sections/landingPage/segment/carousel';

// import '../../style/customWeb.scss'
import HomePageSwipeMobile from './HomePageSwipeMobile';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { useDevice } from '../../sections/landingPage/hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function HomePage() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const [instagramData, setInstagramData] = useState([]);

  const { settingsDetails } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails?.settings,
  }));

  useEffect(() => {
    dispatch(getSettingsDetails({ dispatch }));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const INSTA_ACCESS_TOKEN =
        //   'IGQWRQc3NrT250YnFaa29lOHpMV2U4SmZAkM2hCS0V5QXdfaFY3bnpRc1Jtb0dPRVlrWm5KaVJtYVp0UWZAlakxMRzBhRF9DdmhYRlBrd250N25BdUREckFnc2ZADYTU3dnlYX2dUbFZARemtlencwcjA4WlN5OWdTZAU0ZD';
        const INSTA_ACCESS_TOKEN = settingsDetails?.instagram_token;
        const response = await axios.get(
          `https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${INSTA_ACCESS_TOKEN}`
        );
        console.log(response?.data);
        setInstagramData(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram data:', error);
      }
    };

    fetchData();
  }, [settingsDetails?.instagram_token]);

  const miniCardsDatas = [
    {
      img: '/assets/imagesWeb/Home/cyborg 3.png',
      heading: 'STEM & Innovation Course',
      subHead: 'For Grade 1-12  Students',
      link: '/robotics-ai-course',
      description: `STEM & Innovation course trains students on skills such as Coding, Robotics, AI, IoT
      and more. Curriculum is designed on STEM topics that nurtures creativity and fosters
      innovation.`,
      FeaturesLists: [
        'Hands-On Learning',
        'Interdisciplinary Approach',
        'Customized Curriculum',
        'Expert Instructors',
      ],
      BenefitLists: [
        'Project Based Learning',
        'Develop 21st Century skills',
        'Get Ready for AI ERA',
        'Master STEM Skills',
      ],
    },
    {
      img: '/assets/imagesWeb/Home/lens.png',
      heading: 'STEM & Tinkering Lab',
      subHead: 'For CBSE/ICSE/ INTERNATIONAL SCHOOLS',
      link: '/robotics-atl-tinkering-lab',

      description:
        'STEM Labs are innovation spaces to tinker and learn emerging technologies such as Coding,Robotics , AI , IoT. Our structured curriculum is aligned to NEP 2020 , CBSE , ICSE, IB Schools.  ',
      FeaturesLists: [
        'Align with National Education Policy',
        'Integrated curriculum for skill development',
        'learning management System',
      ],
      BenefitLists: [
        'Promotes Hands-On Learning',
        'Encourages Creativity and Innovation',
        'Prepares for Future Careers',
        'Enhances 21st century skills',
      ],
    },
    {
      img: '/assets/imagesWeb/Home/mouse.png',
      heading: 'STEM Facilitator / Educator Course',
      subHead: 'For Science & Engineering Graduates',
      link: '/robotics-trainer',

      description:
        'Our STEM Facilitator course upskills Graduates and Educators through experiential training on Coding, Robotics, AI, IoT and 3D Printing to Become a Certified STEM Facilitator. ',
      FeaturesLists: [
        'Expert Instruction',
        'Collaborative Learning',
        'Hands-On Activities',
        'Access to Resources',
      ],
      BenefitLists: [
        ' Inspire Future Innovators',
        'New Career Opportunities ',
        'Networking',
        'Enhanced teaching skills',
      ],
    },
    {
      img: '/assets/imagesWeb/Home/vr-headset.png',
      heading: 'Emerging Technologies for Colleges',
      subHead: 'For Science , Diploma and Engineering Colleges',
      link: '/college-robotics-ai',
      description: `This course enhances the Engineering and Science skills for College Students,
      preparing them for the career challenges and futuristic opportunities in Robotics, AI,
      Coding, 3D Design, and Electronics.`,
      FeaturesLists: [
        'Career Enhancement',
        'Advanced College Lab',
        ' Cross-Disciplinary Learning',
        'Hands-On Learning',
      ],
      BenefitLists: [
        'Career Advancement',
        'Holistic Education',
        'Advanced Facilities',
        'Preparedness for the Future',
      ],
    },
    {
      img: '/assets/imagesWeb/Home/walkie-talkie.png',
      heading: 'DIY STEM and Robotics KIT',
      subHead: 'For Grade 1-12  Students & Graduates',
      link: '#',

      description:
        'Our kit Deepens understanding of STEM, Coding, Robotics, AI, 3D design, Electronics concepts with project based learning for better retention.',

      FeaturesLists: [
        'Comprehensive Kit',
        'User-Friendly',
        'Educational Content',
        'Interactive Learning',
      ],
      BenefitLists: [
        'Deepens STEM Understanding',
        'Hands-On Learning',
        'Skill Development',
        'Creativity and Innovation',
      ],
    },
    {
      img: '/assets/imagesWeb/Home/partner.png',
      heading: 'Partner with us ',
      subHead: '',
      link: '/partner-program',

      description:
        'Partner with STEM-Xpert and boost your income at Zero Risk ! Become a driving force in empowering Coding , Robotics , AI, IoT and STEM education among student communities.',
      FeaturesLists: [
        'Flexible options',
        'Access to all our products',
        'ERP access',
        'lMS for students',
      ],
      BenefitLists: [
        'Low Investment- High Returns',
        'Established brand',
        'Marketing and technical support',
        'Training & support',
      ],
    },
  ];

  return (
    <>
      <MetaHelmet title="Home " />
      {/* header */}
      <StyledRoot>
        <Grid container spacing={2} className="image-container-mob">
          {/* Text Column */}
          <Grid item xs={12} sm={6} className={isMobile ? '' : 'content-box-main'}>
            <Box paddingLeft={!isMobile && 30} display="flex">
              {isMobile ? (
                <HomePageSwipeMobile />
              ) : (
                <div className="slider">
                  <div className="caption">
                    Say Hello to &nbsp;
                    <div className="text-box">
                      <div>Coding</div>
                      <div>Robotics</div>
                      <div>Artificial Intelligence</div>
                      <div>IoT</div>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Grid>

          {/* Image Column */}
          <Grid item xs={12} sm={6} className="image-box">
            <Box width="70%" height="100%">
              <img src="/assets/imagesWeb/Home/chat-bot.png" alt="mainimg" />
            </Box>
          </Grid>
        </Grid>
      </StyledRoot>

      <Container>
        {isMobile ? (
          <Stack pt={10}>
            <span className="home-page-mini-header-mobile">
              {' '}
              STEM-Xpert, Your gateway to Emerging Technologies.
            </span>
          </Stack>
        ) : (
          <Stack display="flex" alignItems="center" justifyContent="center" pt={10}>
            <span className="home-page-mini-header">
              {' '}
              STEM-Xpert, Your gateway to Emerging Technologies.
            </span>
          </Stack>
        )}
        <Grid container>
          <Grid item md={6} lg={6} xs={12}>
            <Container sx={{ p: isMobile ? 2 : 6 }}>
              {isMobile ? (
                <Stack>
                  {/* <Stack padding>
                    <span className="home-page-mini-header-mobile">
                      {' '}
                      STEM-Xpert, Your gateway to Emerging Technologies.
                    </span>
                  </Stack> */}
                  <Box>
                    <Typography color="#d9cbcb" fontSize={16} textAlign="justify">
                      {`
                      We specialize in providing training centered on 'STEM pedagogy', focusing on emerging technologies like Coding, Robotics, Artificial Intelligence, Internet of Things, 3D Printing, and more. Our programs cater to students from Grade 1 to 12 and graduates, also offering hands-on learning experiences for schools and colleges such as Robotics & AIoT Labs, Tinkering Labs, and Innovation Space.`}
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <>
                  {/* <Stack display="flex" alignItems="center" justifyContent="center" padding={5}>
                    <span className="home-page-mini-header">
                      {' '}
                      STEM-Xpert, Your gateway to Emerging Technologies.
                    </span>
                  </Stack> */}
                  <Box display="flex" justifyContent="center" textAlign="center">
                    <Typography color="#d9cbcb" fontSize={19} textAlign="justify" lineHeight={1.5}>
                      {`
                     We specialize in providing training centered on 'STEM pedagogy', focusing on emerging technologies like Coding, Robotics, Artificial Intelligence, Internet of Things, 3D Printing, and more. Our programs cater to students from Grade 1 to 12 and graduates, also offering hands-on learning experiences for schools and colleges such as Robotics & AIoT Labs, Tinkering Labs, and Innovation Space.
                      `}
                    </Typography>
                  </Box>
                </>
              )}
            </Container>
          </Grid>

          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Stack display="flex" justifyContent="center" py={5}>
              <VideoPlayer videoId="45DYk9-ox38" height={300} width={500} />
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: isMobile ? ' flex-start' : 'center',
            justifyContent: 'center',
            pb: 5,
            px: isMobile ? 3 : 10,
          }}
        >
          <img
            src="/assets/imagesWeb/Courses/mechine.png"
            alt="mainimg"
            style={{
              width: '60px',
              height: '50px',
              objectFit: 'contain',
              marginBottom: isMobile ? '10px' : 0,
            }}
          />
          <Typography
            sx={{
              mx: isMobile ? 2 : 0,
              color: '#FFFFFF',
              fontSize: isMobile ? '16px' : '20px',
              fontWeight: 0,
              padding: isMobile ? 0 : 2,
            }}
            className="font-normal-common"
          >
            {isMobile ? (
              <span>
                Are you prepared to delve into the realms of Programming, Robotics, AI, IoT, and
                beyond?
              </span>
            ) : (
              <span>
                Are you prepared to delve into the realms of Programming,
                <br /> Robotics, Artificial Intelligence, IoT, and beyond?
              </span>
            )}
          </Typography>
        </Box>
      </Stack>
      {/* technologies */}
      {isMobile ? (
        <Container>
          <Grid container spacing={2}>
            {miniCardsDatas?.map((item, key) => (
              <Grid key={key} item xs={12}>
                <Link to={item?.link}>
                  <Box className="flip-card-mobile" style={{ Width: '100%' }}>
                    <Box className="flip-card-inner-mobile">
                      <Box className="flip-card-front-mobile">
                        <Stack
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pt: 3,
                          }}
                          className="front"
                        >
                          <Stack>
                            <img
                              src={item?.img}
                              alt="mainimg"
                              style={{ width: '120px', height: '100px', objectFit: 'contain' }}
                            />
                          </Stack>
                          <Stack textAlign="center" padding={1}>
                            <Typography className="mini-card-last-header-mobile">
                              {item?.heading}
                            </Typography>
                            <Typography variant="subtitle2" className="mini-description">
                              {item?.subHead}
                            </Typography>
                            <Typography variant="body2" className="main-description">
                              {item?.description}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* </Card> */}
                      </Box>
                      <Box className="flip-card-back-mobile">
                        <Stack
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 2,
                          }}
                          className=""
                        >
                          <Stack
                            sx={{
                              border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              width: '90%',
                            }}
                          >
                            <Stack className="mini-flip-data-features-mobile">
                              <Stack className="flip-card-back-title-1-mobile">Features</Stack>
                              <ul className="mini-card-last-list">
                                {item?.FeaturesLists?.map((feature, index) => (
                                  <li key={index} className="text-white">
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              marginTop: 1,
                              width: '90%',
                            }}
                          >
                            <Stack className="mini-flip-data-benefits-mobile">
                              <Stack className="flip-card-back-title-2-mobile">Benefits</Stack>
                              <ul className="mini-card-last-list">
                                {item?.BenefitLists?.map((benifit, index) => (
                                  <li key={index}>{benifit}</li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : (
        <Container sx={{ px: 10 }}>
          <Grid container spacing={3}>
            {miniCardsDatas?.map((item, key) => (
              <Grid key={key} item md={4} lg={4}>
                <Link to={item?.link}>
                  <Box className="flip-card">
                    <Box className="flip-card-inner">
                      <Box className="flip-card-front">
                        <Stack
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pt: 3,
                          }}
                          className="front"
                        >
                          <Stack sx={{ minHeight: 120, maxHeight: 150 }}>
                            <img
                              src={item?.img}
                              alt="mainimg"
                              style={{ width: '120px', height: '100px', objectFit: 'contain' }}
                            />
                          </Stack>
                          <Stack textAlign="center" padding={1}>
                            <Typography className="mini-card-last-header">
                              {item?.heading}
                            </Typography>
                            <Typography variant="subtitle2" className="mini-description">
                              {item?.subHead}
                            </Typography>
                            <Typography variant="body2" className="main-description">
                              {item?.description}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* </Card> */}
                      </Box>
                      <Box className="flip-card-back">
                        <Stack
                          sx={{
                            // display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 2,
                          }}
                        >
                          <Stack
                            sx={{
                              border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              width: '90%',
                            }}
                          >
                            <Stack className="mini-flip-data-features">
                              <Stack className="flip-card-back-title-1">Features</Stack>
                              <ul className="mini-card-last-list">
                                {item?.FeaturesLists?.map((feature, index) => (
                                  <li key={index} className="">
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              border: '1px solid  rgba(255, 255, 255, 0.04)',
                              borderRadius: '10px',
                              paddingLeft: 3,
                              paddingRight: 3,
                              marginTop: 1,
                              width: '90%',
                              minHeight: 200,
                            }}
                          >
                            <Stack className="mini-flip-data-benefits">
                              <Stack className="flip-card-back-title-2">Benefits</Stack>
                              <ul className="mini-card-last-list">
                                {item?.BenefitLists?.map((benifit, index) => (
                                  <li key={index}>{benifit}</li>
                                ))}
                              </ul>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}

      <Stack>
        <BranchSearch />
      </Stack>

      {/* //carousel */}
      <MiniImageCarousel instagramData={instagramData} />
      {/* //carousel */}

      {/* brands */}
      <Stack sx={{ padding: isMobile ? '' : 3 }}>
        <BrandImages />
      </Stack>
    </>
  );
}
