import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Grid,
  Link,
  Stack,
  Button,
  Container,
  Typography,
  CardContent,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import VideoPlayer from './VideoPlayer';
import { useDevice } from '../hooks/useResponsive';

function LearningPage({ featuresBoxes, refValue }) {
  const isMobile = useDevice()?.isMobile;
  return (
    <div>
      <div>
        <Stack py={isMobile ? 0 : 3}>
          <VideoPlayer videoId="eT_jtgOyXFc" />
        </Stack>
        <Stack ref={refValue} py={isMobile ? 0 : 3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Card
              className="img-headline"
              style={{
                backgroundColor: '#212121',
                border: 'none',
                height: isMobile ? 'auto' : '550px',
                borderRadius: '15px',
                padding: '10px',
                margin: '10px',
                boxShadow: 'none',
                width: isMobile ? 'auto' : 1100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container spacing={3}>
                {/* Image - col-6 */}
                <Grid item xs={12} md={6}>
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Stack textAlign="" spacing={2} padding={0}>
                      <Stack display="flex" alignItems="center">
                        <img
                          src="/assets/imagesWeb/partner/Learning-Center.png"
                          alt="mainimg"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: 10,
                          }}
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Grid>

                {/* Inputs - col-6 */}
                <Grid item xs={12} md={6}>
                  {/* Add your inputs here */}
                  <Stack className="" sx={{ pt: 3 }}>
                    <Typography className="lab-experience-box-title">Learning Center </Typography>
                    <Stack>
                      <Typography
                        className="affiliate-box-title-description"
                        textAlign="justify"
                        sx={{ py: 2, pr: 1 }}
                      >
                        STEM-Xpert Learning Center is an innovative hub dedicated to delivering
                        interdisciplinary and experiential education for students and graduates from
                        Grade 1 to 12 in the realms of STEM, Robotics, Artificial Intelligence,
                        Machine Learning, Internet of Things, 3D Printing, and Coding. With our
                        unique approach to interdisciplinary and experiential learning, the Learning
                        Center presents a remarkable business prospect in STEM education.
                      </Typography>
                      <Typography
                        className="affiliate-box-title-description"
                        textAlign="justify"
                        pr={1}
                      >
                        This venture requires minimal investment and space, making it an ideal
                        opportunity with substantial profit potential. As a pioneer in the industry,
                        we offer a rapidly expanding business model, providing services such as
                        camps, workshops, and events focused on AI, Robotics, IoT, Coding, and 3D
                        Printing. Partnering with us not only ensures a mutually beneficial
                        collaboration but also affords you the chance to contribute to shaping the
                        next generation of adept problem solvers.
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* Submit Button */}
                  <Stack display="flex" alignItems={isMobile ? 'center' : ''} sx={{ pt: 3 }}>
                    <Link
                      href={`${paths.public.partner_regstration}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        className="px-5"
                        sx={{ width: 242, backgroundColor: '#05286C', boxShadow: 'none' }}
                      >
                        Start a Learning Center
                      </Button>
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Stack>
        <Stack my={3}>
          <Stack className="feature" p={isMobile && 3}>
            <Typography className="feature-title">Features</Typography>
          </Stack>
          <Container>
            <Grid container spacing={isMobile ? '0' : 5} paddingY={3} paddingX={isMobile ? 0 : 5}>
              {featuresBoxes?.map((i, k) => (
                <Grid item md={6} key={k} py={isMobile && 1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={isMobile ? 'feature-partner-boxes-mobile' : 'feature-partner-boxes'}
                    padding={3}
                  >
                    <img
                      alt={i?.title}
                      src={i?.img}
                      style={{
                        height: isMobile ? ' 100%' : '70px',
                        width: isMobile ? '' : '60px',
                        objectFit: 'contain',
                        padding: isMobile && 5,
                      }}
                    />
                    <Stack
                      className={
                        isMobile ? 'feature-lab-box-title-mobile' : 'feature-lab-box-title'
                      }
                    >
                      {i?.title}
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Stack>
      </div>
    </div>
  );
}

export default LearningPage;

LearningPage.propTypes = {
  featuresBoxes: PropTypes.array,
  refValue: PropTypes.any,
};
