import PropType from 'prop-types';
import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import LabModelItemHorizontal from './labModel-item-horizontal';

const LabModelEquipments = ({ labModelData }) => {
  const [isEquipments, setIsEquipments] = useState(true);

  // const renderSkeleton = (
  //   <>
  //     {[...Array(16)].map((_, index) => (
  //       <PostItemSkeleton key={index} variant="horizontal" />
  //     ))}
  //   </>
  // );

  const renderList = (
    <>
      {labModelData?.map((post) => (
        <LabModelItemHorizontal key={post.id} post={post} />
      ))}
    </>
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontWeight={700}>
          Equipments
          <Typography fontSize={14} fontWeight={400} color="#919EAB" pt="4px">
            {labModelData?.length} Equipments
          </Typography>
        </Typography>
        <Button onClick={() => setIsEquipments(!isEquipments)}>
          {isEquipments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>
      {isEquipments && (
        <Box
          mt={3}
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
        >
          {renderList}
          {/* {loading ? renderSkeleton : renderList} */}
        </Box>
      )}
    </>
  );
};

export default LabModelEquipments;

LabModelEquipments.propTypes = {
  labModelData: PropType.any,
};

// {
//   /* // <Grid key={key} md="4">
//             //   <Card
//             //     sx={{
//             //       mr: '24px',
//             //       mb: '24px',
//             //       boxShadow: 'none',
//             //       border: '1px solid #F0F0F0',
//             //     }}
//             //   >
//             //     <Box display="flex" alignItems="center" p={0}>
//             //       <Image
//             //         alt="our office 2"
//             //         src={item?.image}
//             //         ratio="1/1"
//             //         sx={{ borderRadius: 0, objectFit: 'cover' }}
//             //       />
//             //       <Typography fontSize={16} fontWeight={600} sx={{ p: 0 }}>
//             //         {item?.title}
//             //         <Typography fontSize={13} fontWeight={400} color="#919EAB" pt="8px">
//             //           {item?.description}
//             //         </Typography>
//             //       </Typography>
//             //     </Box>
//             //   </Card>
//             // </Grid> */
// }
