import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

// ----------------------------------------------------------------------

export default function AlertCenter({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Timeline
        sx={{
          m: 0,
          p: 3,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {list.map((item, index) => (
          <AlertItem key={item.id} item={item} lastTimeline={index === list.length - 1} />
        ))}
      </Timeline>
    </Card>
  );
}

AlertCenter.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

// ----------------------------------------------------------------------

function AlertItem({ item }) {
  const handleColor = (type) => {
    switch (type) {
      case 'onGoing':
        return 'warning';
      case 'completed':
        return 'success';
      default:
        return 'error';
    }
  };

  return (
    <TimelineItem sx={{ minHeight: '50px' }}>
      {item?.count > 0 && (
        <>
          <TimelineSeparator>
            <TimelineDot color={(item && handleColor(item?.type)) || 'error'} />
            {/* {lastTimeline ? null : <TimelineConnector />} */}
          </TimelineSeparator>
          <TimelineContent>
            <Typography width="75%" variant="subtitle2">
              {item?.title}
            </Typography>
          </TimelineContent>
        </>
      )}
    </TimelineItem>
  );
}

AlertItem.propTypes = {
  item: PropTypes.object,
};
