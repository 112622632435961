import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
// import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { Box, TextField, Typography, Autocomplete, InputAdornment } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllPartner } from 'src/server/api/partner';
import { getAllCountries } from 'src/server/api/country';
import { getAllLabModel } from 'src/server/api/labModel';
import { updateSchoolDetails } from 'src/server/api/school';
import { getAllState, getAllDistrict } from 'src/server/api/address';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';

// ----------------------------------------------------------------------

export default function UpdateSchoolProfile({ schoolData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');

  const { partnersList, labModelList, countryList, stateList, districtList, loading } = useSelector(
    (state) => ({
      partnersList: state.partner.partner,
      labModelList: state.labModel.labModel,
      countryList: state.country.country,
      stateList: state.address.state,
      districtList: state.address.district,
      loading: state.school.loading,
    })
  );

  const [selectedLabModel, setSelectedLabModel] = useState();
  const [searchLabModelText, setSearchLabModelText] = useState('');
  // partner
  const [selectedPartner, setSelectedPartner] = useState();
  const [searchPartnerText, setSearchPartnerText] = useState('');

  const [selectedCountry, setSelectedCountry] = useState();
  const [searchCountryText, setSearchCountryText] = useState('');
  const UAE_COUNTRY_ID = selectedCountry?.value === '660f7c95ca0f7e05752a3820';

  const [selectedState, setSelectedState] = useState();
  const [searchStateText, setSearchStateText] = useState('');

  const [selectedDistrict, setSelectedDistrict] = useState();
  const [searchDistrictText, setSearchDistrictText] = useState('');

  // const [selectedCode, setSelectedCode] = useState('91');

  // const handlePhoneInputChange = (value, inputName) => {
  //   const country_code = getCountryCallingCode(value);
  //   setSelectedCode(country_code);
  // };

  const optionPartner = [
    ...(partnersList?.partners || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionLabModel = [
    ...(labModelList?.labModels || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const optionCountry = [
    { label: 'Other', value: '' },
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionDistrict = [
    ...(districtList?.districts || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: '',
      search: searchPartnerText,
      dispatch,
      limit: '',
      sort: '',
    };
    dispatch(getAllPartner(credentials));
  }, [searchPartnerText, dispatch]);

  useEffect(() => {
    const credentials = {
      page: '',
      limit: '',
      search: searchLabModelText,
      dispatch,
    };
    dispatch(getAllLabModel(credentials));
  }, [searchLabModelText, dispatch]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchCountryText,
      dispatch,
      limit: 10,
    };
    dispatch(getAllCountries(credentials));
  }, [dispatch, searchCountryText]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchStateText,
      limit: 10,
      dispatch,
      countryId: selectedCountry?.value,
    };
    dispatch(getAllState(credentials));
  }, [dispatch, searchStateText, selectedCountry]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchDistrictText,
      limit: 10,
      dispatch,
      stateId: selectedState?.value,
    };
    dispatch(getAllDistrict(credentials));
  }, [dispatch, searchDistrictText, selectedState]);

  const defaultPartner = useMemo(
    () => ({
      label: schoolData?.partner?.name || '',
      value: schoolData?.partner?._id || '',
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedPartner(defaultPartner);
  }, [defaultPartner]);

  const defaultLabModel = useMemo(
    () => ({
      label: schoolData?.lab_model?.title || '',
      value: schoolData?.lab_model?._id || '',
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedLabModel(defaultLabModel);
  }, [defaultLabModel]);

  const defaultCountry = useMemo(() => {
    if (!schoolData?.address?.other_country) {
      return {
        label: schoolData?.address?.country?.name || '',
        value: schoolData?.address?.country?._id || '',
      };
    }
    return {
      label: 'Other',
      value: '',
    };
  }, [schoolData]);

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  const defaultState = useMemo(
    () => ({
      label: schoolData?.address?.state?.name || '',
      value: schoolData?.address?.state?._id || '',
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedState(defaultState);
  }, [defaultState]);

  const defaultDistrict = useMemo(
    () => ({
      label: schoolData?.address?.district?.name || '',
      value: schoolData?.address?.district?._id || '',
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedDistrict(defaultDistrict);
  }, [defaultDistrict]);

  const NewJobSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone required'),
    email: Yup.string().email(),
    partner: Yup.object(),
    lab_model: Yup.object(),
    address: Yup.object().shape({
      address_line1: Yup.string(),
      address_line2: Yup.string(),
      pincode: Yup.number(),
      other_country: Yup.string(),
      other_state: Yup.string(),
      other_district: Yup.string(),
      country: Yup.object(),
      state: Yup.object(),
      district: Yup.object(),
    }),
  });

  const formattedInstallationDate = schoolData?.installation_date
    ? moment(schoolData?.installation_date).format('YYYY-MM-DD')
    : '';

  const countryCode = schoolData?.countryCode || '91';
  const phone = `+${countryCode}${schoolData?.phone?.replace(/\s+/g, '')}` || '';

  const defaultValues = useMemo(
    () => ({
      name: schoolData?.name || '',
      installation_date: formattedInstallationDate || '',
      email: schoolData?.email || '',
      phone,
      lab_model: defaultLabModel || {},
      partner: defaultPartner || {},
      address: {
        address_line1: schoolData?.address?.address_line1 || '',
        address_line2: schoolData?.address?.address_line2 || '',
        pincode: schoolData?.address?.pincode || 0,
        other_country: schoolData?.address?.other_country || '',
        other_state: schoolData?.address?.other_state || '',
        other_district: schoolData?.address?.other_district || '',
        country: defaultCountry || {},
        state: defaultState || {},
        district: defaultDistrict || {},
      },
    }),
    [
      defaultCountry,
      defaultDistrict,
      defaultLabModel,
      defaultPartner,
      defaultState,
      formattedInstallationDate,
      schoolData?.address?.address_line1,
      schoolData?.address?.address_line2,
      schoolData?.address?.pincode,
      schoolData?.address?.other_country,
      schoolData?.address?.other_state,
      schoolData?.address?.other_district,
      schoolData?.email,
      schoolData?.name,
      phone,
    ]
  );

  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const otherCountry = watch('address.other_country');

  useEffect(() => {
    if (schoolData?._id) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, schoolData]);

  const onSubmit = handleSubmit(async (data) => {
    const schoolId = schoolData?._id;
    const modifiedData = {
      ...data,
      partner: selectedPartner?.value || null,
      lab_model: selectedLabModel?.value || null,
      // phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,
      // country_code: selectedCode,
      address: {
        ...data?.address,
        pincode: data?.address?.pincode || null,
        country: selectedCountry?.label === 'Other' ? null : selectedCountry?.value || null,
        state: selectedCountry?.label === 'Other' ? null : selectedState?.value || null,
        district: selectedCountry?.label === 'Other' ? null : selectedDistrict?.value || null,
        other_country: selectedCountry?.value ? '' : data?.address?.other_country,
        other_state: selectedCountry?.value ? '' : data?.address?.other_state,
        other_district: selectedCountry?.value ? '' : data?.address?.other_district,
      },
    };
    ['phone', 'email']?.forEach((key) => {
      delete modifiedData[key];
    });

    const credentials = {
      state: modifiedData,
      schoolId,
      dispatch,
      navigate,
    };
    // console.log('credentials', credentials);
    dispatch(updateSchoolDetails(credentials));
  });

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} mt={5} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={loading}
          sx={{ ml: 2, width: '250px' }}
          // disabled={!selectedLabModel || !schoolData?.lab_model?._id}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card sx={{ padding: 4 }}>
        <Stack direction="row" spacing={2}>
          <Stack width="50%" spacing={2.5}>
            <Typography variant="subtitle1">Basic Details</Typography>
            <RHFTextField name="name" label="School Name" type="text" required />
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionPartner}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultPartner}
              defaultValue={defaultPartner}
              onChange={(e, values) => setSelectedPartner(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Partner"
                  // name="head_partner_type"
                  onChange={(e) => setSearchPartnerText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionLabModel}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultLabModel}
              defaultValue={defaultLabModel}
              onChange={(e, values) => setSelectedLabModel(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lab Model"
                  onChange={(e) => setSearchLabModelText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            <TextField
              name="installation_date"
              label="Installation Date"
              type="date"
              {...register('installation_date', {
                required: {
                  value: false,
                  message: 'Installation Date is required',
                },
              })}
              sx={{ width: '50%' }}
              error={!!errors?.installation_date}
              helperText={errors?.installation_date?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="subtitle1">School Admin</Typography>
            <RHFPhoneInput
              name="phone"
              label="Phone"
              placeholder="Enter Phone"
              // setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
              disabled
            />
            <RHFTextField name="email" label="Email" type="email" disabled />
          </Stack>
          <Stack width="50%" spacing={2.5}>
            <Typography variant="subtitle1">School Address</Typography>
            <RHFTextField
              name="address.address_line1"
              label="Address Line 1"
              type="text"
              required
            />
            <RHFTextField name="address.address_line2" label="Address Line 2" type="text" />
            <Stack spacing={2} direction="row">
              <RHFTextField
                name="address.pincode"
                label="Pincode"
                type="number"
                // required
              />
              <Autocomplete
                id="partner-select-demo"
                sx={{ width: '100%' }}
                options={optionCountry}
                autoHighlight
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option?.label}
                  </Box>
                )}
                // value={defaultCountry}
                defaultValue={defaultCountry}
                onChange={(e, values) => setSelectedCountry(values)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    required={!otherCountry}
                    // name="head_partner_type"
                    onChange={(e) => setSearchCountryText(e.target.value)}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Stack>
            {selectedCountry?.value && (
              <Stack spacing={2} direction="row">
                <Autocomplete
                  id="partner-select-demo"
                  sx={{ width: '50%' }}
                  options={optionState}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option?.label}
                    </Box>
                  )}
                  // value={defaultState}
                  defaultValue={defaultState}
                  onChange={(e, values) => setSelectedState(values)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State/Emirates/Province"
                      required={selectedCountry?.value && !UAE_COUNTRY_ID}
                      // name="head_partner_type"
                      onChange={(e) => setSearchStateText(e.target.value)}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {!UAE_COUNTRY_ID && selectedState?.value && (
                  <Autocomplete
                    id="partner-select-demo"
                    sx={{ width: '50%' }}
                    options={optionDistrict}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option?.label}
                      </Box>
                    )}
                    // value={defaultDistrict}
                    defaultValue={defaultDistrict}
                    onChange={(e, values) => setSelectedDistrict(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District"
                        required={selectedCountry?.value && !UAE_COUNTRY_ID}
                        // name="head_partner_type"
                        onChange={(e) => setSearchDistrictText(e.target.value)}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Stack>
            )}
            {selectedCountry?.label === 'Other' && (
              <Stack spacing={2} direction="row">
                <RHFTextField
                  name="address.other_country"
                  label="Other Country"
                  type="text"
                  required={!selectedCountry?.value}
                />
                <RHFTextField
                  name="address.other_state"
                  label="Other State/Emirates/Province"
                  type="text"
                />
              </Stack>
            )}
            {selectedCountry?.label === 'Other' && (
              <Stack width="50%">
                <RHFTextField name="address.other_district" label="Other District" type="text" />
              </Stack>
            )}
          </Stack>
        </Stack>
        {renderActions}
      </Card>
    </FormProvider>
  );
}

UpdateSchoolProfile.propTypes = {
  schoolData: PropTypes.any,
};
