import React from 'react';
import styled from '@emotion/styled';

import { Box, Grid, Link, Stack, Button, Container, Typography } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BrandImages from 'src/sections/landingPage/segment/BrandImages';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';

export function AptitudeTest() {
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;
  console.log(isMobile);

  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  // const miniImages = [
  //   { img: '/assets/images/test/guide.png', title: 'Guidelines' },
  //   { img: '/assets/images/test/price.png', title: 'Prize' },
  //   { img: '/assets/images/test/contactus.png', title: 'Contact US' },
  // ];
  const exitingPrice = [
    {
      img1: '/assets/imagesWeb/test/star.png',
      img2: '/assets/imagesWeb/test/star.png',
      img3: '/assets/imagesWeb/test/star.png',
      score: 'score 90%',
      scholarship: '75% Scholarship',
    },
    {
      img1: '/assets/imagesWeb/test/star.png',
      img2: '/assets/imagesWeb/test/star.png',
      score: 'score 75%-90%',
      scholarship: '50% Scholarship',
    },
    {
      img1: '/assets/imagesWeb/test/star.png',
      score: 'score 50%-75%',
      scholarship: '25% Scholarship',
    },
  ];

  return (
    <>
      <MetaHelmet title="STEM Test" />
      <StyledRoot>
        {isMobile === true ? (
          <Container className="landgmainsection" maxWidth="lg" sx={{ pt: 2 }}>
            <Grid
              container
              spacing={2}
              className="image-container"
              sx={{ position: 'relative', minHeight: '200px', display: 'flex', padding: 2 }}
            >
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={6}
                className="content-box"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: '',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ color: '#E1E1E1', fontSize: 22, fontWeight: 600 }}>
                    STEM Aptitude Test (SAT) &nbsp;
                  </Typography>
                  <Typography className="course-subtitle-mobile" pt={isMobile && 1}>
                    What is{' '}
                    <b style={{ fontWeight: 600, color: '#E1E1E1' }}>STEM Aptitude Test(SAT)?</b>{' '}
                    Its your ticket to a vibrant world of STEM (Science, Technology, Engineering,
                    and Mathematics) opportunities.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} className="image-box">
                <Box
                  sx={{
                    width: '70%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src="/assets/imagesWeb/test/Group.png"
                    alt="mainimg"
                    style={{ width: '70%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Grid container spacing={2} className="image-container" style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              sm={6}
              className="content-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <Typography className="test-main-header">STEM Aptitude Test (SAT)</Typography>
                <Typography className="test-sub-header">
                  What is{' '}
                  <b className="" style={{ color: 'white', fontWeight: 600 }}>
                    STEM Aptitude Test(SAT) ?
                  </b>{' '}
                  {`It's your ticket to a vibrant`} <br /> world of STEM (Science, Technology,
                  Engineering, and
                  <br /> Mathematics) opportunities.
                </Typography>
                <Stack marginTop={3}>
                  <Link href="/contactUs" target="_blank" rel="noreferrer">
                    <Button
                      variant="contained"
                      className="px-1"
                      size="lg"
                      style={{
                        width: isMobile === true ? '100%' : 'auto',
                        maxWidth: '250px',
                        borderRadius: 15,
                        backgroundColor: '#05286C',
                        boxShadow: 'none',
                        height: '60px',
                      }}
                    >
                      Take STEM Aptitude Test Now
                    </Button>
                  </Link>
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="image-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ width: '70%', height: '100%' }}>
                <img
                  src="/assets/imagesWeb/test/Group.png"
                  alt="mainimg"
                  style={{ width: '70%', height: '100%', objectFit: 'contain' }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </StyledRoot>

      <Container sx={{ py: isMobile === true ? 2 : 15 }}>
        <Box style={{ textAlign: isMobile ? 'justify' : 'center' }}>
          <Typography sx={{ color: '#d9cbcb' }}>
            Unlock a world of possibilities with SAT: Discover your STEM strengths, enhance
            problem-solving skills, and
            {isMobile ? '' : <br />}
            {`receive expert tech course guidance. Your future in STEM starts here! Don't miss this incredible
            opportunity.`}
            {isMobile ? '' : <br />}
            Take SAT and seize your destiny !
          </Typography>
        </Box>
      </Container>
      {/* <Stack>
        <RegisterForm />
      </Stack> */}
      {/* <Container>
        <Grid
          container
          spacing={1}
          paddingY={5}
          paddingX={isMobile ? 0 : 15}
          display={isMobile ? 'flex' : ''}
          alignItems={isMobile ? 'center' : ''}
          justifyContent={isMobile ? 'center' : ''}
        >
          {miniImages?.map((i, k) => (
            <Grid key={k} item xs={4} sm={4}>
              <Box paddingTop={3}>
                <Stack className="test-boxes">
                  <Stack display={'flex'} alignItems={'center'} justifyItems={'center'}>
                    <Stack paddingTop={isMobile ? 0 : 3} className="test-img-parent">
                      <img
                        alt={i?.title}
                        src={i?.img}
                        style={{ height: '100%', width: '60%', objectFit: 'contain' }}
                        className="img-container-test"
                      />
                    </Stack>
                    <Stack className="test-box-title">{i?.title}</Stack>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container> */}
      <Container>
        <Stack>
          <Typography className="win-exiting-prize">Win Exciting Prize</Typography>
        </Stack>
      </Container>
      <Container>
        <Grid container spacing={1} paddingY={5} paddingX={isMobile ? 0 : 15}>
          {exitingPrice?.map((i, k) => (
            <Grid key={k} item xs={4} sm={4}>
              <Box paddingTop={3}>
                <Stack className="star-boxes">
                  <Stack display="flex" alignItems="center" justifyItems="center">
                    <Stack paddingTop={3} className="star-img-parent">
                      <img alt={i?.img1} src={i?.img1} className="star-img" />
                      {i?.img2 && (
                        <img
                          alt={i?.img1}
                          src={i?.img2}
                          className="star-img"
                          style={{ marginLeft: 5 }}
                        />
                      )}
                      {i?.img3 && (
                        <img
                          alt={i?.star}
                          src={i?.img3}
                          className="star-img"
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </Stack>
                    <Stack className="star-box-score">{i?.score}</Stack>
                    <Stack>
                      <Typography className="star-box-scholarship">{i?.scholarship}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="row"
        padding={isMobile === true ? '' : 5}
        sx={{ px: isMobile ? 2 : 0 }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: isMobile ? 10 : 5,
            borderRadius: 20,
            width: isMobile ? '380px' : '820px',
            height: isMobile ? 'auto' : '',
            backgroundColor: '#212121',
          }}
        >
          <img
            src="/assets/imagesWeb/test/head.png"
            alt="mainimg"
            style={{ width: '30px', height: '20px', objectFit: 'contain' }}
          />
          &nbsp; &nbsp; &nbsp;
          <Typography className={isMobile ? 'header-div-content-mobile' : 'header-div-content'}>
            ALL PARTICIPANTS WILL GET PARTICIPATION CERTIFICATES AND 10% DISCOUNT ON STEM COURSES
          </Typography>
        </div>
      </Grid>
      <Grid sx={{ padding: isMobile === true ? 3 : 5 }}>
        <Typography className="rules-and-regulations">Guidelines</Typography>
      </Grid>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="row"
        padding={isMobile === true ? '' : 3}
        sx={{ px: isMobile ? 2 : 0 }}
      >
        <Stack
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: isMobile === true ? 5 : 50,
            paddingBottom: isMobile === true ? 5 : 50,
            borderRadius: 30,
            width: isMobile === true ? '380px' : 960,
            backgroundColor: '#212121',
            height: isMobile === true ? 'auto' : 'auto',
          }}
        >
          <ul
            className={isMobile ? 'list-styles-mobile' : 'list-styles'}
            style={{
              listStyle: '',
              padding: isMobile ? 25 : '',
              margin: 0,
            }}
          >
            <li>25 Questions will be asked for this aptitude test</li>
            <li>
              To win the test, students must attempt as many questions as possible within the time
              limit
            </li>
            <li>This test is of 5 minutes in duration</li>
            <li>No extra time will be allotted so be careful while attending the Exam</li>
            <li>One mark will be awarded for each correct Answer</li>
            <li>Questions will be on the topic of Science, Technology, and Mathematics</li>
          </ul>
        </Stack>
      </Grid>
      <Stack marginTop={3} flexDirection="row" justifyContent="center">
        <a href="/contactUs" target="_blank" rel="noreferrer">
          <Button
            variant="contained"
            className="px-1"
            size="lg"
            style={{
              width: isMobile === true ? '100%' : '350px',
              maxWidth: '350px',
              borderRadius: 15,
              backgroundColor: '#05286C',
              boxShadow: 'none',
              height: '60px',
            }}
          >
            Take STEM Aptitude Test
          </Button>
        </a>
      </Stack>
      <Stack sx={{ padding: isMobile === true ? '' : 5 }}>
        <BrandImages />
      </Stack>
    </>
  );
}
