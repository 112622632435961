import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Card, Stack, Button, Container, Typography, LinearProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

function AcademyExamsOver() {
  const mobile = useResponsive('down', 'md');
  const navigate = useNavigate();

  const { loading, studentExamsLoading } = useSelector((state) => ({
    loading: state.lmsExam.loading,
    studentExamsLoading: state.studentExam.loading,
  }));

  useEffect(() => {
    localStorage.removeItem('t');
    localStorage?.removeItem('b');
    localStorage.removeItem('p');
    localStorage.removeItem('s');
    // localStorage.removeItem('ans');
  }, []);

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Exams" />

      {loading && studentExamsLoading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ width: '100%', mt: mobile ? 10 : 3, mb: 7, typography: 'body1' }}>
        <Container maxWidth="md" sx={{ ml: 0 }}>
          <Stack component={Card} spacing={3} sx={{ p: 3 }}>
            <Typography variant="body2" color="green">
              {`
Congratulations! Your exam has been completed successfully.

`}
            </Typography>
            <Typography variant="body2">
              {`

Now that you've finished your exam, our team will carefully review your responses and calculate your score. Please be patient as we ensure the accuracy and fairness of the assessment process.

`}
            </Typography>
            <Typography variant="body2">
              {`

We'll notify you of your exam score and provide any additional feedback or information within the specified timeframe. In the meantime, feel free to relax and take a well-deserved break.
`}
            </Typography>

            <Typography variant="body2">
              {`

Thank you for your participation and effort during the exam. We appreciate your dedication to your studies and wish you the best of luck with your results.
`}
            </Typography>
            <Typography variant="body2">
              {`

If you have any questions or concerns, don't hesitate to reach out to our support team for assistance.`}
            </Typography>

            <Button
              variant="contained"
              color="warning"
              sx={{ mr: 2, width: '60%', margin: 'auto' }}
              onClick={() => navigate(paths.auth.exams)}
            >
              Enroll other exams
            </Button>
          </Stack>
        </Container>
      </Box>
    </Container>
  );
}

export default AcademyExamsOver;
