import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';

import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// @mui
import { Box, Grid, Dialog, Tooltip, Typography, DialogContent } from '@mui/material';

import { colors } from 'src/theme/colors';

// import TextMaxLine from 'src/components/text-max-line';

import LabImages from './labModelImageCarousal';

LabModelViewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function LabModelViewDialog({ open, setOpen, data }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="testnew"
        sx={{ maxWidth: '100%' }}
      >
        {/* <DialogTitle id="responsive-dialog-title">
          Lab Model Details
        </DialogTitle> */}
        <Box display="flex" justifyContent="end" mt={2} mr={3}>
          <Tooltip title="Close">
            <CloseIcon onClick={handleClose} fontSize="large" sx={{ cursor: 'pointer' }} />
          </Tooltip>
        </Box>
        <DialogContent sx={{ minWidth: '600px', py: 3, mx: 5, mb: 5 }}>
          <Grid item spacing={2} container>
            <Grid item md="6">
              <Typography variant="h4" display="flex" alignItems="center">
                {data?.title}
              </Typography>
              <Typography variant="caption">
                {data?.description ? parse(data.description) : null}
              </Typography>
              {data?.curriculum_data && (
                <>
                  <Typography pt={3} variant="h6" color={colors.secondary_1}>
                    Curriculum
                  </Typography>
                  <Typography variant="caption">
                    {data?.curriculum_data ? parse(data.curriculum_data) : null}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item md="6">
              <LabImages list={data?.images} />
              <Typography pt={3} pb={1} variant="h6" color={colors.secondary_1}>
                Lab Includes
              </Typography>
              {data?.equipment_list?.map((item, key) => (
                <Typography key={key} display="flex" alignItems="center">
                  <FiberManualRecordIcon sx={{ fontSize: '13px', mr: 1 }} />
                  {item?.name}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
