import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSale = createAsyncThunk(
  'sale/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/sale/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Sale Item created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSale = createAsyncThunk(
  'Sale/list',
  async ({ page, limit, partner, status, type, dispatch, partnerType, date, country }) => {
    try {
      const response = await get(
        `/sale/admin/all?page=${page && page}&limit=${limit || 5}&partner=${partner || ''}&status=${
          status || ''
        }&type=${type || ''}&partner_type=${partnerType || ''}&dateFrom=${date.from}&dateTo=${
          date.to
        }&country=${country || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSubPartnerSale = createAsyncThunk(
  'subPartner-Sale/list',
  async ({ page, limit, partner, status, type, dispatch, partnerType, date, country }) => {
    try {
      const response = await get(
        `/sale/v2/sub-partner/all?page=${page && page}&limit=${limit || 5}&partner=${partner || ''}&status=${
          status || ''
        }&type=${type || ''}&partner_type=${partnerType || ''}&dateFrom=${date.from}&dateTo=${
          date.to
        }&country=${country || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSinglePartnerSale = createAsyncThunk(
  'Sale/list',
  async ({ page, limit, partner, status, type, dispatch, partnerType, date }) => {
    try {
      const response = await get(
        `/sale/country-head/all/${partner}?page=${page || 1}&limit=${limit || 5}&status=${
          status || ''
        }&type=${type || ''}&partner_type=${partnerType || ''}&dateFrom=${date.from}&dateTo=${
          date.to
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSaleDetails = createAsyncThunk('Sale/single', async ({ saleId, dispatch }) => {
  try {
    const response = await get(`/sale/admin/${saleId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSaleDetails = createAsyncThunk(
  'Sale/update',
  async ({ state, saleId, dispatch, navigate }) => {
    try {
      const URL = `/sale/admin/${saleId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Sale Item updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSale = createAsyncThunk(
  'Sale/delete',
  async ({ saleId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/sale/admin/${saleId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Sale Item deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
