import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  createContent,
  deleteContent,
  getAllContents,
  getContentDetails,
  updateContentDetails,
  getAcademyContentDetails,
  getAcademyContentsByCourse,
} from '../api/content';
// import { covertToJSON } from '../../utils/stateToJson';

export const contentSlice = createSlice({
  name: 'content',
  initialState: {
    loading: false,
    error: {},
    contents: {},
    contentDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create content
    [createContent.pending]: (state) => {
      state.loading = true;
    },

    [createContent.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contents;
      const newContentList = jsonState?.contents;

      const modifiedContentList = {
        ...jsonState,
        contents: Array.isArray(newContentList)
          ? [action.payload, ...newContentList]
          : [action.payload],
      };
      state.loading = false;
      state.contentDetails = action.payload;
      state.contents = modifiedContentList;
      state.error = {};
    },
    [createContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all contents
    [getAcademyContentsByCourse.pending]: (state) => {
      state.loading = true;
    },
    [getAcademyContentsByCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.contents = action.payload;
      state.error = {};
    },
    [getAcademyContentsByCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all contents
    [getAllContents.pending]: (state) => {
      state.loading = true;
    },
    [getAllContents.fulfilled]: (state, action) => {
      state.loading = false;
      state.contents = action.payload;
      state.error = {};
    },
    [getAllContents.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single content
    [getContentDetails.pending]: (state) => {
      state.loading = true;
    },
    [getContentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.contentDetails = action.payload;
      state.error = {};
    },
    [getContentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update content details
    [updateContentDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateContentDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contents;
      const modifiedContentList = {
        ...jsonState,
        contents: jsonState.contents?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.contents = modifiedContentList;
      state.contentDetails = action.payload;
      state.contents = modifiedContentList;
      state.error = {};
    },
    [updateContentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete content
    [deleteContent.pending]: (state) => {
      state.loading = true;
    },

    [deleteContent.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contents;
      const modifiedContentList = {
        ...jsonState,
        contents: jsonState.contents?.filter((contents) => contents._id !== action.payload),
      };
      state.loading = false;
      state.contents = modifiedContentList;
      state.error = {};
    },

    [deleteContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // public
    [getAcademyContentDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAcademyContentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.contentDetails = action.payload;
      state.error = {};
    },
    [getAcademyContentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = contentSlice.actions;

export default contentSlice.reducer;
