import PropType from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Card, Grid, Button, Typography, Pagination, paginationClasses } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';
import { getSettingsDetails } from 'src/server/api/settings';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content/empty-content';

const RegisteredExamList = ({ materialData, attendedExams, page, setPage, total }) => {
  const mobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAttendedExams = (id) => {
    const isExamAttended =
      attendedExams && attendedExams?.length && attendedExams?.some((item) => item?.exam === id);
    return isExamAttended;
  };
  console.log(materialData?.exams);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={mobile ? 0 : 1} mt="5px">
        {materialData?.exams?.length <= 0 && (
          <EmptyContent filled title="No Data" sx={{ py: 10 }} />
        )}
        {materialData?.exams?.map((item, key) => (
          <Grid key={key} item md="4" sm={12} sx={{ width: mobile && '100%' }}>
            <Link
              key={key}
              to={paths.auth.exams_view(item?._id, item?.exam?._id)}
              style={{ textDecoration: 'none' }}
            >
              <Card
                sx={{
                  mr: mobile ? 0 : '24px',
                  mb: '24px',
                  boxShadow: 'none',
                  border: '1px solid #F0F0F0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {attendedExams && attendedExams?.length > 0 && handleAttendedExams(item?._id) && (
                  <Box
                    sx={{
                      p: 3,
                      top: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#faa74ae0',
                      zIndex: 10,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" color={colors.white}>
                      You already attended
                      <br /> this exam!
                    </Typography>
                    <Image
                      alt="file preview"
                      src="/assets/images/lms/stamp.png"
                      sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                )}
                <Box p={2}>
                  <Typography fontSize={16} mb={1} fontWeight={600}>
                    {item?.exam?.title}
                  </Typography>
                  <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
                    {item?.exam?.description}
                  </TextMaxLine>
                </Box>
                <Button
                  sx={{
                    borderRadius: 100,
                    backgroundColor: colors.stem_blue,
                    color: '#fff',
                    fontWeight: 400,
                    minWidth: 100,
                    px: 1,
                    mr: 2,
                  }}
                  color="info"
                  variant="contained"
                  size="small"
                  onClick={() => navigate(paths.auth.exams_view(item?._id, item?.exam?._id))}
                >
                  Attend Exam
                </Button>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      {materialData?.exams?.length > 0 && (
        <Pagination
          onChange={(e, value) => setPage(value)}
          count={total}
          defaultValue={page}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default RegisteredExamList;

RegisteredExamList.propTypes = {
  materialData: PropType.any,
  attendedExams: PropType.any,
  page: PropType.any,
  setPage: PropType.any,
  total: PropType.any,
};
