// @mui
import { Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import SchoolProfileView from 'src/sections/school/school-profile-view';

export default function SchoolProfile() {
  return (
    <>
      <MetaHelmet title="School Profile" />
      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Profile"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Profile',
            },
          ]}
          action="" // add create button here
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <SchoolProfileView />
      </Container>
    </>
  );
}
