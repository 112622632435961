import PropType from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Card, Grid, Button, Typography, Pagination, paginationClasses } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';
import { getSettingsDetails } from 'src/server/api/settings';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content/empty-content';

const ExamList = ({ materialData, attendedExams, pageExam, setPageExam, examTotal }) => {
  const mobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAttendedExams = (id) => {
    const isExamAttended =
      attendedExams && attendedExams?.length && attendedExams?.some((item) => item?.exam === id);
    return isExamAttended;
  };

  const finalDataForExam = materialData?.exams?.map((item) => {
    const matchingItem = materialData?.examContentCounts?.find(
      (secondItem) => secondItem.examId === item._id
    );
    if (matchingItem) {
      return {
        ...item,
        examContentCount: matchingItem.examContentCount,
      };
    }
    return item;
  });

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={mobile ? 0 : 1} mt="5px">
        {materialData?.exams?.length <= 0 && (
          <EmptyContent filled title="No Data" sx={{ py: 10 }} />
        )}

        {finalDataForExam?.map((item, key) => (
          <Grid key={key} item md="4" sm={12} sx={{ width: mobile && '100%' }}>
            <Link
              key={key}
              to={
                handleAttendedExams(item?._id) || item?.examContentCount <= 0
                  ? '#'
                  : paths.auth.exams_register(item?._id)
              }
              style={{ textDecoration: 'none' }}
            >
              <Card
                sx={{
                  mr: mobile ? 0 : '24px',
                  mb: '24px',
                  boxShadow: 'none',
                  border: '1px solid #F0F0F0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {attendedExams && attendedExams?.length > 0 && handleAttendedExams(item?._id) && (
                  <Box
                    sx={{
                      pr: 3,
                      pl: 2,
                      top: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#faa74a7a',
                      zIndex: 10,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ mb: 1, fontSize: '10px' }}
                      color={colors.secondary}
                    >
                      You already attended this exam!
                    </Typography>
                    <Image
                      alt="file preview"
                      src="/assets/images/lms/stamp.png"
                      sx={{
                        width: '60px',
                        height: '60px',
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                )}
                <Box p={2}>
                  <Typography fontSize={16} mb={1} fontWeight={600}>
                    {item?.title}
                  </Typography>
                  <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
                    {item?.description}
                  </TextMaxLine>
                </Box>
                {attendedExams && attendedExams?.length > 0 && handleAttendedExams(item?._id) ? (
                  ''
                ) : (
                  <Button
                    sx={{
                      borderRadius: 100,
                      backgroundColor: colors.stem_blue,
                      color: '#fff',
                      fontWeight: 400,
                      minWidth: 100,
                      // px: 1,
                      p: 0,
                      mr: 2,
                    }}
                    disabled={handleAttendedExams(item?._id) || item?.examContentCount <= 0}
                    color="info"
                    variant="contained"
                    size="small"
                    onClick={() => navigate(paths.auth.exams_register(item?._id))}
                  >
                    Register Exam
                  </Button>
                )}
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      {materialData?.exams?.length > 0 && (
        <Pagination
          onChange={(e, value) => setPageExam(value)}
          count={examTotal}
          defaultValue={pageExam}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default ExamList;

ExamList.propTypes = {
  materialData: PropType.any,
  attendedExams: PropType.any,
  pageExam: PropType.any,
  setPageExam: PropType.any,
  examTotal: PropType.any,
};
