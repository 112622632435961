import React from 'react';

import { Box, Card, Grid, Link, Stack, Button, Typography, CardContent } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useDevice } from '../hooks/useResponsive';

function Form() {
  const { isMobile } = useDevice();

  return (
    <div>
      {isMobile === true ? (
        <Box>
          <Card
            className="img-headline"
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              height: 'auto', // Auto height for better responsiveness
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'end',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={2} padding={0}>
                    <Stack display="flex" alignItems="">
                      <img
                        src="/assets/imagesWeb/Courses/online.png"
                        alt="mainimg"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                {/* Add your inputs here */}
                <Stack className="online-offline-course-banner" sx={{ pt: 10 }}>
                  <Typography className="online-offline-course-banner-title">
                    Experience our Lab for Free!
                  </Typography>
                  <Stack>
                    <Typography
                      className="online-offline-course-banner-description"
                      textAlign="justify"
                      sx={{ py: 2 }}
                    >
                      Discover the future firsthand by immersing yourself in our state-of-the-art
                      lab, where curiosity meets cutting-edge technology. Explore, experiment, and
                      experience the thrill of discovery—all at no cost. Join us and witness the
                      limitless possibilities at our lab!
                    </Typography>
                  </Stack>
                </Stack>
                {/* Submit Button */}
                <Stack display="flex" sx={{ pt: 3 }}>
                  <Link
                    href={`${paths.public.school_regstration}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className="px-5"
                      sx={{ width: 242, backgroundColor: '#05286C', boxShadow: 'none' }}
                    >
                      Experience Lab for Free
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            className="img-headline"
            style={{
              backgroundColor: '#212121',
              border: 'none',
              height: '450px',
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
              width: 1100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'end',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={2} padding={0}>
                    <Stack display="flex" alignItems="">
                      <img
                        src="/assets/imagesWeb/Lab/experience.png"
                        alt="mainimg"
                        style={{ width: '90%', height: '100%', objectFit: 'contain' }}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                {/* Add your inputs here */}
                <Stack className="online-offline-course-banner" sx={{ pt: 10 }}>
                  <Typography className="lab-experience-box-title">
                    Experience our Lab
                    <br />
                    for Free!
                  </Typography>
                  <Stack>
                    <Typography
                      className="lab-experience-box-title-description"
                      textAlign="justify"
                      sx={{ py: 2, pr: 2 }}
                    >
                      Discover the future firsthand by immersing yourself in our state-of-the-art
                      lab, where curiosity meets cutting-edge technology. Explore, experiment, and
                      experience the thrill of discovery—all at no cost. Join us and witness the
                      limitless possibilities at our lab!
                    </Typography>
                  </Stack>
                </Stack>
                {/* Submit Button */}
                <Stack display="flex" sx={{ pt: 3 }}>
                  <Link
                    href={`${paths.public.school_regstration}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      className="px-5"
                      sx={{ width: 242, backgroundColor: '#05286C', boxShadow: 'none' }}
                    >
                      Experience Lab for Free
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </div>
  );
}

export default Form;
