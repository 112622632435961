import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSettingsDetails } from 'src/server/api/settings';
// eslint-disable-next-line consistent-return
export const CurrencySwitch = () => {
  const { settingsData } = useSelector((state) => ({
    settingsData: state.settings.settingsDetails,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);
  const currency = settingsData?.settings?.defaultCurrencySymbol;

  if (currency) return currency;
};
