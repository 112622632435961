import { createSlice } from '@reduxjs/toolkit';

import { getAllLmsExam, getLmsExamDetails, getAllLmsExamContent } from '../api/lmsExam';

export const lmsExamSlice = createSlice({
  name: 'lmsExam',
  initialState: {
    loading: false,
    error: {},
    // sale
    exams: {},
    staffDropList: [],
    examDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all school
    [getAllLmsExam.pending]: (state) => {
      state.loading = true;
    },
    [getAllLmsExam.fulfilled]: (state, action) => {
      state.loading = false;
      state.exams = action.payload;
      state.error = {};
    },
    [getAllLmsExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getLmsExamDetails.pending]: (state) => {
      state.loading = true;
    },
    [getLmsExamDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.examDetails = action.payload;
      state.error = {};
    },
    [getLmsExamDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllLmsExamContent.pending]: (state) => {
      state.loading = true;
    },
    [getAllLmsExamContent.fulfilled]: (state, action) => {
      state.loading = false;
      state.exams = action.payload;
      state.error = {};
    },
    [getAllLmsExamContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = lmsExamSlice.actions;

export default lmsExamSlice.reducer;
