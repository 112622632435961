import React, { useRef, useState } from 'react';

import {
  Box,
  Grid,
  Link,
  Stack,
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { frontEndUrl } from 'src/server/api/http';

import CountryPage from 'src/sections/landingPage/segment/CountryPage';
import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';
import LearningPage from 'src/sections/landingPage/segment/LearningPage';
import AffiliatePage from 'src/sections/landingPage/segment/AffiliatePage';

function PartnerWithUs() {
  const isMobile = useDevice()?.isMobile;
  const [alignment, setAlignment] = useState('learningCenter');

  const handleChange = (event, newAlignment) => {
    console.log(newAlignment);
    setAlignment(newAlignment);
  };

  const curriculumImages =
    // alignment === 'innovation'
    //   ?

    [
      {
        img: '/assets/imagesWeb/partner/partner1.png',
        title: 'STEM & Tinkering Centre',
        link: `${frontEndUrl}/robotics-atl-tinkering-lab`,
      },
      {
        img: '/assets/imagesWeb/partner/partner2.png',
        title: 'STEM Facilitator Course',
        link: `${frontEndUrl}/robotics-trainer`,
      },
      {
        img: '/assets/imagesWeb/partner/partner4.png',
        title: 'STEM & Innovation Course',
        link: `${frontEndUrl}/robotics-ai-course`,
      },
      { img: '/assets/imagesWeb/partner/partner3.png', title: ' DIY Kits', link: '#' },
      {
        img: '/assets/imagesWeb/partner/partner5.png',
        title: 'Emerging Technologies for College Students',
        link: `${frontEndUrl}/college-robotics-ai`,
      },
      { img: '/assets/imagesWeb/Courses/learner.png', title: 'Learning Centers', link: '#' },
    ];
  // : [
  //     // { img: '/assets/images/Lab/python.png', title: 'Python' },
  //     { img: '/assets/images/Lab/python.png', title: 'STEM & Tinkering Labs' },
  //     { img: '/assets/images/Lab/github.png', title: 'STEM Facilitator Course' },
  //     { img: '/assets/images/Lab/mechine.png', title: 'STEM & Innovation Course' },
  //     { img: '/assets/images/Lab/electronics.png', title: ' DIY Kits' },
  //     { img: '/assets/images/Lab/mobile.png', title: 'Emerging Technologies for College Students' },
  //     { img: '/assets/images/Lab/3d.png', title: 'Learning Centers' },
  //     // { img: '/assets/images/Lab/3d.png', title: '3d Printing' },
  //   ];

  const partnerDatas = [
    { img: '/assets/imagesWeb/partner/partnerGraph.png', title: 'Low Cost & High Return Business' },
    { img: '/assets/imagesWeb/partner/easy.png', title: 'Easy to start !' },
    { img: '/assets/imagesWeb/partner/fast.png', title: 'Fastest Growing Business.' },
    { img: '/assets/imagesWeb/partner/network.png', title: ' Established Brand & Huge Network' },
    { img: '/assets/imagesWeb/partner/model.png', title: 'Proven Business Model' },
    { img: '/assets/imagesWeb/partner/profit.png', title: 'Accelerated Growth Potential' },
    {
      img: '/assets/imagesWeb/partner/support.png',
      title: 'Guidance & Support From Professionals',
    },
    {
      img: '/assets/imagesWeb/partner/self.png',
      title: 'Government Initiatives to Skill Education',
    },
  ];
  // : [
  //     { img: '/assets/images/Lab/python.png', title: 'Low Cost & High Return Business' },
  //     { img: '/assets/images/Lab/github.png', title: 'Easy to start !' },
  //     { img: '/assets/images/Lab/mechine.png', title: 'Fastest Growing Business.' },
  //     { img: '/assets/images/Lab/electronics.png', title: ' Established Brand & Huge Network' },
  //     { img: '/assets/images/Lab/mobile.png', title: 'Proven Business Model' },
  //     { img: '/assets/images/Lab/3d.png', title: 'Accelerated Growth Potential' },
  //     { img: '/assets/images/Lab/3d.png', title: 'Guidance & Support From Professionals' },
  //     { img: '/assets/images/Lab/3d.png', title: 'Government Initiatives to Skill Education' },
  //   ];

  const featuresBoxes =
    // eslint-disable-next-line no-nested-ternary
    alignment === 'affiliateMarketing'
      ? [
          {
            img: '/assets/imagesWeb/partner/zero.png',
            title: 'User-friendly affiliate portal: easily track progress',
          },
          { img: '/assets/imagesWeb/partner/risk.png', title: 'Zero risk & no investment' },
          { img: '/assets/imagesWeb/partner/support.png', title: 'Training and marketing support' },
          {
            img: '/assets/imagesWeb/partner/coll.png',
            title: 'Joint marketing campaigns and collaborations',
          },
          { img: '/assets/imagesWeb/partner/perfomance.png', title: 'Performance-based bonuses ' },
          {
            img: '/assets/imagesWeb/partner/brand.png',
            title: 'Cutting-edge technology products and services',
          },
        ]
      : alignment === 'learningCenter'
        ? [
            { img: '/assets/imagesWeb/partner/money.png', title: 'Low investment high return' },
            { img: '/assets/imagesWeb/partner/price.png', title: 'Best margins in the market' },
            {
              img: '/assets/imagesWeb/partner/data.png',
              title: 'Get full access to Learning Management System ',
            },
            {
              img: '/assets/imagesWeb/partner/training.png',
              title: 'Training and operational guidance',
            },
            {
              img: '/assets/imagesWeb/partner/diy.png',
              title:
                'Get access to technical and training resources, video-recorded sessions and DIY Kits ',
            },
            {
              img: '/assets/imagesWeb/partner/megaphone.png',
              title: 'Marketing and advertising resources',
            },
          ]
        : [
            { img: '/assets/imagesWeb/partner/rights.png', title: 'Exclusive territorial rights' },
            {
              img: '/assets/imagesWeb/partner/training.png',
              title: 'Training and operational guidance',
            },
            {
              img: '/assets/imagesWeb/partner/model.png',
              title: 'Implement a proven business model',
            },
            {
              img: '/assets/imagesWeb/partner/partnerGraph.png',
              title: 'Low capital investment & strategic advantage',
            },
            {
              img: '/assets/imagesWeb/partner/affiliates.png',
              title: 'Guidance to affiliate marketers and learning center to boost your business ',
            },
            {
              img: '/assets/imagesWeb/partner/edu.png',
              title: 'Cutting edge products in STEM education with huge business potential',
            },
          ];

  const scrollTargetRef = useRef(null);

  const handleClick = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Stack px={isMobile ? 2 : 0} py={isMobile ? 0 : 4}>
          <Grid
            container
            spacing={2}
            className="image-container"
            sx={{ position: '', minHeight: '200px', display: 'flex', padding: 2 }}
          >
            {/* Text Column */}
            <Grid
              item
              xs={12}
              sm={6}
              className="content-box"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Box
                sx={{ width: '100%', display: '', alignItems: 'center', justifyContent: 'center' }}
              >
                <Typography
                  sx={{ color: '#E1E1E1', fontSize: 28, fontWeight: 600, lineHeight: 'normal' }}
                >
                  Join Hands With Us !
                </Typography>
                <Typography className="course-subtitle-mobile" py={1}>
                  Are you enthusiastic about advancing STEM education and motivating children to
                  explore careers in STEM? Do you envision establishing your own enterprise to
                  enhance your earnings?
                </Typography>
                <Typography className="course-subtitle-mobile" py={1}>
                  Embrace the opportunity to join our partner program and contribute to the Robotics
                  and Artificial Intelligence revolution. Revel in the gratification of owning a
                  business that positively impacts the lives of children while generating
                  substantial income! The STEM-Xpert Partner Program provides a Risk-free and
                  Investment-free avenue for anyone aspiring to venture into the Robotics and AI
                  Education sector.
                </Typography>
                <Typography className="course-subtitle-mobile" py={1}>
                  Collaborate with STEM-Xpert to foster the next generation of innovators and
                  brilliant thinkers!
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              className="image-box"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
            >
              <Box sx={{ width: isMobile ? '100%' : '70%', height: '70%' }}>
                <img
                  src="/assets/imagesWeb/partner/share.png"
                  alt="mainimg"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    transform: 'scaleX(-1)',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Container>
        <Grid container spacing={1} paddingY={5}>
          <Grid
            item
            xs={12}
            md={6}
            className={isMobile ? 'curriculum-image-mobile' : 'curriculum-image'}
          >
            <Stack className={isMobile === true ? 'curriculum-image-mobile' : 'curriculum-image'}>
              <img
                src="/assets/imagesWeb/Lab/main-image.png"
                style={{ objectFit: 'contain', transform: 'rotateY(180deg)' }}
                alt="curriculum"
                height={isMobile ? '100%' : '800px'}
                width={isMobile ? '50%' : '70%'}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack display="flex" alignItems="center" justifyContent="center">
              <Typography className="franchisee-products-title" style={{ color: '#FFFFFF' }}>
                Our Products
              </Typography>
            </Stack>
            <Grid container spacing={isMobile ? 1 : 0} className="curriculum-alignment">
              <Grid item xs={6} md={6}>
                {curriculumImages?.slice?.(0, 3)?.map((i, k) => (
                  <Box key={k} paddingTop={3}>
                    <Stack component={Link} href={i?.link} className="franchisee-products-boxes">
                      <Stack display="flex" alignItems="center" justifyItems="center" px={2}>
                        <Stack paddingTop={3} className="lab-curriculum-img-parent">
                          <img
                            alt={i?.title}
                            src={i?.img}
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                            className="img-container-lab-curriculum"
                          />
                        </Stack>
                        <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                      </Stack>
                    </Stack>
                  </Box>
                ))}
              </Grid>

              <Grid item xs={6} md={6}>
                {curriculumImages?.slice?.(3, 6)?.map((i, k) => (
                  <Box key={k} paddingTop={3}>
                    {i?.title === 'Learning Centers' ? (
                      <Stack
                        component="button"
                        onClick={handleClick}
                        className="franchisee-products-boxes"
                        height={200}
                      >
                        <Stack display="flex" alignItems="center" justifyItems="center" px={2}>
                          <Stack paddingTop={3} className="lab-curriculum-img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-lab-curriculum"
                            />
                          </Stack>
                          <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack
                        component={Link}
                        href={i?.link}
                        className="franchisee-products-boxes"
                        height={200}
                      >
                        <Stack display="flex" alignItems="center" justifyItems="center" px={2}>
                          <Stack paddingTop={3} className="lab-curriculum-img-parent">
                            <img
                              alt={i?.title}
                              src={i?.img}
                              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              className="img-container-lab-curriculum"
                            />
                          </Stack>
                          <Stack className="lab-curriculum-box-title">{i?.title}</Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Stack className="franchisee-products-title" py={1}>
        Why Partner with STEM-Xpert?
      </Stack>

      {isMobile ? (
        <Grid item container spacing={2} p={1}>
          {partnerDatas.map((item, index) => (
            <Grid key={index} item xs={6} md={6}>
              <Stack
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px={2}
                className="franchisee-partner-boxes"
              >
                <Box className="lab-curriculum-img-parent" mb={2}>
                  <img
                    alt={item?.title}
                    src={item?.img}
                    style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                    className="img-container-lab-curriculum"
                  />
                </Box>
                <Typography variant="body1" align="center" className="lab-curriculum-box-title">
                  {item?.title}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid
            sx={{
              display: isMobile ? 'flex' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {partnerDatas.slice(0, 4).map((item, index) => (
              <Stack key={index} className="franchisee-partner-boxes" mx={2} my={3}>
                <Stack display="flex" alignItems="center" justifyItems="center" px={2}>
                  <Stack paddingTop={3} className="lab-curriculum-img-parent">
                    <img
                      alt={item?.title}
                      src={item?.img}
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                      className="img-container-lab-curriculum"
                    />
                  </Stack>
                  <Stack className="lab-curriculum-box-title">{item?.title}</Stack>
                </Stack>
              </Stack>
            ))}
          </Grid>
          <Grid
            sx={{
              display: isMobile ? 'flex' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {partnerDatas.slice(4, 8).map((item, index) => (
              <Stack key={index} className="franchisee-partner-boxes" mx={2} my={3}>
                <Stack display="flex" alignItems="center" justifyItems="center" px={2}>
                  <Stack paddingTop={3} className="lab-curriculum-img-parent">
                    <img
                      alt={item?.title}
                      src={item?.img}
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                      className="img-container-lab-curriculum"
                    />
                  </Stack>
                  <Stack className="lab-curriculum-box-title">{item?.title}</Stack>
                </Stack>
              </Stack>
            ))}
          </Grid>
        </>
      )}

      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="row"
        paddingY={isMobile ? 2 : 5}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid',
            padding: 4,
            borderRadius: 45,
            width: 'auto',
          }}
          className="btn-group"
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            // onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              id="affiliateMarketing"
              value="affiliateMarketing"
              onChange={handleChange}
              className={`lab-switch-button ${alignment === 'affiliateMarketing' ? 'active' : ''}`}
              sx={{ fontSize: isMobile ? 10 : 14 }}
            >
              {/* <a href="#sectionId" style={{ textDecoration: 'none', color: 'white' }}> */}
              Affiliate Marketing
              {/* </a> */}
            </ToggleButton>
            {/* <Link to={'#'}> */}
            <ToggleButton
              id="learningCenter"
              style={{ borderRadius: 25, marginLeft: 5 }}
              value="learningCenter"
              sx={{ fontSize: isMobile ? 10 : 14 }}
              onChange={handleChange}
              className={`lab-switch-button ${alignment === 'learningCenter' ? 'active' : ''}`}
            >
              {/* <Link className="scroll-smooth" href="#sectionId"> */}
              Learning Center
              {/* </Link> */}
            </ToggleButton>
            <ToggleButton
              id="countryPartner"
              style={{ borderRadius: 25, marginLeft: 5 }}
              value="countryPartner"
              sx={{ fontSize: isMobile ? 10 : 14 }}
              onChange={handleChange}
              className={`lab-switch-button ${alignment === 'countryPartner' ? 'active' : ''}`}
            >
              {/* <Link className="scroll-smooth" href="#sectionId"> */}
              Partner
              {/* </Link> */}
            </ToggleButton>
            {/* </Link> */}
          </ToggleButtonGroup>
        </div>
      </Grid>

      {alignment === 'affiliateMarketing' && <AffiliatePage featuresBoxes={featuresBoxes} />}
      {alignment === 'learningCenter' && (
        <LearningPage refValue={scrollTargetRef} featuresBoxes={featuresBoxes} />
      )}
      {alignment === 'countryPartner' && <CountryPage featuresBoxes={featuresBoxes} />}
      <Box
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        sx={{ display: 'flex', justifyContent: 'center' }}
        py={5}
      >
        <Box px={isMobile && 1} width="235px">
          <img
            src="/assets/imagesWeb/franchisee/man.jpeg"
            alt=""
            style={{
              objectFit: 'contain',
              height: isMobile ? 'auto' : '350px',
              width: '100%',
              borderRadius: 10,
            }}
          />
        </Box>
        <Box width={isMobile ? '100%' : '50%'} px={isMobile ? 2 : 3} pt={isMobile && 1}>
          <Stack py={1}>
            <Typography sx={{ color: '#d9cbcb', fontSize: 18 }}>Mohammed Shihab P S </Typography>
            <Typography sx={{ color: '#d9cbcb', fontSize: 14 }} py={0}>
              {' '}
              Director, Global Sales
            </Typography>
          </Stack>
          <Typography sx={{ color: '#d9cbcb', fontSize: 16, textAlign: 'justify' }} py={1}>
            {`Shihab, a Dubai-based Entrepreneur and seasoned Trainer, boasts a stellar 13-year career, globally
              acknowledged as an employability expert. He shaped the UAE's market for seven years, holding 70+
              certifications, including NLP Master Practitioner and Emotional Intelligence Coach, blending business
              acumen with IT and psychology expertise.`}
          </Typography>
          <Typography sx={{ color: '#d9cbcb', textAlign: 'justify' }}>
            Founder and Managing Director of Skill Nest Training and Coaching Pvt Ltd, Mohammed
            Shihab seamlessly integrates education and business. Known for dynamic programs, he is a
            certified leader and behavioral trainer who leaves a lasting impact. Embrace Shihab, the
            STEM-Xpert, for a transformative journey in education and business coaching.
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default PartnerWithUs;
