import { createAsyncThunk } from '@reduxjs/toolkit';

// import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSupportMaterial = createAsyncThunk(
  'support-material/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/support-material/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'Support Material created successfully' })
        );
        // dispatch(
        //   getAllSupportMaterial({
        //     page: 1,
        //     search: '',
        //     limit: 10,
        //     labModelId: '',
        //     school: '',
        //     dispatch,
        //   })
        // );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSupportMaterial = createAsyncThunk(
  'support-material/list',
  async ({ page, search, limit, labModelId, lab_model, dispatch }) => {
    try {
      const URL = `/support-material/admin/all/${labModelId || ''}?page=${page || 1}&search=${
        search || ''
      }&limit=${limit || 10}&lab_model=${lab_model || ''}`;

      const response = await get(URL);
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSupportMaterialDetails = createAsyncThunk(
  'support-material/single',
  async ({ materialId, dispatch }) => {
    try {
      const response = await get(`/support-material/admin/${materialId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSupportMaterialDetails = createAsyncThunk(
  'support-material/update',
  async ({ state, supportMaterialId, dispatch, handleClose }) => {
    try {
      const URL = `/support-material/admin/${supportMaterialId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        // dispatch(
        //   getAllSupportMaterial({
        //     page: 1,
        //     search: '',
        //     limit: 10,
        //     labModelId: '',
        //     school: '',
        //     dispatch,
        //   })
        // );
        dispatch(
          activeSnack({ type: 'success', message: 'Support Material updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSupportMaterial = createAsyncThunk(
  'support-material/delete',
  async ({ supportMaterialId, dispatch }) => {
    try {
      const response = await del(`/support-material/admin/${supportMaterialId}/`);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'Support Material deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
