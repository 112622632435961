import jsPDF from 'jspdf';
import * as React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { toPng } from 'html-to-image';
import { useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import DownloadIcon from '@mui/icons-material/Download';
import CollectionsIcon from '@mui/icons-material/Collections';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// @mui
import {
  Stack,
  Dialog,
  Button,
  Avatar,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { UseDownloadImage } from 'src/hooks/use-download-image';

import backgroundImage from 'src/assets/Dashboard/certificate.png';
import StudentCertificate from 'src/pages/Common/Certificate/student-certificate-download';

StudentCertificateDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  certificateData: PropTypes.any,
};

export default function StudentCertificateDialog({ open, setOpen, certificateData }) {
  const theme = useTheme();
  const certificateRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [certificateType, setCertificateType] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCertificateType = (type) => {
    setCertificateType(type);
  };

  // const imgUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/70/Example.png' //for test only

  const imgUrl = certificateData?.image?.url;

  const handleDownloadPDF = async () => {
    try {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // To handle CORS issues

      img.onload = () => {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('l', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = img.width;
        const imgHeight = img.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const newWidth = imgWidth * ratio;
        const newHeight = imgHeight * ratio;

        pdf.addImage(img, 'PNG', 0, 0, newWidth, newHeight);
        pdf.save(
          `${
            certificateData?.title ||
            certificateData?.student_exam?.exam?.title ||
            certificateData?.student_exam?.course?.title
          }.pdf`
        );
      };

      img.src = imgUrl;
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // const handleDownloadImage = () => {
  //   toPng(certificateRef.current).then((dataUrl) => {
  //     const link = document.createElement('a');
  //     link.download = 'certificate.png';
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // };

  // const handleDownloadPDF = () => {
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF();
  //   const certificateContent = document.getElementById('certificate-content');

  //   if (certificateContent) {
  //     doc.addImage(backgroundImage, 'PNG', 0, 50, 210, 150); // Add background image
  //     doc.setFontSize(12);
  //     doc.setTextColor('#030303');
  //     doc.text(certificateNUmber, 63, 65, { align: 'left' });
  //     doc.setFontSize(28);
  //     doc.setTextColor('#030303');
  //     doc.text(name, 105, 133, { align: 'center' }); // Add name
  //     doc.setFontSize(18);
  //     doc.text('in recognition of his/her participation in the', 105, 148, { align: 'center' }); // Add participation text
  //     doc.text(exam, 105, 158, { align: 'center' }); // Add test name
  //     doc.setFontSize(12);
  //     doc.setTextColor('#030303');
  //     doc.text(date, 48, 191, { align: 'left' });
  //     doc.save(`${exam}certificate.pdf`);
  //   }
  // };

  const handleDownloadCertificate = () => {
    setLoading(true);
    if (certificateType === 'pdf') {
      // handleDownloadPDF();
      handleDownloadPDF();
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
      }, 5000);
    }
    if (certificateType === 'img') {
      UseDownloadImage(
        imgUrl,
        certificateData?.title ||
          certificateData?.student_exam?.exam?.title ||
          certificateData?.student_exam?.course?.title
      );
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
      }, 2500);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="partner-type-modal"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Download Certificate '}{' '}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'relative',
              left: '15.5rem',
              top: '-15px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <Stack direction="row" justifyContent="center" spacing={7}>
            <Button
              onClick={() => handleCertificateType('pdf')}
              variant={certificateType === 'pdf' ? 'contained' : 'outlined'}
              color="error"
              className="role-select-card"
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'fit-content',
                // p: 2,
                borderRadius: '8px',
                mb: 5,
              }}
            >
              <Avatar sx={{ bgcolor: 'red', mb: 1 }} variant="rounded">
                <PictureAsPdfIcon />
              </Avatar>
              <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                PDF
              </Typography>
            </Button>
            <Button
              onClick={() => handleCertificateType('img')}
              variant={certificateType === 'img' ? 'contained' : 'outlined'}
              color="info"
              className="role-select-card"
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'fit-content',
                // p: 2,
                borderRadius: '8px',
                mb: 5,
              }}
            >
              <Avatar sx={{ bgcolor: 'skyblue', mb: 1 }} variant="rounded">
                <CollectionsIcon />
              </Avatar>
              <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                IMAGE
              </Typography>
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="end" mb={3}>
            <LoadingButton
              disabled={!certificateType}
              loading={loading}
              variant="contained"
              color="success"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadCertificate}
            >
              Download
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack display="none">
        <StudentCertificate
          certificateRef={certificateRef}
          id="certificate-content"
          backgroundImage={backgroundImage}
        />
      </Stack>
    </div>
  );
}
