import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Tooltip,
  CardMedia,
  Typography,
  Pagination,
  CardContent,
  paginationClasses,
} from '@mui/material';

import { deleteSession, getAllSessions } from 'src/server/api/session';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';

import session from '../../assets/labModel/session.png';

SessionList.propTypes = {
  handleEditSession: PropTypes.any,
};

export default function SessionList({ handleEditSession }) {
  const dispatch = useDispatch();

  const { sessionList, deleteMessage } = useSelector((state) => ({
    loading: state.session.loading,
    sessionList: state.session.sessions,
    deleteMessage: state.session.deleteMessage,
  }));

  const [confirmDelete, setConfirmDelete] = useState({ value: '', status: false });

  const [pageSession, setPageSession] = useState(1);
  const totalSessions = sessionList?.total;
  const sessionCount = Math.ceil(totalSessions / 10);

  useEffect(() => {
    const credentials = {
      page: pageSession,
      search: '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllSessions(credentials));
  }, [dispatch, pageSession]);

  const handleDelete = (id) => {
    setConfirmDelete({
      value: id,
      status: true,
    });
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      sessionId: confirmDelete?.value,
      dispatch,
    };
    dispatch(deleteSession(credentials));
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDelete.status}
        onClose={() => setConfirmDelete({ value: '', status: false })}
        title="Delete"
        content="Are you sure want to delete This Session ?"
        deleteMessage={deleteMessage || {}}
        action={
          <Button variant="contained" color="error" onClick={handleDeleteConfirmClick}>
            Delete
          </Button>
        }
      />
      <Grid container spacing={1}>
        {sessionList &&
          sessionList?.sessions?.length > 0 &&
          sessionList?.sessions?.map((item, key) => (
            <Grid md="4" sm={6} xs={12}>
              <Card
                variant="outlined"
                key={key}
                sx={{ minWidth: 200, marginRight: '12px', marginTop: '20px', boxShadow: 'none' }}
              >
                <CardContent sx={{ py: '12px !important', px: '15px !important' }}>
                  <Grid display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <CardMedia
                        component="img"
                        image={session}
                        alt="green iguana"
                        sx={{ width: '50px', height: '50px' }}
                      />
                      <Stack ml="16px">
                        <Typography
                          fontSize={28}
                          fontWeight={600}
                          display="flex"
                          alignItems="center"
                        >
                          {item?.count}
                          <Typography fontSize={16} fontWeight={400} pl={1}>
                            {item?.title}
                          </Typography>
                        </Typography>
                        <TextMaxLine variant="caption" line={4}>
                          {item?.description}
                        </TextMaxLine>
                      </Stack>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Edit">
                        <Iconify
                          onClick={() => handleEditSession(item)}
                          sx={{ cursor: 'pointer' }}
                          icon="solar:pen-bold"
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Iconify
                          onClick={() => handleDelete(item?._id)}
                          sx={{ cursor: 'pointer', color: 'red' }}
                          icon="solar:trash-bin-trash-bold"
                        />
                      </Tooltip>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      {sessionList?.total > 10 && (
        <Pagination
          onChange={(e, value) => setPageSession(value)}
          count={sessionCount}
          defaultValue={pageSession}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
