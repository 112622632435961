import React from 'react';

function HomePageSwipeMobile() {
  return (
    <div>
      <div className="slider-mobile">
        <div className="caption-mobile">Say Hello to &nbsp;</div>
        <div className="text-box-mobile">
          <div>Coding</div>
          <div>Robotics</div>
          <div>Artificial Intelligence</div>
          <div>IoT</div>
        </div>
      </div>
    </div>
  );
}

export default HomePageSwipeMobile;
