import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { getAllSchool } from 'src/server/api/school';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function CourseTableToolbar({
  filters,
  onFilters,
  //
  isSearchAvailable,
  dateFilter,

  setSelectedSchool,
}) {
  const popover = usePopover();
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  // const { sate, setSate } = dateFilter;
  // LabModel
  const [searchValue] = useState('');
  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );
  // console.log(onFilters);
  // const { schoolList } = useSelector((state) => ({
  //   schoolList: state.school.school,
  // }));

  // const optionSchool = [
  //   { label: 'All', value: '' },
  //   ...(schoolList?.schools || []).map((results) => ({
  //     label: results?.name,
  //     value: results?._id,
  //   })),
  // ];
  // const handleAutocompleteChange = (_, newValue) => {
  //   setSelectedSchool(newValue?.value || '');
  // };
  // const handleTextFieldChange = (event) => {
  //   setSearchValue(event.target.value);

  //   // You can also call the existing onSearchProp if needed
  //   if (onSearchProp) {
  //     onSearchProp(event);
  //   }
  // };
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValue,
      limit: 10,
      partner: '',
      lab_model: '',
      dispatch,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllSchool(credentials));
  }, [dispatch, searchValue]);

  // const handleFilterService = useCallback(
  //   (event) => {
  //     onFilters(
  //       'service',
  //       typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
  //     );
  //   },
  //   [onFilters]
  // );

  // const handleFilterStartDate = useCallback(
  //   (newValue) => {
  //     onFilters('startDate', newValue);
  //   },
  //   [onFilters]
  // );

  // const handleFilterEndDate = useCallback(
  //   (newValue) => {
  //     onFilters('endDate', newValue);
  //   },
  //   [onFilters]
  // );
  // const statusOptions = [
  //   { label: 'Pending', value: 'pending' },
  //   { label: 'Approved', value: 'approved' },
  // ];
  // const handleSaleStatusChange = (event) => {
  //   setSaleStatus(event.target.value);
  // };
  // const handleCopy = () => {
  //   // You can add any additional logic here when the link is copied
  //   console.log('Link copied to clipboard');
  //   enqueueSnackbar('Link copied!', { variant: 'success' });
  // };
  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="end"
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {isSearchAvailable && (
          <Stack
            lg={3}
            direction="row"
            alignItems="center"
            spacing={2}
            flexGrow={1}
            sx={{ width: 1 }}
          >
            <TextField
              fullWidth
              value={filters.name}
              onChange={handleFilterName}
              placeholder="Search course"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

CourseTableToolbar.propTypes = {
  isSearchAvailable: PropTypes.bool,
  filters: PropTypes.object,
  dateFilter: PropTypes.object,
  onFilters: PropTypes.func,
  setSelectedSchool: PropTypes.any,
};
