import { createSlice } from '@reduxjs/toolkit';

import {
  logoutApi,
  registerApi,
  adminLoginApi,
  passwordReset,
  getUserProfile,
  forgotPassword,
  updatePassword,
  loginSchoolApi,
  studentLoginApi,
  confirmPassword,
  loginPartnerApi,
  otpVerification,
  getStudentProfile,
  updateUserProfile,
  registerAcademyV2,
  emailVerifyAcademy,
  studentRegisterApi,
  updateStudentProfile,
} from '../api/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    passwordLoading: false,
    forgottLoading: false,
    loading: false,
    partner: {},
    loginLoading: false,
    otpLoading: false,
    error: {},
    user: {
      email: '',
      username: '',
      isActive: false,
    },
    otpData: {},
    userData: {},
  },
  reducers: {},
  extraReducers: {
    // get single student profile
    [getStudentProfile.pending]: (state) => {
      state.loading = true;
    },
    [getStudentProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = {};
    },
    [getStudentProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update student profile

    [updateStudentProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateStudentProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateStudentProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // login
    [studentLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [studentLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [studentLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    // login partner
    [loginPartnerApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginPartnerApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [loginPartnerApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    [loginSchoolApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginSchoolApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [loginSchoolApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // register academy details
    [emailVerifyAcademy.pending]: (state) => {
      state.otpLoading = true;
    },
    [emailVerifyAcademy.fulfilled]: (state, action) => {
      state.otpLoading = false;
      state.userData = action.payload;
      state.error = {};
    },
    [emailVerifyAcademy.rejected]: (state, action) => {
      state.otpLoading = false;
      state.error = action.error;
    },
    // register school details
    [registerAcademyV2.pending]: (state) => {
      state.loading = true;
    },
    [registerAcademyV2.fulfilled]: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.error = {};
    },
    [registerAcademyV2.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    /// admin login
    [adminLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [adminLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [adminLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    // update profile

    // register
    [registerApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [registerApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [registerApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    // student register
    [studentRegisterApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [studentRegisterApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [studentRegisterApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // profile
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update profile
    [updateUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // logout
    [logoutApi.fulfilled]: (state) => {
      state.user = {};
      state.error = {};
    },
    [logoutApi.rejected]: (state, action) => {
      state.error = action.error;
    },
    // confirmPassword
    [confirmPassword.pending]: (state) => {
      state.loading = true;
    },
    [confirmPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
    },
    [confirmPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [forgotPassword.pending]: (state) => {
      state.forgottLoading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.forgottLoading = false;
      state.error = {};
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgottLoading = false;
      state.error = action.error;
    },

    [passwordReset.pending]: (state) => {
      state.passwordLoading = true;
    },
    [passwordReset.fulfilled]: (state) => {
      state.passwordLoading = false;
      state.error = {};
    },
    [passwordReset.rejected]: (state, action) => {
      state.passwordLoading = false;
      state.error = action.error;
    },

    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    [updatePassword.fulfilled]: (state) => {
      state.loading = false;
      state.error = {};
    },
    [updatePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [otpVerification.pending]: (state) => {
      state.loading = true;
    },
    [otpVerification.fulfilled]: (state, action) => {
      state.loading = false;
      state.otpData = action.payload;
      state.error = {};
    },
    [otpVerification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
// export const {} = authSlice.actions;

export default authSlice.reducer;
