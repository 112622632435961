import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import { Typography, ListItemText, CircularProgress } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';
// import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { useNavigate } from 'react-router';

import { paths } from 'src/routes/routes/paths';

import { fDate, fTime } from 'src/utils/format-time';

import { deleteExam } from 'src/server/api/exam';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function StudentExamTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  handleEditClick,
  index,
  page,
  rowsPerPage,
  studentId,
}) {
  const {
    exam,
    createdAt,
    course,
    completion_percentage,
    pass_percentage,
    status,
    exam_grade,
    is_certificate_issued,
    _id,
    student,
  } = row;

  const dispatch = useDispatch();
  const confirm = useBoolean();
  const popover = usePopover();
  const { role } = useGetRoles();
  const navigate = useNavigate();
  console.log(role);

  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      ExamId: id,
      dispatch,
    };

    dispatch(deleteExam(credentials));
    confirm.onFalse();
  };
  const handleViewClick = (id) => {
    navigate(paths.dashboard.exam_view(id), {
      state: id,
    });
    // handleCloseMenu();
  };

  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow hover selected={selected}>
          <TableCell>
            <Typography sx={{ textTransform: 'capitalize', marginLeft: '5px' }} variant="caption">
              {(page - 1) * rowsPerPage + index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <ListItemText
              primary={fDate(createdAt)}
              secondary={fTime(createdAt)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>

          {!studentId && <TableCell>{student?.name}</TableCell>}
          <TableCell>{exam?.title}</TableCell>
          <TableCell>{exam?.exam_type}</TableCell>

          <TableCell>{course?.title}</TableCell>
          <TableCell>{completion_percentage}</TableCell>
          <TableCell>{pass_percentage}</TableCell>
          <TableCell>
            <ListItemText
              primary={
                <Label variant="soft" color={status === 'completed' ? 'success' : 'info'}>
                  {status}
                </Label>
              }
              secondary={
                <>
                  {is_certificate_issued && (
                    <Label variant="soft" color="warning">
                      Certificate Issued
                    </Label>
                  )}
                </>
              }
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell>{exam_grade?.grade}</TableCell>
        </TableRow>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={() => handleViewClick(_id)}>
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem onClick={() => handleEditClick(row)}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete '${exam?.title}' ?`}
        action={
          <Button variant="contained" color="error" onClick={() => handleDeleteConfirmClick(_id)}>
            Delete
          </Button>
        }
      />
    </>
  );
}

StudentExamTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  handleEditClick: PropTypes.any,
  index: PropTypes.any,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
  studentId: PropTypes.any,
};
