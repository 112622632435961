import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const registerSchool = createAsyncThunk(
  'school/register',
  async ({ state, reset, enqueueSnackbar, openDialog }) => {
    try {
      const URL = `/school/register`;
      const response = await post(URL, state);
      if (response) {
        enqueueSnackbar('School Registration Form Submitted Successfully!');
        reset();
        openDialog();
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const registerSchoolV2 = createAsyncThunk(
  'school/register/v2',
  async ({ state, reset, dispatch, router }) => {
    try {
      const URL = '/school/v2/profile/new';
      const response = await post(URL, state);
      if (response) {
        if (reset) {
          reset();
        }
        if (router) {
          router.push(paths.public.school_login);
        }
        dispatch(activeSnack({ type: 'success', message: 'School Registered Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const emailVerifySchool = createAsyncThunk(
  'school/register',
  async ({ state, dispatch, handleOtpCode }) => {
    try {
      const URL = `/school/v2/register`;
      const response = await post(URL, state);
      if (response?.user) {
        if (handleOtpCode) {
          handleOtpCode();
        }
        dispatch(activeSnack({ type: 'success', message: response?.message }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: response?.message }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const CheckPartnerCode = createAsyncThunk('partnerCode/check', async ({ state }) => {
  try {
    const URL = `/partner-code/verify`;
    const response = await post(URL, state);
    if (response) {
      return response;
    }
    // Handle the case where response is falsy
    // For example, return null or throw an error
    // return null;
    throw new Error('Response is falsy');
  } catch (error) {
    throw error?.response?.data?.message;
  }
});

export const createSchool = createAsyncThunk(
  'school/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/school/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllSchool({
            page: 1,
            dispatch,
            limit: sessionStorage.getItem(`rowsPerPage_school`),
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'School Item created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSchool = createAsyncThunk(
  'school/list',
  async ({ page, search, limit, partner, lab_model, dispatch, countryId, stateId, districtId }) => {
    console.log('inside api', countryId, stateId, districtId);

    try {
      const response = await get(
        `/school/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&partner=${
          partner || ''
        }&lab_model=${lab_model || ''}&country=${countryId || ''}&state=${stateId || ''}&district=${districtId || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolDetails = createAsyncThunk(
  'school/single',
  async ({ schoolId, dispatch }) => {
    try {
      const response = await get(`/school/admin/${schoolId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSchoolDetails = createAsyncThunk(
  'school/update',
  async ({ state, schoolId, dispatch, navigate, handleNext }) => {
    try {
      const URL = `/school/admin/${schoolId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.school);
        }
        if (handleNext) {
          handleNext();
        }
        dispatch(activeSnack({ type: 'success', message: 'School updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSchool = createAsyncThunk(
  'school/delete',
  async ({ schoolId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/school/admin/${schoolId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'School Item deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
