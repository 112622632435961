import PropTypes from 'prop-types';

import { Box, Card, Grid, Stack, Container, Typography } from '@mui/material';

import { useDevice } from '../hooks/useResponsive';

function BranchSearch({ px }) {
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  // const [selectedCountry, setSelectedCountry] = useState('');
  // const [selectedState, setSelectedState] = useState('');
  // const [selectedStreet, setSelectedStreet] = useState('');
  // const [selectedCenter, setSelectedCenter] = useState('');

  // const CountryOptions = [
  //   {
  //     value: 'IND',
  //     label: 'India',
  //   },
  //   {
  //     value: 'US',
  //     label: 'United States',
  //   },
  //   {
  //     value: 'UK',
  //     label: 'United Kingdom',
  //   },
  //   {
  //     value: 'UAE',
  //     label: 'United Arab Emirates',
  //   },
  // ];
  // const StateOptions = [
  //   {
  //     value: 'KL',
  //     label: 'Kerala',
  //   },
  //   {
  //     value: 'TN',
  //     label: 'Tamil Nadu',
  //   },
  //   {
  //     value: 'MH',
  //     label: 'MahaRashtra',
  //   },
  //   {
  //     value: 'KA',
  //     label: 'Karnataka',
  //   },
  //   {
  //     value: 'PB',
  //     label: 'Punjab',
  //   },
  //   {
  //     value: 'J&K',
  //     label: 'Jammu&kashmir',
  //   },
  // ];
  // const StreetOptions = [
  //   {
  //     value: 'DL',
  //     label: 'Delhi',
  //   },
  //   {
  //     value: 'BG',
  //     label: 'Banglore',
  //   },
  //   {
  //     value: 'Mumabi',
  //     label: 'Mumbai',
  //   },
  //   {
  //     value: 'CT',
  //     label: 'Calicut',
  //   },
  //   {
  //     value: 'KI',
  //     label: 'Kochi',
  //   },
  //   {
  //     value: 'KT',
  //     label: 'Kolkatha',
  //   },
  // ];
  // const CenterOptions = [
  //   {
  //     value: 'Kozhikode',
  //     label: 'Kozhikode',
  //   },
  //   {
  //     value: 'Malappuram',
  //     label: 'Malappuram',
  //   },
  //   {
  //     value: 'Ramanattukara',
  //     label: 'Ramanattukara',
  //   },
  // ];

  const centerDatas = [
    {
      heading: 'STEM-Xpert',
      // center: 'STEMXpert Ramanattukara',
      //   place: 'India Kerala Kannur Payyanur',
      address: 'Door No.7, Ground Floor, KINFRA Advanced Technology Park, Ramanattukara  ',
      mobile: ' PH: +91 9400451133',
    },
    {
      heading: 'Crinnolabs',
      // center: 'STEMXpert Kozhikode',
      //   place: 'India Kerala Kannur Payyanur',
      address: 'City Tower Ramanattukara, Kozhikode. ',
      mobile: 'PH: +91 9400451133',
    },
    {
      heading: 'Techgenius Robotics',
      // center: 'Malappuram',
      //   place: 'Konnar Tower Edavannapara',
      address: 'Konnar Tower Edavannappara, Malappuram ',
      mobile: 'PH: +91 9400451133',
    },
  ];
  return (
    <Container maxWidth="xl">
      <Stack mt={px || 15}>
        <Stack padding={2}>
          {/* <Container> */}
          <Box
            mb={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              px: isMobile === true ? 1 : 0,
            }}
          >
            <img
              src="/assets/imagesWeb/Home/Location.png"
              alt="mainimg"
              style={{ width: '80px', height: '70px', objectFit: 'contain' }}
            />
            <Typography
              sx={{ mx: 1, color: '#FFF', fontSize: '20px', fontWeight: 0 }}
              className="font-normal-common"
            >
              Discover our Nearby centers !
            </Typography>
          </Box>
          {/* </Container> */}
        </Stack>
        {/* <Stack>
        {isMobile === true ? (
          <>
            <Box padding={2}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={6} md={3}>
                  <Stack display={'flex'} alignItems={'center'} component="form">
                    <TextField
                      sx={{ my: 1 }}
                      className="p-2 "
                      id="outlined-select-country"
                      select
                      label="Select Country"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      variant="outlined"
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                    >
                      <MenuItem value="" disabled sx={{ backgroundColor: 'black' }}>
                        Select Country...
                      </MenuItem>
                      {CountryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      sx={{ my: 1 }}
                      id="outlined-select-state"
                      select
                      label="Select State"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      variant="outlined"
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select State...
                      </MenuItem>
                      {StateOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      sx={{ my: 1 }}
                      id="outlined-select-Street"
                      select
                      label="Select Street"
                      value={selectedStreet}
                      onChange={(e) => setSelectedStreet(e.target.value)}
                      variant="outlined"
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Street...
                      </MenuItem>
                      {StreetOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      sx={{ my: 1 }}
                      id="outlined-select-center"
                      select
                      label="Select Center"
                      value={selectedCenter}
                      onChange={(e) => setSelectedCenter(e.target.value)}
                      variant="outlined"
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Center...
                      </MenuItem>
                      {CenterOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                  </Stack>
                </Grid>
              </Grid>

              <Grid container spacing={1} alignItems="center" justifyContent="center" padding={2}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    className="px-1"
                    size="lg"
                    sx={{
                      borderRadius: 1,
                      backgroundColor: '#05286C',
                      boxShadow: 'none',
                      height: '40px',
                      width: '100px',
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    disabled
                    className="px-5"
                    size="lg"
                    sx={{
                      boxShadow: 'none',
                      height: '40px',
                      width: isMobile === true ? 220 : '',
                    }}
                  >
                    Not found? &nbsp;
                    <Link className="" style={{ color: '#F77E3F' }} to={'/'}>
                      Contact us
                    </Link>
                  </Button>
                </Stack>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Box
              padding={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                display={'flex'}
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                className=""
              >
                <div>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Country"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    variant="outlined"
                    InputProps={{ style: { color: 'white' } }}
                    className="select-form"
                  >
                    <MenuItem value="" disabled sx={{ backgroundColor: 'red' }}>
                      Select Country...
                    </MenuItem>
                    {CountryOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select State"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    variant="outlined"
                    InputProps={{ style: { color: 'white' } }}
                  >
                    <MenuItem value="" disabled>
                      Select State...
                    </MenuItem>
                    {StateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Street"
                    value={selectedStreet}
                    onChange={(e) => setSelectedStreet(e.target.value)}
                    variant="outlined"
                    InputProps={{ style: { color: 'white' } }}
                  >
                    <MenuItem value="" disabled>
                      Select Street...
                    </MenuItem>
                    {StreetOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div>
                  <TextField
                    className="text-white"
                    id="outlined-select-currency"
                    select
                    label="Select Center"
                    value={selectedCenter}
                    onChange={(e) => setSelectedCenter(e.target.value)}
                    variant="outlined"
                    InputProps={{ style: { color: 'white' } }}
                  >
                    <MenuItem value="" disabled>
                      Select Center...
                    </MenuItem>
                    {CenterOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Box>
            </Box>

            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
              padding={1}
              className="submit-button-home"
            >
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className="px-1 "
                  size="lg"
                  sx={{
                    width: '232px',
                    maxWidth: '242px',
                    borderRadius: 10,
                    backgroundColor: '#05286C',
                    boxShadow: 'none',
                    height: '40px',
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  disabled
                  className="px-5"
                  size="lg"
                  sx={{ width: '250px', maxWidth: '250px', boxShadow: 'none', height: '40px' }}
                >
                  Not found? &nbsp;
                  <Link className="" style={{ color: '#F77E3F' }} to={'/'}>
                    Contact us
                  </Link>
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Stack> */}

        {/* <Typography sx={{ paddingLeft: isMobile === true ? 2 : 20, color: '#d9cbcb' }}>Nearby centers :</Typography> */}
        <Grid container justifyContent="center" padding={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile === true ? 'column' : 'row',
              gap: 2,
            }}
          >
            {centerDatas.map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: isMobile === true ? '100%' : '365px',
                  height: isMobile === true ? '180px' : '200px',
                  backgroundColor: '#212121',
                  padding: 1,
                  boxShadow: 'none',
                  borderRadius: 4,
                }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    color: '#666666',
                    textAlign: 'center',
                    padding: '',
                  }}
                >
                  <Typography variant="h6" className="after-button-submit-headline">
                    {item?.heading}
                  </Typography>

                  {/* <Typography variant="body1" sx={{ mb: 3, fontSize: 12 }}>
                  {item?.place}
                </Typography> */}
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize: 13, textTransform: 'capitalize' }}
                  >
                    {item?.center}
                  </Typography>

                  <Typography
                    variant="body1"
                    className="after-button-submit"
                    sx={{ fontSize: 13, px: 6 }}
                  >
                    {item?.address}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="after-button-submit"
                    sx={{ fontSize: 13, px: 6 }}
                  >
                    {item?.mobile}
                  </Typography>
                </Stack>
              </Card>
            ))}
          </Box>
        </Grid>
      </Stack>
    </Container>
  );
}

export default BranchSearch;

BranchSearch.propTypes = {
  px: PropTypes.any,
};
