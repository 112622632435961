// import PropTypes from 'prop-types';

// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import { useTheme } from '@mui/material/styles';
// import CardHeader from '@mui/material/CardHeader';

// import Chart, { useChart } from 'src/components/chart';

// // ----------------------------------------------------------------------

// export default function CourseProgressionChart({
//   // selectedYear,
//   // handleYearChange,

//   title,
//   subheader,
//   chart,
//   ...other
// }) {
//   const theme = useTheme();

//   const {
//     colors = [
//       ['#00A76F', ' #00A76F'],
//       [theme.palette.primary.light, theme.palette.primary.main],
//     ],
//     categories,
//     series,
//     options,
//   } = chart;

//   const chartData = series && series[0];

//   // const currentYear = new Date().getFullYear();
//   // const availableYears = Array.from({ length: currentYear - 2019 + 1 }, (_, index) => 2019 + index);
//   const chartOptions = useChart({
//     colors: colors?.map((colr) => colr[1]),
//     fill: {
//       type: 'gradient',
//       gradient: {
//         colorStops: colors?.map((colr) => [
//           { offset: 0, color: colr[0], opacity: 1 },
//           { offset: 100, color: colr[1], opacity: 1 },
//         ]),
//       },
//     },
//     stroke: {
//       curve: 'smooth',
//     },
//     xaxis: {
//       categories,
//     },
//     yaxis: {
//       max: 100,
//       labels: {
//         formatter: (value) =>
//           // You can customize the label format here
//           `${value} %`, // Example: Adding a dollar sign to the y-axis labels
//       },
//       // You can add more y-axis customization options here
//     },
//     ...options,
//   });

//   return (
//     <Card {...other}>
//       <CardHeader title={title} subheader={subheader} />

//       <Box sx={{ mt: 3, mx: 3 }}>
//         <Chart
//           dir="ltr"
//           type="line"
//           series={chartData?.data || []}
//           options={chartOptions}
//           width="100%"
//           height={300}
//         />
//       </Box>
//     </Card>
//   );
// }

// CourseProgressionChart.propTypes = {
//   chart: PropTypes.object,
//   subheader: PropTypes.string,
//   title: PropTypes.string,
//   selectedYear: PropTypes.any,
//   handleYearChange: PropTypes.any,
// };

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

export default function CourseProgressionChart({ series }) {
  const { progressionData } = useSelector((state) => ({
    progressionData: state.dashboard.progressionData,
  }));

  const chartOptions = useChart({
    plotOptions: {
      bar: {
        columnWidth: '16%',
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: '#42a86f', // Custom color for bars
            },
          ],
        },
      },
    },
    stroke: {
      show: false,
    },
    xaxis: {
      categories: progressionData?.map((item) => item?.title),
    },
    yaxis: {
      max: 100,
      labels: {
        formatter: (value) =>
          // You can customize the label format here
          `${value} %`, // Example: Adding a dollar sign to the y-axis labels
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `${value} %`,
      },
    },
  });

  return (
    <Chart dir="ltr" type="bar" series={series} options={chartOptions} width="100%" height={310} />
  );
}

CourseProgressionChart.propTypes = {
  series: PropTypes.array,
};
