import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSaleItem = createAsyncThunk(
  'saleItem/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/sale-item/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Sale Item created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSaleItem = createAsyncThunk(
  'SaleItem/list',
  async ({ page, search, dispatch, limit, sale_item_type, country, partner_type, course }) => {
    try {
      const URL = `/sale-item/admin/all?page=${page && page}&search=${search && search}&limit=${
        limit && limit
      }&sale_item_type=${sale_item_type && sale_item_type}&country=${country || ''}&partner_type=${
        partner_type || ''
      }&course=${course || ''}`;

      const URL_DROPDOWN = `/sale-item/admin/all?search=${search && search}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      console.log(response);
      if (response) {
        return {
          // response,
          saleItems: response?.saleItems,
          total: response?.total,
          page: response?.page,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSaleItemDetails = createAsyncThunk(
  'SaleItem/single',
  async ({ saleItemId, dispatch }) => {
    try {
      const response = await get(`/sale-item/admin/${saleItemId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSaleItemDetails = createAsyncThunk(
  'SaleItem/update',
  async ({ state, saleItemId, dispatch, navigate }) => {
    try {
      const URL = `/sale-item/admin/${saleItemId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Sale Item updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSaleItem = createAsyncThunk(
  'SaleItem/delete',
  async ({ saleItemId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/sale-item/admin/${saleItemId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Sale Item deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
