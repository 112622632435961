import PropType from 'prop-types';
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
// import jsPDF from 'jspdf';

import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Typography,
  Pagination,
  paginationClasses,
} from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDate } from 'src/utils/format-time';

import { colors } from 'src/theme/colors';
// import backgroundImage from 'src/assets/Dashboard/certificate.png';
import StudentCertificate from 'src/pages/Common/Certificate/student-certificate-download';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content/empty-content';

import StudentCertificateDialog from 'src/sections/studentCertificate/StudentCertificateDialog';

const CertificateList = ({ materialData, page, setPage, total }) => {
  const mobile = useResponsive('down', 'md');

  const [openCertificateDialog, setOpenCertificateDialog] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState({});

  // const handleDownloadPDF = async (item) => {
  //   setLoading(true);

  //   const contentWidth = 297; // Width of content in millimeters
  //   const contentHeight = 210;
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF({
  //     orientation: contentWidth > contentHeight ? 'l' : 'p',
  //   });
  //   const certificateContent = document.getElementById('certificate-content');

  //   if (certificateContent) {
  //     doc.addImage(item?.certificate_template?.background_image?.url, 'PNG', 0, 0, 297, 210); // Add background image
  //     doc.setFontSize(14);
  //     doc.setTextColor('#030303');
  //     doc.text(item?.certificate_number, 90, 21.3, { align: 'left' });
  //     doc.setFontSize(28);
  //     doc.setTextColor('#030303');
  //     doc.text(item?.student?.name, 147, 118, { align: 'center' }); // Add name
  //     doc.setFontSize(18);
  //     doc.text('in recognition of his/her participation in the', 147, 133, { align: 'center' }); // Add participation text
  //     doc.setFontSize(25);
  //     doc.setTextColor('#030303');
  //     doc.text(item?.student_exam?.exam?.title, 147, 145, { align: 'center' }); // Add test name
  //     doc.setFontSize(14);
  //     doc.setTextColor('#030303');
  //     doc.text(fDate(item?.createdAt), 68, 197.5, { align: 'left' });
  //     doc.save(`${item?.student?.name}-${item?.student_exam?.exam?.title}-certificate.pdf`);
  //   }
  //   setLoading(false);
  // };

  const handleDownloadPDF = (data) => {
    if (data) {
      setOpenCertificateDialog(true);
      setCertificateUrl(data);
    }
  };

  return (
    <>
      {openCertificateDialog && (
        <StudentCertificateDialog
          open={openCertificateDialog}
          setOpen={setOpenCertificateDialog}
          certificateData={certificateUrl}
        />
      )}
      {materialData?.total <= 0 && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      <Grid container spacing={mobile ? 0 : 1} mt="5px">
        {materialData?.studentCertificates?.map((item, key) => (
          <Grid key={key} md="4" item sm={12} sx={{ width: mobile && '100%' }}>
            {/* <Link
              key={key}
              to={paths?.dashboard?.lab_single(item?._id)}
              style={{ textDecoration: 'none' }}
            > */}
            <Card
              sx={{
                pb: mobile && 2,
                mr: mobile ? 0 : '24px',
                mb: '24px',
                boxShadow: 'none',
                border: '1px solid #F0F0F0',
                display: 'flex',
                alignItems: mobile ? 'end' : 'center',
                justifyContent: 'space-between',
                flexDirection: mobile ? 'column' : 'row',
              }}
            >
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    p: 0,
                    ml: 1,
                    transform: 'scale(1.6)',
                    width: '50px',
                    height: '50px',
                  }}
                >
                  <Image
                    alt="file preview"
                    src="/assets/images/lms/medallions.png"
                    sx={{
                      width: 1,
                      height: 1,
                      borderRadius: 1,
                    }}
                  />
                </Box>{' '}
                <Box p={2}>
                  <Typography fontSize={16} mb={1} fontWeight={600}>
                    {item?.student_exam?.exam?.title}
                  </Typography>
                  <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
                    Issued on {fDate(item?.createdAt)}
                  </TextMaxLine>
                </Box>
              </Stack>

              <Button
                sx={{
                  borderRadius: 100,
                  backgroundColor: colors.stem_blue,
                  color: '#fff',
                  fontWeight: 400,
                  minWidth: 110,
                  px: 1,
                  mr: 1,
                }}
                onClick={() => handleDownloadPDF(item)}
                color="info"
                variant="contained"
                size="small"
                startIcon={<CloudDownloadRoundedIcon fontSize="small" />}
              >
                Download
              </Button>
              <Stack display="none">
                <StudentCertificate
                  id="certificate-content"
                  backgroundImage={item?.certificate_template?.background_image?.url}
                />
              </Stack>
            </Card>
            {/* </Link> */}
          </Grid>
        ))}
      </Grid>
      {materialData?.total > 0 && (
        <Pagination
          onChange={(e, value) => setPage(value)}
          count={total}
          defaultValue={page}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default CertificateList;

CertificateList.propTypes = {
  materialData: PropType.any,
  page: PropType.any,
  setPage: PropType.any,
  total: PropType.any,
};
