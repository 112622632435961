import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllSubscriptionList = createAsyncThunk(
  'subscription/list',
  async ({ page, search, dispatch, limit, school, labModel, payment, sortDate }) => {
    try {
      const response = await get(
        `/subscription/admin/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 5
        }&school=${school || ''}&lab_model=${labModel || ''}&sort=${payment || ''}&dateFrom=${
          sortDate?.from || ''
        }&dateTo=${sortDate?.to || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createSubscriptionList = createAsyncThunk(
  'subscription/create',
  async ({ state, dispatch, handleClose, handleClear, subscriptionPage }) => {
    try {
      const URL = `/subscription/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: subscriptionPage,
          search: '',
          limit: sessionStorage.getItem(`rowsPerPage_subscription`),
          dispatch,
        };
        dispatch(getAllSubscriptionList(cred));
        dispatch(activeSnack({ type: 'success', message: 'Subscription created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSubscriptionListDetails = createAsyncThunk(
  'subscription/update',
  async ({ state, subscriptionId, dispatch, handleClose, handleClear, subscriptionPage }) => {
    try {
      const URL = `/subscription/admin/${subscriptionId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: subscriptionPage,
          search: '',
          limit: sessionStorage.getItem(`rowsPerPage_subscription`),
          dispatch,
        };
        dispatch(getAllSubscriptionList(cred));
        dispatch(activeSnack({ type: 'success', message: 'Subscription updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSubscriptionList = createAsyncThunk(
  'subscription/delete',
  async ({ subscriptionId, dispatch, handleCloseDeleteDialog, subscriptionPage }) => {
    try {
      const response = await del(`/subscription/admin/${subscriptionId}/`);
      if (response) {
        handleCloseDeleteDialog();
        const cred = {
          page: subscriptionPage,
          search: '',
          limit: sessionStorage.getItem(`rowsPerPage_subscription`),
          dispatch,
        };
        dispatch(getAllSubscriptionList(cred));
        dispatch(activeSnack({ type: 'success', message: 'Subscription deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSubscriptionPaymentStatus = createAsyncThunk(
  'subscription/status',
  async ({ state, dispatch, subscriptionPage, subscriptionId }) => {
    try {
      const URL = `/subscription/admin/school/status/update/${subscriptionId}`;
      const response = await post(URL, state);
      if (response) {
        const cred = {
          page: subscriptionPage,
          search: '',
          limit: sessionStorage.getItem(`rowsPerPage_subscription`),
          dispatch,
        };
        dispatch(getAllSubscriptionList(cred));
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
