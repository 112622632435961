// eslint-disable-next-line import/no-duplicates
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Select, InputLabel, FormControl, Autocomplete } from '@mui/material';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllCourses } from 'src/server/api/courses';
import { getAllStudents } from 'src/server/api/student';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function StudentExamTableToolbar({
  filters,
  dateFilter,
  onFilters,
  //
  isSearchAvailable,
  studentExamStatus,
  setStudentExamStatus,
  setStudentExamStatusLabel,
  setSelectedStudentExam,
  setSelectedStudent,
  setSelectedCourse,
  studentId,

  // dateFilter,
}) {
  const popover = usePopover();
  const dispatch = useDispatch();
  const [courseSearchText, setCourseSearchText] = useState('');
  const [studentSearchText, setStudentSearchText] = useState('');
  // const dispatch = useDispatch();

  // const { sate, setSate } = dateFilter;
  const { courseList, studentsList } = useSelector((state) => ({
    courseList: state.Course.course,
    studentsList: state.student.students,
  }));

  const { role } = useGetRoles();
  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );
  const { state, setSate } = dateFilter;

  const optionCourse = [
    { label: 'All', value: '' },
    ...(courseList?.courses || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const handleAutocompleteChangeCourse = (e, newValue) => {
    setSelectedCourse(newValue || '');
  };

  const handleTextFieldChangeCourse = (event) => {
    setCourseSearchText(event.target.value);
  };

  const optionStudent = [
    { label: 'All', value: '' },
    ...(studentsList?.students || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const handleAutocompleteChangeStudent = (e, newValue) => {
    setSelectedStudent(newValue || '');
  };

  const handleTextFieldChangeStudent = (event) => {
    setStudentSearchText(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: courseSearchText,
      school: '',
      sort: '',
      dispatch,
    };
    dispatch(getAllCourses(credentials));
  }, [dispatch, courseSearchText]);

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: studentSearchText,
      dispatch,
    };
    dispatch(getAllStudents(credentials));
  }, [dispatch, studentSearchText]);

  const studentExamTypeOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'In Progress', value: 'in progress' },
    { label: 'Completed', value: 'completed' },
  ];
  const handleTypeChange = (event) => {
    setStudentExamStatus(event.target.value);
    setStudentExamStatusLabel(event?.target.value);
  };
  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        // justifyContent="end"
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {role === 'super_admin' && (
          <>
            {!studentId && (
              <Stack lg={3}>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: 250 }}
                  options={optionStudent}
                  autoHighlight
                  size="small"
                  className="small-select"
                  getOptionLabel={(option) => option.label}
                  onChange={handleAutocompleteChangeStudent}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Student"
                      onChange={handleTextFieldChangeStudent}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            <Stack lg={3}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 250 }}
                options={optionCourse}
                autoHighlight
                size="small"
                className="small-select"
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeCourse}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    onChange={handleTextFieldChangeCourse}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Stack>
            <Stack flexDirection="row" lg={6}>
              <TextField
                name="date"
                label="From Date"
                sx={{ width: '100%', mr: 2 }}
                size="small"
                type="date"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSate({
                    ...state,
                    from: e.target.value,
                  })
                }
              />
              <TextField
                name="date"
                label="To Date"
                sx={{ width: '100%' }}
                size="small"
                type="date"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setSate({
                    ...state,
                    to: e.target.value,
                  })
                }
              />
            </Stack>
          </>
        )}

        <Stack lg={3} sx={{ width: '250px' }}>
          <FormControl size="small" className="small-select">
            <InputLabel sx={{ backgroundColor: 'white' }}>Student Exam Type</InputLabel>
            <Select value={studentExamStatus} onChange={handleTypeChange}>
              {studentExamTypeOptions.map((option) => (
                <MenuItem key={option.value} title={option?.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {isSearchAvailable && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            flexGrow={1}
            sx={{ width: '100%' }}
            lg={3}
          >
            <TextField
              fullWidth
              // value={filters.name}
              onChange={handleFilterName}
              placeholder="Search a Exam"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

StudentExamTableToolbar.propTypes = {
  isSearchAvailable: PropTypes.bool,
  filters: PropTypes.object,
  setStudentExamStatus: PropTypes.any,
  onFilters: PropTypes.func,
  setStudentExamStatusLabel: PropTypes.any,
  setSelectedCourse: PropTypes.any,
  studentExamStatus: PropTypes.any,
  setSelectedStudentExam: PropTypes.any,
  dateFilter: PropTypes.object,
  setSelectedStudent: PropTypes.any,
  studentId: PropTypes.any,
};
