import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import AcademyLandingView from 'src/sections/lms/landing';

// ----------------------------------------------------------------------

export default function AcademyLandingPage() {
  return (
    <>
      <MetaHelmet title="Landing" />

      <AcademyLandingView />
    </>
  );
}
