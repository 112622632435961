// import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogContent,
} from '@mui/material';

// components

import { getAllCourses } from 'src/server/api/courses';
import { getAllSessions } from 'src/server/api/session';
import { getAllLabModel } from 'src/server/api/labModel';
import { createClass, updateClass } from 'src/server/api/class';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

ClassDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setIsUpdate: PropTypes.func,
  isUpdate: PropTypes.object,
  setSelectedLab: PropTypes.any,
  setSelectedSesion: PropTypes.any,
};

export default function ClassDialog({
  open,
  setOpen,
  isUpdate,
  setIsUpdate,
  setSelectedLab,
  setSelectedSesion,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedLabModel, setSelectedLabModel] = useState('');
  const [searchLabModel, setSearchLabModel] = useState('');
  const [selectedSession, setSelectedSession] = useState('');
  const [searchSession, setSearchSession] = useState('');
  const defaultCourse = useMemo(
    () => ({
      label: isUpdate?.course?.title,
      value: isUpdate?.course?._id,
    }),
    [isUpdate]
  );
  const [selectedCourse, setSelectedCourse] = useState(defaultCourse || {});
  const [searchCourse, setSearchCourse] = useState('');

  const handleClose = () => {
    setOpen(false);
    setIsUpdate({});
  };
  const dispatch = useDispatch();

  const { loading, labModel, courseList, sessionList } = useSelector((state) => ({
    loading: state.class.loading,
    labModel: state.labModel.labModel,
    courseList: state.Course.course,
    sessionList: state.session.sessions,
  }));

  // const CertificateTemplateSchema = Yup.object().shape({
  //   name: Yup.string().required(),
  //   remarks: Yup.string(),
  //   type: Yup.string().required(),
  // });

  useEffect(() => {
    setSelectedLabModel(isUpdate?.lab_model?._id);
    setSelectedSession(isUpdate?.session?._id);
    setSelectedCourse({
      label: isUpdate?.course?.title,
      value: isUpdate?.course?._id,
    });
  }, [isUpdate]);

  const defaultValues = useMemo(() => {
    if (isUpdate?._id) {
      return {
        title: isUpdate?.title || '',
        description: isUpdate?.description || '',
      };
    }
    return {
      title: '',
      description: '',
    };
  }, [isUpdate?._id, isUpdate?.description, isUpdate?.title]);

  const methods = useForm({
    // resolver: yupResolver(CertificateTemplateSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const handleAutocompleteChangeLabModel = (_, newValue) => {
    setSelectedLabModel(newValue?.value || '');
  };

  const handleTextFieldChangeLabModel = (event) => {
    setSearchLabModel(event.target.value);
  };

  const optionLabmodel = [
    ...(labModel?.labModels || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchLabModel,
      limit: 10,
      dispatch,
    };
    dispatch(getAllLabModel(credentials));
  }, [dispatch, searchLabModel]);

  const handleAutocompleteChangeSession = (_, newValue) => {
    setSelectedSession(newValue?.value || '');
  };

  const handleTextFieldChangeSession = (event) => {
    setSearchSession(event.target.value);
  };

  const optionSession = [
    ...(sessionList?.sessions || []).map((results) => ({
      label: `${results?.title} (${results?.count})`,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchSession,
      limit: 10,
      dispatch,
    };
    dispatch(getAllSessions(credentials));
  }, [dispatch, searchSession]);

  const handleAutocompleteChangeCourse = (_, newValue) => {
    setSelectedCourse({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleTextFieldChangeCourse = (event) => {
    setSearchCourse(event.target.value);
  };

  const optionCourse = [
    ...(courseList?.courses || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchCourse,
      limit: 10,
      dispatch,
    };
    dispatch(getAllCourses(credentials));
  }, [dispatch, searchCourse]);

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: {
        ...data,
        lab_model: selectedLabModel,
        session: selectedSession,
        course: selectedCourse?.value || null,
      },
      dispatch,
      handleClose,
      setSelectedLab,
      setSelectedSesion,
      labeModels: labModel?.labModels,
      sessions: sessionList?.sessions,
    };
    const UpdateCredentials = {
      state: {
        ...data,
        lab_model: selectedLabModel,
        session: selectedSession,
        course: selectedCourse?.value || null,
      },
      classId: isUpdate?._id,
      dispatch,
      handleClose,
      handleClear,
      setSelectedLab,
      setSelectedSesion,
      labeModels: labModel?.labModels,
      sessions: sessionList?.sessions,
    };

    if (isUpdate?._id) {
      dispatch(updateClass(UpdateCredentials));
    } else {
      dispatch(createClass(CreateCredentials));
    }
  });

  const renderCourseForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack spacing={1.5}>
        <RHFTextField name="title" label="Title" type="text" required placeholder="Title" />
      </Stack>
      {isUpdate?._id ? (
        <Stack spacing={1.5}>
          <TextField
            name="labModel"
            label="Lab Model"
            type="text"
            required
            value={isUpdate?.lab_model?.title}
          />
        </Stack>
      ) : (
        <Stack spacing={1.5}>
          <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={optionLabmodel}
            autoHighlight
            getOptionLabel={(option) => option.label}
            // onChange={(_, newValue) => setSelectedCountry(newValue?.value || '')}
            onChange={handleAutocompleteChangeLabModel}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lab Model"
                required
                onChange={handleTextFieldChangeLabModel}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Stack>
      )}
      {isUpdate?._id ? (
        <Stack spacing={1.5}>
          <TextField
            name="session"
            label="Session"
            type="text"
            required
            value={isUpdate?.session?.title}
          />
        </Stack>
      ) : (
        <Stack spacing={1.5}>
          <Autocomplete
            id="session-select-demo"
            sx={{ width: '100%' }}
            options={optionSession}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeSession}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Session"
                required
                onChange={handleTextFieldChangeSession}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Stack>
      )}
      <Stack spacing={1.5}>
        <Autocomplete
          id="course-select-demo"
          sx={{ width: '100%' }}
          options={optionCourse}
          autoHighlight
          getOptionLabel={(option) => option.label}
          // onChange={(_, newValue) => setSelectedCountry(newValue?.value || '')}
          onChange={handleAutocompleteChangeCourse}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          defaultValue={selectedCourse?.value && selectedCourse}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Course"
              onChange={handleTextFieldChangeCourse}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              helperText="Classes created without course will not be shown in onboarding"
            />
          )}
        />
      </Stack>
      <Stack spacing={1.5}>
        <RHFTextField
          name="description"
          type="text"
          placeholder="Description"
          label="Description"
          multiline
          rows={4}
        />
      </Stack>
      <Stack
        sx={{
          marginBottom: '1rem',
          alignItems: 'center',
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          disabled={!selectedLabModel || !selectedSession}
          sx={{ color: 'white', marginLeft: 'auto' }}
          color="warning"
        >
          Submit Form
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="testnew template"
        sx={{ maxWidth: '100%' }}
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Class' : 'Create Class'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderCourseForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
