import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';

import TextMaxLine from 'src/components/text-max-line';
import Carousel, { useCarousel } from 'src/components/carousel';

// ----------------------------------------------------------------------

function LabModels({
  title,
  subheader,
  list,
  selectedLabModel,
  setSelectedLabModel,
  sx,
  ...other
}) {
  const theme = useTheme();
  const isWeb = useResponsive('up', 'sm');
  const carousel = useCarousel({
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  const modifiedList = [
    {
      title: 'All',
      _id: 1,
    },
    ...list,
  ];

  return (
    <Box sx={{ py: 2, ...sx }} {...other} position="relative">
      {!isWeb && (
        <Stack direction="row" spacing={1} justifyContent="end" mb={2}>
          <ArrowBackIcon onClick={carousel.onPrev} />
          <ArrowForwardIcon onClick={carousel.onNext} />
        </Stack>
      )}
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {modifiedList?.map((item) => (
          <LabModelItem
            onClick={() =>
              setSelectedLabModel({
                title: item?.title,
                _id: item?._id,
              })
            }
            selectedLabModel={selectedLabModel}
            key={item._id}
            item={item}
          />
        ))}
      </Carousel>
      {isWeb && (
        <Stack direction="row" justifyContent="end" mr={1} mt={2}>
          <ArrowBackIosNewIcon
            onClick={carousel.onPrev}
            sx={{ cursor: 'pointer', position: 'absolute', top: 30, left: -35, fontSize: 35 }}
          />
          <ArrowForwardIosIcon
            onClick={carousel.onNext}
            sx={{ cursor: 'pointer', position: 'absolute', top: 30, right: -13, fontSize: 35 }}
          />
        </Stack>
      )}
    </Box>
  );
}

LabModels.propTypes = {
  selectedLabModel: PropTypes.object,
  setSelectedLabModel: PropTypes.func,
  list: PropTypes.array,
  subheader: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
};

export default React.memo(LabModels);
// ----------------------------------------------------------------------

function LabModelItem({ item, selectedLabModel, onClick }) {
  const check = selectedLabModel?._id === item?._id;
  const mobile = useResponsive('down', 'md');

  return (
    <Paper
      sx={{
        mr: 3,
        position: 'relative',
      }}
    >
      <Button
        fullWidth
        variant={check ? 'contained' : 'outlined'}
        size="large"
        color={check ? 'info' : 'inherit'}
        sx={{
          px: mobile ? 0 : '',
          fontWeight: 300,
          height: 65,
          backgroundColor: check ? colors.stem_blue : colors.white,
          color: check ? colors.white : colors.stem_blue,
        }}
        onClick={onClick}
      >
        <TextMaxLine variant="body2" line={mobile ? 2 : 1}>
          {item?.title}
        </TextMaxLine>
      </Button>
    </Paper>
  );
}

LabModelItem.propTypes = {
  selectedLabModel: PropTypes.object,
  onClick: PropTypes.func,
  item: PropTypes.object,
};
