import React from 'react';

import {
  Box,
  Card,
  Grid,
  Link,
  Stack,
  Button,
  Container,
  Typography,
  CardContent,
} from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { useDevice } from '../hooks/useResponsive';

function Form() {
  const { isMobile } = useDevice();

  return (
    <div>
      {isMobile === true ? (
        <Box>
          <Card
            className="img-headline"
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              height: 'auto', // Auto height for better responsiveness
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={12}>
                <Stack spacing={2} padding={0} className="course-main-image-mobile">
                  <Stack>
                    <img
                      src="/assets/imagesWeb/Courses/online.png"
                      alt="mainimg"
                      style={{ width: '80%', height: '100%', objectFit: 'contain' }}
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                {/* Add your inputs here */}
                <Stack className="online-offline-course-banner-mobile" sx={{ pt: 0 }}>
                  <Typography className="online-offline-course-banner-title-mobile">
                    Try our free online
                    <br />
                    classes now!
                  </Typography>
                  <Stack>
                    <Typography
                      className="online-offline-course-banner-description-mobile"
                      sx={{ py: 2 }}
                    >
                      Discover a world of knowledge with our free, interactive online classes! Dive
                      into engaging lessons, learn new skills, and expand your horizons—all at your
                      own pace. Get started today and embark on a learning journey tailored just for
                      you!+
                    </Typography>
                  </Stack>
                </Stack>
                {/* Submit Button */}
                <Stack display="flex" sx={{ pt: 0 }}>
                  <Link href="/contactUs" target="_blank" rel="noreferrer">
                    <Button
                      variant="contained"
                      size="large"
                      className="px-5"
                      sx={{ width: 'auto', backgroundColor: '#05286C', boxShadow: 'none' }}
                    >
                      Try Our Free Class
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Box>
      ) : (
        <Container maxWidth="xl">
          <Card
            className="img-headline"
            style={{
              backgroundColor: '#212121',
              border: 'none',
              height: '500px',
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'end',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={2} padding={0}>
                    <Stack display="flex" alignItems="">
                      <img
                        src="/assets/imagesWeb/Courses/online.png"
                        alt="mainimg"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                {/* Add your inputs here */}
                <Stack className="online-offline-course-banner" sx={{ pt: 10 }}>
                  <Typography className="online-offline-course-banner-title">
                    Try our free online classes now!
                  </Typography>

                  <Stack>
                    <Typography
                      className="online-offline-course-banner-description"
                      textAlign="justify"
                      pr={10}
                      lineHeight={1.8}
                      sx={{ py: 2 }}
                    >
                      Discover a world of knowledge with our free, interactive online classes! Dive
                      into engaging lessons, learn new skills, and expand your horizons—all at your
                      own pace. Get started today and embark on a learning journey tailored just for
                      you!
                    </Typography>
                  </Stack>
                </Stack>
                {/* Submit Button */}
                <Stack display="flex" sx={{ pt: 3 }}>
                  <Link href="/contactUs" target="_blank" rel="noreferrer">
                    <Button
                      variant="contained"
                      size="large"
                      className="px-5"
                      sx={{ backgroundColor: '#05286C', boxShadow: 'none' }}
                    >
                      Try Our Free Online Classes now !
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default Form;
