import PropTypes from 'prop-types';

import { Step, Stepper, StepLabel } from '@mui/material';

import { QontoStepIcon, QontoConnector } from './styles';

// ----------------------------------------------------------------------

const OnBoardingStepper = ({ steps, activeStep }) => (
  <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
    {steps?.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);
export default OnBoardingStepper;
OnBoardingStepper.propTypes = {
  steps: PropTypes.any,
  activeStep: PropTypes.any,
};
