import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Tooltip,
  CardMedia,
  Typography,
  Pagination,
  CardContent,
  paginationClasses,
} from '@mui/material';

import { deleteClass } from 'src/server/api/class';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';

import classIcon from '../../assets/labModel/file.png';

ClassData.propTypes = {
  classes: PropTypes.any,
  handleEditClass: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  total: PropTypes.any,
};

export default function ClassData({ classes, handleEditClass, page, setPage, total }) {
  const dispatch = useDispatch();
  const { deleteMessage } = useSelector((state) => ({
    deleteMessage: state.class.deleteMessage,
  }));
  const [confirmDelete, setConfirmDelete] = useState({ value: '', status: false });

  const handleCloseModal = () => {
    setConfirmDelete({ value: '', status: false });
  };

  const handleDelete = (id) => {
    setConfirmDelete({
      value: id,
      status: true,
    });
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      classId: confirmDelete?.value,
      dispatch,
      handleCloseModal,
    };
    dispatch(deleteClass(credentials));
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDelete.status}
        onClose={handleCloseModal}
        title="Delete"
        content="Are you sure want to delete This Class ?"
        deleteMessage={deleteMessage || {}}
        action={
          <Button variant="contained" color="error" onClick={handleDeleteConfirmClick}>
            Delete
          </Button>
        }
      />
      <Grid container spacing={1}>
        {classes &&
          classes?.classes?.length > 0 &&
          classes?.classes?.map((item, key) => (
            <Grid lg="4" md="4" sm="6" xs="12">
              <Card
                variant="outlined"
                key={key}
                sx={{
                  minWidth: 244,
                  marginRight: '24px',
                  marginTop: '40px',
                  boxShadow: 'none',
                  minHeight: '110px',
                }}
              >
                <CardContent>
                  <Grid display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <CardMedia
                        component="img"
                        image={classIcon}
                        alt="green iguana"
                        sx={{ width: '24px', height: '24px' }}
                      />
                      <Stack ml="16px" pr={1.5}>
                        <Typography fontSize={16} fontWeight={600}>
                          {item?.title}
                        </Typography>
                        {!item?.course && (
                          <Typography
                            variant="caption"
                            color="red"
                          >{`"No Course Added"`}</Typography>
                        )}
                        <TextMaxLine variant="caption" line={2}>
                          {item?.description}
                        </TextMaxLine>
                      </Stack>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Edit">
                        <Iconify
                          onClick={() => handleEditClass(item)}
                          sx={{
                            cursor: 'pointer',
                            width: '20px',
                          }}
                          icon="solar:pen-bold"
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Iconify
                          onClick={() => handleDelete(item?._id)}
                          sx={{ cursor: 'pointer', color: 'red' }}
                          icon="solar:trash-bin-trash-bold"
                        />
                      </Tooltip>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      {classes?.total > 20 && (
        <Pagination
          onChange={(e, value) => setPage(value)}
          count={total}
          defaultValue={page}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
