import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import bag from 'src/assets/Dashboard/stack.png';
import kit from 'src/assets/Dashboard/ic-glass-buy.png';
import coin from 'src/assets/Dashboard/Coin Bag-small.png';
import desktop from 'src/assets/Dashboard/Desktop computer graph-small.png';
import ShareLinkAllCourse from 'src/pages/Common/ShareLinkAllCourse/shareLinkAllCourse';

import ActiveUsers from './active-users';
import BalanceView from './balance-view';
import SalesEarningChart from './sales-earning-chart';
import PartnerCheckInWidgets from './partner-check-in-widgets';
// ----------------------------------------------------------------------

const SPACING = 2;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  // const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const { dashboardData, earningsChartData, salesChart } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
    earningsChartData: state.dashboard.earningsChart,
    salesChart: state.dashboard.salesChart,
  }));
  const { totalSalesCount, totalSalesValue, totalEarningsValue, totalEarningsCount } =
    dashboardData;
  const averageSales = totalSalesValue / totalSalesCount;

  const averageEarnings = totalEarningsValue / totalEarningsCount;

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
  };
  const totalSold =
    (dashboardData?.totalSoldCount?.totalSchoolSoldCount || 0) +
    (dashboardData?.totalSoldCount?.totalCourseSoldCount || 0) +
    (dashboardData?.totalSoldCount?.totalProductSoldCount || 0);

  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid>
        <Typography sx={{ fontWeight: '800', fontSize: mdUp ? '24px' : '15px' }}>
          Hi, Welcome Back 👋
        </Typography>
      </Grid>
      <Grid xs={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Total Sales',
                label1: 'Today',
                percent: averageSales,
                total: dashboardData?.totalSalesCount,
                today: dashboardData?.todaySalesCount,
                img: desktop,
                link: paths.dashboard.sale,
              },
              {
                label: 'Total Earnings',
                label1: 'Today',
                percent: averageEarnings,
                total: dashboardData?.totalEarningsCount,
                today: dashboardData?.todayEarningsCount,
                link: paths.dashboard.earnings,
                img: coin,
              },
            ],
          }}
        />
      </Grid>
      <Grid xs={12}>
        <ActiveUsers
          chart={{
            series: [
              {
                img: bag,
                label: 'Total Sales Value',
                total: dashboardData?.totalSalesValue,
                background: '#fff3da',
                // 'linear-gradient(135deg, rgba(244, 153, 53, 0.8) 0%, rgba(238, 130, 54, 0.16) 100%)',
              },
              {
                img: kit,
                label: 'Total Earnings Value',
                total: dashboardData?.totalEarningsValue,
                background: '#fdecde',
                // 'linear-gradient(135deg, rgba(255, 214, 102, 0.8) 0%, rgba(255, 171, 0, 0.16) 100%)',
              },
            ],
          }}
        />
      </Grid>
      {earningsChartData && salesChart && (
        <Grid item md={8} xs={12}>
          <SalesEarningChart
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            title="Yearly Sales & Earnings"
            subheader={`sales & earnings of the year ${selectedYear}`}
            chart={{
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              series: [
                {
                  year: selectedYear,
                  data: [
                    {
                      name: 'Total Sales',
                      data: salesChart || [],
                    },
                    {
                      name: 'Total Earnings',
                      data: earningsChartData || [],
                    },
                  ],
                },
              ],
            }}
          />
        </Grid>
      )}

      <Grid md={4} xs={12}>
        <BalanceView
          percentageIncrement={dashboardData?.percentageIncrement}
          incrementBalance={dashboardData?.incrementBalance}
          totalProduct={dashboardData?.totalSoldCount?.totalProductSoldCount}
          totalCourse={dashboardData?.totalSoldCount?.totalCourseSoldCount}
          totalSchool={dashboardData?.totalSoldCount?.totalSchoolSoldCount}
          totalSold={totalSold}
          chart={{
            series: [
              {
                img: bag,
                label: 'Total Sales Value',
                total: dashboardData?.totalSalesValue,
                background:
                  'linear-gradient(135deg, rgba(244, 153, 53, 0.8) 0%, rgba(238, 130, 54, 0.16) 100%)',
              },
              {
                img: kit,
                label: 'Total Earnings Value',
                total: dashboardData?.totalEarningsValue,
                background:
                  'linear-gradient(135deg, rgba(255, 214, 102, 0.8) 0%, rgba(255, 171, 0, 0.16) 100%)',
              },
            ],
          }}
        />
      </Grid>

      <Grid>
        <ShareLinkAllCourse />
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
