import PropType from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import LockIcon from '@mui/icons-material/Lock';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  Box,
  Tab,
  Grid,
  Card,
  Tabs,
  Stack,
  alpha,
  Container,
  Typography,
  Pagination,
  paginationClasses,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAcademyContentDetails, getAcademyContentsByCourse } from 'src/server/api/content';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import TabContent from '../School/SessionView/TabContent';

export default function AcademyContentView({ minimal }) {
  const dispatch = useDispatch();
  const params = useParams();
  const theme = useTheme();
  const settings = useSettingsContext();

  const { contentDetails, contents, loading } = useSelector((state) => ({
    contentDetails: state.content.contentDetails,
    contents: state.content.contents,
    loading: state.content.loading,
  }));

  const courseDetails = contentDetails?.course;

  const [pageContent, setPageContent] = useState(1);
  const totalContent = contents?.total;
  const totalClassCount = Math.ceil(totalContent / 10);

  const [activeTab, setActiveTab] = useState('video');

  useEffect(() => {
    if (contentDetails?.video_url) {
      setActiveTab('video');
    }
    if (!contentDetails?.video_url && contentDetails?.pdf_url) {
      setActiveTab('document');
    }
    if (
      !contentDetails?.video_url &&
      !contentDetails?.pdf_url &&
      contentDetails?.training_pdf_url
    ) {
      setActiveTab('document');
    }
  }, [contentDetails?.pdf_url, contentDetails?.training_pdf_url, contentDetails?.video_url]);

  useEffect(() => {
    const credentials = {
      contentId: params?.id,
      dispatch,
    };
    dispatch(getAcademyContentDetails(credentials));
  }, [dispatch, params?.id]);

  useEffect(() => {
    if (courseDetails?._id) {
      const credentials = {
        page: pageContent,
        search: '',
        limit: 10,
        courseId: courseDetails?._id,
        dispatch,
      };
      dispatch(getAcademyContentsByCourse(credentials));
    }
  }, [dispatch, contentDetails, courseDetails?._id, pageContent]);

  useEffect(() => {
    if (contentDetails?.video_url || contentDetails?.video_url?.length > 0) {
      setActiveTab('video');
    } else if (contentDetails?.pdf_url && contentDetails?.pdf_url?.length > 0) {
      setActiveTab('document');
    } else {
      setActiveTab('training');
    }
  }, [contentDetails?.pdf_url, contentDetails?.training_pdf_url, contentDetails?.video_url]);

  // const filteredContents = contents?.contents?.filter((item) => item?._id !== contentDetails?._id);

  const TABS = [];

  if (contentDetails?.video_url) {
    TABS.push({ value: 'video', label: 'Video' });
  }
  if (contentDetails?.pdf_url && contentDetails?.pdf_url?.length > 0) {
    TABS.push({ value: 'document', label: 'Document' });
  }
  if (contentDetails?.training_pdf_url && contentDetails?.training_pdf_url?.length > 0) {
    TABS.push({ value: 'training', label: 'Training Material' });
  }

  // const handleCompletedContent = () => {
  //   const credentials = {
  //     schoolCourseId: courseDetails?._id,
  //     state: {
  //       content: contentDetails?._id,
  //     },
  //     dispatch,
  //   };
  //   dispatch(createCompletedContent(credentials));
  // };

  // const handleCompletedStatus = () => {
  //   const isCompleted = courseDetails?.completed_contents?.some(
  //     (item) => item?.content === contentDetails?._id
  //   );
  //   return isCompleted;
  // };

  return (
    <>
      {loading ? (
        <DashboardLoading />
      ) : (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
          <MetaHelmet title="Content" />
          {/* {!minimal && (
          <CustomBreadcrumbs
            heading="Course Details"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.root,
              },

              {
                name: 'Courses',
                href: paths.dashboard.course,
              },
              {
                name: 'Course View',
                href: paths.dashboard.course_view(1),
              },
              {
                name: 'Session View',
              },
            ]}
            action=""
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )} */}
          <Grid container>
            <Grid md="9">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontSize={24} fontWeight={700}>
                  {contentDetails?.title}
                </Typography>
                {/* {handleCompletedStatus() ? (
                  <Typography
                    fontWeight={700}
                    fontSize={15}
                    color="#22C55E"
                    display="flex"
                    alignItems="center"
                  >
                    <Check sx={{ color: '#22C55E', mr: '8px', width: '20px', height: '20px' }} />
                    Completed
                  </Typography>
                ) : (
                  <Button
                    size="small"
                    onClick={() => handleCompletedContent()}
                    sx={{
                      border: '1px solid #919EAB52',
                      p: '8px',
                      fontWeight: 500,
                      fontSize: '13px',
                      mr: 4,
                      '&:hover': {
                        backgroundColor: '#00A76F29',
                        color: '#00A76F',
                        border: 'none',
                      },
                    }}
                  >
                    Mark as completed
                  </Button>
                )} */}
              </Box>
              <Typography fontSize={15} fontWeight={400} color="#637381" pt="8px" width="90%">
                {parse(contentDetails?.description)}
              </Typography>
              <Stack mt="20px" mr={2}>
                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  sx={{
                    boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                  }}
                >
                  {TABS?.map((tab) => (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  ))}
                </Tabs>
                <TabContent activeTab={activeTab} />
              </Stack>
            </Grid>

            <Grid md="3">
              {/* <Card sx={{ mr: '24px', mb: '24px', minHeight: '92px' }}> */}
              <Scrollbar sx={{ maxHeight: '700px' }}>
                {contents?.contents?.map((item, key) => (
                  <Link
                    key={key}
                    // to={item?.is_locked ? '#' : paths.auth.course_content_view(item?._id)}
                    to={paths.auth.course_content_view(item?._id)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Card
                      sx={{
                        mb: 2,
                        backgroundColor: item?._id === contentDetails?._id && '#fff4dd',
                        color:
                          item?._id === contentDetails?._id &&
                          settings?.themeMode === 'dark' &&
                          'black',
                      }}
                    >
                      <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          display="flex"
                          flexDirection="column"
                        >
                          {item?.title}
                          {`${(pageContent - 1) * 10 + key + 1}. ${item?.title}`}
                          <Stack direction="row" spacing={0} my="12px">
                            {item?.pdf_url && item?.pdf_url?.length > 0 && (
                              <TextSnippetIcon sx={{ color: '#795192' }} />
                            )}
                            {(item?.video_url || item?.video_url?.length > 0) && (
                              <PlayCircleIcon sx={{ color: '#795192' }} />
                            )}
                          </Stack>
                        </Typography>
                        {/* {item?.is_locked && (
                          <LockIcon
                            sx={{ mr: '8px', width: '18px', height: '18px', color: '#959595' }}
                          />
                        )} */}
                      </Box>
                    </Card>
                  </Link>
                ))}
              </Scrollbar>
              {contents?.total > 10 && (
                <Stack>
                  <Pagination
                    onChange={(e, value) => setPageContent(value)}
                    count={totalClassCount}
                    defaultValue={pageContent}
                    sx={{
                      mt: 2,
                      [`& .${paginationClasses.ul}`]: {
                        justifyContent: 'center',
                      },
                    }}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

AcademyContentView.propTypes = {
  minimal: PropType.bool,
};
