import PropTypes from 'prop-types';

export const UseDownloadImage = async (imgUrl, fileName) => {
  const imageUrl = imgUrl;

  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.png`; // You can set the file name here
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the image:', error);
  }
};

UseDownloadImage.propTypes = {
  pdfUrl: PropTypes.string,
  fileName: PropTypes.string,
};
