import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { fNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------

export default function AdminCheckInWidgets({ chart, ...other }) {
  const routes = useRouter();
  // const theme = useTheme();

  // const smUp = useResponsive('up', 'sm');

  const {
    // colors = [
    //   [theme.palette.primary.light, theme.palette.primary.main],
    //   [theme.palette.warning.light, theme.palette.warning.main],
    // ],
    series,
    // options,
  } = chart;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', gap: '1rem' }}>
      {series.map((item, index) => (
        <Card {...other} key={item.label} sx={{ width: '100%' }}>
          <Stack
            direction={{ xs: 'column', md: 'column' }}
            // divider={
            //   <Divider
            //     orientation={smUp ? 'vertical' : 'horizontal'}
            //     flexItem
            //     sx={{ borderStyle: 'dashed' }}
            //   />
            // }
          >
            <Stack
              onClick={() => routes?.push(item.link)}
              spacing={3}
              direction={{ md: 'row', xs: 'row' }}
              alignItems="center"
              justifyContent={{ sm: 'space-between', xs: 'space-around' }}
              sx={{
                py: 2,
                px: 2,
                width: 1,
                flexDirection: '',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Grid lg={6}>
                <Typography sx={{ mb: 0.5, fontSize: '14px', fontWeight: '600' }}>
                  {item.label}
                </Typography>
                <Typography sx={{ mb: 0.5, fontSize: '32px', fontWeight: '700' }}>
                  {fNumber(item.total)}
                </Typography>
              </Grid>
              <Grid lg={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={item.img}
                  alt="sparklin"
                  style={{ objectFit: 'contain', width: '100px', height: '100px' }}
                />
              </Grid>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
}

AdminCheckInWidgets.propTypes = {
  chart: PropTypes.object,
};
