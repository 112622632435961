import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllExams = createAsyncThunk(
  'exam/list',

  async ({ page, limit, sort, date, search, school, course, exam_type, dispatch }) => {
    try {
      const response = await get(
        `/exam/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&school=${school || ''}&course=${course || ''}&dateFrom=${date?.from || ''}&dateTo=${
          date?.to || ''
        }&exam_type=${exam_type || ''}&sort=${sort || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createExam = createAsyncThunk(
  'exam/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/exam/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Exam created successfully' }));

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExamDetails = createAsyncThunk('exam/single', async ({ ExamId, dispatch }) => {
  try {
    const response = await get(`/exam/admin/${ExamId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateExamDetails = createAsyncThunk(
  'exam/update',
  async ({ state, ExamId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/exam/admin/${ExamId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllExams({
            page: sessionStorage.getItem('page-exam'),
            limit: sessionStorage.getItem(`rowsPerPage_admin-exam`),
            sort: '',
            date: '',
            search: '',
            school: '',
            course: '',
            exam_type: '',
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Exam updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteExam = createAsyncThunk('exam/delete', async ({ ExamId, dispatch }) => {
  try {
    const response = await del(`/exam/admin/${ExamId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Exam deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
// .......................................................................................................
// exam content

export const getAllExamContents = createAsyncThunk(
  'exam-content/list',

  async ({ page, limit, search, exam, school, dispatch }) => {
    try {
      const response = await get(
        `/exam-content/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&school=${school || ''}&exam=${exam || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createExamContent = createAsyncThunk(
  'exam-content/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/exam-content/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Exam Content created successfully' }));

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExamContentDetails = createAsyncThunk(
  'exam-content/single',
  async ({ ExamContentId, dispatch }) => {
    try {
      const response = await get(`/exam-content/admin/${ExamContentId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateExamContentDetails = createAsyncThunk(
  'exam-content/update',
  async ({ state, ExamContentId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/exam-content/admin/${ExamContentId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        handleClear();

        dispatch(activeSnack({ type: 'success', message: 'Exam Content updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteExamContent = createAsyncThunk(
  'exam-content/delete',
  async ({ ExamContentId, dispatch }) => {
    try {
      const response = await del(`/exam-content/admin/${ExamContentId}/`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Exam Content deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
