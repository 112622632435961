import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSaleItem,
  getAllSaleItem,
  deleteSaleItem,
  getSaleItemDetails,
  updateSaleItemDetails,
} from '../api/saleItem';

export const saleItemSlice = createSlice({
  name: 'saleItem',
  initialState: {
    loading: false,
    error: {},
    // saleItem
    saleItem: {},
    staffDropList: [],
    saleItemDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create saleItem details
    [createSaleItem.pending]: (state) => {
      state.loading = true;
    },
    [createSaleItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.saleItems;

      const oldSaleItemsList = jsonState?.saleItems;

      const modifiedSaleItemList = {
        ...jsonState,
        saleItems: Array.isArray(oldSaleItemsList)
          ? [action.payload, ...oldSaleItemsList]
          : [action.payload],
      };
      state.loading = false;
      state.saleItemDetails = action.payload;
      state.saleItem = modifiedSaleItemList;
      state.error = {};
    },
    [createSaleItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all saleItem
    [getAllSaleItem.pending]: (state) => {
      state.loading = true;
    },
    [getAllSaleItem.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleItem = action.payload;
      state.error = {};
    },
    [getAllSaleItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single saleItem details
    [getSaleItemDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSaleItemDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleItemDetails = action.payload;
      state.error = {};
    },
    [getSaleItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update saleItem details
    [updateSaleItemDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSaleItemDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.saleItemDetails = action.payload;
      state.error = {};
    },
    [updateSaleItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete saleItem
    [deleteSaleItem.pending]: (state) => {
      state.loading = true;
    },
    [deleteSaleItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.saleItem;
      const modifiedSaleItemList = {
        ...jsonState,
        saleItems: jsonState.saleItems?.filter((saleItem) => saleItem._id !== action.payload),
      };
      state.loading = false;
      state.saleItem = modifiedSaleItemList;
      state.error = {};
    },
    [deleteSaleItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = saleItemSlice.actions;

export default saleItemSlice.reducer;
