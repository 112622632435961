import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useParams, useRouter } from 'src/routes/hooks';

import { SentIcon } from 'src/assets/icons';
import { confirmPassword } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function PasswordVerificationView({ type }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));

  const userType = params?.type;

  const handleUserRole = () => {
    switch (userType) {
      case 'partner':
        return 'partner';
      case 'school':
        return 'school_user';
      case 'student':
        return 'student';
      default:
        return '';
    }
  };

  const onSubmit = () => {
    const credentials = {
      state: { user_type: handleUserRole() },
      enqueueSnackbar,
      router,
      token: params?.token,
      type: userType,
    };
    dispatch(confirmPassword(credentials));
  };

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      {/* <RHFTextField
        name="newPassword"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}

      {/* <RHFTextField
        name="confirmPassword"
        label="Confirm New Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        onClick={onSubmit}
      >
        Accept
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.public.admin_login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
      {/* <Link
        component={RouterLink}
        href={paths.auth.forgot_password}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          textDecoration: 'underline',
          color: '#00A76F',
        }}
      >
        Reset Verification
      </Link> */}
    </Stack>
  );

  const renderHead = (
    <>
      <SentIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ mt: 3, mb: 5 }}>
        <Typography variant="h4">
          Verify password for
          <br /> your account
        </Typography>

        <Typography variant="body2" color={green[600]}>
          Email verification has been successfully completed.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          You are now able to verify the password for your account.
          <br />
          Please ensure that your password is unique and strong.
        </Typography>
      </Stack>
    </>
  );

  return (
    // <FormProvider methods={methods} onSubmit={onSubmit}>
    <Stack>
      {renderHead}

      {renderForm}
    </Stack>
    // </FormProvider>
  );
}

PasswordVerificationView.propTypes = {
  type: PropTypes.any,
};
