import React from 'react';

import { Stack, Container, Typography } from '@mui/material';

function Terms() {
  return (
    <Stack>
      <Container sx={{ color: '#A9A9A9', py: 5 }}>
        <Typography variant="h3">Terms and Conditions</Typography>
        <Typography>
          {`
          These terms and conditions constitute a legally binding agreement between STEM-Xpert, Techgenius Innovations Pvt Ltd (referred to as "STEM-Xpert,", “The Company” "we," "us," or "our") and the users (referred to as "You") on the Company's Websites, Products, and services, as described below.
          `}
        </Typography>
        <Typography>
          {`
         This agreement encompasses all essential points related to our terms and conditions and may be subject to modification by the company from time to time. This agreement, inclusive of all terms and conditions, applies to all types of visitors, users, others who access the website ("Users"), and buyers of the company’s products and services.
          `}
        </Typography>
        <Typography>
          Please carefully read through all the terms & conditions and ensure a full understanding
          of the contents of this agreement. In case of doubts or questions about your rights and
          obligations resulting from accepting our agreement, kindly contact and consult with us
        </Typography>
        <Typography>
          By using or accessing our websites, you signify that you have read, fully understood, and
          agreed to abide by this agreement. You also consent to the collection and use of your
          information as per the Company privacy policy.
        </Typography>
        <br />
        <Typography>
          <Typography variant="h5">1. Our Company</Typography>
          Techgenius Innovations Pvt Ltd is the parent company, and STEM-Xpert is a brand name under
          the parent company.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 2. Trademarks</Typography>
          All page headers, graphics, logos, service names, etc., available/posted on our websites
          are trademarks of the Company. No rights are granted to any party or user to use our
          trademarks – service & product names, logos, graphics, or other information posted on the
          websites without the written permission of the Company.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 3. Copyright & Content</Typography>
          Any content like logos, graphics, pictures, textual, audio, videos, and data made
          available on the websites are exclusive properties of the Company and are protected by
          copyright laws. The Company reserves all rights to the content posted on the websites.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 4. User Login Credentials</Typography>
          Users are solely responsible for their login and password credentials across all websites
          within the Company. It is strictly advised not to share user login and password with
          anyone, and users must ensure that their login credentials are not misused by anyone.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 5. Guarantee/Warranty</Typography>
          {`
        The Company products are covered by different warranty periods, as indicated on respective pages, ensuring that products are free from defects in materials and workmanship. Decisions regarding warranty acceptance will be taken by the Company and shall be accepted by customers. The Company may or may not charge for repairs and return shipping if the item's warranty is void due to any reasons.
          `}
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 6. Terms and Condition of Sales</Typography>
          The conditions set out below apply to all transactions for the sales of our products, and
          online & offline services by the Company to the buyers of products and/or services. These
          conditions apply to all orders placed with the Company by the buyers.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 7. Prices</Typography>
          {`The prices of the products or Services are as contained by the Company's non-binding quotations or the current list of prices displayed on the websites. Prices are exclusive of delivery, packaging, carriage, insurance, Tax, and Duties which may be charged. The Company reserves the right to adjust the prices of any products/services at any time.`}
        </Typography>
        <br />

        <Typography variant="h5">8. Payment of Products and Services</Typography>
        <Typography>
          Payment is due before shipment of the product or performance of services as per agreed
          terms and conditions with the company.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 9. Refund policy</Typography>
          a) Manufacturing Defect for DIY Kits If the product exhibits a manufacturing defect,
          please notify us within 2 days of receiving the product. A video of the unboxing of the
          kit may be required to assess the issue. Refunds are not applicable for other than
          manufacturing defects. For manufacturing defects, once the return is approved, the
          replacement will be processedd
          <br /> b)Services Refund and Services Discontinuation:: Services, including workshops and
          events, are subject to specific terms mentioned in the service agreement. If you wish to
          discontinue a service, a 10% discontinuation fee may apply. Cancel your online class or
          recorded video subscription within 7 days of registration and payment to be eligible for a
          refund.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 10. Delivery of Products</Typography>
          For delivery of sold products within India, the price quoted includes delivery charges.
          For deliveries outside India, delivery, tax, and duties are excluded from the quoted
          price. Delivery dates quoted are approximate, and the Company will not be liable for any
          delay in the delivery of the Products.
          <br />
          If the buyer fails to take delivery of the products or fails to give adequate delivery
          instructions & returns to the Company, then the Company reserves all the rights to decide
          whether to resend the parcel or not.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 11. Risk and Property</Typography>
          The risk of damage to or loss of the Products passes on delivery or if a buyer fails to
          take delivery without good cause at the time when the delivery is tendered. Title to and
          the property in the product do not pass to a buyer until the Company has received full
          payment of the price and all other sums due.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 12. Limitation of Liability</Typography>
          The Company shall not be liable for any use of the products by the buyers. The Company is
          not liable to any buyers for any indirect, special, or consequential loss or damage. Any
          liability of the Company to the buyers is limited to the price.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 13. Confidentiality Agreement</Typography>
          All users/buyers must not use the company information directly or indirectly for their own
          purpose or those of any other person, company, business entity, or organization whatsoever
          any trade secrets or confidential information relating to or belonging to the COMPANY.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 14. User-Generated Content </Typography>
          {`User-generated content includes any comments, projects, forum posts, or links directed to third-party websites that users submit to the Company. Users are responsible for protecting their rights to share content on the Company's websites.`}
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 15. Educational Courses, Labs, Kits, and Workshops </Typography>
          For users participating in online/offline courses, accessing labs, kits, and workshops
          offered by the Company :
          <br />
          a. Course Participation: Users are expected to actively engage in online courses and
          adhere to the outlined guidelines for offline courses, labs, and workshops.
          <br /> b. Material Handling: In the case of physical kits and workshop materials, users
          are responsible for handling them as per the provided instructions. Any damage caused by
          incorrect handling or improper use may not be covered under warranty.
          <br /> c. Workshop Attendance: For offline workshops, users are required to attend as per
          the specified schedule. Any changes or cancellations should be communicated in advance.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5">
            {' '}
            16. Termination and Cancellation of User Rights/Account{' '}
          </Typography>
          {`
         The Company reserves the right to terminate and cancel user rights and accounts without prior notice if a user doesn't comply with the mentioned terms and conditions.
          `}
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 17.Site Policies, Modification, and Severability </Typography>
          We reserve the right to make changes to our website, policies, and these Terms and
          Conditions at any time. If any of these conditions shall be deemed invalid, void, or for
          any reason unenforceable, that condition shall be deemed severable and will not affect the
          validity and enforceability of any remaining conditions.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> 18. FORCE MAJEURE </Typography>
          Neither the COMPANY nor the User shall be liable for any inability to fulfill their
          commitments hereunder occasioned in the whole of, or in part, by force majeure, including,
          but not restricted to, strike, lock-out, fire, break–down, war, destruction of network,
          act, or regulation, or restriction of Government, inability to secure Government
          authorization or approval, or any other cause beyond their reasonable control.
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> Labs: </Typography>
          Terms and Conditions for STEM-Xpert Labs
          <br />
          <Typography py={2}>1. Introduction:</Typography>
          {`
          These Terms and Conditions ("Agreement") are entered into by and between STEM-Xpert ("Company," "we," or "us") and the school purchasing labs, equipment, and services ("purchaser," "you," or "school"). By purchasing and/or using our labs, kits, and services, you agree to be bound by the terms and conditions outlined below.
          `}
          <Typography py={2}>2. Labs Models Offered:</Typography>
          STEM-Xpert offers Innovation Space Lab, Robotics, and AIoT Lab. Tinkering Centre. Schools
          get access to free video-recorded sessions on signing the T & C. However, recorded
          sessions on specific topics are available for a nominal fee. Lab equipment for
          experiential learning by students can be purchased at a competitive price.
          <Typography py={2}>3. Pricing:</Typography>
          {`
      The prices for labs, kits, or services are as stated in STEM-Xpert's non-binding quotation or the current list price displayed on the website. Prices exclude delivery, packaging, carriage, insurance, and customs duties. STEM-Xpert reserves the right to adjust prices at any time.
          `}
          <Typography py={2}>4. Risk and Property:</Typography>
          Risk of damage or loss of kits passes on delivery. Title and property in the kits do not
          pass to the purchaser until STEM-Xpert receives full payment of the price and all other
          sums due.
          <Typography py={2}>5. Limitation of Liability:</Typography>
          The purchaser assumes sole responsibility for the use of the kits and indemnifies
          STEM-Xpert against any claims, costs, damages, losses, and liabilities arising from such
          use. STEM-Xpert is not liable for indirect, special, or consequential loss or damage.
          Liability is limited to the price of the contract.
          <Typography py={2}>6. Confidentiality:</Typography>
          The purchaser agrees to keep confidential information acquired as a consequence of the
          contract. Information may only be disclosed when bound by law or to professional advisers
          necessary for the performance of their services.
          <Typography py={2}>7. General:</Typography>- These conditions and associated documents
          constitute the entire agreement, superseding any prior written or oral agreements.
          <br />
          - If any provision is held to be illegal, void, or unenforceable, the remainder of the
          contract remains valid.
          <br />
          - Nothing in this contract excludes liability for fraud.
          <br />- Only parties involved in the contract have the right to enforce its terms
          <Typography py={2}>8. Fraud Cases:</Typography>
          All suspected fraud cases are reported to relevant authorities without exception.
          <Typography py={2}>9. Price Changes:</Typography>
          STEM-Xpert reserves the right to change pricing even after successful checkout, following
          communication with the customer.
          <Typography py={2}>10. Jurisdiction:</Typography>
          This agreement is subject to jurisdiction only
          <br />
          <Typography py={2}>
            {`
            By using STEM-Xpert's labs, kits, and services, you acknowledge that you have read, understood, and agree to these terms and conditions.
            `}
          </Typography>
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> STEM and Innovation Course: </Typography>
          Terms and Conditions for STEM and Innovation Course
          <br />
          <Typography py={2}>1. Introduction:</Typography>
          {`
       
These Terms and Conditions ("Agreement") are entered into by and between STEM-Xpert ("Company," "we," or "us") and the Participant/Attendee for registering for attending any courses under STEM & Innovation courses. By purchasing any of our courses and services, you agree to be bound by the terms and conditions outlined below.
          `}
          <Typography py={2}>2. Definitions:</Typography>
          - The Participant: The individual legally responsible for the training course payment.
          <br />- The Attendee: The individual undergoing the training outlined on the enrollment
          form.
          <Typography py={2}>3. Participant/Attendee Responsibilities:</Typography>
          {`
          - It is the participant/attendee's responsibility to inform the company of any change in circumstances (e.g., address, name, or contact details).
          `}
          <br />
          - Participants/attendees must keep the company informed of study progress, requirements,
          and progression through course levels
          <br />
          {`- All submitted work (exams and assignments) must be the participant/attendee's own work.`}
          <Typography py={2}>4. Course Structure and Participation:</Typography>
          - The STEM and Innovation Course consists of three parts: Learner (without kit), Maker
          Advanced Part 1 (with kit/without kit option), and Maker Advanced Part 2 (with kit/without
          kit option).
          <br />
          - Participants/attendees progress to the next level upon completing each preceding level.
          <br />- Active engagement in online courses and adherence to outlined guidelines are
          expected.
          <Typography py={2}>5. Course Schedule:</Typography>
          -Participants/attendees are required to attend as per the specified schedule.
          <br />- Any changes or cancellations should be communicated in advance.
          <Typography py={2}>6.Course Content:</Typography>
          - Course content for all study programs is provided solely for education and guidance.
          <br />- The course content is reviewed regularly and is subject to change without notice.
          <Typography py={2}>7. Course Fees:</Typography>
          - Course fees include all study material.
          <br />
          {`- The "Full Course" fee is a discounted rate for paying per the approved schedule.`}
          <br />- The study and support aspect of the fee is based on a specific timescale and work
          submission agreement.
          <Typography py={2}>8. Payment:</Typography>
          Payment shall be done as per the schedule provided for each course, in advance.
          <br />
          Payment link shall be available online
          <br />- Failure to comply may result in the participant/attendee being unable to access
          future sessions and being liable for all costs.
          <Typography py={2}>9. Cancellation and Refund Policy:</Typography>
          Cancellation within 7 days of payment becomes eligible for a refund after a 10% deduction
          of processing fee.
          <br />- No refund is available after the 7-day period has passed.
          <Typography py={2}>10. Program of Study:</Typography>- STEM-Xpert reserves the right to
          change the delivery mode, submission process, learning platforms, and materials as needed.
          <Typography py={2}>11.Study Materials:</Typography>- Study materials are protected by
          copyright, and the participant/attendee is granted a nonexclusive, non-transferable right
          for personal use.
          <Typography py={2}>12.Usage of Kits:</Typography>- Kit prices are not included in the
          price for the Learner level. For Maker Advanced Part 1 and Part 2, participants/attendees
          can choose the option with or without a kit.
          <Typography py={2}>13. Confidentiality:</Typography>
          {`- The participant/attendee shall keep all materials and confidential information concerning the company's business in strict confidence.`}
          <Typography py={2}>14.Limitation of Liability:</Typography>- The total liability arising
          in connection with this agreement is limited to the price paid by the
          participant/attendee.
          <Typography py={2}>15. Entire Agreement:</Typography>- These terms and conditions override
          any other communication, document, or representation made by STEM-Xpert.
          <Typography py={2}>16. Governing Law and Jurisdiction:</Typography>- This agreement is
          governed by the laws of the place, and any disputes shall be subject to the jurisdiction
          of the courts of Kozhikode.
          <Typography py={2}>
            By enrolling in our STEM and Innovation Course or other individual courses, the
            participant/attendee acknowledges and agrees to these terms and conditions. STEM-Xpert
            reserves the right to amend these terms, and any changes will be communicated to the
            participant/attendee.
          </Typography>
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> STEM Facilitator Course: </Typography>
          <Typography py={2}>1. Introduction:</Typography>
          {`
          These Terms and Conditions ("Agreement") are entered into by and between STEM-Xpert ("Company," "we," or "us")
          and the Participant/Attendee for register and attend the training on STEM Facilitator course. By purchasing
          our course and services, you agree to be bound by the terms and conditions outlined below.
          `}
          <Typography py={2}>2. Definitions:</Typography>
          - The Participant: The individual legally responsible for the training course payment.
          <br />- The Attendee: The individual undergoing the training outlined on the enrollment
          form.
          <Typography py={2}>3. Participant/Attendee Responsibilities:</Typography>
          {`- It is the participant/attendee's responsibility to inform the company of any change in circumstances (e.g.,
          address, name, or contact details).`}
          <br />
          {`- Participants/attendees must keep the company informed of study progress, requirements, and progression
          through course levels.`}
          <br />
          {`- All submitted work (exam and assignments) must be the participant/attendee's own work.`}
          <Typography py={2}>4. Course Structure and Participation:</Typography>
          - The STEM Facilitator Course consists of two levels- Basic and Advanced.
          <br />
          - Participants/attendees progress to the next level upon completing each preceding level.
          <br />- Active engagement in online courses and adherence to outlined guidelines are
          expected.
          <Typography py={2}>5. Course Schedule:</Typography>
          -Participants/attendees are required to attend as per the specified schedule.
          <br />- Any changes or cancellations should be communicated in advance
          <Typography py={2}>6.Course Content:</Typography>
          - Course content for all study programs are provided solely for education and guidance.
          <br />- The course content is reviewed regularly and is subject to change without notice.
          <Typography py={2}>7. Course Fees:</Typography>
          - Course fees include all study material.
          <br />
          {`- The "Full Course" fee is a discounted rate for paying per the approved schedule.`}
          <br />- The study and support aspect of the fee are based on a specific timescale and work
          submission agreement.
          <Typography py={2}>8. Payment:</Typography>
          Payment shall be done as per the schedule provided for each course, in advance.
          <br />
          Payment link shall be available online.
          <br />- Failure to comply may result in the participant/attendee unable to access the
          future sessions and being liable for all costs.
          <Typography py={2}>9. Cancellation and Refund Policy:</Typography>
          Cancellation within 7 days of payment becomes eligible for refund after 10% deduction of
          processing fee.
          <br />- No refund is available after 7 days period has passed.
          <Typography py={2}>10. Program of Study:</Typography>- STEM-Xpert reserves the right to
          change the delivery mode, submission process, learning platforms, and materials as needed.
          <Typography py={2}>11.Study Materials:</Typography>- Study materials are protected by
          copyright, and the participant/attendee is granted a nonexclusive, non-transferable right
          for personal use.
          <Typography py={2}>12.Usage of Kits:</Typography>
          Participants/attendees can choose the option with or without a kit as per the requirement
          <Typography py={2}>13. Confidentiality:</Typography>
          {`- The participant/attendee shall keep all materials and
          confidential information concerning the company's business in strict confidence.`}
          <Typography py={2}>14.Limitation of Liability:</Typography>- The total liability arising
          in connection with this agreement is limited to the price paid by the
          participant/attendee.
          <Typography py={2}>15. Entire Agreement:</Typography>- These terms and conditions override
          any other communication, document, or representation made by STEM-Xpert.
          <Typography py={2}>16. Governing Law and Jurisdiction:</Typography>- This agreement is
          governed by the laws of the place, and any disputes shall be subject to the jurisdiction
          of the courts of Kozhikode
          <Typography py={2}>
            By enrolling in our STEM Facilitator Course or other individual courses, the
            participant/attendee acknowledges and agrees to these terms and conditions. STEM-Xpert
            reserves the right to amend these terms, and any changes will be communicated to the
            participant/attendee.
          </Typography>
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5">
            {' '}
            Emerging Technologies for Colleges course & Workshops:{' '}
          </Typography>
          <Typography py={2}>1. Introduction:</Typography>
          {`
          These Terms and Conditions ("Agreement") are entered into by and between STEM-Xpert ("Company," "we," or "us")
          and the Participant/Attendee for register and attend the training on Emerging Technologies for Colleges
          course. By purchasing our course and services, you agree to be bound by the terms and conditions outlined
          below
          `}
          <Typography py={2}>2. Definitions:</Typography>
          - The Participant: The individual legally responsible for the training course payment.
          <br />- The Attendee: The individual undergoing the training outlined on the enrollment
          form
          <Typography py={2}>3. Participant/Attendee Responsibilities:</Typography>
          {`- It is the participant/attendee's responsibility to inform the company of any change in circumstances (e.g.,
          address, name, or contact details).`}
          <br />
          - Participants/attendees must keep the company informed of study progress, requirements,
          and progression through course levels.
          <br />
          {`- All submitted work (exam and assignments) must be the participant/attendee's own work.`}
          <Typography py={2}>4. Course Structure and Participation:</Typography>
          - The Emerging Technologies for Colleges Course is structured to enable college senior
          students and graduates to upskill in emerging technologies through theory and practical
          applications.
          <br />- Active engagement in online courses and adherence to outlined guidelines are
          expected.
          <Typography py={2}>5. Course Schedule:</Typography>
          Participants/attendees are required to attend as per the specified schedule.
          <br />- Any changes or cancellations should be communicated in advance.
          <Typography py={2}>6.Course Content:</Typography>
          - Course content for all study programs are provided solely for education and guidance.
          <br />- The course content is reviewed regularly and is subject to change without notice.
          <Typography py={2}>7. Course Fees:</Typography>
          - Course fees include all study material.
          <br />
          {`- The "Full Course" fee is a discounted rate for paying per the approved schedule.`}
          <br />- The study and support aspect of the fee are based on a specific timescale and work
          submission agreement.
          <Typography py={2}>8. Payment:</Typography>
          Payment shall be done as per the schedule provided for each course, in advance.
          <br />
          Payment link shall be available online.
          <br />- Failure to comply may result in the participant/attendee unable to access the
          future sessions and being liable for all costs.
          <Typography py={2}>9. Cancellation and Refund Policy:</Typography>
          Cancellation within 7 days of payment becomes eligible for refund after 10% deduction of
          processing fee.
          <br />- No refund is available after 7 days period has passed.
          <Typography py={2}>10. Program of Study:</Typography>- STEM-Xpert reserves the right to
          change the delivery mode, submission process, learning platforms, and materials as needed.
          <Typography py={2}>11.Study Materials:</Typography>- Study materials are protected by
          copyright, and the participant/attendee is granted a nonexclusive, non-transferable right
          for personal use.
          <Typography py={2}>12.Usage of Kits:</Typography>
          Participants/attendees can choose the option with or without a kit as per the requirement
          <Typography py={2}>13. Confidentiality:</Typography>
          {`- The participant/attendee shall keep all materials and
          confidential information concerning the company's business in strict confidence.`}
          <Typography py={2}>14.Limitation of Liability:</Typography>- The total liability arising
          in connection with this agreement is limited to the price paid by the
          participant/attendee.
          <Typography py={2}>15. Entire Agreement:</Typography>- These terms and conditions override
          any other communication, document, or representation made by STEM-Xpert.
          <Typography py={2}>16. Governing Law and Jurisdiction:</Typography>- This agreement is
          governed by the laws of the place, and any disputes shall be subject to the jurisdiction
          of the courts of Kozhikode
          <Typography py={2}>
            By enrolling in our STEM Facilitator Course or other individual courses, the
            participant/attendee acknowledges and agrees to these terms and conditions. STEM-Xpert
            reserves the right to amend these terms, and any changes will be communicated to the
            participant/attendee.
          </Typography>
        </Typography>
        <br />

        <Typography>
          <Typography variant="h5"> DIY STEM and Robtics Kit: </Typography>
          <Typography py={1} color="gray">
            Please read the fine prints of each product before buying it, it provides all the
            details about the services or the product you purchase{' '}
          </Typography>
          <Typography py={2}>1. Material Handling:</Typography>
          In the case of physical kits and workshop materials provided by STEM-Xpert, users are
          responsible for handling them according to the provided instructions. Any damage caused by
          incorrect handling or improper use may not be covered under warranty.
          <Typography py={2}>2. Risk and Property:</Typography>
          The risk of damage to or loss of the products passes upon delivery or if a buyer fails to
          take delivery without good cause at the time when the delivery is tendered. Title to and
          the property in the product do not pass to a buyer until STEM-Xpert has received full
          payment of the price and all other sums due.
          <Typography py={2}>3. Guarantee/Warranty:</Typography>
          {`All STEM-Xpert kits are covered by different warranty periods, as indicated on the relevant pages. This
          warranty confirms that the product is free from defects in materials and workmanship. For products like
          electronic parts, the warranty is applicable only when the full batch of items is found to be defective. If
          defects arise during this period, STEM-Xpert will, at its option, repair or replace the purchased goods or
          refund the money. STEM-Xpert's liability under this warranty is subject to confirmation that the material is
          faulty due to poor materials or workmanship. STEM-Xpert will not be responsible for any damage caused by
          incorrect connection or improper use of the material. Furthermore, STEM-Xpert will not be liable for any
          damage to third-party or attached equipment resulting from the use of these products.`}
          <br />
          {`Decisions regarding warranty acceptance will be made by STEM-Xpert and must be accepted by the customer.
          STEM-Xpert may or may not charge for repairs and return shipping if the item's warranty is void due to any
          reason.`}
          <Typography py={2}>4. Terms and Conditions of Sale:</Typography>
          The following conditions apply to all transactions for the sale of kits and materials by
          STEMXpert to a purchaser of services.
          <br />
          - These conditions shall apply to all orders placed with STEM-Xpert by the purchaser.
          <br />
          {`- Acceptance of any order by STEM-Xpert is conditional upon the purchaser's acceptance of these conditions,
          which override all other inconsistent terms and conditions.`}
          <br />- Acceptance of a purchase order by STEM-Xpert does not imply an obligation to
          accept subsequent purchase orders for goods and/or services.x
          <Typography py={2}>5. Price:</Typography>
          {`The price of the kits or services is as stated in STEM-Xpert's non-binding quotation or the current list price
          displayed on the website. Prices are exclusive of delivery, packaging, carriage, insurance, and custom duties.
          STEM-Xpert reserves the right to adjust prices at any time.`}
          <br />
          For all international users, pricing shall be on Ex-works basis and in INR.
          <Typography py={2}>6. Payment:</Typography>
          Payment is due prior to shipment of the kits or performance of services.
          <br />
          Payment shall be done through bank transfer and shall be as per schedule provided.
          Nonpayment of full amount or the scheduled amount in time shall result in cancellation of
          order and shall become liable for the total cost of product.
          <Typography py={2}>7. Delivery:</Typography>
          {`Delivery charges apply unless kits are picked up directly from STEM-Xpert's premises. Delivery occurs when the
          kits are handed over to the purchaser, carrier company, or delivery representative. Delivery dates are
          approximate, and STEM-Xpert will not be liable for any delay in the delivery of the kits.`}
          <br />
          If the purchaser fails to take delivery of the kits or provide adequate delivery
          instructions and it returns to STEM-Xpert, STEM-Xpert reserves the right to decide whether
          to resend the parcel.
          <br />
          Any claim by the purchaser for non or incorrect delivery must be advised to STEM-Xpert
          within 3 days of receipt of the kits.
          <Typography py={2}>8. Risk and Property:</Typography>
          Risk of damage to or loss of the kits passes on delivery or, if the purchaser fails to
          take delivery without good cause, at the time when delivery is tendered.
          <br />
          Title to and property in the kits do not pass to the purchaser until STEM-Xpert has
          received full payment of the price and all other sums due.
          <Typography py={2}>9. Limitation of Liability:</Typography>
          The purchaser shall accept sole responsibility for and STEM-Xpert shall not be liable for
          any use of the kits by the purchaser, its agents, and employees. The purchaser shall hold
          STEMXpert harmless and fully indemnified against any claims, costs, damages, loss, and
          liabilities arising out of such use.
          <br />
          {`STEM-Xpert is not liable to the purchaser for any indirect, special, or consequential loss or damage.
          STEM-Xpert's liability in connection with the contract is limited to the price.`}
          <Typography py={2}>10. Confidentiality:</Typography>
          The purchaser will keep confidential information acquired in consequence of the contract,
          including business correspondence and technical literature. Information may be disclosed
          only when bound by law or to professional advisers where necessary for the performance of
          their services.
          <Typography py={2}>11. Entire Agreement:</Typography>- These terms and conditions override
          any other communication, document, or representation made by STEM-Xpert.
          <Typography py={2}>12. Governing Law and Jurisdiction:</Typography>- This agreement is
          governed by the laws of the place, and any disputes shall be subject to the jurisdiction
          of the courts of Kozhikode.
          <Typography py={2} variant="h6">
            13. General:
          </Typography>
          These conditions and the documents referred to in them set out the entire agreement
          between the parties, superseding any prior written or oral agreement. If any provision is
          held to be illegal, void, or unenforceable, the legality, validity, and enforceability of
          the remainder of the contract are not affected. Nothing in this contract excludes
          liability for fraud.
          <br />
          No person, other than the parties, has a right to enforce any term or condition of the
          contract. All suspected fraud cases are handed directly to the relevant authorities
          without exception.
        </Typography>
        <br />
      </Container>
    </Stack>
  );
}

export default Terms;
