import { createSlice } from '@reduxjs/toolkit';

import {
  getAllRegistrations,
  downloadRegistrations,
  getRegistrationSingleview,
  getSubpartnerRegistrations,
  getRegistrationPaymentCount,
  getSubPartnerRegistrationPaymentCount,
} from '../api/registrations';

export const registrationsSlice = createSlice({
  name: 'registrationsSlice',
  initialState: {
    loading: false,
    error: {},
    allRegistrations: [],
    subpartnerRegistrations: [],
    singleVeiwData: {},
    downloadRegistrations: {},
    paymentCount: {},
    subPartnerPaymentCount: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all lists into Admin

    [getAllRegistrations.pending]: (state) => {
      state.loading = true;
    },
    [getAllRegistrations.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRegistrations = action.payload;
    },
    [getAllRegistrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getSubpartnerRegistrations.pending]: (state) => {
      state.loading = true;
    },
    [getSubpartnerRegistrations.fulfilled]: (state, action) => {
      state.loading = false;
      state.subpartnerRegistrations = action.payload;
    },
    [getSubpartnerRegistrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // single view admin

    [getRegistrationSingleview.pending]: (state) => {
      state.loading = true;
    },
    [getRegistrationSingleview.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleVeiwData = action.payload;
    },
    [getRegistrationSingleview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getRegistrationPaymentCount.pending]: (state) => {
      state.loading = true;
    },
    [getRegistrationPaymentCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentCount = action.payload;
    },
    [getRegistrationPaymentCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getSubPartnerRegistrationPaymentCount.pending]: (state) => {
      state.loading = true;
    },
    [getSubPartnerRegistrationPaymentCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.subPartnerPaymentCount = action.payload;
    },
    [getSubPartnerRegistrationPaymentCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [downloadRegistrations.pending]: (state) => {
      state.loading = true;
    },
    [downloadRegistrations.fulfilled]: (state, action) => {
      state.loading = false;
      state.downloadRegistrations = action.payload;
    },
    [downloadRegistrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = registrationsSlice.actions;

export default registrationsSlice.reducer;
