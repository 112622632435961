import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { _jobs } from 'src/_mock';

import AcdemyRegistrationForm from './academy-registration-form';
import AcademyRegistrationSuccessDialog from './academy-success-dialog';

export default function AcademyRegistrationView({ id }) {
  const router = useRouter();

  const currentJob = _jobs.find((job) => job.id === id);

  const openDialogBool = useBoolean();

  const openDialogFun = () => openDialogBool.onTrue();

  const { otpData } = useSelector((state) => ({
    otpData: state.auth.otpData,
  }));

  const email = otpData?.email;
  const isNewUser = otpData?.response?.is_new_user;

  const closeDialogFun = () => {
    openDialogBool.onFalse();
    router.push(paths.public.admin_login);
  };

  return (
    <>
      <AcademyRegistrationSuccessDialog open={openDialogBool.value} onClose={closeDialogFun} />
      <AcdemyRegistrationForm
        currentJob={currentJob}
        openDialog={openDialogFun}
        email={email}
        isNewUser={isNewUser}
      />
    </>
  );
}

AcademyRegistrationView.propTypes = {
  id: PropTypes.string,
};
