import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createStudent,
  deleteStudent,
  getAllStudents,
  getStudentDetails,
  updateStudentDetails,
} from '../api/student';

export const studentSlice = createSlice({
  name: 'student',
  initialState: {
    loading: false,
    error: {},
    // students
    students: {},
    studentDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create students details
    // [createStudent.pending]: (state) => {
    //   state.loading = true;
    // },
    // [createStudent.fulfilled]: (state, action) => {
    //   // const jsonState = covertToJSON(state)?.students;
    //   // const newStudentList = jsonState?.students;
    //   // const modifiedStudentList = {
    //   //   ...jsonState,
    //   //   students: Array.isArray(newStudentList)
    //   //     ? [action.payload, ...newStudentList]
    //   //     : [action.payload],
    //   // };
    //   state.loading = false;
    //   state.students = action.payload;
    //   // state.studentDetails = action.payload;
    //   // state.students = modifiedStudentList;
    //   state.error = {};
    // },
    // [createStudent.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
    [createStudent.pending]: (state) => {
      state.loading = true;
    },
    [createStudent.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.studentDetails = action.payload;
    },
    [createStudent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all students
    [getAllStudents.pending]: (state) => {
      state.loading = true;
    },
    [getAllStudents.fulfilled]: (state, action) => {
      state.loading = false;
      state.students = action.payload;
      state.error = {};
    },
    [getAllStudents.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single students details
    [getStudentDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStudentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentDetails = action.payload;
      state.error = {};
    },
    [getStudentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update students details
    [updateStudentDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateStudentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.studentDetails = action.payload;
      state.error = {};
    },
    [updateStudentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete students
    [deleteStudent.pending]: (state) => {
      state.loading = true;
    },

    [deleteStudent.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.students;
      console.log('JSON State :', jsonState);
      const modifiedStudentList = {
        ...jsonState,
        students: jsonState.students?.filter((students) => students._id !== action.payload),
      };
      state.loading = false;
      state.students = modifiedStudentList;
      state.error = {};
    },
    [deleteStudent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = studentSlice.actions;

export default studentSlice.reducer;
