import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Grid,
  Link,
  Stack,
  Button,
  Container,
  Typography,
  CardContent,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useDevice } from '../hooks/useResponsive';

function CountryPage({ featuresBoxes }) {
  const isMobile = useDevice()?.isMobile;
  return (
    <>
      <Stack
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        px={isMobile && 1}
        pb={isMobile ? 1 : 4}
      >
        <img
          src="/assets/imagesWeb/partner/coutry.jpg"
          alt=""
          style={{
            objectFit: isMobile ? 'contain' : 'fill',
            height: isMobile ? 'auto' : '70vh',
            width: isMobile ? '100%' : '75%',
            borderRadius: 20,
          }}
        />
      </Stack>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            className="img-headline"
            style={{
              backgroundColor: '#212121',
              border: 'none',
              height: isMobile ? 'auto' : '620px',
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
              width: isMobile ? 'auto' : 1100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={2} padding={0}>
                    <Stack display="flex" alignItems="">
                      <img
                        src="/assets/imagesWeb/partner/us.jpg"
                        alt="mainimg"
                        style={{
                          width: isMobile ? '100%' : '90%',
                          height: '100%',
                          objectFit: 'contain',
                          borderRadius: 10,
                        }}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                {/* Add your inputs here */}
                <Stack className="online-offline-course-banner" sx={{ pt: 3 }}>
                  <Typography className="lab-experience-box-title"> Partner </Typography>
                  <Stack>
                    <Typography
                      py={2}
                      className="affiliate-box-title-description"
                      textAlign="justify"
                      pr={1}
                    >
                      Exciting opportunities await as a Partner with STEM-Xpert, offering the chance
                      to influence the STEM education landscape in various capacities and achieve
                      substantial income. Partnering with STEM-Xpert provides access to cutting-edge
                      products, services, comprehensive training, ongoing support, a proven business
                      model, and a supportive network of franchisees.
                    </Typography>
                    <Typography
                      pb={2}
                      className="affiliate-box-title-description"
                      textAlign="justify"
                      pr={1}
                    >
                      {`As a Partner, whether you're operating at the country, state, regional, or district level, you will play a pivotal role in developing and executing marketing plans aligned with the Company's vision and goals. Collaborating closely with regional teams, you'll identify and foster strategic alliances with organizations, drive impactful marketing campaigns, and closely monitor key performance metrics. Your efforts will contribute to blueprinting highly successful ventures in STEM education.`}
                    </Typography>
                    <Typography
                      className="affiliate-box-title-description"
                      textAlign="justify"
                      pr={1}
                    >
                      {`Furthermore, as a Partner, you'll have the gratifying opportunity to mentor affiliate marketers and partners, facilitating their success while also enhancing your own earning potential. Join us as we revolutionize STEM education together.`}
                    </Typography>
                  </Stack>
                </Stack>
                {/* Submit Button */}
                <Stack display="flex" alignItems={isMobile ? 'center' : ''} sx={{ pt: 3 }}>
                  <Link
                    href={`${paths.public.partner_regstration}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      className="px-5"
                      sx={{ width: 242, backgroundColor: '#05286C', boxShadow: 'none' }}
                    >
                      Become Partner
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Stack>
      <Stack p={isMobile ? 0 : 5}>
        <Stack className="feature" p={isMobile && 3} py={!isMobile && 3}>
          <Typography className="feature-title">Features</Typography>
        </Stack>
        <Container>
          <Grid container spacing={isMobile ? '0' : 5} paddingX={isMobile ? 0 : 5}>
            {featuresBoxes?.map((i, k) => (
              <Grid item md={6} key={k} py={isMobile && 1}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={isMobile ? 'feature-partner-boxes-mobile' : 'feature-partner-boxes'}
                  padding={3}
                >
                  <img
                    alt={i?.title}
                    src={i?.img}
                    style={{
                      height: isMobile ? ' 100%' : '70px',
                      width: isMobile ? '' : '60px',
                      objectFit: 'contain',
                      padding: isMobile && 5,
                    }}
                  />
                  <Stack
                    className={isMobile ? 'feature-lab-box-title-mobile' : 'feature-lab-box-title'}
                  >
                    {i?.title}
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Stack>
    </>
  );
}

export default CountryPage;

CountryPage.propTypes = {
  featuresBoxes: PropTypes.array,
};
