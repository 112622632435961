import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Box, TextField, Autocomplete } from '@mui/material';

const OspSelectAutocomplete = ({
  name,
  control,
  options,
  defaultValue,
  label,
  required,
  handleTextFieldChange,
  multiple,
  disabled,
  onChange,
}) => {
  // Helper function to get the correct defaultValue
  const getDefaultValue = () => {
    if (multiple) {
      return Array.isArray(defaultValue) ? defaultValue : [];
    }
    return defaultValue || null;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultValue()}
      render={({ field }) => {
        const getValue = () => {
          if (multiple) {
            return Array.isArray(field.value) ? field.value : [];
          }
          return field.value || null;
        };
        return (
          <Autocomplete
            {...field}
            multiple={multiple || false}
            id={`${name}-select-demo`}
            sx={{ width: '100%' }}
            options={options || []}
            className="auto-tag-input-2"
            autoHighlight
            size="small"
            disabled={disabled || false}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, newValue) => {
              field.onChange(newValue);
              if (onChange) onChange(event, newValue);
            }}
            onInputChange={(event, newInputValue) => {
              if (handleTextFieldChange) handleTextFieldChange(newInputValue);
            }}
            value={getValue()}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                size="small"
                required={required}
                // onChange={(e) => {
                //   field.onChange(e);
                //   if (handleTextFieldChange) handleTextFieldChange(e);
                // }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

OspSelectAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  required: PropTypes.bool,
  handleTextFieldChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OspSelectAutocomplete;
