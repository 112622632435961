import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Typography, DialogContent } from '@mui/material';

// components

ImageDeleteError.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleDeleteFromLocal: PropTypes.any,
};

export default function ImageDeleteError({ open, setOpen, handleDeleteFromLocal }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open?.status}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent sx={{ minWidth: '550px', p: 2 }}>
          <Typography variant="subtitle2" mb={2}>
            Image resource not found
          </Typography>
          <Typography variant="caption">
            The image file was not found on the server. It may have been deleted or removed due to
            an error. Please delete it manually and save the changes.
          </Typography>
          <Stack spacing={2} sx={{ px: 0 }}>
            <Stack sx={{ alignItems: 'flex-end' }}>
              <Stack sx={{ marginBottom: '1rem', flexDirection: 'row' }} alignItems="center">
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="button"
                  className="cancelButtonModal"
                  onClick={handleClose}
                  sx={{ mr: 1.5 }}
                >
                  Close{' '}
                </LoadingButton>
                <LoadingButton
                  type="button"
                  variant="contained"
                  size="small"
                  className="submitButtonModal"
                  onClick={handleDeleteFromLocal}
                >
                  Delete Manually
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
