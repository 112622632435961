import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createContent = createAsyncThunk(
  'content/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/content/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Content created successfully' }));

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllContents = createAsyncThunk(
  'session/list',
  async ({ page, search, limit, courseId, school, dispatch }) => {
    try {
      const response = await get(
        `/content/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&course=${courseId ?? ''}&school=${school || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getContentDetails = createAsyncThunk(
  'course-content/single',
  async ({ contentId, dispatch }) => {
    try {
      const response = await get(`/content/admin/${contentId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateContentDetails = createAsyncThunk(
  'content/update',
  async ({ state, ContentId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/content/admin/${ContentId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(activeSnack({ type: 'success', message: 'Content updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteContent = createAsyncThunk('content/delete', async ({ ContentId, dispatch }) => {
  try {
    const response = await del(`/content/admin/${ContentId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Content deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
// public
export const getAcademyContentDetails = createAsyncThunk(
  'academy/content/single',
  async ({ contentId, dispatch }) => {
    try {
      const response = await get(`/content/${contentId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAcademyContentsByCourse = createAsyncThunk(
  'academy/course/content',
  async ({ page, search, limit, courseId, dispatch }) => {
    try {
      const response = await get(
        `/content/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&course=${
          courseId || ''
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
