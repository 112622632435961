import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardMedia } from '@mui/material';

// ----------------------------------------------------------------------

export default function ClassAndDivision({ title, total, icon, sx, ...other }) {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 5,
        pl: 3,
        ...sx,
      }}
      {...other}
    >
      <Box>
        <Box sx={{ mb: 1, color: 'text.secondary', typography: 'subtitle2' }}>{title}</Box>
        <Box sx={{ typography: 'h3' }}>{total}</Box>
      </Box>
      {/* 
      <Box
        sx={{
          lineHeight: 0,
          display:'flex',
          alignItems:'center'
        }}
      > */}
      <CardMedia component="img" alt={icon} src={icon} sx={{ height: '100px', width: '100px' }} />
      {/* </Box> */}
    </Card>
  );
}

ClassAndDivision.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};
