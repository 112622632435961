import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSchoolUser,
  getAllSchoolUser,
  deleteSchoolUser,
  getSchoolUserDetails,
  getSchoolUserProfile,
  updateSchoolUserDetails,
  updateSchoolUserProfile,
} from '../api/schoolUser';

export const schoolUserSlice = createSlice({
  name: 'sale',
  initialState: {
    loading: false,
    error: {},
    // sale
    schoolUser: {},
    staffDropList: [],
    schoolUserDetails: {},
    schoolUserProfile: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create schoolUser details
    [createSchoolUser.pending]: (state) => {
      state.loading = true;
    },
    [createSchoolUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schoolUser;
      const newSchoolList = jsonState?.schoolUsers;
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolUsers: Array.isArray(newSchoolList)
          ? [action.payload]
          : [action.payload, ...newSchoolList],
      };
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.schoolUser = modifiedSchoolUserList;
      state.error = {};
    },
    [createSchoolUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all schoolUser
    [getAllSchoolUser.pending]: (state) => {
      state.loading = true;
    },
    [getAllSchoolUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUser = action.payload;
      state.error = {};
    },
    [getAllSchoolUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single schoolUser details
    [getSchoolUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [getSchoolUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update schoolUser details
    [updateSchoolUserDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateSchoolUserDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.schoolUserDetails = action.payload;
    //   state.error = {};
    // },
    [updateSchoolUserDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state).schoolUser;
      console.log(jsonState);
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolusers: jsonState?.schoolusers?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.exams = modifiedSchoolUserList;
      state.schoolUserDetails = action.payload;
      state.schoolUser = modifiedSchoolUserList;
      state.error = {};
    },
    [updateSchoolUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete schoolUser
    [deleteSchoolUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchoolUser.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.schoolUser;
      console.log('JSON State :', jsonState);

      const modifiedSchoolUserList = {
        ...jsonState,
        schoolusers: jsonState.schoolusers?.filter(
          (schoolusers) => schoolusers._id !== action.payload
        ),
      };

      state.loading = false;
      state.schoolUser = modifiedSchoolUserList;
      state.error = {};
    },
    [deleteSchoolUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get schoolUser Profile
    [getSchoolUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserProfile = action.payload;
      state.error = {};
    },
    [getSchoolUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateSchoolUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateSchoolUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserProfile = action.payload;
      state.error = {};
    },
    [updateSchoolUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = schoolUserSlice.actions;

export default schoolUserSlice.reducer;
