import { createAsyncThunk } from '@reduxjs/toolkit';

// import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createStudent = createAsyncThunk(
  'student/create',
  async ({ state, setOpenStudentSuccessDialog, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/student/admin/new`;
      const response = await post(URL, state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Student created Successfully' }));
        setOpenStudentSuccessDialog(true);
        handleClose();
        handleClear();

        dispatch(
          getAllStudents({
            page: 1,
            search: '',
            limit: 10,
            school: '',
            student_type: '',
            dispatch,
          })
        );
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      console.log(error);
      throw error?.response?.data?.message;
    }
  }
);

export const getAllStudents = createAsyncThunk(
  'student/list',
  async ({ page, search, date, limit, school, student_type, dispatch }) => {
    try {
      const URL_ALL = `/student/admin/all/?page=${page ?? 1}&search=${search ?? ''}&limit=${
        limit || 5
      }&school=${school || ''}&student_type=${student_type || ''}&dateFrom=${
        date?.from || ''
      }&dateTo=${date?.to || ''}`;
      //
      const response = await get(URL_ALL);
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getStudentDetails = createAsyncThunk(
  'student/single',
  async ({ StudentId, dispatch }) => {
    try {
      const response = await get(`/student/admin/${StudentId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateStudentDetails = createAsyncThunk(
  'student/update',
  async ({ state, StudentId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/student/admin/${StudentId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Student updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteStudent = createAsyncThunk('student/delete', async ({ StudentId, dispatch }) => {
  try {
    const response = await del(`/student/admin/${StudentId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Student deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
