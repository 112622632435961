import PropType from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import { alpha, useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { isAfter } from 'src/utils/format-time';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllSchoolClass } from 'src/server/api/schoolClass';
import { getAllSchoolCourse } from 'src/server/api/schoolCourse';
import { getAllSchoolDivision } from 'src/server/api/schoolDivision';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import CourseTableRow from 'src/sections/schoolCourse/course-table-row';
import CourseTableToolbar from 'src/sections/schoolCourse/course-table-toolbar';
import ClassAndDivisionFilter from 'src/sections/schoolCourse/class-division-filter';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CourseListView({ minimal, partnerId }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const { role } = useGetRoles();

  const { loading, schoolClassList, schoolCourse, schoolDivision } = useSelector((state) => ({
    loading: state.schoolCourse.loading,
    schoolClassList: state.schoolClass.schoolClasses,
    schoolCourse: state.schoolCourse.schoolCourse,
    schoolDivision: state.schoolDivision.schoolDivisions,
  }));

  const [type, setType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');

  const [searchText, setSearchText] = useState();
  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });
  const [sortValue, setSortValue] = useState('all');

  const [tableData, setTableData] = useState([]);
  const totalPage = schoolCourse?.total;

  console.log('sorttab', sortValue);

  const TABLE_HEAD = [
    { id: 'title', label: 'Course Name' },
    { id: 'class_name', label: 'Class ' },
    { id: 'division_name', label: 'Division' },
    { id: 'percentage', label: 'Progress' },
    { id: 'status', label: 'Status' },
  ];
  if (role === 'super_admin') {
    TABLE_HEAD.push({ id: 'partner', label: 'Partner' }, { id: '' });
  }
  if (sortValue === 'completed') {
    TABLE_HEAD.push({ id: 'completedDate', label: 'Completed Date' }, { id: '' });
  }
  if (sortValue !== 'completed') {
    TABLE_HEAD.push({ id: '' });
  }
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText || '',
      limit: table.rowsPerPage,
      schoolClass: selectedClass,
      schoolClassDivision: selectedDivision,
      sort: sortValue,
      dispatch,
    };
    dispatch(getAllSchoolCourse(credentials));
  }, [
    dispatch,
    searchText,
    selectedClass,
    selectedDivision,
    sortValue,
    table.page,
    table.rowsPerPage,
  ]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      dispatch,
      // limit: 10,
    };
    dispatch(getAllSchoolClass(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      schoolClass: selectedClass,
      dispatch,
    };
    dispatch(getAllSchoolDivision(credentials));
  }, [dispatch, selectedClass]);

  useEffect(() => {
    setTableData(schoolCourse?.schoolCourses);
  }, [schoolCourse]);

  const dateError = isAfter(sortDate.from, sortDate.to);

  console.log(dateError);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  console.log(schoolCourse);
  const TABS = [
    {
      value: 'all',
      label: 'All',
      color: 'default',
      count: schoolCourse?.schoolCourseCounts?.allCount || 0,
    },
    {
      value: 'NOT_STARTED',
      label: 'Not Started',
      color: 'warning',
      count: schoolCourse?.schoolCourseCounts?.notStartedCount || 0,
    },
    {
      value: 'ON_GOING',
      label: 'Ongoing',
      color: 'info',
      count: schoolCourse?.schoolCourseCounts?.onGoingCount || 0,
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
      color: 'success',
      count: schoolCourse?.schoolCourseCounts?.completedCount || 0,
    },
    // {
    //   value: 'rejected',
    //   label: 'Rejected',
    //   color: 'error',
    //   count: 0,
    // },
  ];

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);

      enqueueSnackbar('Delete success!');

      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const filterData = {
    schoolClassList,
    selectedClass,
    setSelectedClass,
    schoolDivision,
    selectedDivision,
    setSelectedDivision,
  };

  return (
    <>
      {/* <Container maxWidth={settings.themeStretch ? false : 'lg'}> */}
      <Container maxWidth="xl">
        <MetaHelmet title="School Course" />
        {!minimal && (
          <CustomBreadcrumbs
            heading="My Course"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.root,
              },

              {
                name: 'Courses',
              },
            ]}
            action=""
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )}
        <ClassAndDivisionFilter filterData={filterData} />
        <Typography fontSize={18} fontWeight={700} py="24px">
          Courses
        </Typography>
        <Card>
          <Tabs
            value={sortValue}
            onChange={(e, newValue) => setSortValue(newValue)}
            sx={{
              px: 2.5,
              boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS?.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                iconPosition="end"
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === sortValue) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <CourseTableToolbar
            filters={sortValue}
            isSearchAvailable
            type={type}
            setType={setType}
            onFilters={(e, searchTextValue) => setSearchText(searchTextValue)}
            dateError={dateError}
            dateFilter={{ sate: sortDate, setSate: setSortDate }}
            // add other filters dropdown
          />

          {/* <CourseTableFiltersResult
            filters={[
              {
                title: 'Status',
                value: sort,
              },
              {
                title: 'Search',
                value: searchText,
              },
            ]}
            // onFilters={handleFilters}
            // onResetFilters={handleResetFilters}
            results={tableData?.length}
            sx={{ p: 2.5, pt: 0 }}
          /> */}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  // onSelectAllRows={(checked) =>
                  //   table.onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {(tableData || []).map((row) => (
                    <CourseTableRow
                      key={row.id}
                      row={row}
                      page={table.page + 1}
                      rowsPerPage={table.rowsPerPage || 5}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      sort={sortValue}
                    />
                  ))}
                </TableBody>
                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                /> */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

CourseListView.propTypes = {
  minimal: PropType.bool,
  partnerId: PropType.string,
};
