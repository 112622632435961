import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getLmsExamDetails } from 'src/server/api/lmsExam';
import { registerStudentExam } from 'src/server/api/studentExam';

import TextMaxLine from 'src/components/text-max-line';

function AcademyExamRegister() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { examDetails, loading, studentExamsLoading } = useSelector((state) => ({
    loading: state.lmsExam.loading,
    examDetails: state.lmsExam.examDetails,
    studentExamsLoading: state.studentExam.loading,
    settingsDetails: state.settings.settingsDetails,
  }));

  const handleRegisterExam = () => {
    const credentials = {
      state: {
        exam: examDetails?._id,
      },
      enqueueSnackbar,
      navigate,
    };
    dispatch(registerStudentExam(credentials));
  };

  useEffect(() => {
    const credentials = {
      examId: params?.id,
      dispatch,
    };
    dispatch(getLmsExamDetails(credentials));
  }, [dispatch, params?.id]);

  const renderContentBeforeExam = (
    <Stack component={Card} spacing={3} sx={{ p: 3 }}>
      <Typography variant="body2">
        Welcome to the exam session! Before you begin, it is important to familiarize yourself with
        the terms and conditions of the exam. Understanding these terms will help ensure a smooth
        and fair examination process. Each set of questions in the exam will have a time limit of 3
        minutes. This means that you will have 3 minutes to review and answer each set of questions.
        Each set will consist of 5 questions. you will need to read and respond to all 5 questions
        within the allotted 3-minute time frame. During the exam, you will be required to select one
        answer for each question within the set. Make sure to carefully read each question and
        select the most appropriate answer before the time runs out. If you encounter any technical
        issues or have questions during the exam, dont hesitate to seek assistance from the exam
        proctor or technical support staff. Now that you are familiar with the exam terms, you are
        ready to begin. Best of luck!
      </Typography>

      <Button
        variant="contained"
        color="warning"
        sx={{ mr: 2, width: '60%', margin: 'auto' }}
        onClick={handleRegisterExam}
      >
        Register Exam
        {studentExamsLoading && <CircularProgress color="inherit" size={17} sx={{ mx: 1 }} />}
      </Button>
    </Stack>
  );

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Exams" />

      {loading && studentExamsLoading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress />
        </Box>
      )}

      <Box p={2}>
        <Typography fontSize={16} mb={1} fontWeight={600}>
          {examDetails?.title}
        </Typography>
        <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
          {examDetails?.description}
        </TextMaxLine>
      </Box>
      <Container maxWidth="md" className="exams_wrap" sx={{ ml: 0, mb: 10 }}>
        {renderContentBeforeExam}
      </Container>
    </Container>
  );
}

export default AcademyExamRegister;
