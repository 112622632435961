import PropTypes from 'prop-types';

import { Box, Stack, TextField, Autocomplete, InputAdornment } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function SchoolCourseTableToolbar({
  allCourses,
  setSearchText,
  setSelectedClass,
  selectedClass,
  setSelectedDivision,
  schoolClassList,
  schoolDivision,
}) {
  const handleFilterName = (event) => {
    setSearchText(event.target.value);
  };

  const handleAutocompleteChangeClass = (event, value) => {
    setSelectedClass(value);
  };

  const handleAutocompleteChangeDivision = (event, value) => {
    setSelectedDivision(value);
  };

  // const optionCourse = [
  //   ...(allCourses?.schoolCourses || []).map((results) => ({
  //     label: results?.title,
  //     value: results?._id,
  //   })),
  // ];

  const optionClass = [
    ...(schoolClassList?.schoolClasses || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const optionDivision = [
    ...(schoolDivision?.schoolDivisions || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
        width: '100%',
      }}
    >
      {/* Course Search TextField */}
      <TextField
        fullWidth
        size="small"
        onChange={handleFilterName}
        placeholder="Search "
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        sx={{ width: '33%' }}
      />

      {/* Class Autocomplete */}
      <Autocomplete
        id="class-select"
        options={optionClass}
        autoHighlight
        size="small"
        sx={{ width: '33%' }}
        getOptionLabel={(option) => option.label}
        onChange={handleAutocompleteChangeClass}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Class"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />

      {/* Division Autocomplete */}

      {selectedClass?.value && schoolDivision?.schoolDivisions?.length > 0 && (
        <Autocomplete
          id="division-select"
          options={optionDivision}
          autoHighlight
          size="small"
          sx={{ width: '33%' }}
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChangeDivision}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Division"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      )}
    </Stack>
  );
}

SchoolCourseTableToolbar.propTypes = {
  allCourses: PropTypes.any,
  setSearchText: PropTypes.func.isRequired,
  setSelectedClass: PropTypes.func.isRequired,
  selectedClass: PropTypes.any,
  setSelectedDivision: PropTypes.func.isRequired,
  schoolClassList: PropTypes.any,
  schoolDivision: PropTypes.any,
};
