import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Tab,
  Tabs,
  Card,
  alpha,
  Table,
  Paper,
  useTheme,
  TableRow,
  TableBody,
  TableCell,
  CardContent,
  CircularProgress,
} from '@mui/material';

import { getAllSchoolClass } from 'src/server/api/schoolClass';
import { getAllSchoolCourse } from 'src/server/api/schoolCourse';
import { getAllSchoolDivision } from 'src/server/api/schoolDivision';

import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import SchoolCourseTableRow from './school-course-table-row';
import SchoolCourseTableToolbar from './school-course-table-toolbar';

export default function SchoolCourseList({ Id }) {
  const [sort, setSort] = useState('');

  // const [courseOptions, setCourseOptions] = useState([]);
  // const [classOptions, setClassOptions] = useState([]);
  // const [divisionOptions, setDivisionOptions] = useState([]);
  const [searchText, setSearchText] = useState();
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  const dispatch = useDispatch();

  const { loading, schoolCourse, schoolClassList, schoolDivision } = useSelector((state) => ({
    loading: state.schoolCourse.loading,
    schoolCourse: state.schoolCourse.schoolCourse,
    schoolClassList: state.schoolClass.schoolClasses,
    schoolDivision: state.schoolDivision.schoolDivisions,
  }));

  const theme = useTheme();
  const table = useTable();

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: table.rowsPerPage,
      sort,
      search: searchText,
      schoolClass: selectedClass?.value,
      schoolClassDivision: selectedDivision?.value,
      Id,
    };
    dispatch(getAllSchoolCourse(credentials));
  }, [
    dispatch,
    table.page,
    table.rowsPerPage,
    sort,
    Id,
    selectedClass?.value,
    selectedDivision?.value,
    searchText,
  ]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      dispatch,
      school: Id,
    };
    dispatch(getAllSchoolClass(credentials));
  }, [dispatch, Id]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      schoolClass: selectedClass?.value,
      dispatch,
    };
    dispatch(getAllSchoolDivision(credentials));
  }, [dispatch, selectedClass]);

  const totalPage = schoolCourse?.total || 0;
  const tableData = schoolCourse?.schoolCourses || [];
  const notFound = !tableData?.length;

  const TABLE_HEAD = [
    { id: 'coursename', label: 'Course Name' },
    { id: 'class', label: 'Class' },
    { id: 'division', label: 'Division' },
    { id: 'progress', label: 'Progress' },
    { id: 'status', label: 'Status' },
  ];

  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'info',
      count: schoolCourse?.schoolCourseCounts?.allCount ?? 0,
    },
    {
      value: 'NOT_STARTED',
      label: 'Not Started',
      color: 'error',
      count: schoolCourse?.schoolCourseCounts?.notStartedCount ?? 0,
    },
    {
      value: 'ON_GOING',
      label: 'Ongoing',
      color: 'warning',
      count: schoolCourse?.schoolCourseCounts?.onGoingCount ?? 0,
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
      color: 'success',
      count: schoolCourse?.schoolCourseCounts?.completedCount ?? 0,
    },
  ];

  return (
    <Card
      sx={{
        boxShadow: theme.shadows[1],
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <Tabs
          value={sort}
          onChange={(e, newValue) => setSort(newValue)}
          sx={{
            mb: 1,
            boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            px: 2.5,
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              iconPosition="end"
              icon={<Label color={tab.color}>{tab.count}</Label>}
              sx={{ marginRight: '10px' }}
            />
          ))}
        </Tabs>

        {/* Toolbar with filters and actions */}
        <SchoolCourseTableToolbar
          allCourses={schoolCourse}
          setSearchText={setSearchText}
          searchvalue={searchText}
          setSelectedClass={setSelectedClass}
          selectedClass={selectedClass}
          setSelectedDivision={setSelectedDivision}
          schoolClassList={schoolClassList}
          schoolDivision={schoolDivision}
        />

        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            {/* Table Head */}
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            {/* Table Body */}
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                    <Paper sx={{ textAlign: 'center' }}>
                      <CircularProgress color="warning" />
                    </Paper>
                  </TableCell>
                </TableRow>
              ) : (
                tableData.map((row) => <SchoolCourseTableRow key={row.id} row={row} />)
              )}

              {!loading && tableData.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length}>
                    <TableNoData />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            <TableNoData title="Earnings" notFound={notFound} />
          </Table>
        </Scrollbar>

        <TablePaginationCustom
          count={totalPage}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </CardContent>
    </Card>
  );
}

SchoolCourseList.propTypes = {
  Id: PropTypes.string.isRequired,
};
