import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MovingIcon from '@mui/icons-material/Moving';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { getPartnerBalanceChartData } from 'src/server/api/dashboard';

import AdminWidgetSummary from './admin-widget-summary2';
// ----------------------------------------------------------------------

export default function AdminCheckInWidgets({
  // percentageIncrement,
  incrementBalance,
  totalSold,
  totalProduct,
  totalCourse,
  totalSchool,
  chart,
  ...other
}) {
  // const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { balanceChartData, dashboardData } = useSelector((state) => ({
    loading: state.dashboard.loading,
    balanceChartData: state.dashboard.balanceChart,
    dashboardData: state.dashboard.dashboardData,
  }));
  console.log(incrementBalance);
  // const smUp = useResponsive('up', 'sm');

  // const {
  //   colors = [
  //     [theme.palette.primary.light, theme.palette.primary.main],
  //     [theme.palette.warning.light, theme.palette.warning.main],
  //   ],
  //   series,
  //   options,
  // } = chart;

  function checkSignTheme() {
    if (incrementBalance > 0) {
      return '#22C55E';
    }
    if (incrementBalance < 0) {
      return 'red';
    }
    return 'inherit';
  }
  function checkSign() {
    if (incrementBalance > 0) {
      return `+ ${incrementBalance} `;
    }
    if (incrementBalance < 0) {
      return `- ${Math.abs(incrementBalance)} `;
    }
    return incrementBalance || '0 ';
  }

  useEffect(() => {
    const credentialsYear = {
      enqueueSnackbar,
      year: 2023,
    };

    dispatch(getPartnerBalanceChartData(credentialsYear));
  }, [dispatch, enqueueSnackbar]);

  const percentageIncrement = dashboardData?.percentageIncrement;

  console.log(percentageIncrement);

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Card {...other} sx={{ width: '100%' }}>
        <Stack direction={{ xs: 'column', sm: 'column' }}>
          <Stack
            spacing={3}
            sx={{
              py: 2,
              px: 2,
              width: 1,
              // px: { xs: 3, sm: 0 },
            }}
          >
            <Stack direction="column" alignItems="left" justifyContent={{ sm: 'center' }}>
              <Typography sx={{ mb: 0.5, fontSize: '14px', fontWeight: '600' }}>
                Total Product Sold
              </Typography>
              <Typography sx={{ mb: 0.5, fontSize: '32px', fontWeight: '700', color: '#A5471E' }}>
                {totalSold}
              </Typography>
            </Stack>
            <Stack>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Typography sx={{ color: '#637381', fontWeight: '400', fontSize: '14px' }}>
                  Courses
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>{totalCourse}</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Typography sx={{ color: '#637381', fontWeight: '400', fontSize: '14px' }}>
                  School
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>{totalSchool}</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#637381', fontWeight: '400', fontSize: '14px' }}>
                  Products
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>{totalProduct}</Typography>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Card {...other} sx={{ width: '100%' }}>
        <Stack direction={{ xs: 'column', sm: 'column' }}>
          <Stack
            spacing={3}
            sx={{
              py: 3,
              px: 2,
              width: 1,
            }}
          >
            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Grid>
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Progress</Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    opacity: '0.6',
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#212B36',
                  }}
                >
                  (Monthly)
                </Typography>

                <Typography
                  sx={{
                    color: { checkSignTheme },
                    fontSize: '24px',
                    fontWeight: '700',
                  }}
                >
                  {checkSign}
                </Typography>
              </Grid>
              <Grid>
                <AdminWidgetSummary
                  chart={{
                    series: balanceChartData || [],
                  }}
                />
              </Grid>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: '400', display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    backgroundColor:
                      percentageIncrement > 0 ? 'rgba(34, 197, 94, 0.16)' : 'rgba(255, 0, 0, 0.16)',
                    borderRadius: '50%',
                    marginRight: '5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '16px',
                    height: '16px',
                    padding: '10px',
                  }}
                >
                  {percentageIncrement > 0 ? (
                    <MovingIcon sx={{ width: '13px', height: '13px', color: '#22C55E' }} />
                  ) : (
                    <TrendingDownIcon sx={{ width: '13px', height: '13px', color: 'red' }} />
                  )}
                </div>
                {percentageIncrement === null && (
                  <span style={{ fontWeight: '700' }}>0 %&nbsp;&nbsp;</span>
                )}
                {percentageIncrement !== null && (
                  <span style={{ fontWeight: '700' }}>
                    {percentageIncrement > 0 ? `+${percentageIncrement}` : `${percentageIncrement}`}{' '}
                    %&nbsp;&nbsp;
                  </span>
                )}
                than last month
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}

AdminCheckInWidgets.propTypes = {
  chart: PropTypes.object,

  incrementBalance: PropTypes.any,
  percentageIncrement: PropTypes.any,
  totalSold: PropTypes.any,
  totalCourse: PropTypes.any,
  totalSchool: PropTypes.any,
  totalProduct: PropTypes.any,
};
