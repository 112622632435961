import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Container, Typography } from '@mui/material';

import { useDevice } from '../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const CustomDots = styled('ul')(({ theme }) => ({
  position: 'absolute',
  bottom: '10px', // Position the dots at the bottom
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '5px',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  zIndex: 1, // Ensure dots are above other content
}));

const CustomDot = styled('li')(({ active }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: active ? '#FFA500' : 'rgba(0, 0, 0, 0.3)',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

export default function MiniImageCarousel({ instagramData }) {
  const { isMobile } = useDevice();

  // Configuration for the carousel
  const carouselSettings = {
    dots: true,
    dotsClass: 'slick-dots custom-dots', // Use custom dots class
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: isMobile ? 2 : 5,
    slidesToScroll: isMobile ? 2 : 5,
    appendDots: (dots) => (
      <CustomDots>
        {dots.map((dot, index) => (
          <CustomDot key={index} active={dot.props.className.includes('slick-active')}>
            {dot}
          </CustomDot>
        ))}
      </CustomDots>
    ),
    customPaging: () => <div />, // Custom dots handle styling
    arrows: false, // Hide the default arrows
  };

  const iconImage = [
    { img: `/assets/imagesWeb/Home/youtube.png`, link: 'https://www.youtube.com/crinnolabs' },
    { img: `/assets/imagesWeb/Home/fb.png`, link: 'https://www.facebook.com/StemXpert' },
    { img: `/assets/imagesWeb/Home/instagram.png`, link: 'https://www.instagram.com/stem_xpert' },
    {
      img: `/assets/imagesWeb/Home/whatsapp.png`,
      link: 'https://api.whatsapp.com/send?phone=+91 9400451133',
    },
  ];

  const onlyPhotos = instagramData?.filter((item) => item.media_type === 'IMAGE');

  return (
    <div>
      <StyledRoot sx={{ backgroundColor: '#1A1A1A', paddingTop: '20px', paddingBottom: '20px' }}>
        <Container maxWidth="lg" sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
          <Box sx={{ textAlign: 'center', padding: 5 }}>
            <Typography className="swiper-headline">Show Off Your Builds </Typography>
            <Typography className="font-size-12" style={{ color: '#949494' }}>
              #STEMXpert{' '}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Slider {...carouselSettings}>
              {onlyPhotos?.map((item, key) => (
                <div key={key} className="image-mini-carousal">
                  <img
                    className=""
                    src={item?.media_url}
                    alt="Event Slide 1"
                    style={{
                      objectFit: 'contain',
                      height: 200,
                      width: isMobile ? '100%' : '90%',
                      padding: isMobile ? 5 : 0,
                      borderRadius: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </div>
              ))}
            </Slider>

            <Grid container justifyContent="center" alignItems="center" padding={2}>
              <Stack direction="row" spacing={isMobile ? 1 : 3}>
                {iconImage?.map((item, key) => (
                  <a href={item?.link} key={key} target="_blank" rel="noreferrer">
                    <img
                      className=""
                      src={item?.img}
                      alt="Event Slide 1"
                      style={{ objectFit: 'contain', width: 35, height: 30, padding: 5 }}
                    />
                  </a>
                ))}
              </Stack>
            </Grid>
          </Box>
        </Container>
      </StyledRoot>
    </div>
  );
}

MiniImageCarousel.propTypes = {
  sx: PropTypes.object,
  instagramData: PropTypes.array,
};
