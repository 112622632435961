import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createPartnerType,
  getAllPartnerType,
  deletePartnerType,
  getPartnerTypeDetails,
  updatePartnerTypeDetails,
} from '../api/partnerType';

export const partnerTypeSlice = createSlice({
  name: 'partnerType',
  initialState: {
    loading: false,
    error: {},
    // partner type
    partnerType: {},
    staffDropList: [],
    partnerTypeDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create  partner type details
    [createPartnerType.pending]: (state) => {
      state.loading = true;
    },
    [createPartnerType.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerType;
      const oldPartnerTypesList = jsonState?.partnerTypes;

      const modifiedPartnerTypeList = {
        ...jsonState,
        partnerTypes: Array.isArray(oldPartnerTypesList)
          ? [action.payload, ...oldPartnerTypesList]
          : [action.payload],
      };
      state.loading = false;
      state.partnerTypeDetails = action.payload;
      state.partnerType = modifiedPartnerTypeList;
      state.error = {};
    },
    [createPartnerType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all  partner type
    [getAllPartnerType.pending]: (state) => {
      state.loading = true;
    },
    [getAllPartnerType.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerType = action.payload;
      state.error = {};
    },
    [getAllPartnerType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single  partner type details
    [getPartnerTypeDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerTypeDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerTypeDetails = action.payload;
      state.error = {};
    },
    [getPartnerTypeDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update  partner type details
    [updatePartnerTypeDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updatePartnerTypeDetails.fulfilled]: (state, action) => {

    //   state.loading = false;
    //   state.partnerTypeDetails = action.payload;
    //   state.error = {};
    // },
    [updatePartnerTypeDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerType;
      const modifiedPartnerTypeList = {
        ...jsonState,
        partnerTypes: jsonState.partnerTypes?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
        // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.partnerTypeDetails = action.payload;
      state.partnerType = modifiedPartnerTypeList;
      state.error = {};
    },
    [updatePartnerTypeDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deletePartnerType.pending]: (state) => {
      state.loading = true;
    },
    [deletePartnerType.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partnerType;
      const modifiedPartnerTypeList = {
        ...jsonState,
        partnerTypes: jsonState.partnerTypes?.filter(
          (partnerType) => partnerType._id !== action.payload
        ),
      };
      state.loading = false;
      state.partnerType = modifiedPartnerTypeList;
      state.error = {};
    },
    [deletePartnerType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = partnerTypeSlice.actions;

export default partnerTypeSlice.reducer;
