import React from 'react';
import PropTypes from 'prop-types';

// import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
// import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import { Box, Typography, ListItemText, LinearProgress } from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';

// import { fDate, fTime } from 'src/utils/format-time';

import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';

export default function SchoolCourseTableRow({ row }) {
  const {
    title,
    school_class,
    school_division,
    is_completed,
    completion_percentage,
    // updatedAt,
    total_content_count,
    completed_contents,
  } = row;

  //   const dispatch = useDispatch();
  //   const confirm = useBoolean();
  //   const { role } = useGetRoles();

  // Function to handle the color based on completion percentage
  const handleColor = (value) => {
    if (value >= 80) return 'success';
    if (value >= 60) return 'warning';
    if (value >= 40) return 'info';
    return 'error';
  };

  const getStatusLabel = () => {
    if (completed_contents.length === 0) {
      return 'Not started';
    }
    return is_completed ? 'Completed' : 'Ongoing';
  };

  const getStatusColor = () => {
    if (completed_contents.length === 0) {
      return 'info';
    }
    return is_completed ? 'success' : 'warning';
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <ListItemText
            primary={school_class?.course?.title || title}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={school_class?.title}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={school_division?.title}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          />
        </TableCell>

        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={completion_percentage}
              color={handleColor(completion_percentage)}
              sx={{ height: 8, width: '60%' }}
            />
            <Typography variant="body2" ml="5px" fontWeight="bold" color="black">
              {`(${completed_contents.length} / ${total_content_count})`}
            </Typography>
          </Box>

          <Typography pt={1} variant="body2">
            {`${completion_percentage?.toFixed(2)}% completed`}
          </Typography>
        </TableCell>

        <TableCell>
          <Label variant="soft" color={getStatusColor()}>
            {getStatusLabel()}
          </Label>
        </TableCell>

        {/* {is_completed && (
          <TableCell>
            <ListItemText
              primary={fDate(updatedAt)}
              secondary={fTime(updatedAt)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{ component: 'span', typography: 'caption' }}
            />
          </TableCell>
        )} */}
      </TableRow>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover> */}
    </>
  );
}

SchoolCourseTableRow.propTypes = {
  row: PropTypes.object.isRequired, // Define the expected shape of row more precisely if possible
};
