import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from './http';
import { activeSnack } from '../store/common';

export const getAllClasses = createAsyncThunk(
  'class/school-user/all',
  async ({ page, search, limit, labModel, dispatch }) => {
    try {
      const response = await get(
        `/class/all?lab_model=${labModel || ''}&page=${page || 1}&search=${search || ''}&limit=${
          limit || 10
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getClasses = createAsyncThunk(
  'class/school-user/all',
  async ({ page, search, limit, labModel, session, dispatch }) => {
    try {
      const response = await get(
        `/class/admin/all?lab_model=${labModel || ''}&session=${session || ''}&page=${
          page || 1
        }&search=${search || ''}&limit=${limit || 10}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createClass = createAsyncThunk(
  'class/new',
  async ({
    state,
    dispatch,
    handleClose,
    setSelectedLab,
    setSelectedSesion,
    labeModels,
    sessions,
  }) => {
    try {
      const URL = `/class/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (setSelectedLab) {
          const labModel = labeModels?.filter((item) => item?._id === response?.lab_model);
          setSelectedLab({
            label: labModel && labModel[0] && labModel[0]?.title,
            value: labModel && labModel[0] && labModel[0]?._id,
          });
        }
        if (setSelectedSesion) {
          const session = sessions?.filter((item) => item?._id === response?.session);
          setSelectedSesion({
            label: session && session[0] && session[0]?.title,
            value: session && session[0] && session[0]?._id,
          });
        }
        const credentials = {
          page: 1,
          search: '',
          limit: 20,
          labModel: response?.lab_model,
          session: response?.session,
          dispatch,
        };
        dispatch(getClasses(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Class updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateClass = createAsyncThunk(
  'class/update',
  async ({
    state,
    classId,
    dispatch,
    handleClose,
    setSelectedLab,
    setSelectedSesion,
    labeModels,
    sessions,
  }) => {
    try {
      const URL = `/class/admin/${classId}`;
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (setSelectedLab) {
          const labModel = labeModels?.filter((item) => item?._id === response?.lab_model);
          setSelectedLab({
            label: labModel && labModel[0] && labModel[0]?.title,
            value: labModel && labModel[0] && labModel[0]?._id,
          });
        }
        if (setSelectedSesion) {
          const session = sessions?.filter((item) => item?._id === response?.session);
          setSelectedSesion({
            label: session && session[0] && session[0]?.title,
            value: session && session[0] && session[0]?._id,
          });
        }
        const credentials = {
          page: 1,
          search: '',
          limit: 20,
          labModel: response?.lab_model,
          session: response?.session,
          dispatch,
        };
        dispatch(getClasses(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Class updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteClass = createAsyncThunk(
  'class/delete',
  async ({ classId, dispatch, handleCloseModal }) => {
    try {
      const URL = `/class/admin/${classId}`;
      const response = await del(URL);
      if (response) {
        if (response?._id && handleCloseModal) {
          handleCloseModal();
        }
        dispatch(activeSnack({ type: 'success', message: 'Class deleted successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
