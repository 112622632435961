import React from 'react';
import PropType from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import YouTube from 'react-youtube';

import { styled } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';

// import useResponsive from 'src/hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function Youtube({ link }) {
  // const isMobile = useResponsive('sm', 'xs', 'md');
  const opts = {
    // width: isMobile ? '500px' : '1000px',
    // height: '550px',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <StyledRoot sx={{ marginBottom: 0 }}>
        <Container
          className=""
          maxWidth="lg"
          sx={{
            // paddingTop: { xs: '0', sm: '45px' },
            // paddingBottom: { xs: '0', sm: '125px' },
            paddingLeft: { xs: '0', sm: '16px' },
            paddingRight: { xs: '0', sm: '16px' },
          }}
        >
          <Grid container direction="row" className="bg-color">
            <Grid className="react-player">
              <YouTube
                videoId={link}
                id={link}
                opts={opts}
                className="react-player-video"
                iframeClassName="w-100"
                // className={string}
                // style={object}
                // title={string}
                // loading={string}
                // onReady={func}
                // onPlay={func}
                // onPause={func}
                // onEnd={func}
                // onError={func}
                // onStateChange={func}
                // onPlaybackRateChange={func}
                // onPlaybackQualityChange={func}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
    </div>
  );
}

Youtube.propTypes = {
  link: PropType.any,
};
