import { createSlice } from '@reduxjs/toolkit';

import { getAllCustomFields } from '../api/customField';

export const customFieldSlice = createSlice({
  name: 'customField',
  initialState: {
    loading: false,
    updateLoading: false,
    error: {},
    customFields: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllCustomFields.pending]: (state) => {
      state.loading = true;
    },
    [getAllCustomFields.fulfilled]: (state, action) => {
      state.loading = false;
      state.customFields = action.payload;
    },
    [getAllCustomFields.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = customFieldSlice.actions;

export default customFieldSlice.reducer;
