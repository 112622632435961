import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Chip, Radio, RadioGroup, Typography, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

export default function ClassAndDivisionFilter({ filterData }) {
  const {
    schoolClassList,
    selectedClass,
    setSelectedClass,
    schoolDivision,
    selectedDivision,
    setSelectedDivision,
  } = filterData;

  const handleSelectedClass = () => {
    setSelectedClass('');
    setSelectedDivision('');
  };

  return (
    <Stack>
      <Typography fontSize={18} fontWeight={700} pb="24px">
        Select Class & Divisions
      </Typography>
      <Stack direction="row" spacing={2} flexWrap="wrap">
        <Chip
          label="All"
          onClick={handleSelectedClass}
          sx={{
            width: 'max-content',
            height: '40px',
            borderRadius: '50px',
            color: selectedClass === '' ? 'white' : '#212B36',
            backgroundColor: selectedClass === '' ? '#007867' : 'white',
            fontSize: '14px',
            fontWeight: 700,
            border: '1px solid #919EAB52',
            '&:hover': {
              backgroundColor: selectedClass === '' ? '#007867' : '#00A76F',
              color: 'white',
            },
          }}
        />
        {schoolClassList?.schoolClasses?.map((item, key) => (
          <Chip
            key={key}
            label={`${item?.title}`}
            onClick={() => {
              setSelectedClass(item?._id);
              setSelectedDivision('');
            }}
            sx={{
              width: 'max-content',
              height: '40px',
              borderRadius: '50px',
              color: selectedClass === item?._id ? 'white' : '#212B36',
              backgroundColor: selectedClass === item?._id ? '#007867' : 'white',
              fontSize: '14px',
              fontWeight: 700,
              border: '1px solid #919EAB52',
              '&:hover': {
                backgroundColor: selectedClass === item?._id ? '#007867' : '#00A76F',
                color: 'white',
              },
            }}
          />
        ))}
      </Stack>
      {selectedClass && (
        <Stack mt="24px" direction="row" flexWrap="wrap" ml={2}>
          <RadioGroup
            defaultValue="div1"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {schoolDivision &&
              schoolDivision?.total > 0 &&
              schoolDivision?.schoolDivisions?.map((item, key) => (
                <FormControlLabel
                  key={key}
                  value={item?._id}
                  control={<Radio style={{ color: selectedDivision === item?._id && '#007867' }} />}
                  label={item?.title}
                  onChange={() => setSelectedDivision(item?._id)}
                />
              ))}
          </RadioGroup>
        </Stack>
      )}
    </Stack>
  );
}

ClassAndDivisionFilter.propTypes = {
  filterData: PropTypes.any,
};
