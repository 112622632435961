import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

// import LinkIcon from 'src/assets/other/link.png';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Grid, Card, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { frontEndUrl } from 'src/server/api/http';
import StudyKit from 'src/assets/other/StudyKit.svg';
import EearningImg from 'src/assets/other/EarningImg.svg';
import PartnerImg from 'src/assets/other/PartnerRefer.svg';
import ProductEran from 'src/assets/other/productEarn.svg';
import SchoolImg from 'src/assets/other/saleitemSchool.svg';

export const SaleItemCards = ({ handleScroll = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { partnerDetails } = useSelector((state) => ({
    partnerDetails: state.partner.profile,
  }));

  const cards = [
    {
      title: 'STEM & Tinkering Labs to Your School',
      description:
        'Empower your school with advanced STEM labs for hands-on learning in Coding, Robotics, AI, and IoT. Share your referral link to help schools unlock rewards while transforming education and building a tech-driven generation.',
      buttonLabel: 'Share & Earn',
      image: SchoolImg,
      type: 'school',
    },
    {
      title: 'Earn with Courses',
      description:
        'Promote and earn effortlessly with specialized courses in Robotics, AI and more. Share your referral link to connect learners with programs ranging from foundational to advanced STEM education.',
      buttonLabel: 'Share & Earn',
      image: EearningImg,
      type: 'course',
    },
    {
      title: 'Earn with DIY Kits',
      // description:
      //   'Refer STEM study kits designed for hands-on learning in Robotics, IoT, and Electronics. Earn rewards for every successful purchase while supporting learners with tools to innovate.',
      description:
        'Refer STEM study kits designed for hands-on learning in Robotics, IoT, and Electronics. Earn rewards for every successful purchase while supporting learners with tools to innovate.',
      buttonLabel: 'Share & Earn',
      image: StudyKit,
      type: 'kit',
    },
    {
      title: 'Earn with Partners',
      description:
        'Expand the STEM network by referring new partners and enjoy valuable rewards. Join the community of shaping the future of education.',
      buttonLabel: 'Share & Earn',
      image: PartnerImg,
      type: 'partner',
    },
    {
      title: 'Earn with Products',
      description:
        'Promote STEM events, fests, and competitions within your network to unlock rewards for every successful sign-up and create valuable educational opportunities.',
      image: ProductEran,
      type: 'other_product',
    },
  ];

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };

  const setCopyPath = (type) => {
    switch (type) {
      case 'school':
        return `${frontEndUrl}${paths.public.school_regstration}?ref_id=${partnerDetails?.findPartner?.partner_code}`;
      case 'course':
        return `${frontEndUrl}${paths.public.academy_landing}?ref_id=${partnerDetails?.findPartner?.partner_code}`;
      case 'partner':
        return `${frontEndUrl}${paths.public.partner_regstration}?ref_id=${partnerDetails?.findPartner?.partner_code}`;
      default:
        return `${frontEndUrl}?ref_id=${partnerDetails?.findPartner?.partner_code}`;
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Full-width Card */}
      {cards.slice(0, 1).map((card, index) => (
        <Grid item xs={12} key={index}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              p: 2,
              height: '100%',
              gap: 2.5,
              boxShadow: '0px 1px 4px 0px #919EAB5C',
            }}
          >
            {/* Box for Image */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: { xs: 2, md: 0 },
              }}
            >
              <CardMedia
                component="img"
                image={card?.image}
                alt={card?.title}
                sx={{
                  width: { xs: '100%', md: 168 },
                  aspectRatio: 1 / 1,
                  borderRadius: 1,
                }}
              />
            </Box>
            {/* Box for Content */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" gutterBottom>
                {card?.title}
              </Typography>
              <Typography
                variant="caption"
                color="#637381"
                fontSize={13}
                sx={{ mb: 1, height: 80, width: { sm: '100%', md: 600 } }}
              >
                {card.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
              >
                {/* Share & Earn Button */}
                <CopyToClipboard
                  text={`${frontEndUrl}${paths.public.school_regstration}?ref_id=${partnerDetails?.findPartner?.partner_code}`}
                  onCopy={handleCopy}
                >
                  <Button
                    variant="contained"
                    startIcon={<LinkIcon sx={{ fontSize: 16 }} />}
                    sx={{
                      backgroundColor: '#FF5630',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: '#FF5630',
                      },
                    }}
                  >
                    {/* <CardMedia
                      component="img"
                      src={LinkIcon}
                      alt="icon"
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                      }}
                    /> */}
                    {card?.buttonLabel}
                  </Button>
                </CopyToClipboard>

                {/* View All Button */}
                <Button
                  variant="outlined"
                  onClick={() => handleScroll(card?.type)}
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  View all
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}

      {/* Other Cards */}
      {cards?.slice(1).map((card, index) => (
        <Grid key={index} item xs={12} sm={6} md={6}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              p: 2,
              height: '100%',
              gap: 2.5,
              boxShadow: '0px 1px 4px 0px #919EAB5C',
            }}
          >
            <CardMedia
              component="img"
              image={card.image}
              alt={card.title}
              sx={{ width: { xs: '100%', md: 168 }, aspectRatio: 1 / 1, borderRadius: 1 }}
            />
            <Box
              sx={{
                flex: 1,
                mt: { xs: 2, md: 0 },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6" gutterBottom pb={0.5}>
                {card.title}
              </Typography>
              <Typography
                variant="caption"
                color="#637381"
                fontSize={13}
                sx={{ mb: 1, height: 80 }}
              >
                {card.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
              >
                {index !== 3 && (
                  <CopyToClipboard
                    text={setCopyPath(card?.type)}
                    onCopy={handleCopy}
                    // Disable CopyToClipboard functionality for the second card
                    disabled={index === 1}
                  >
                    <Button
                      variant="contained"
                      disabled={index === 1}
                      startIcon={<LinkIcon sx={{ fontSize: 16 }} />}
                      sx={{
                        backgroundColor: index === 1 ? '#FFB380' : '#FF5630', // Orange color for disabled
                        padding: '8px 16px',
                        '&.Mui-disabled': {
                          backgroundColor: '#FFB380',
                          color: '#fff',
                          opacity: 1,
                        },
                        '&:hover': {
                          backgroundColor: index === 1 ? '#FFB380' : '#FF5630', // Keep the disabled hover effect same
                        },
                      }}
                    >
                      {card.buttonLabel}
                    </Button>
                  </CopyToClipboard>
                )}
                <Button
                  variant="outlined"
                  onClick={() => handleScroll(card?.type)}
                  disabled={card?.type === 'kit'}
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  View all
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

SaleItemCards.propTypes = {
  handleScroll: PropTypes.any,
};
