import PropTypes from 'prop-types';

import { Box, Card, Chip, Stack, TextField, Typography, Autocomplete } from '@mui/material';

ClassToolBar.propTypes = {
  filterData: PropTypes.any,
};

export default function ClassToolBar({ filterData }) {
  const {
    optionLabmodel,
    optionSession,
    handleAutocompleteChangeLabModel,
    handleTextFieldChangeLabModel,
    handleAutocompleteChangeSession,
    handleTextFieldChangeSession,
    selectedLabModel,
    selectedSession,
  } = filterData;
  return (
    <Card sx={{ left: -10 }}>
      <Stack direction="row" spacing={2} m={2}>
        <Autocomplete
          id="country-select-demo"
          className="remove-clear"
          sx={{ width: '100%' }}
          options={optionLabmodel}
          autoHighlight
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChangeLabModel}
          // defaultValue={selectedLabModel?.value && selectedLabModel}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Change Lab Model"
              onChange={handleTextFieldChangeLabModel}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Autocomplete
          id="country-select-demo"
          className="remove-clear"
          sx={{ width: '100%' }}
          options={optionSession}
          autoHighlight
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChangeSession}
          // defaultValue={selectedSession?.value && selectedSession?.labe}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Change Session"
              onChange={handleTextFieldChangeSession}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={3} m={4}>
        <Box>
          <Typography variant="caption" fontSize={15} fontWeight="bold" pr={1}>
            Lab Model :
          </Typography>
          <Chip color="primary" size="small" label={selectedLabModel?.label} />
        </Box>
        <Box>
          <Typography variant="caption" fontSize={15} fontWeight="bold" pr={1}>
            Session :
          </Typography>
          <Chip color="default" size="small" label={selectedSession?.label} />
        </Box>
      </Stack>
    </Card>
  );
}
