import PropType from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box, Card, Grid, Stack, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import TextMaxLine from 'src/components/text-max-line';

const SupportMaterials = ({ materialData }) => {
  const [isMaterials, setIsMaterials] = useState(true);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={10}>
        <Typography fontSize={18} fontWeight={700}>
          Support Materials
          <Typography fontSize={14} fontWeight={400} color="#919EAB" pt="4px">
            {materialData?.total} Materials
          </Typography>
        </Typography>
        <Button onClick={() => setIsMaterials(!isMaterials)}>
          {isMaterials ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>
      {isMaterials && (
        <Grid container spacing={1} mt="32px">
          {materialData?.supportMaterials?.map((item, key) => (
            <Grid key={key} md="6" lg="6" sm="12" xs="12">
              <Link
                key={key}
                to={paths?.dashboard?.lab_single(item?._id)}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    mr: '24px',
                    mb: '24px',
                    boxShadow: 'none',
                    border: '1px solid #F0F0F0',
                  }}
                >
                  <Box p={2}>
                    <Typography fontSize={16} fontWeight={600}>
                      {item?.title}
                    </Typography>
                    <Stack direction="row" my={1}>
                      {item && item?.pdf_url && item?.pdf_url[0] && (
                        <TextSnippetIcon fontSize="medium" sx={{ color: '#795192' }} />
                      )}
                      {item && item?.video_url && (
                        <PlayCircleIcon fontSize="medium" sx={{ color: '#795192' }} />
                      )}
                    </Stack>

                    <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
                      {item?.description}
                    </TextMaxLine>
                  </Box>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SupportMaterials;

SupportMaterials.propTypes = {
  materialData: PropType.any,
};
