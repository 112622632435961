import { Outlet } from 'react-router-dom';

import { Stack, Container } from '@mui/material';

import Footer from './Footer';
import Header from './Header';

export default function SimpleLayout() {
  return (
    <Container
      maxWidth="xxl"
      style={{ backgroundColor: '#1A1A1A', paddingRight: 0, paddingLeft: 0 }}
    >
      <Stack direction="column" className="header-links-main">
        <Header />
      </Stack>
      <Outlet />

      <Footer />
    </Container>
  );
}
