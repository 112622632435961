import { useState } from 'react';
import PropType from 'prop-types';
import { pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@react-pdf-viewer/core/lib/styles/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

import { Stack, Container } from '@mui/material';

import Scrollbar from 'src/components/scrollbar';

import Youtube from 'src/sections/common/youtube';

export default function TabContent({ activeTab }) {
  const { materialDetails } = useSelector((state) => ({
    materialDetails: state.supportMaterial.materialDetails,
  }));

  const [numPages, setNumPages] = useState(null);
  console.log(numPages); // do not remove

  const onDocumentLoadSuccess = ({ Pages }) => {
    setNumPages(Pages);
  };

  const pdfUrl =
    materialDetails &&
    materialDetails?.pdf_url &&
    materialDetails?.pdf_url[0] &&
    materialDetails?.pdf_url[0]?.url;

  const videoUrl =
    materialDetails &&
    materialDetails?.video_url &&
    materialDetails?.video_url[0] &&
    materialDetails?.video_url[0]?.url;

  const trainingPdfUrl =
    materialDetails &&
    materialDetails?.training_pdf_url &&
    materialDetails?.training_pdf_url[0] &&
    materialDetails?.training_pdf_url[0]?.url;

  return (
    <Container maxWidth="xl">
      <Stack mt="30px">
        {activeTab === 'video' && <Youtube link={videoUrl || materialDetails?.video_url} />}
        {activeTab === 'document' && pdfUrl && (
          <Scrollbar sx={{ maxHeight: 600 }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={pdfUrl}
                defaultScale={SpecialZoomLevel.PageWidth}
                onDocumentLoad={onDocumentLoadSuccess}
              />
            </Worker>
          </Scrollbar>
          // <div className="my-pdf-reader">
          //   <Box display="flex" justifyContent="center">
          //     <iframe
          //       src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`}
          //       width="1200"
          //       height="580"
          //       title="pdf-viewer"
          //       className="my-pdf-reader-iframe"
          //       id="my-pdf-reader-iframe"
          //       frameBorder="0"
          //       style={{ background: 'transparent', backgroundColor: '#ffff' }}
          //     />
          //   </Box>
          // </div>
        )}
        {activeTab === 'training' && trainingPdfUrl && (
          <Scrollbar sx={{ maxHeight: 600 }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={trainingPdfUrl}
                defaultScale={SpecialZoomLevel.PageWidth}
                onDocumentLoad={onDocumentLoadSuccess}
              />
            </Worker>
          </Scrollbar>
          // <div className="my-pdf-reader">
          //   <Box display="flex" justifyContent="center">
          //     <iframe
          //       src={`https://docs.google.com/viewer?url=${trainingPdfUrl}&embedded=true`}
          //       width="1200"
          //       height="580"
          //       title="pdf-viewer"
          //       className="my-pdf-reader-iframe"
          //       id="my-pdf-reader-iframe"
          //       frameBorder="0"
          //       style={{ background: 'transparent', backgroundColor: '#ffff' }}
          //     />
          //   </Box>
          // </div>
        )}
      </Stack>
    </Container>
  );
}

TabContent.propTypes = {
  activeTab: PropType.any,
};
