import Container from '@mui/material/Container';

import { paths } from 'src/routes/routes/paths';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import ShareLinkAllCourse from '../../../sections/saleItem/shareLinkAllCourse';

export default function SaleItemsListViewForPartner() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Sale Item"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },

          {
            name: 'Sale Item',
          },
        ]}
        // action={
        //   <Stack direction="row" spacing={2}>
        //     {partnerProfile?.partnerCommission?.map((item, key) => (
        //       <Card sx={{ p: 3 }} key={key}>
        //         <Typography variant="h5" textTransform="capitalize">
        //           {item?.type}
        //         </Typography>
        //         <Typography variant="subtitle2">
        //           Direct Income: {item?.direct_commission}
        //         </Typography>
        //         <Typography variant="subtitle2">
        //           Hierarchy Income: {item?.hierarchy_commission}
        //         </Typography>
        //       </Card>
        //     ))}
        //   </Stack>
        // }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ShareLinkAllCourse />
    </Container>
  );
}
