import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSchoolDivision,
  getAllSchoolDivision,
  deleteSchoolDivision,
  getSchoolDivisionDetails,
  updateSchoolDivisionDetails,
} from '../api/schoolDivision';

export const schoolDivisionSlice = createSlice({
  name: 'schoolDivision',
  initialState: {
    loading: false,
    error: {},
    // schoolDivision
    schoolDivisions: {},
    staffDropList: [],
    schoolUserDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create schoolDivision details
    [createSchoolDivision.pending]: (state) => {
      state.loading = true;
    },
    [createSchoolDivision.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schoolDivisions;
      // const newSchoolList = jsonState?.schoolDivisions;
      // const modifiedSchoolUserList = {
      //   ...jsonState,
      //   schoolDivisions: Array.isArray(newSchoolList)
      //     ? [action.payload, ...newSchoolList]
      //     : [action.payload],
      // };
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolDivisions: action.payload,
      };
      state.loading = false;
      state.schoolDivisions = modifiedSchoolUserList;
      state.error = {};
    },
    [createSchoolDivision.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all schoolDivision
    [getAllSchoolDivision.pending]: (state) => {
      state.loading = true;
    },
    [getAllSchoolDivision.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolDivisions = action.payload;
      state.error = {};
    },
    [getAllSchoolDivision.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single schoolDivision details
    [getSchoolDivisionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolDivisionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [getSchoolDivisionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update schoolDivision details
    [updateSchoolDivisionDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSchoolDivisionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolUserDetails = action.payload;
      state.error = {};
    },
    [updateSchoolDivisionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete schoolDivision
    [deleteSchoolDivision.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchoolDivision.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schoolDivisions;
      const modifiedSchoolUserList = {
        ...jsonState,
        schoolDivisions: jsonState.schoolDivisions?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.schoolDivision = modifiedSchoolUserList;
      state.error = {};
    },
    [deleteSchoolDivision.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = schoolDivisionSlice.actions;

export default schoolDivisionSlice.reducer;
