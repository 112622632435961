import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import { Box, Stack, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { getClasses } from 'src/server/api/class';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllSessions } from 'src/server/api/session';
import { getAllLabModel } from 'src/server/api/labModel';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import ClassData from 'src/sections/class/classData';
import ClassDialog from 'src/sections/class/ClassDialog';
import SessionList from 'src/sections/class/sessionList';
import ClassToolBar from 'src/sections/class/ClassToolbar';
import SessionDialog from 'src/sections/class/SessionDialog';

// ----------------------------------------------------------------------

export default function ClassList() {
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const [openCreateClassDialog, setOpenCreateClassDialog] = useState(false);
  const [classData, setClassData] = useState({});
  const [openCreateSessionDialog, setOpenCreateSessionDialog] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const { classes, labModel, sessionList } = useSelector((state) => ({
    loading: state.certificateTemplate.loading,
    classes: state.class.classes,
    labModel: state.labModel.labModel,
    sessionList: state.session.sessions,
  }));

  const defaultLabModel = useMemo(
    () => ({
      label:
        labModel && labModel?.labModels && labModel?.labModels[0] && labModel?.labModels[0]?.title,
      value:
        labModel && labModel?.labModels && labModel?.labModels[0] && labModel?.labModels[0]?._id,
    }),
    [labModel]
  );

  const defaultSession = useMemo(
    () => ({
      label:
        sessionList &&
        sessionList?.sessions &&
        sessionList?.sessions[0] &&
        sessionList?.sessions[0]?.title,
      value:
        sessionList &&
        sessionList?.sessions &&
        sessionList?.sessions[0] &&
        sessionList?.sessions[0]?._id,
    }),
    [sessionList]
  );

  const [selectedLabModel, setSelectedLabModel] = useState(defaultLabModel || {});
  const [searchLabModel, setSearchLabModel] = useState('');
  const [selectedSession, setSelectedSession] = useState(defaultSession || {});
  const [searchSession, setSearchSession] = useState('');
  const [pageClass, setPageClass] = useState(1);
  const totalClass = classes?.total;
  const totalClassCount = Math.ceil(totalClass / 20);

  useEffect(() => {
    if (labModel && labModel?.labModels && labModel?.labModels[0]) {
      setSelectedLabModel({
        label: labModel?.labModels[0]?.title,
        value: labModel?.labModels[0]?._id,
      });
    }
  }, [labModel]);

  useEffect(() => {
    if (sessionList && sessionList?.sessions && sessionList?.sessions[0]) {
      setSelectedSession({
        label: `${sessionList?.sessions[0]?.title} (${sessionList?.sessions[0]?.count})`,
        value: sessionList?.sessions[0]?._id,
      });
    }
  }, [sessionList]);

  // console.log(labModel && labModel?.labModels && labModel?.labModels[0] && labModel?.labModels[0]?.title);

  const handleEditSession = (item) => {
    setSessionData(item);
    setOpenCreateSessionDialog(true);
  };

  const handleEditClass = (item) => {
    setClassData(item);
    setOpenCreateClassDialog(true);
  };

  const handleAutocompleteChangeLabModel = (_, newValue) => {
    setSelectedLabModel({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleTextFieldChangeLabModel = (event) => {
    setSearchLabModel(event.target.value);
  };

  const optionLabmodel = [
    ...(labModel?.labModels || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchLabModel,
      limit: 10,
      dispatch,
    };
    dispatch(getAllLabModel(credentials));
  }, [dispatch, searchLabModel]);

  const handleAutocompleteChangeSession = (_, newValue) => {
    setSelectedSession({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleTextFieldChangeSession = (event) => {
    setSearchSession(event.target.value);
  };

  const optionSession = [
    ...(sessionList?.sessions || []).map((results) => ({
      label: `${results?.title} (${results?.count})`,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchSession,
      limit: 10,
      dispatch,
    };
    dispatch(getAllSessions(credentials));
  }, [dispatch, searchSession]);

  useEffect(() => {
    const credentials = {
      page: pageClass,
      search: '',
      limit: 20,
      labModel: selectedLabModel?.value,
      session: selectedSession?.value,
      dispatch,
    };
    dispatch(getClasses(credentials));
  }, [dispatch, pageClass, selectedSession, selectedLabModel, labModel, sessionList]);

  const filterData = {
    optionLabmodel,
    optionSession,
    handleAutocompleteChangeLabModel,
    handleTextFieldChangeLabModel,
    handleAutocompleteChangeSession,
    handleTextFieldChangeSession,
    selectedLabModel,
    selectedSession,
  };

  return (
    <>
      {openCreateClassDialog && (
        <ClassDialog
          isUpdate={classData}
          setIsUpdate={setClassData}
          setOpen={setOpenCreateClassDialog}
          open={openCreateClassDialog}
          setSelectedLab={setSelectedLabModel}
          setSelectedSesion={setSelectedSession}
        />
      )}
      {openCreateSessionDialog && (
        <SessionDialog
          isUpdate={sessionData}
          setIsUpdate={setSessionData}
          setOpen={setOpenCreateSessionDialog}
          open={openCreateSessionDialog}
        />
      )}
      <MetaHelmet title="Class" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Class"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Class',
            },
          ]}
          action={
            <Button
              onClick={() => setOpenCreateClassDialog(true)}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Class
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack mb={5}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h6" pr={1}>
              Sessions
            </Typography>
            <Button
              size="small"
              onClick={() => setOpenCreateSessionDialog(true)}
              sx={{
                backgroundColor: 'black',
                color: 'white',
                p: 1,
                '&:hover': {
                  backgroundColor: 'black',
                  color: 'white',
                },
              }}
            >
              {' '}
              + New Session
            </Button>
          </Box>
          <SessionList handleEditSession={handleEditSession} />
        </Stack>
        <ClassToolBar filterData={filterData} />
        <ClassData
          classes={classes}
          handleEditClass={handleEditClass}
          page={pageClass}
          setPage={setPageClass}
          total={totalClassCount}
        />
      </Container>
    </>
  );
}
