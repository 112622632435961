import PropType from 'prop-types';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Tab, Grid, Tabs, Stack, alpha, Container, Typography } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getSupportMaterialDetails } from 'src/server/api/supportMaterial';

import TabContent from './TabContent';

export default function LabView({ minimal }) {
  const dispatch = useDispatch();
  const params = useParams();
  const theme = useTheme();

  const { materialDetails } = useSelector((state) => ({
    materialDetails: state.supportMaterial.materialDetails,
  }));

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const credentials = {
      materialId: params?.id,
      dispatch,
    };
    dispatch(getSupportMaterialDetails(credentials));
  }, [dispatch, params?.id]);

  useEffect(() => {
    if (materialDetails?.video_url || materialDetails?.video_url?.length > 0) {
      setActiveTab('video');
    } else if (materialDetails?.pdf_url || materialDetails?.pdf_url?.length > 0) {
      setActiveTab('document');
    } else {
      setActiveTab('training');
    }
  }, [materialDetails?.pdf_url, materialDetails?.training_pdf_url, materialDetails?.video_url]);

  const TABS = [];

  if (materialDetails?.video_url || materialDetails?.video_url?.length > 0) {
    TABS.push({ value: 'video', label: 'Video' });
  }
  if (materialDetails?.pdf_url || materialDetails?.pdf_url?.length > 0) {
    TABS.push({ value: 'document', label: 'Manual' });
  }
  if (materialDetails?.training_pdf_url || materialDetails?.training_pdf_url?.length > 0) {
    TABS.push({ value: 'training', label: 'Trainer Material' });
  }

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Material" />
      <Grid container>
        <Grid md="12">
          <Box>
            <Typography fontSize={24} fontWeight={700}>
              {materialDetails?.title}
            </Typography>
            <Typography fontSize={15} fontWeight={400} color="#637381" pt="8px">
              {materialDetails?.description}
            </Typography>
            {/* <Button
              size="small"
              sx={{
                border: '1px solid #919EAB52',
                p: '8px',
                fontWeight: 500,
                fontSize: '13px',
                mr: 4,
                '&:hover': {
                  backgroundColor: '#00A76F29',
                  color: '#00A76F',
                  border: 'none',
                },
              }}
            >
              Mark as completed
            </Button> */}
          </Box>
          <Stack mt="20px">
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{
                boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS?.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <TabContent activeTab={activeTab} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

LabView.propTypes = {
  minimal: PropType.bool,
};
