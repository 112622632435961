import { createAsyncThunk } from '@reduxjs/toolkit';

import { useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSchoolUser = createAsyncThunk(
  'school-user/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/school-user/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllSchoolUser({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            partner: '',
            lab_model: '',
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'School-user Item created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSchoolUser = createAsyncThunk(
  'school-user/list',
  async ({ page, search, dispatch, limit, partner, lab_model, school }) => {
    try {
      const URL = `/school-user/admin/all?page=${page && page}&search=${search && search}&limit=${
        limit && limit
      }&partner=${partner || ''}&lab_model=${lab_model || ''}&school=${school || ''}`;

      const URL_DROPDOWN = `/school-user/admin/all?search=${search && search}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolUserDetails = createAsyncThunk(
  'school-user/single',
  async ({ schoolUserId, dispatch }) => {
    try {
      const response = await get(`/school-user/admin/${schoolUserId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSchoolUserDetails = createAsyncThunk(
  'school-user/update',
  async ({ state, schoolUserId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/school-user/admin/${schoolUserId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'School-user Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSchoolUser = createAsyncThunk(
  'school-user/delete',
  async ({ schoolUserId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/school-user/admin/${schoolUserId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(
          activeSnack({ type: 'success', message: 'School-user Item deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolUserProfile = createAsyncThunk('school-user', async ({ dispatch }) => {
  try {
    const response = await get('/school-user');
    if (response) {
      useSetEncryptLocalData('isOnBoarded', response?.is_onboarded);
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSchoolUserProfile = createAsyncThunk(
  'school-user',
  async ({ state, dispatch }) => {
    try {
      const response = await put('/school-user', state);
      if (response) {
        const cred = {
          dispatch,
        };
        dispatch(getSchoolUserProfile(cred));
        dispatch(activeSnack({ type: 'success', message: 'Profile updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
