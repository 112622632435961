import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Card, Stack, CardHeader, CardContent } from '@mui/material';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

import classIcon from 'src/assets/labModel/stack.png';
import divisionIcon from 'src/assets/labModel/stack1.png';
import { getProgressionGraphData } from 'src/server/api/dashboard';

import AboutSchool from './about-school';
import AlertCenter from './alert-center';
import ClassAndDivision from './class-division';
import CourseStatistics from './courseStatistics';
import SchoolDashboardCard from './school-dashboard-card';
import CourseProgressionChart from './courseProgressionChart';

const SPACING = 2;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { dashboardData, schoolUserProfile, progressionData } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
    progressionData: state.dashboard.progressionData,
  }));

  const phoneNumber = schoolUserProfile?.school?.country_code
    ? `+${schoolUserProfile?.school?.country_code} ${schoolUserProfile?.school?.phone}`
    : schoolUserProfile?.school?.phone;

  const aboutSchoolData = {
    locationData: [
      {
        title: schoolUserProfile?.school?.address?.address_line1 || '',
        icon: <LocationOnIcon fontSize="small" />,
      },
      {
        title: `${schoolUserProfile?.school?.address?.address_line1 || ''},${
          schoolUserProfile?.school?.address?.pincode || ''
        }`,
        icon: <EmailRoundedIcon fontSize="small" />,
      },
      {
        title: `${schoolUserProfile?.school?.address?.district?._id ? schoolUserProfile?.school?.address?.district?.name : schoolUserProfile?.school?.address?.other_district},${schoolUserProfile?.school?.address?.state?._id ? schoolUserProfile?.school?.address?.state?.name : schoolUserProfile?.school?.address?.other_state}`,
        icon: <LocalPhoneRoundedIcon fontSize="small" />,
      },
    ],
    contactData: [
      {
        title: schoolUserProfile?.school?.email,
        icon: <EmailRoundedIcon fontSize="small" />,
      },
      {
        title: phoneNumber,
        icon: <LocalPhoneRoundedIcon fontSize="small" />,
      },
    ],
  };

  const classAndDivisionData = [
    {
      title: 'Total Classes in school',
      count: dashboardData?.totalSchoolClassesCount || 0,
      img: classIcon,
    },
    {
      title: 'Total Divisions in school',
      count: dashboardData?.totalSchoolDivisionsCount || 0,
      img: divisionIcon,
    },
  ];

  const list = [
    {
      type: 'onGoing',
      count: dashboardData?.totalIsCourseOngoingSchoolClass,
      title: `${dashboardData?.totalIsCourseOngoingSchoolClass} ${
        dashboardData?.totalIsCourseOngoingSchoolClass === 1 ? 'Class' : 'Classes'
      } not completed course yet!`,
    },
    {
      type: 'completed',
      count: dashboardData?.totalIsCourseCompletedSchoolClass,
      title: `${dashboardData?.totalIsCourseCompletedSchoolClass} ${
        dashboardData?.totalIsCourseCompletedSchoolClass === 1 ? 'Class' : 'Classes'
      } completed course successfully!`,
      // title: `${dashboardData?.totalIsCourseCompletedSchoolClass || 0} ${
      //   dashboardData?.totalIsCourseCompletedSchoolClass === 1 ? 'Class' : 'Classes'
      // } completed course succesfully!`,
    },
    // {
    //   type: 'exam',
    //   title: `2 Exams pending`,
    // },
    // {
    //   type: 'test',
    //   title: `Do it right now bruh`,
    // },
    // {
    //   type: 'new',
    //   title: `Do it right now bruh`,
    // },
  ];

  useEffect(() => {
    dispatch(getProgressionGraphData({ enqueueSnackbar }));
  }, [dispatch, enqueueSnackbar]);

  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid xs={12} sm={12} md={6}>
        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mb: 2, display: 'flex', gap: '1rem' }}>
          <SchoolDashboardCard post={schoolUserProfile} />
        </Stack>
        <AboutSchool title="About School" data={aboutSchoolData} />
      </Grid>

      <Grid md={6} xs={12} sm={12}>
        <CourseStatistics
          title="Course Statistics"
          chart={{
            series: [
              { label: 'Ongoing', value: dashboardData?.totalOnGoingSchoolCoursesCount || 0 },
              { label: 'Completed', value: dashboardData?.totalCompletedSchoolCoursesCount || 0 },
            ],
          }}
          // sx={{ height: '380px' }}
        />
      </Grid>
      {classAndDivisionData?.map((item) => (
        <Grid md={6} xs={12}>
          <ClassAndDivision title={item?.title} total={item?.count} icon={item?.img} />
        </Grid>
      ))}
      <Grid item md={8} xs={12}>
        <Card sx={{ minHeight: '450px' }}>
          <CardHeader title="Course Progression Graph" subheader="By Classes" />
          <CardContent>
            <CourseProgressionChart
              series={[
                {
                  name: 'Percentage of Course Progression',
                  data: progressionData?.map((item) => item?.percentage),
                },
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <AlertCenter title="Alert Center" list={list} sx={{ minHeight: '450px' }} />
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
