import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createResource = createAsyncThunk(
  'resource/create',
  async ({ state, dispatch, handleClose, handleClear, setCategory }) => {
    console.log('all formdatas', state);

    try {
      const URL = `/resources/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: 1,
          search: '',
          limit: 18,
          category: response?.category,
          dispatch,
        };
        dispatch(getAllResources(cred));
        if (setCategory) {
          setCategory(response?.category);
        }
        dispatch(activeSnack({ type: 'success', message: 'Resource created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllResources = createAsyncThunk(
  'resource/list',
  async ({ page, search, dispatch, fileType, limit, category, labModel, course, exam }) => {
    try {
      const response = await get(
        `/resources/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&category=${category || ''}&lab_models=${labModel || ''}&courses=${course || ''}&exam=${
          exam || ''
        }&media_type=${fileType || ''}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getResourceDetails = createAsyncThunk(
  'resource/single',
  async ({ resourceId, dispatch }) => {
    try {
      const response = await get(`/resource/admin/${resourceId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateResourceDetails = createAsyncThunk(
  'resource/update',
  async ({ state, resourceId, dispatch, handleClose, handleClear, setCategory }) => {
    console.log('all formdatas', state);

    try {
      const URL = `/resources/admin/${resourceId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          search: '',
          limit: 18,
          category: response?.category,
          dispatch,
        };
        dispatch(getAllResources(cred));
        if (setCategory) {
          setCategory(response?.category);
        }
        dispatch(activeSnack({ type: 'success', message: 'Resource updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteResource = createAsyncThunk(
  'resource/delete',
  async ({ resourceId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/resources/admin/${resourceId}/`);
      if (response) {
        handleCloseDeleteDialog();
        const cred = {
          page: 1,
          search: '',
          limit: 18,
          category: response?.category,
          dispatch,
        };
        dispatch(getAllResources(cred));
        dispatch(activeSnack({ type: 'success', message: 'Resource deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
