import { useState, useCallback } from 'react';

// ----------------------------------------------------------------------

export default function useCountryCodePopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    open: Boolean(anchorEl),
    anchorEl,
    onClose,
    onOpen,
    setOpen: setAnchorEl,
  };
}
